interface GlobalJobBoardNavbarMenuItemsDataTypes {
  name: string;
  linkTo: string;
}

export const GlobalJobBoardNavbarMenuItemsData: GlobalJobBoardNavbarMenuItemsDataTypes[] =
  [
    {
      name: 'Find a Job',
      linkTo: '/global',
    },
    {
      name: 'Hire Me',
      linkTo: '#',
    },
    {
      name: 'Companies',
      linkTo: '#',
    },
    {
      name: 'How it Works',
      linkTo: '#',
    },
    {
      name: 'Blog',
      linkTo: '#',
    },
  ];
