import React from 'react';
import {
  StyledReportingTabDescriptionAndValue,
  StyledReportingTabIconAndArrow,
  StyledReportingTabMainWrapper,
  StyledReportingTabTitleAndData,
  StyledValueWrapper,
} from './tab.styled';
import ReportingEmployeesTabIcon from '../../../../../icons/reporting-employees-tab-icons';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';

interface TabProps {
  title: string;
  description: string;
  count: string | number;
  trending: string; // "up" or "down"
  icon: string;
}

const Tab: React.FC<TabProps> = ({
  title,
  description,
  count,
  trending,
  icon,
}) => {
  return (
    <StyledReportingTabMainWrapper>
      <StyledReportingTabIconAndArrow>
        <ReportingEmployeesTabIcon iconType={icon} />
        <ReportingEmployeesTabIcon iconType="rightArrow" />
      </StyledReportingTabIconAndArrow>
      <StyledReportingTabTitleAndData>
        <Text
          fontSize={20}
          fontFamily={font.family}
          color={colors.base_black}
          fontWeight={700}
          margin="0"
        >
          {title}
        </Text>
        <StyledReportingTabDescriptionAndValue>
          <Text
            fontSize={14}
            fontFamily={font.family}
            color={colors.base_black}
            fontWeight={400}
            margin="0"
          >
            {description}
          </Text>
          {count !== '' && (
            <StyledValueWrapper
              backgroundColor={
                trending === 'up' ? colors.light_green : colors.primary_50
              }
            >
              <Text
                fontSize={14}
                fontFamily={font.family}
                color={
                  trending === 'up' ? colors.forest_green : colors.primary_500
                }
                fontWeight={700}
                margin="0"
              >
                {count}
              </Text>
              <ReportingEmployeesTabIcon
                iconType={
                  trending === 'up' ? 'mainTabStatusUp' : 'mainTabStatusDown'
                }
              />
            </StyledValueWrapper>
          )}
        </StyledReportingTabDescriptionAndValue>
      </StyledReportingTabTitleAndData>
    </StyledReportingTabMainWrapper>
  );
};

export default Tab;
