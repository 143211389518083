import React, { useState } from 'react';
import {
  StyledGlobalJobBoardHeroSearchBtn,
  StyledGlobalJobBoardHeroSearchInput,
  StyledGlobalJobBoardHeroSearchInputAndIconWrapper,
  StyledGlobalJobBoardHeroSearchInputIconWrapper,
  StyledGlobalJobBoardHeroSearchInputSeparator,
  StyledGlobalJobBoardHeroSearchWrapper,
  StyledGlobalJobBoardHeroTitleAndSearchMainWrapper,
  StyledGlobalJobBoardHeroTitleWrapper,
} from './global-job-board-hero-left-component.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import { LightGreySearchIcon } from '../../../../../../../icons/light-grey-search-icon';
import { LightGreyLocationIcon } from '../../../../../../../icons/light-grey-location-icon';
import { useJobListings } from '../../../../hooks/job-listings-context';

const GlobalJobBoardHeroLeftComponent: React.FC = () => {
  const { searchJobs } = useJobListings();
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');

  const handleSearch = () => {
    searchJobs(jobTitle, location);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <StyledGlobalJobBoardHeroTitleAndSearchMainWrapper>
      <StyledGlobalJobBoardHeroTitleWrapper>
        <div>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={72}
            fontWeight={600}
            lineHeight="79.2px"
            color={colors.base_black}
            margin="0"
          >
            Empower your
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={72}
            fontWeight={600}
            lineHeight="79.2px"
            color={colors.base_black}
            margin="0"
          >
            Career&nbsp;
            <span style={{ color: colors.secondary_500 }}>Journey</span>
          </Text>
        </div>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={18}
          fontWeight={400}
          lineHeight="26px"
          color={colors.base_black}
          margin="0"
        >
          Search and find your dream job now easier than ever,
          <br />
          you can simply browse and find a job!
        </Text>
      </StyledGlobalJobBoardHeroTitleWrapper>
      <StyledGlobalJobBoardHeroSearchWrapper>
        <StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
          <StyledGlobalJobBoardHeroSearchInput
            type="text"
            placeholder="Job title"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <StyledGlobalJobBoardHeroSearchInputIconWrapper>
            <LightGreySearchIcon />
          </StyledGlobalJobBoardHeroSearchInputIconWrapper>
        </StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
        <StyledGlobalJobBoardHeroSearchInputSeparator />
        <StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
          <StyledGlobalJobBoardHeroSearchInput
            type="text"
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <StyledGlobalJobBoardHeroSearchInputIconWrapper>
            <LightGreyLocationIcon />
          </StyledGlobalJobBoardHeroSearchInputIconWrapper>
        </StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
        <StyledGlobalJobBoardHeroSearchBtn onClick={handleSearch}>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={500}
            lineHeight="19.5px"
            color={colors.white}
            margin="0"
          >
            Search
          </Text>
        </StyledGlobalJobBoardHeroSearchBtn>
      </StyledGlobalJobBoardHeroSearchWrapper>
    </StyledGlobalJobBoardHeroTitleAndSearchMainWrapper>
  );
};

export default GlobalJobBoardHeroLeftComponent;
