import styled, { keyframes, css } from 'styled-components';

const fillAnimation = (light: number, dark: number) => keyframes`
  0% {
    background: conic-gradient(
      transparent 0deg,
      transparent 0deg,
      transparent 0deg 360deg
    );
  }
  100% {
    background: conic-gradient(
      lightblue 0deg,
      lightblue ${light}deg,
      darkblue ${light}deg ${light + dark}deg,
      #8a8acb ${light + dark}deg 360deg
    );
  }
`;

const rotateDuringFill = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const PieChartWrapper = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 200px;
  height: 200px;
`;

export const RotatingWrapper = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  animation: ${rotateDuringFill} 1s linear forwards;
`;

export const Circle = styled.div<{ light: number; dark: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    transparent 0deg,
    transparent 0deg,
    transparent 0deg 360deg
  );
  will-change: background;
  animation: ${({ light, dark }) =>
    css`
      ${fillAnimation(light, dark)} 1s linear forwards
    `};
`;

export const InnerCircle = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 1;
`;

export const ViewsText = styled.div`
  position: absolute;
  text-align: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
