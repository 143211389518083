import React from 'react';

const ClockIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="13" r="7" stroke="#33363F" />
      <path d="M5 5L3 7" stroke="#33363F" strokeLinecap="round" />
      <path d="M19 5L21 7" stroke="#33363F" strokeLinecap="round" />
      <path
        d="M9 11L11.8093 12.8729C11.9172 12.9448 12.0622 12.9223 12.1432 12.821L14 10.5"
        stroke="#33363F"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ClockIcon;
