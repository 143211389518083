import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';

export const FeaturesContainer = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.h2`
  font-size: 40px;
  padding-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #f9846f29;
  text-decoration-thickness: 6px;
  text-underline-offset: 2px;

  ${media.laptop(`
    font-size: 28px;
    text-decoration: underline 5px #f9846f29;
    text-underline-offset: 7px;
    width: 80%;
  `)}
`;

export const Cards = styled.div`
  width: 90%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  ${media.laptop(`
    width: 80%;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
  `)}
`;
