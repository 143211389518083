import { useState } from 'react';
import { ReportingBarAndMultiLineChartDataTypes } from '../../components/charts/charts-data-types';
import { ReportingTableState } from '../../components/tables/reporting-employees-table/reporting-employees-table';

interface ReportingSalariesTablePayrollOverviewDataTypes {
  name: string;
  grossPay: string;
  netPay: string;
  taxesDeducted: string;
  benefitsDeducted: string;
  overtimePay: string;
  department: string;
}

interface ReportingSalariesTableSalaryTrendsDataTypes {
  name: string;
  historicalSalary: string;
  dateOfLastIncrease: string;
  percentIncrease: string;
  jobTitle: string;
}

interface ReportingSalariesTableCompensationComparationTypes {
  jobTitle: string;
  averageCompanySalary: string;
  industryStandardSalary: string;
  regionalAverageSalary: string;
}

export const useReportingSalariesData = () => {
  const [multiLineChartData, setMultiLineChartData] = useState<
    ReportingBarAndMultiLineChartDataTypes[]
  >([
    { name: 'Jan', avg: 10, max: 40, min: 5 },
    { name: 'Feb', avg: 20, max: 50, min: 10 },
    { name: 'Mar', avg: 30, max: 60, min: 15 },
    { name: 'Apr', avg: 25, max: 55, min: 20 },
    { name: 'May', avg: 35, max: 70, min: 25 },
    { name: 'Jun', avg: 30, max: 60, min: 20 },
    { name: 'Jul', avg: 40, max: 80, min: 30 },
    { name: 'Aug', avg: 35, max: 65, min: 25 },
    { name: 'Sep', avg: 40, max: 70, min: 35 },
    { name: 'Oct', avg: 30, max: 60, min: 20 },
    { name: 'Nov', avg: 35, max: 55, min: 25 },
    { name: 'Dec', avg: 25, max: 50, min: 15 },
  ]);

  const [
    reportingSalariesTablePayrollOverviewData,
    setReportingSalariesTablePayrollOverviewData,
  ] = useState<
    ReportingTableState<ReportingSalariesTablePayrollOverviewDataTypes>
  >({
    headers: [
      'Name',
      'Gross Pay',
      'Net Pay',
      'Taxes Deducted',
      'Benefits Deducted',
      'Overtime Pay',
      'Department',
    ],

    data: [
      {
        name: 'John Don',
        grossPay: 'Lorem ipsum ',
        netPay: 'Lorem ipsum ',
        taxesDeducted: 'Lorem ipsum ',
        benefitsDeducted: 'Lorem ipsum ',
        overtimePay: 'Lorem ipsum ',
        department: 'Lorem ipsum dolar',
      },
    ],
  });

  const [
    reportingSalariesTableSalaryTrendsData,
    setReportingSalariesTableSalaryTrendswData,
  ] = useState<
    ReportingTableState<ReportingSalariesTableSalaryTrendsDataTypes>
  >({
    headers: [
      'Name',
      'Historical Salary',
      'Date of Last Increase',
      'Percent Increase',
      'Job Title',
    ],

    data: [
      {
        name: 'John Don',
        historicalSalary: 'Lorem ipsum ',
        dateOfLastIncrease: 'Lorem ipsum ',
        percentIncrease: 'Lorem ipsum ',
        jobTitle: 'Lorem ipsum ',
      },
    ],
  });

  const [
    reportingSalariesTableCompensationComparation,
    setReportingSalariesTableCompensationComparation,
  ] = useState<
    ReportingTableState<ReportingSalariesTableCompensationComparationTypes>
  >({
    headers: [
      'Job Title',
      'Average Company Salary',
      'Industry Standard Salary',
      'Regional Average Salary',
    ],

    data: [
      {
        jobTitle: 'React Developer',
        averageCompanySalary: 'Lorem ipsum ',
        industryStandardSalary: 'Lorem ipsum ',
        regionalAverageSalary: 'Lorem ipsum ',
      },
    ],
  });

  return {
    multiLineChartData,
    reportingSalariesTablePayrollOverviewData,
    reportingSalariesTableSalaryTrendsData,
    reportingSalariesTableCompensationComparation,
    setMultiLineChartData,
    setReportingSalariesTablePayrollOverviewData,
    setReportingSalariesTableSalaryTrendswData,
    setReportingSalariesTableCompensationComparation,
  };
};
