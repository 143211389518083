import React, { useState } from 'react';
import axios from 'axios';
import {
  StyledColorTemplate,
  StyledColorTemplateName,
  StyledColorTemplatesWrapper,
  StyledSettingsChangeColorsMainWrapper,
  StyledColorTemplatePreview,
  StyledFirstColorCircle,
  StyledColorCircle,
  StyledSaveButton,
} from '../../../settings/components/tabs/components/tabs/change-colors/change-colors.styled';
import { Text, font } from '../../../../../style-utils/text';
import templates from '../../../../../style-utils/color-templates';
import { apiRoute } from '../../../../../constants/api-constants';
import { colors } from '../../../../../style-utils/colors';
import MessagePopup from '../../../../messages/message-popup/message-popup';
import { Cookie } from 'universal-cookie';

interface PaymentProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  cookies: Cookie;
}

const Payment: React.FC<PaymentProps> = ({ user, cookies, setUser }) => {
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(
    user.color_template && user.color_template.trim() !== ''
      ? user.color_template
      : 'Color 01'
  );

  const removeAllAuthorizationTokens = () => {
    const cookies = document.cookie.split('; ');

    cookies.forEach((cookie) => {
      const [name] = cookie.split('=');
      if (name.trim() === 'authorization_token') {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    });
  };

  const handleSave = () => {
    axios
      .post(`${apiRoute}/color-template`, {
        id: user.id,
        colorTemplate: selectedTemplate,
      })
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data.user);
          if (cookies) {
            removeAllAuthorizationTokens();
            cookies.set('authorization_token', response.data.token, {
              path: '/',
            });
          }
          console.log('Color template updated successfully');
          setPopUpVisible(true);
          setTimeout(() => {
            setPopUpVisible(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error('Error updating color template:', error);
        console.log('An error occurred while updating the color template');
      });
  };

  return (
    <StyledSettingsChangeColorsMainWrapper>
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={20}
        fontWeight={700}
        color={colors.base_black}
      >
        Choose template
      </Text>
      <StyledColorTemplatesWrapper>
        {Object.keys(templates).map((templateName) => (
          <StyledColorTemplate
            key={templateName}
            onClick={() => setSelectedTemplate(templateName)}
            isTemplateSelected={selectedTemplate === templateName}
          >
            <StyledColorTemplateName>{templateName}</StyledColorTemplateName>
            <StyledColorTemplatePreview>
              <StyledFirstColorCircle
                color={templates[templateName]['text_color']}
              />
              <StyledColorCircle
                color={templates[templateName]['background_fill_color']}
              />
              <StyledColorCircle
                color={templates[templateName]['background_color']}
              />
            </StyledColorTemplatePreview>
          </StyledColorTemplate>
        ))}
      </StyledColorTemplatesWrapper>
      <StyledSaveButton onClick={handleSave}>Save</StyledSaveButton>
      {popUpVisible && (
        <MessagePopup
          text="Color template updated successfully"
          type="success"
        />
      )}
    </StyledSettingsChangeColorsMainWrapper>
  );
};

export default Payment;
