import React, { useEffect, useRef, useState } from 'react';
import {
  EditBtn,
  EmployeeAttachButton,
  EmployeeDetailHeader,
  EmployeeDetailStyledInput,
  EmployeeDetailsWrapper,
  EmployeeTileOffApplicationButton,
  StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper,
} from '../employee.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { PlusIcon } from '../../../icons/plus-icon-small';
import { EmployeeEducationDetailsData } from '../../../../interfaces/employee-interfaces';
import MessagePopup from '../../../messages/message-popup/message-popup';
import {
  StyledEducationDetailCircle,
  StyledEducationDetailLeftItemWrapper,
  StyledEducationDetailWrapper,
  StyledEducationRightSideItemEducationDetailsWrapper,
  StyledEducationRightSideItemEducationRangeWrapper,
  StyledEducationRightSideItemWrapper,
  StyledEmployeeEducationTabDetailsWrapper,
  StyledImageAndTitleWrapper,
  StyledTitleImage,
  StyledVerticalLine,
} from './employee-education-tab.styled';
import EmployeeEducationEetailsTitleIcon from '../../../icons/employee_education_details_title_icon';
import AddEmployeeEducation from './Add-employee-education/add-employee-education';
import { NewEmployeeDataTypes } from '../add-employee';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import { EditIcon } from '../../../icons/edit-icon';
import { AddEmployeeStyledButton } from '../add-employee.styled';
import { WrapperDiv } from '../../jobs/jobs.styled';

interface EmployeeEducationTabProps {
  employee: NewEmployeeDataTypes;
  handleToggleEditMode: (toggleFor: string) => void;
  educationDetailsEditMode: boolean;
}

type EducationField = keyof EmployeeEducationDetailsData;

const EmployeeEducationTab: React.FC<EmployeeEducationTabProps> = ({
  employee,
  handleToggleEditMode,
  educationDetailsEditMode,
}) => {
  const [addNewEducationDetail, setAddNewEducationDetail] =
    useState<boolean>(false);
  const [educationDetails, setEducationDetails] = useState<
    EmployeeEducationDetailsData[]
  >([]);
  const [editInitialEducationValues, setEditInitialEducationValues] = useState<
    EmployeeEducationDetailsData[]
  >([]);

  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [maxWidth, setMaxWidth] = useState(0);
  const rangeRefs = useRef<(HTMLDivElement | null)[]>([]);

  const fetchEmployeeEducationDetails = () => {
    axios
      .get(`${apiRoute}/employee/${employee.id}/education`)
      .then((response) => {
        setEducationDetails(response.data);
        setEditInitialEducationValues(response.data);
      })
      .catch((error) => {
        console.error('Error fetching education details:', error);
        setPopUp({ text: 'Failed to load education details', type: 'error' });
      });
  };

  useEffect(() => {
    fetchEmployeeEducationDetails();
  }, [employee.id]);

  useEffect(() => {
    if (rangeRefs.current.length > 0) {
      const widths = rangeRefs.current.map((ref) => ref?.offsetWidth || 0);
      const widest = Math.max(...widths);
      setMaxWidth(widest);
    }
  }, [educationDetails]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  const handleEditEducation = async () => {
    try {
      const promises = editInitialEducationValues.map((educationDetail) => {
        const formData = new FormData();
        formData.append('id', educationDetail.id);
        formData.append('type', educationDetail.type);
        formData.append('institution_name', educationDetail.institution_name);
        formData.append('duration', educationDetail.duration);

        return axios.put(`${apiRoute}/employee/${employee.id}/edit-education`, {
          id: educationDetail.id,
          type: educationDetail.type,
          institution_name: educationDetail.institution_name,
          duration: educationDetail.duration,
        });
      });

      await Promise.all(promises);

      setPopUp({
        text: 'Education details updated successfully',
        type: 'success',
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios error:', axiosError.message);
        if (axiosError.response) {
          console.error('Response error:', axiosError.response.data);
        } else if (axiosError.request) {
          console.error('Request error:', axiosError.request);
        }
      } else {
        console.error('Non-Axios error:', error);
      }
    } finally {
      handleToggleEditMode('education');
    }
  };

  const handleInputChange = (
    index: number,
    field: EducationField,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedValues = [...editInitialEducationValues];
    updatedValues[index][field] = e.target.value;
    setEditInitialEducationValues(updatedValues);
  };

  return (
    <EmployeeDetailsWrapper>
      {educationDetails.length === 0 && (
        <EmployeeDetailHeader>
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_black}
            fontSize={24}
            fontWeight={700}
            lineHeight="28.8px"
          >
            No Education Details
          </Text>
          <StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
            <EmployeeTileOffApplicationButton
              onClick={() => setAddNewEducationDetail(true)}
            >
              <PlusIcon />
              Add New
            </EmployeeTileOffApplicationButton>
          </StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
        </EmployeeDetailHeader>
      )}
      {educationDetails.length > 0 && (
        <StyledEmployeeEducationTabDetailsWrapper>
          <EmployeeDetailHeader>
            <StyledImageAndTitleWrapper>
              <StyledTitleImage>
                <EmployeeEducationEetailsTitleIcon />
              </StyledTitleImage>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.primary_500}
                fontSize={40}
                fontWeight={700}
                lineHeight="60px"
              >
                Education
              </Text>
            </StyledImageAndTitleWrapper>
            <StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
              <EmployeeAttachButton
                onClick={() => setAddNewEducationDetail(true)}
              >
                <PlusIcon />
                Add New
              </EmployeeAttachButton>
              <EditBtn onClick={() => handleToggleEditMode('education')}>
                Edit <EditIcon />
              </EditBtn>
            </StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
          </EmployeeDetailHeader>
          {educationDetails.map((educationDetail, index) => (
            <StyledEducationDetailWrapper key={educationDetail.id}>
              <StyledEducationDetailLeftItemWrapper>
                <StyledVerticalLine />
                <StyledEducationDetailCircle />
              </StyledEducationDetailLeftItemWrapper>
              <StyledEducationRightSideItemWrapper>
                <StyledEducationRightSideItemEducationRangeWrapper
                  ref={(el) => (rangeRefs.current[index] = el)}
                  style={{ width: maxWidth ? `${maxWidth}px` : 'auto' }}
                >
                  {!educationDetailsEditMode ? (
                    <Text
                      fontFamily={font.family}
                      margin="0"
                      color={colors.base_black}
                      fontSize={20}
                      fontWeight={300}
                      lineHeight="48px"
                    >
                      {educationDetail.duration}
                    </Text>
                  ) : (
                    <WrapperDiv flexDirection="column">
                      <Text
                        fontFamily="Lato"
                        margin="0"
                        fontSize={14}
                        fontWeight={500}
                      >
                        Duration:
                      </Text>
                      <EmployeeDetailStyledInput
                        type="text"
                        value={
                          editInitialEducationValues[index]?.duration || ''
                        }
                        onChange={(e) =>
                          handleInputChange(index, 'duration', e)
                        }
                      />
                    </WrapperDiv>
                  )}
                </StyledEducationRightSideItemEducationRangeWrapper>
                <StyledEducationRightSideItemEducationDetailsWrapper>
                  {!educationDetailsEditMode ? (
                    <>
                      <Text
                        fontFamily={font.family}
                        margin="0"
                        color={colors.primary_500}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight="6px"
                      >
                        {educationDetail.type}
                      </Text>
                      <Text
                        fontFamily={font.family}
                        margin="0"
                        color={colors.base_black}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight="24px"
                      >
                        {educationDetail.institution_name}
                      </Text>
                    </>
                  ) : (
                    <div style={{ marginLeft: 110 }}>
                      <WrapperDiv flexDirection="column" mb="10px">
                        <Text
                          fontFamily="Lato"
                          margin="0"
                          fontSize={14}
                          fontWeight={500}
                        >
                          Institution name:
                        </Text>
                        <EmployeeDetailStyledInput
                          type="text"
                          value={editInitialEducationValues[index]?.type || ''}
                          onChange={(e) => handleInputChange(index, 'type', e)}
                        />
                      </WrapperDiv>
                      <WrapperDiv flexDirection="column">
                        <Text
                          fontFamily="Lato"
                          margin="0"
                          fontSize={14}
                          fontWeight={500}
                        >
                          Education type:
                        </Text>
                        <EmployeeDetailStyledInput
                          type="text"
                          value={
                            editInitialEducationValues[index]
                              ?.institution_name || ''
                          }
                          onChange={(e) =>
                            handleInputChange(index, 'institution_name', e)
                          }
                        />
                      </WrapperDiv>
                    </div>
                  )}
                </StyledEducationRightSideItemEducationDetailsWrapper>
              </StyledEducationRightSideItemWrapper>
            </StyledEducationDetailWrapper>
          ))}
          {educationDetailsEditMode && (
            <WrapperDiv mt="16px" justifyContent="end">
              <AddEmployeeStyledButton
                bgColor={colors.primary_400}
                borderRadius="4px"
                padding="8px 40px"
                color={colors.base_white}
                type="button"
                onClick={() => handleToggleEditMode('education')}
              >
                Cancel
              </AddEmployeeStyledButton>
              <AddEmployeeStyledButton
                bgColor={colors.primary_600}
                borderRadius="4px"
                padding="8px 40px"
                color={colors.base_white}
                type="button"
                onClick={handleEditEducation}
              >
                Save
              </AddEmployeeStyledButton>
            </WrapperDiv>
          )}
        </StyledEmployeeEducationTabDetailsWrapper>
      )}
      {addNewEducationDetail && (
        <AddEmployeeEducation
          setAddNewEducationDetail={setAddNewEducationDetail}
          employee={employee}
          setPopUp={setPopUp}
          fetchEmployeeEducationDetails={fetchEmployeeEducationDetails}
        />
      )}
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </EmployeeDetailsWrapper>
  );
};

export default EmployeeEducationTab;
