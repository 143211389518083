import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Company } from '../../../../app-router/component-props';
import {
  StyledCompanyDataGrid,
  StyledCompanyDataGridItem,
  StyledCompanyDataWrapper,
  StyledCompanyImage,
  StyledCompanyInfoAndImageFormWrapper,
  StyledCompanyInfoWrapper,
  StyledCompanyProfilePictureAndTextWrapper,
  StyledEditBtn,
  StyledEditCompanyInput,
  StyledInputAndErrorMsgWrapper,
  StyledSubmitBtn,
  StyledUploadProfilePictureHiddenInput,
} from './company-info.styled';
import { Text, font } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import { SettingsEditIcon } from '../../../../icons/settings-edit-icon';
import CompanyDemoImage from '../../../../../assets/images/img_avatar.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SettingsWebsiteIcon } from '../../../../icons/settings-website-icon';
import { SettingsAddressIcon } from '../../../../icons/settings-address-icon';
import { SettingsPhoneIcon } from '../../../../icons/settings-phone-icon';
import { SettingsTaxNumberIcon } from '../../../../icons/settings-taxnumber-icon';
import { SettingsDescriptionIcon } from '../../../../icons/settings-description-icon';
import { SettingsEmailIcon } from '../../../../icons/settings-email-icon';
import axios from 'axios';
import { apiRoute } from '../../../../../constants/api-constants';
import MessagePopup from '../../../../messages/message-popup/message-popup';

interface PropsTypes {
  user: Company;
  setEditCompanyInfoVisible: Dispatch<SetStateAction<boolean>>;
  fetchCompanyData: () => void;
}

export const editCompanyInfoValidationSchema = yup.object().shape({
  id: yup.string().required('ID is required!'),
  name: yup.string().required('Name is required!'),
  description: yup.string().required('Description is required!'),
  email: yup
    .string()
    .required('Email is required!')
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'Please enter a valid email address'
    ),
  website: yup.string().required('Website is required!'),
  address: yup.string().required('Address is required!'),
  phone_number: yup
    .string()
    .required('Phone number is required!')
    .matches(
      /^\+\d+$/,
      'Please enter a valid phone number, for example: +38975000000'
    ),
  tax_number: yup.string().required('Tax number is required!'),
});

const EditCompanyInfo: React.FC<PropsTypes> = ({
  user,
  setEditCompanyInfoVisible,
  fetchCompanyData,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);

  const editCompanyInfoInitValues: Company = {
    id: user.id || '',
    name: user.name || '/',
    description: user.description || '/',
    email: user.email || '/',
    website: user.website || '/',
    address: user.address || '/',
    phone_number: user.phone_number || '/',
    tax_number: user.tax_number || '/',
  };

  const formik = useFormik({
    initialValues: editCompanyInfoInitValues,
    validationSchema: editCompanyInfoValidationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: (values) => {
      console.log('Submitted values', values);
      updateCompanyInfo(values);
    },
  });

  function updateCompanyInfo(values: Company) {
    // Create FormData to handle both file and text data
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('name', values.name);
    formData.append('description', values.description || '');
    formData.append('email', values.email || '');
    formData.append('website', values.website || '');
    formData.append('address', values.address || '');
    formData.append('phone_number', values.phone_number || '');
    formData.append('tax_number', values.tax_number || '');

    // If a profile picture is selected, append it to the FormData
    if (profilePicture) {
      formData.append('profile_picture', profilePicture);
    }

    axios
      .put(`${apiRoute}/update-company`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Company information updated successfully:', response.data);
        setPopUp({
          text: 'Company profile updated successfully',
          type: 'success',
        });
        fetchCompanyData();
        setEditCompanyInfoVisible(false);
      })
      .catch((error) => {
        console.error(
          'Error updating company information:',
          error.response?.data?.message || error.message
        );
        setPopUp({
          text: error.response?.data?.message || error.message,
          type: 'error',
        });
      });
  }

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <StyledCompanyInfoWrapper>
      <StyledEditBtn onClick={() => setEditCompanyInfoVisible(false)}>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={14}
          fontWeight={700}
          color={colors.base_black}
        >
          Cancel editing
        </Text>
        <SettingsEditIcon />
      </StyledEditBtn>
      <StyledCompanyInfoAndImageFormWrapper
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <StyledCompanyProfilePictureAndTextWrapper>
          <StyledCompanyImage
            fromPage="edit"
            onClick={handleImageClick}
            src={
              profilePicture
                ? URL.createObjectURL(profilePicture)
                : user.profile_picture || CompanyDemoImage
            }
          />
          <StyledUploadProfilePictureHiddenInput
            type="file"
            name="profile_picture"
            ref={fileInputRef}
            onChange={(e) => {
              if (e.currentTarget.files) {
                setProfilePicture(e.currentTarget.files[0]);
              }
            }}
            accept="image/*"
          />
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={10}
            fontWeight={700}
            color={colors.primary_500}
          >
            Click on the image to select a new one
          </Text>
        </StyledCompanyProfilePictureAndTextWrapper>
        <StyledCompanyDataWrapper>
          <StyledInputAndErrorMsgWrapper>
            <StyledEditCompanyInput
              type="text"
              name="name"
              value={formik.values.name}
              isName={true}
            />
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={10}
              fontWeight={700}
              color={colors.error}
            >
              {formik.errors.name}
            </Text>
          </StyledInputAndErrorMsgWrapper>
          <StyledCompanyDataGrid>
            <StyledInputAndErrorMsgWrapper>
              <StyledCompanyDataGridItem>
                <SettingsDescriptionIcon />
                <StyledEditCompanyInput
                  type="text"
                  name="description"
                  value={formik.values.description}
                />
              </StyledCompanyDataGridItem>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={10}
                fontWeight={700}
                color={colors.error}
                pl={50}
              >
                {formik.errors.description}
              </Text>
            </StyledInputAndErrorMsgWrapper>
            <StyledInputAndErrorMsgWrapper>
              <StyledCompanyDataGridItem>
                <SettingsEmailIcon />
                <StyledEditCompanyInput
                  type="text"
                  name="email"
                  value={formik.values.email}
                />
              </StyledCompanyDataGridItem>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={10}
                fontWeight={700}
                color={colors.error}
                pl={50}
              >
                {formik.errors.email}
              </Text>
            </StyledInputAndErrorMsgWrapper>
            <StyledInputAndErrorMsgWrapper>
              <StyledCompanyDataGridItem>
                <SettingsWebsiteIcon />
                <StyledEditCompanyInput
                  type="text"
                  name="website"
                  value={formik.values.website}
                />
              </StyledCompanyDataGridItem>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={10}
                fontWeight={700}
                color={colors.error}
                pl={50}
              >
                {formik.errors.website}
              </Text>
            </StyledInputAndErrorMsgWrapper>
            <StyledInputAndErrorMsgWrapper>
              <StyledCompanyDataGridItem>
                <SettingsAddressIcon />
                <StyledEditCompanyInput
                  type="text"
                  name="address"
                  value={formik.values.address}
                />
              </StyledCompanyDataGridItem>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={10}
                fontWeight={700}
                color={colors.error}
                pl={50}
              >
                {formik.errors.address}
              </Text>
            </StyledInputAndErrorMsgWrapper>
            <StyledInputAndErrorMsgWrapper>
              <StyledCompanyDataGridItem>
                <SettingsPhoneIcon />
                <StyledEditCompanyInput
                  type="text"
                  name="phone_number"
                  value={formik.values.phone_number}
                />
              </StyledCompanyDataGridItem>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={10}
                fontWeight={700}
                color={colors.error}
                pl={50}
              >
                {formik.errors.phone_number}
              </Text>
            </StyledInputAndErrorMsgWrapper>
            <StyledInputAndErrorMsgWrapper>
              <StyledCompanyDataGridItem>
                <SettingsTaxNumberIcon />
                <StyledEditCompanyInput
                  type="text"
                  name="tax_number"
                  value={formik.values.tax_number}
                />
              </StyledCompanyDataGridItem>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={10}
                fontWeight={700}
                color={colors.error}
                pl={50}
              >
                {formik.errors.tax_number}
              </Text>
            </StyledInputAndErrorMsgWrapper>
          </StyledCompanyDataGrid>
          <StyledSubmitBtn
            type="submit"
            onClick={() => setValidateOnChange(true)}
          >
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={16}
              fontWeight={700}
              color={colors.base_white}
            >
              Save
            </Text>
          </StyledSubmitBtn>
        </StyledCompanyDataWrapper>
      </StyledCompanyInfoAndImageFormWrapper>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </StyledCompanyInfoWrapper>
  );
};

export default EditCompanyInfo;
