import React, { useState } from 'react';
import { useJobOffer } from '../../hooks/global-job-offer-provider';
import {
  DeleteButton,
  InputErrorMsgWrapper,
  StyledArrowIconWrapper,
  StyledBackBtn,
  StyledCreateNewJobForm,
  StyledCustomSkillInput,
  StyledDropDownMenuSelectInput,
  StyledFlowStepWrapper,
  StyledFormInputAndErrorWrapper,
  StyledGridInputsWrapper,
  StyledInputAndLabelWrapper,
  StyledNavButtonsWrapper,
  StyledSelectInputAndArrowIconWrapper,
  StyledSelectedSkill,
  StyledSelectedSkillsWrapper,
  StyledStepIndicatorWrapper,
  StyledSubmitBtn,
  StyledTextInput,
  StyledTitleAndPageIndicatorWrapper,
} from '../../create-job-offer-flow.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { useFormik } from 'formik';
import { CreateNewJobSecondStepTypes } from '../../../../../../interfaces/job-interfaces';
import { createNewJobSecondStepSchema } from '../../validation-schemas';
import { colors } from '../../../../../../style-utils/colors';
import { SelectFieldDownArrowIcon } from '../../../../../icons/select-field-down-arrow-icon';
import { ErrorMsgLabelIcon } from '../../../../../icons/error-label-icon';
import { BackArrow } from '../../../../../icons/back-arrow';
import StepIndicator from '../../step-indicator/step-indicator';
import { TextEditor } from '../../../../../styled-quill/text-editor';

const predefinedSkills = [
  'JavaScript',
  'Java',
  'PHP',
  'Python',
  'NodeJS',
  'React',
  'Angular',
  'Vue',
  'SQL',
  'PostgreSQL',
  'MongoDB',
  'Firebase',
  'NextJS',
];

interface SecondStepFormPropsTypes {
  handleNextStep: () => void;
  handlePrevStep: () => void;
}

export const AdditionalInformations: React.FC<SecondStepFormPropsTypes> = ({
  handleNextStep,
  handlePrevStep,
}) => {
  const { jobOffer, updateJobOffer, key } = useJobOffer();
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [customSkillInput, setCustomSkillInput] = useState<string>('');

  const secondStepInitValues: CreateNewJobSecondStepTypes = {
    job_category: jobOffer.job_category,
    specialty: jobOffer.specialty,
    seniority_level: jobOffer.seniority_level,
    job_duration: jobOffer.job_duration,
    responsibilities: jobOffer.responsibilities,
    skills: jobOffer.skills,
  };

  const formik = useFormik({
    initialValues: secondStepInitValues,
    validationSchema: createNewJobSecondStepSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: () => {
      handleNextStep();
    },
  });

  const handleSkillSelect = (skill: string) => {
    // Check if jobOffer.skills is an array and if the skill is not already selected
    if (Array.isArray(jobOffer.skills) && !jobOffer.skills.includes(skill)) {
      // Spread operator to create a new array with the existing skills and the new skill
      const updatedSkills = [...jobOffer.skills, skill];

      // Update the job offer skills
      updateJobOffer('skills', updatedSkills);
      formik.setFieldValue('skills', updatedSkills);
    }
  };

  const handleSkillRemove = (skill: string) => {
    // Ensure jobOffer.skills is an array
    if (Array.isArray(jobOffer.skills)) {
      const updatedSkills = jobOffer.skills.filter((s) => s !== skill);

      // Update the job offer skills and Formik state
      updateJobOffer('skills', updatedSkills);
      formik.setFieldValue('skills', updatedSkills);
    }
  };

  const handleCustomSkillChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomSkillInput(e.target.value);
  };

  const handleCustomSkillAdd = (customSkill: string) => {
    // Ensure jobOffer.skills is an array
    if (
      Array.isArray(jobOffer.skills) &&
      !jobOffer.skills.includes(customSkill)
    ) {
      // Update the job offer skills
      const updatedSkills = [...jobOffer.skills, customSkill];
      updateJobOffer('skills', updatedSkills);
      formik.setFieldValue('skills', updatedSkills);
    }
  };

  const handleCustomSkillKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && customSkillInput.trim() !== '') {
      e.preventDefault();
      handleCustomSkillAdd(customSkillInput.trim());

      setCustomSkillInput('');
    }
  };

  return (
    <StyledFlowStepWrapper key={key}>
      <StyledTitleAndPageIndicatorWrapper>
        <StyledStepIndicatorWrapper>
          <StepIndicator currentStep={2} numberOfSteps={3} />
        </StyledStepIndicatorWrapper>
      </StyledTitleAndPageIndicatorWrapper>
      <StyledCreateNewJobForm
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <StyledGridInputsWrapper>
          {/* Job Category */}
          <StyledInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              Job Category
            </Text>
            <StyledSelectInputAndArrowIconWrapper>
              <StyledArrowIconWrapper>
                <SelectFieldDownArrowIcon />
              </StyledArrowIconWrapper>
              <StyledDropDownMenuSelectInput
                placeholder="Job category"
                name="job_category"
                value={formik.values.job_category}
                onChange={(e) => updateJobOffer('job_category', e.target.value)}
              >
                <option value={'Full Time'}>Full Time</option>
                <option value={'Part Time'}>Part Time</option>
                <option value={'Contractor Based'}>Contractor Based</option>
              </StyledDropDownMenuSelectInput>
            </StyledSelectInputAndArrowIconWrapper>
          </StyledInputAndLabelWrapper>
          {/* Job Category END */}
          {/* Speciality */}
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Specialty
              </Text>
              <StyledTextInput
                placeholder="Specialty"
                name="specialty"
                value={formik.values.specialty}
                onChange={(e) => updateJobOffer('specialty', e.target.value)}
              />
            </StyledInputAndLabelWrapper>
            {formik.errors.specialty && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.specialty}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
          {/* Speciality END*/}
          {/* Seniority Level */}
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Seniority Level
              </Text>
              <StyledSelectInputAndArrowIconWrapper>
                <StyledArrowIconWrapper>
                  <SelectFieldDownArrowIcon />
                </StyledArrowIconWrapper>
                <StyledDropDownMenuSelectInput
                  placeholder="Seniority Level"
                  name="seniority_level"
                  value={formik.values.seniority_level}
                  onChange={(e) =>
                    updateJobOffer('seniority_level', e.target.value)
                  }
                >
                  <option value={''} disabled>
                    Select a Seniority Level
                  </option>
                  <option value={'Internship'}>Internship</option>
                  <option value={'Junior'}>Junior</option>
                  <option value={'Intermediate'}>Intermediate</option>
                  <option value={'Senior'}>Senior</option>
                  <option value={'Lead'}>Lead</option>
                </StyledDropDownMenuSelectInput>
              </StyledSelectInputAndArrowIconWrapper>
            </StyledInputAndLabelWrapper>
            {formik.errors.seniority_level && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.seniority_level}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
          {/* Seniority Level END */}
          {/* Job Duration */}
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Job Duration
              </Text>
              <StyledTextInput
                placeholder="Job Duration"
                name="job_duration"
                value={formik.values.job_duration}
                onChange={(e) => updateJobOffer('job_duration', e.target.value)}
              />
            </StyledInputAndLabelWrapper>
            {formik.errors.job_duration && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.job_duration}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
          {/* Job Duration END*/}
        </StyledGridInputsWrapper>
        {/* responsibilities */}
        <StyledFormInputAndErrorWrapper>
          <StyledInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              Responsibilities
            </Text>
            <TextEditor
              placeholder="Responsibilities"
              changeHandler={(value) => {
                updateJobOffer('responsibilities', value);
                formik.setFieldValue('responsibilities', value);
              }}
              defaultValue={jobOffer.responsibilities}
            />
          </StyledInputAndLabelWrapper>
          {formik.errors.responsibilities && (
            <InputErrorMsgWrapper>
              <ErrorMsgLabelIcon />
              <Text
                fontFamily={font.family}
                color={colors.error}
                fontWeight={400}
                fontSize={12}
                margin="0"
              >
                {formik.errors.responsibilities}
              </Text>
            </InputErrorMsgWrapper>
          )}
        </StyledFormInputAndErrorWrapper>
        {/* responsibilities END*/}
        {/* Skills */}
        <StyledFormInputAndErrorWrapper>
          <StyledInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              Skills
            </Text>
            <StyledSelectInputAndArrowIconWrapper>
              <StyledArrowIconWrapper>
                <SelectFieldDownArrowIcon />
              </StyledArrowIconWrapper>
              <StyledDropDownMenuSelectInput
                placeholder="Skills"
                value=""
                onChange={(e) => handleSkillSelect(e.target.value)}
              >
                <option value="" disabled>
                  Select or Add Skills
                </option>
                {predefinedSkills.map((skill, index) => (
                  <option key={index} value={skill}>
                    {skill}
                  </option>
                ))}
              </StyledDropDownMenuSelectInput>
            </StyledSelectInputAndArrowIconWrapper>
          </StyledInputAndLabelWrapper>
          <StyledSelectedSkillsWrapper>
            <StyledCustomSkillInput
              type="text"
              placeholder="Add a custom skill"
              value={customSkillInput}
              onChange={handleCustomSkillChange}
              onKeyDown={handleCustomSkillKeyDown}
            />
            {formik.values.skills.map((skill, index) => (
              <StyledSelectedSkill key={index}>
                {skill}{' '}
                <DeleteButton onClick={() => handleSkillRemove(skill)}>
                  &#10006;
                </DeleteButton>
              </StyledSelectedSkill>
            ))}
          </StyledSelectedSkillsWrapper>
          {formik.errors.skills && (
            <InputErrorMsgWrapper>
              <ErrorMsgLabelIcon />
              <Text
                fontFamily={font.family}
                color={colors.error}
                fontWeight={400}
                fontSize={12}
                margin="0"
              >
                {formik.errors.skills}
              </Text>
            </InputErrorMsgWrapper>
          )}
        </StyledFormInputAndErrorWrapper>
        {/* Skills END */}
        <StyledNavButtonsWrapper>
          <StyledBackBtn type="button" onClick={() => handlePrevStep()}>
            <BackArrow />
            Back
          </StyledBackBtn>
          <StyledSubmitBtn
            disabled={false}
            type="submit"
            onClick={() => setValidateOnChange(true)}
          >
            Next
          </StyledSubmitBtn>
        </StyledNavButtonsWrapper>
      </StyledCreateNewJobForm>
    </StyledFlowStepWrapper>
  );
};
