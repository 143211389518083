import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';
import { font } from '../../../../../../../../style-utils/text';

export const StyledSettingsChangeColorsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  background-color: ${colors.base_white};
  border-radius: 16px;
`;

export const StyledColorTemplatesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledColorTemplate = styled.div<{
  isTemplateSelected?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ isTemplateSelected }) =>
    isTemplateSelected ? colors.primary_300 : colors.primary_50};
  border-radius: 8px;
  gap: 16px;
  cursor: pointer;
  width: 170px;
`;

export const StyledColorTemplateName = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey_dark};
  font-size: 16px;
  font-weight: 400;
  font-family: ${font.family};
`;

export const StyledColorTemplatePreview = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

export const StyledFirstColorCircle = styled.div<{ color: string }>`
  box-sizing: border-box;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  background-color: ${({ color }) => color};
`;

export const StyledColorCircle = styled(StyledFirstColorCircle)`
  margin-left: -10px;
`;

export const StyledSaveButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary_600};
  color: ${colors.base_white};
  font-size: 16px;
  font-weight: 700;
  font-family: ${font.family};
  padding: 8px 40px;
  margin-left: auto;
  width: max-content;
  border-radius: 4px;
  border-style: none;
  cursor: pointer;
`;

export const StyledDescriptionAndTitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledDescriptionMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background-color: ${colors.secondary_50};
  border-radius: 8px;
`;

export const StyledSelectCustomColorsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 16px;
`;

export const StyledCustomColorTitleAndColorWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const StyledCustomColorWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: 170px;
`;

export const StyledCustomColorCircleAndArrowWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const StyledCustomColorHexInput = styled.input<{ hasError: boolean }>`
  box-sizing: border-box;
  border: ${({ hasError }) => (hasError ? '1px solid red' : 'none')};
  background-color: ${colors.neutral_50};
  border-radius: 8px;
  width: 100px;
  height: 100%;
  margin: 0;
  text-align: center;
  outline: none;

  &:focus {
    outline: none;
  }
`;

export const StyledHexColorPickerWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: -15px;
  padding-top: 75px;
  z-index: 9999;
`;

export const StyledPreviewColorTemplateMainWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor || '#F7F8FA'};
  padding: 48px 64px;
`;

export const StyledPreviewColorTemplateWrapper = styled.div`
  box-sizing: border-box;
  width: 1200px;
`;
