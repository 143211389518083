import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from '../pages/applicants/candidates/candidates-table-components/candidates-table-comments/candidate-comments.styled';

interface ProtectedRouteProps {
  children: JSX.Element;
  entityType: string;
  requiredEntityType: string;
  // eslint-disable-next-line
  user: any;
  loading: boolean;
}

export const ProtectedRoute = ({
  children,
  entityType,
  requiredEntityType,
  user,
  loading,
}: ProtectedRouteProps) => {
  const location = useLocation();

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (entityType !== requiredEntityType) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
