import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  StyledCreateNewEmployeeJobPositionBackBtn,
  StyledCreateNewEmployeeJobPositionBtnsWrapper,
  StyledCreateNewEmployeeJobPositionForm,
  StyledCreateNewEmployeeJobPositionFormInput,
  StyledCreateNewEmployeeJobPositionFormItem,
  StyledCreateNewEmployeeJobPositionMainWrapper,
  StyledCreateNewEmployeeJobPositionSubmitBtn,
} from './create-edit-delete-job-position.styled';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Text, font } from '../../../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../../../style-utils/colors';
import { Company } from '../../../../../../../../../app-router/component-props';
import { apiRoute } from '../../../../../../../../../../constants/api-constants';

interface Props {
  onClose: (success: boolean, message?: string) => void;
  closePopUp: () => void;
  user: Company;
}

interface CreateNewEmployeeJobPositionTypes {
  job_position: string;
}

export const createNewEmployeeJobPositionValuesValidationSchema = yup
  .object()
  .shape({
    job_position: yup.string().required('Job position title is required!'),
  });

const CreateNewEmployeeJobPosition: React.FC<Props> = ({
  onClose,
  user,
  closePopUp,
}) => {
  const baseURL = apiRoute;
  const [validateOnChange] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const createNewEmployeeJobPositionValues: CreateNewEmployeeJobPositionTypes =
    {
      job_position: '',
    };

  const formik = useFormik({
    initialValues: createNewEmployeeJobPositionValues,
    validationSchema: createNewEmployeeJobPositionValuesValidationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: (values) => {
      axios
        .post(`${baseURL}/employee_job_position`, {
          job_position: values.job_position,
          company_id: user.id,
        })
        .then(() => {
          onClose(true, 'Job position created');
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.error || 'Error creating job position';
          onClose(false, errorMessage);
        });
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        closePopUp();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePopUp]);

  return (
    <StyledCreateNewEmployeeJobPositionMainWrapper onClick={() => closePopUp}>
      <div ref={wrapperRef}>
        <StyledCreateNewEmployeeJobPositionForm
          onChange={formik.handleChange}
          onSubmit={formik.handleSubmit}
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_black}
            fontSize={28}
            fontWeight={700}
          >
            Create new job position
          </Text>
          <StyledCreateNewEmployeeJobPositionFormItem>
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={18}
              fontWeight={700}
            >
              Title
            </Text>
            <StyledCreateNewEmployeeJobPositionFormInput
              type="text"
              name="job_position"
              placeholder="Enter title"
              value={formik.values.job_position}
            />
            {formik.errors.job_position && (
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.error}
                fontSize={12}
                fontWeight={700}
              >
                {formik.errors.job_position}
              </Text>
            )}
          </StyledCreateNewEmployeeJobPositionFormItem>
          <StyledCreateNewEmployeeJobPositionBtnsWrapper>
            <StyledCreateNewEmployeeJobPositionBackBtn
              type="button"
              onClick={() => onClose(false)}
            >
              Back to Settings
            </StyledCreateNewEmployeeJobPositionBackBtn>
            <StyledCreateNewEmployeeJobPositionSubmitBtn type="submit">
              Create
            </StyledCreateNewEmployeeJobPositionSubmitBtn>
          </StyledCreateNewEmployeeJobPositionBtnsWrapper>
        </StyledCreateNewEmployeeJobPositionForm>
      </div>
    </StyledCreateNewEmployeeJobPositionMainWrapper>
  );
};

export default CreateNewEmployeeJobPosition;
