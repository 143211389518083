import styled from 'styled-components';

export const NotificationContainer = styled.div<{
  margin: string;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.margin};
  padding: 16px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
`;

export const NotificationDescriptionDiv = styled.div`
  flex: 1;
`;

export const ButtonIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ApproveButton = styled.button`
  background-color: #48bb78; /* Replace with colors.primary_500 */
  color: white;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const DenyButton = styled.button`
  background-color: #f56565; /* Replace with appropriate color */
  color: white;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const DeleteContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;

  &:hover {
    opacity: 0.7;
  }
`;

export const DateHeader = styled.div``;
