import React from 'react';
import { EditIcon } from '../../../icons/edit-icon-smaller';
import { AddIcon } from '../../../icons/add-icon';
import {
  EmployeePerformanceContainer,
  TopHeadingContainer,
  PerformanceButtons,
  Heading2,
  Button,
  Text,
} from './employee-performance-tab.styled';
import { FeedbackComponent } from './components/feedback-component';
import { PerformanceOverviewComponent } from './components/performance-overview-component';
import { RecentActivity } from './components/recent-activity-component';
import { ManagerNotesComponent } from './components/manager-notes-component';

interface EmployeePerformanceTabProps {
  hideEdit?: boolean;
}

export const EmployeePerformanceTab: React.FC<EmployeePerformanceTabProps> = ({
  hideEdit,
}) => {
  return (
    <EmployeePerformanceContainer>
      <TopHeadingContainer>
        <Heading2>Performance</Heading2>
        {!hideEdit && (
          <PerformanceButtons>
            <Button>
              <Text>Edit</Text>
              <EditIcon />
            </Button>
            <Button>
              <Text>Add new</Text>
              <AddIcon />
            </Button>
          </PerformanceButtons>
        )}
      </TopHeadingContainer>
      <PerformanceOverviewComponent />
      <FeedbackComponent />
      <RecentActivity />
      <ManagerNotesComponent />
    </EmployeePerformanceContainer>
  );
};
