import React, { useEffect, useState } from 'react';
import { useJobOffer } from '../../hooks/global-job-offer-provider';
import {
  InputErrorMsgWrapper,
  StyledCreateNewJobForm,
  StyledFlowStepWrapper,
  StyledFormInputAndErrorWrapper,
  StyledFormTextAreaInputAndErrorWrapper,
  StyledGenerateWithAiBtn,
  StyledInputAndLabelWrapper,
  StyledJobTitleAndAIButtonWrapper,
  StyledPageTitleAndDesctiptionWrapper,
  StyledSubmitBtn,
  StyledTextAreaInputAndLabelWrapper,
  StyledTextInput,
  StyledTitleAndPageIndicatorWrapper,
} from '../../create-job-offer-flow.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { useFormik } from 'formik';
import { CreateNewJobFirstStepTypes } from '../../../../../../interfaces/job-interfaces';
import { createNewJobFirstStepSchema } from '../../validation-schemas';
import { Company } from '../../../../../app-router/component-props';
import { colors } from '../../../../../../style-utils/colors';
import { ErrorMsgLabelIcon } from '../../../../../icons/error-label-icon';
import StepIndicator from '../../step-indicator/step-indicator';
import { TextEditor } from '../../../../../styled-quill/text-editor';

interface FirstStepFormPropsTypes {
  user: Company;
  handleNextStep: () => void;
}
export const GenerateWithAi: React.FC<FirstStepFormPropsTypes> = ({
  user,
  handleNextStep,
}) => {
  const { jobOffer, updateJobOffer, generateJobOfferWithAi } = useJobOffer();
  const [loading, setLoading] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [aiGenerated, setAiGenerated] = useState(false);

  const firstStepInitValues: CreateNewJobFirstStepTypes = {
    job_position: jobOffer.job_position,
    job_description: jobOffer.job_description,
  };

  const formik = useFormik({
    initialValues: firstStepInitValues,
    validationSchema: createNewJobFirstStepSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: () => {
      handleNextStep();
    },
  });

  useEffect(() => {
    if (!aiGenerated) {
      formik.setValues({
        job_position: jobOffer.job_position,
        job_description: jobOffer.job_description,
      });

      if (jobOffer.job_position && jobOffer.job_description) {
        formik.validateForm();
      }
    }
  }, [jobOffer, aiGenerated]);
  const handleGenerate = async () => {
    setLoading(true);

    try {
      await generateJobOfferWithAi(user);
      formik.setFieldValue('job_description', jobOffer.job_description);
      setAiGenerated(true);
    } finally {
      setLoading(false);
    }
  };
  const isGenerateButtonDisabled = formik.values.job_position.trim() === '';

  return (
    <StyledFlowStepWrapper>
      <StyledTitleAndPageIndicatorWrapper>
        <StyledPageTitleAndDesctiptionWrapper>
          <Text
            fontSize={30}
            fontWeight={700}
            fontFamily={font.family}
            margin="0"
          >
            Create Job Vacancy
          </Text>
          <Text fontSize={14} fontFamily={font.family} margin="0">
            Create an appealing job post by filling out this form. Let&apos;s
            discover fresh top talents and elevate our team together!
          </Text>
        </StyledPageTitleAndDesctiptionWrapper>
        <StepIndicator currentStep={1} numberOfSteps={3} />
      </StyledTitleAndPageIndicatorWrapper>
      <StyledCreateNewJobForm
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <StyledFormInputAndErrorWrapper>
          <StyledInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              Job title
            </Text>
            <StyledJobTitleAndAIButtonWrapper>
              <StyledTextInput
                placeholder="Job title"
                name="job_position"
                value={formik.values.job_position}
                onChange={(e) => {
                  formik.handleChange(e);
                  updateJobOffer('job_position', e.target.value);
                  setAiGenerated(false);
                }}
              />
              <StyledGenerateWithAiBtn
                disabled={isGenerateButtonDisabled}
                type="button"
                onClick={handleGenerate}
              >
                {!loading ? 'Generate with AI' : 'Generating ...'}
              </StyledGenerateWithAiBtn>
            </StyledJobTitleAndAIButtonWrapper>
          </StyledInputAndLabelWrapper>
          {formik.errors.job_position && (
            <InputErrorMsgWrapper>
              <ErrorMsgLabelIcon />
              <Text
                fontFamily={font.family}
                color={colors.error}
                fontWeight={400}
                fontSize={12}
                margin="0"
              >
                {formik.errors.job_position}
              </Text>
            </InputErrorMsgWrapper>
          )}
        </StyledFormInputAndErrorWrapper>
        <StyledFormTextAreaInputAndErrorWrapper>
          <StyledTextAreaInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              Job description
            </Text>
            <TextEditor
              changeHandler={(value) => {
                updateJobOffer('job_description', value);
                formik.setFieldValue('job_description', value);
                setAiGenerated(false);
              }}
              defaultValue={jobOffer.job_description}
            />
          </StyledTextAreaInputAndLabelWrapper>
          {formik.errors.job_description && (
            <InputErrorMsgWrapper>
              <ErrorMsgLabelIcon />
              <Text
                fontFamily={font.family}
                color={colors.error}
                fontWeight={400}
                fontSize={12}
                margin="0"
              >
                {formik.errors.job_description}
              </Text>
            </InputErrorMsgWrapper>
          )}
        </StyledFormTextAreaInputAndErrorWrapper>
        <StyledSubmitBtn
          disabled={isGenerateButtonDisabled}
          type="submit"
          onClick={() => setValidateOnChange(true)}
        >
          Next
        </StyledSubmitBtn>
      </StyledCreateNewJobForm>
    </StyledFlowStepWrapper>
  );
};
