import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';
import shape from '../../lp-images/shape.png';

export const AboutContainer = styled.div`
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
  background: url(${shape});
  background-size: cover;
  background-repeat: no-repeat;

  ${media.laptop(`
    padding: 70px 0;
    background: none;
  `)}
`;

export const AboutBox = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.laptop(`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `)}
`;

export const Image = styled.img`
  width: 100%;
  max-width: 670px;
  height: auto;
`;

export const ImageContainer = styled.div`
  width: 50%;

  ${media.laptop(`
    width: 80%;
  `)}
`;

export const AboutInfo = styled.div`
  flex: 50%;
  color: #1c1c1c;
`;

export const Heading = styled.h3`
  font-weight: 700;
  font-size: 38px;
  text-decoration: underline;
  text-decoration-color: #f9846f29;
  text-decoration-thickness: 5px;
  text-underline-offset: 5px;

  ${media.laptop(`
    font-size: 25px;
    margin: 20px;
  `)}
`;

export const AboutParagraphs = styled.p`
  color: #131313;
  font-weight: 500;
  line-height: 28px;
  font-size: 16px;

  ${media.laptop(`
    font-size: 14px;
    margin: 20px;
  `)}
`;
