import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const SurveyFormMainWrapper = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  width: 70%;
  height: auto;
  border: none;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: Lato;
`;

export const Heading = styled.h1`
  font-size: 33px;
  font-weight: 700;
  line-height: 39.6px;
  margin: 0;
  padding-bottom: 32px;
`;

export const FormWrapper = styled.div<{ width?: string; gap?: string }>`
  width: ${({ width }) => (width ? width : '95%')};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '24px')};
`;

export const InputWrapper = styled.div<{ width?: string; gap?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`;

export const Text = styled.p<{ color?: string }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  color: ${({ color }) => (color ? color : 'black')};
  margin: 0;
`;

export const Input = styled.input<{
  width?: string;
  textTransform?: string;
  background?: string;
  paddingLeft?: string;
}>`
  width: ${({ width }) => (width ? width : '100%')};
  text-transform: ${(props) => props.textTransform || 'none'};
  background-color: ${({ background }) =>
    background ? background : '#f3f2f2'};
  border: none;
  padding: 16px;
  border-radius: 8px;
  color: #5e5a57;
  padding-left: ${({ paddingLeft }) => paddingLeft || '15px'};

  ::placeholder {
    color: #5e5a57;
    font-size: 14px;
  }
`;

export const InputType = styled.input`
  position: absolute;
`;

export const TextArea = styled.textarea`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: none;
  padding: 14px 16px;
  background: #f3f2f2;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  height: 120px;
  resize: none;

  ::placeholder {
    color: #5e5a57;
    font-size: 14px;
    font-weight: 500;
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: #f3f2f2;
  }
`;

export const DateWrapper = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const SmallerText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #010101;
  margin: 0;
`;

export const QuestionsWrapper = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '24px')};
`;

export const QuestionBox = styled.div<{ pb?: string }>`
  width: 100%;
  height: auto;
  background-color: #f3f2f2;
  border: none;
  border-radius: 4px;
  padding-bottom: ${({ pb }) => (pb ? pb : '16px')};
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #779ccc;
  padding: 15px 12px;
  border: none;
  border-radius: 4px 4px 0px 0px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

export const DropDownWrapper = styled.div<{
  width?: string;
  flexDirection?: string;
}>`
  width: ${({ width }) => (width ? width : '23%')};
  position: relative;
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
  align-items: center;
  justify-content: space-between;
`;

export const DropDown = styled.select`
  -webkit-appearance: none;
  position: relative;
  background-color: #e4eaf5;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const OptionsBtn = styled.button`
  padding: 4px 10px;
  background-color: #779ccc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  cursor: pointer;
`;

export const OptionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const SaveBtn = styled.button`
  background: ${colors.secondary_500};
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 18px;
`;

export const StartBtn = styled.button`
  background: #e94e3c;
  max-width: 138px;
  padding: 16px 24px;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  width: 95%;
  gap: 16px;
`;

export const NewQuestionWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: #779ccc;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  margin-top: 25px;
  cursor: pointer;
`;

export const DeleteIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 12px;
`;
