import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../../../constants/api-constants';
import { JobListing } from '../../../../components/featured-jobs/global-job-board-featured-jobs-types';

interface JobListingsResponse {
  jobListings: JobListing[];
}

const useGlobalJobBoardOtherJobs = (jobId: string | undefined) => {
  const [jobListings, setJobListings] = useState<JobListing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!jobId) return;

    axios
      .get<JobListingsResponse>(
        `${apiRoute}/global-job-board/latest-company-jobs?jobId=${jobId}`
      )
      .then((response) => {
        setJobListings(response.data.jobListings);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching job listings:', err);
        setError('Failed to fetch job listings');
        setLoading(false);
      });
  }, [jobId]);

  return { jobListings, loading, error };
};

export default useGlobalJobBoardOtherJobs;
