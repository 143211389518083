import React, { Dispatch, SetStateAction } from 'react';
import {
  StyledMultiLineChartTab,
  StyledMultiLineChartTabs,
} from './multi-line-chart-tabs.styled';
import { font, Text } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';

interface MultiLineChartTabsProps {
  tabs: string[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

const MultiLineChartTabs: React.FC<MultiLineChartTabsProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <StyledMultiLineChartTabs>
      {tabs.map((tab, index) => (
        <StyledMultiLineChartTab
          isSelected={selectedTab === index}
          key={index}
          onClick={() => setSelectedTab(index)}
        >
          <Text
            fontSize={14}
            fontFamily={font.family}
            color={
              selectedTab === index ? colors.base_white : colors.neutral_600
            }
            fontWeight={700}
            margin="0"
          >
            {tab}
          </Text>
        </StyledMultiLineChartTab>
      ))}
    </StyledMultiLineChartTabs>
  );
};

export default MultiLineChartTabs;
