import React from 'react';
import {
  StyledGlobalJobBoardJustLandedMainWrapper,
  StyledGlobalJobBoardJustLandedTitleAndIcon,
} from '../../../../../components/featured-jobs/global-job-board-featured-jobs.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import GlobalJobBoardJustLandedListing from '../../../../../components/featured-jobs/listing/global-job-board-featured-jobs-listing';
import { useJobListings } from '../../../../hooks/job-listings-context';
import { NavigateFunction } from 'react-router-dom';

interface GlobalJobBoardFeaturedProps {
  navigate: NavigateFunction;
}

const GlobalJobBoardFeatured: React.FC<GlobalJobBoardFeaturedProps> = ({
  navigate,
}) => {
  const { jobListings, noResults } = useJobListings();

  if (noResults) return null;

  return (
    <StyledGlobalJobBoardJustLandedMainWrapper>
      <StyledGlobalJobBoardJustLandedTitleAndIcon>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={500}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          Featured
        </Text>
      </StyledGlobalJobBoardJustLandedTitleAndIcon>
      {jobListings.slice(5, 9).map((job, index) => (
        <GlobalJobBoardJustLandedListing
          key={index}
          jobListing={job}
          backgroundColor={colors.white}
          navigate={navigate}
        />
      ))}
    </StyledGlobalJobBoardJustLandedMainWrapper>
  );
};

export default GlobalJobBoardFeatured;
