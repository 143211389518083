import styled from 'styled-components';

export const StyledEmployeesTimeoffMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledEmployeesTimeoffTitleAndSearchWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledEmployeesTimeoffSearchAndFilterWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
