import React from 'react';

interface FacebookIconProps {
  strokeColor?: string;
}

export const FacebookIcon: React.FC<FacebookIconProps> = ({
  strokeColor = '#5E5A57',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 1.33325H9.99984C9.11578 1.33325 8.26794 1.68444 7.64281 2.30956C7.01769 2.93468 6.6665 3.78253 6.6665 4.66659V6.66659H4.6665V9.33325H6.6665V14.6666H9.33317V9.33325H11.3332L11.9998 6.66659H9.33317V4.66659C9.33317 4.48977 9.40341 4.32021 9.52843 4.19518C9.65346 4.07016 9.82303 3.99992 9.99984 3.99992H11.9998V1.33325Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
