import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';
import { media } from '../../../../../../style-utils/media-queries';

export const ApplicationFormWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: center;
  background: ${colors.white};
  border-radius: 8px;
  width: 100%;
  ${media.laptop(`
      width: auto;
      display: block;
      margin: 0 auto;
  `)}
`;

export const ApplicationFormInputs = styled.form`
  overflow: auto;
  width: 100%;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const ErrorLabel = styled.label`
  font-family: ${font.familyMontserrat};
  font-size: 12px;
  font-weight: 500;
  color: ${colors.error};
  display: block;
  margin-left: 30px;
`;

export const DoubleInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${media.laptop(`
      display: block;
  `)}
`;

export const JobApplicationInput = styled.div<{ double?: boolean }>`
  background: ${colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
  min-width: 0;
`;

export const StyledJobInput = styled.input`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  padding: 16px;
  background: ${colors.alabaster};
  font-family: ${font.familyMontserrat};
  border-radius: 8px;
  box-sizing: border-box;

  ::placeholder {
    color: var(--Neutral-700, #5e5a57);
    font-size: 14px;
    font-weight: 400;
  }

  &:hover,
  &:focus {
    outline: none;
    background: var(--Neutral-50, #f3f2f2);
  }
`;

export const UploadButton = styled.div`
  display: flex;
  flex: 1;
  width: 84%;
  max-width: 208px;
  height: 24px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  border-radius: var(--rounded-1, 4px);
  background: ${colors.ghostWhite};
  cursor: pointer;
  ${media.laptop(`
    width: auto;
  `)}
`;

export const SubmitBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.persianGreen};
  padding: 16px 24px;
  border-radius: 40px;
  margin-left: auto;
  margin-top: 32px;
  border-style: none;
  cursor: pointer;
`;

export const TermsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 12px;
  margin-top: 32px;
`;

export const TermsCheckbox = styled.input`
  box-sizing: border-box;
`;
