import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../stripe-checkout/stripe-checkout';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

console.log(
  'Stripe publishable key:',
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

export const TestPage = () => {
  useEffect(() => {
    if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
      console.error('REACT_APP_STRIPE_PUBLISHABLE_KEY is not set');
    } else {
      console.log(
        'Stripe publishable key:',
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
    }
  }, []);

  return (
    <div style={{ background: 'white' }}>
      <h2>Test Stripe</h2>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};
