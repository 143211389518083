import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavbarAndContentTransparentContainer = styled.div`
  width: 100%;
  max-width: 800px;
  background: transparent;
  box-sizing: border-box;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: Arial, sans-serif;
`;

export const FieldContainer = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
  font-family: ${font.family};
  color: #010101;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 0;
  background: ${colors.neutral_50};
  height: 49px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Button = styled.button<{ isBack: boolean }>`
  padding: 10px 20px;
  background: ${({ isBack }) =>
    isBack ? `${colors.primary_500}` : `${colors.secondary_500}`};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
