import React from 'react';

interface InstagramIconProps {
  strokeColor?: string;
}

export const InstagramIcon: React.FC<InstagramIconProps> = ({
  strokeColor = '#5E5A57',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2733_3422)">
        <path
          d="M11.3335 1.33325H4.66683C2.82588 1.33325 1.3335 2.82564 1.3335 4.66659V11.3333C1.3335 13.1742 2.82588 14.6666 4.66683 14.6666H11.3335C13.1744 14.6666 14.6668 13.1742 14.6668 11.3333V4.66659C14.6668 2.82564 13.1744 1.33325 11.3335 1.33325Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6668 7.57996C10.7491 8.13479 10.6543 8.70143 10.396 9.1993C10.1376 9.69717 9.7289 10.1009 9.22788 10.3531C8.72687 10.6052 8.1591 10.693 7.60532 10.6039C7.05155 10.5148 6.53997 10.2533 6.14336 9.85673C5.74674 9.46012 5.48529 8.94854 5.39618 8.39477C5.30707 7.84099 5.39484 7.27322 5.64701 6.77221C5.89919 6.27119 6.30292 5.86245 6.80079 5.60412C7.29865 5.34579 7.8653 5.25102 8.42013 5.33329C8.98608 5.41721 9.51003 5.68093 9.91459 6.08549C10.3192 6.49006 10.5829 7.01401 10.6668 7.57996Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6665 4.33325H11.6732"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_3422">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
