import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';

export const JobPostingsContainer = styled.div<{ background?: string }>`
  display: flex;
  padding: 16px;
  gap: 16px;
  align-self: stretch;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-right: 8px;
  background: ${(props) => props.background || '#fdfdfd'};
  cursor: pointer;
  ${media.laptop(`
    margin-right: 0px;
    overflow: scroll;
  `)}
`;

export const JobPosition = styled.div<{ color?: string }>`
  color: ${(props) => props.color || '#010101'};
  text-align: center;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  ${media.laptop(`
    text-align: left;
    font-size: 16px;
  `)}
`;

export const Date = styled.div<{ color?: string }>`
  color: ${(props) => props.color || '#010101'};
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-self: flex-start;
  margin-top: 8px;
  ${media.laptop(`
    margin-left: 8px;
  `)}
`;

export const PositionAndDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FormPositionWrapper = styled.div`
  position: relative;
`;

export const JobDescriptionContainer = styled.div<{ color?: string }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  width: 80%;
  color: ${(props) => props.color || '#010101'};
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  ${media.laptop(`
    width: 100%;
  `)}
`;

export const GrayBlockTextContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const GrayBlockText = styled.div<{
  backgroundColor?: string;
  textColor?: string;
}>`
  display: flex;
  width: 64px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--rounded-1, 4px);
  background: ${(props) => props.backgroundColor || '#f3f2f2'};
  color: ${(props) => props.textColor || '#5e5a57'};
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CompanyName = styled.div<{ color: string }>`
  color: ${(props) => props.color || '#e94e3c'};
  text-align: center;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-self: flex-start;
`;

export const SalaryAndLocation = styled.div<{ textColor?: string }>`
  color: ${(props) => props.textColor || '#010101'};
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${media.laptop(`
    font-size: 12px;
    margin-right: 8px;
    text-align: left;
  `)}
`;

export const SalaryAndLocationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor || '#f3f2f2'};
  cursor: pointer;
`;

export const ApplyButton = styled.div<{
  backgroundColor?: string;
  textColor?: string;
}>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor || '#e94e3c'};
  color: ${(props) => props.textColor || '#f6f7f7'};
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const IconAndApplyButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;
