import styled from 'styled-components';

export const SurveyDetailsMainWrapper = styled.div`
  margin-top: 24px;
  width: 95%;
  height: auto;
  border: none;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: Lato;
`;

export const StyledHeadingFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div<{ mt?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt }) => (mt ? mt : `0px`)};
`;

export const CirclesWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  gap: 48px;
`;

export const SurveyActivityCirclesData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
