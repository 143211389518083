import { useState } from 'react';
import { ReportingCustomBarChartDataTypes } from '../../components/charts/charts-data-types';
import { ReportingTableState } from '../../components/tables/reporting-employees-table/reporting-employees-table';

interface ReportingTimeOffTableUsageDataTypes {
  name: string;
  typeOfLeave: string;
  startDate: string;
  endDate: string;
  totalDaysTaken: string;
  department: string;
}

interface ReportingTimeOffTableLeaveBalanceDataTypes {
  name: string;
  currentLeaveEntitlement: string;
  leaveTaken: string;
  remainingBalance: string;
  department: string;
}

interface ReportingTimeOffTableHistoricalLeaaveDataTypes {
  year: string;
  name: string;
  totalLeaveTaken: string;
  typesOfLeave: string;
  department: string;
}

export const useReportingTimeOffData = () => {
  const [customBarChartData, setCustomBarChartData] = useState<
    ReportingCustomBarChartDataTypes[]
  >([
    { name: 'Jan', series1: 30, series2: 45, series3: 20 },
    { name: 'Feb', series1: 40, series2: 55, series3: 25 },
    { name: 'Mar', series1: 50, series2: 60, series3: 30 },
    { name: 'Apr', series1: 45, series2: 65, series3: 35 },
    { name: 'May', series1: 55, series2: 70, series3: 40 },
    { name: 'Jun', series1: 50, series2: 60, series3: 30 },
    { name: 'Jul', series1: 60, series2: 80, series3: 45 },
    { name: 'Aug', series1: 55, series2: 65, series3: 35 },
    { name: 'Sep', series1: 60, series2: 75, series3: 40 },
    { name: 'Oct', series1: 50, series2: 60, series3: 30 },
    { name: 'Nov', series1: 55, series2: 70, series3: 35 },
    { name: 'Dec', series1: 50, series2: 60, series3: 30 },
  ]);

  const [reportingTimeOffTableUsageData, setReportingTimeOffTableUsageData] =
    useState<ReportingTableState<ReportingTimeOffTableUsageDataTypes>>({
      headers: [
        'Name',
        'Type of Leave',
        'Start Date',
        'End Date',
        'Total Days Taken',
        'Department',
      ],

      data: [
        {
          name: 'John Don',
          typeOfLeave: 'Lorem ipsum dolar',
          startDate: '02.08.2024',
          endDate: '21.08.2024',
          totalDaysTaken: '19 Days',
          department: 'Lorem ipsum dolar',
        },
        {
          name: 'John Don',
          typeOfLeave: 'Lorem ipsum dolar',
          startDate: '02.08.2024',
          endDate: '21.08.2024',
          totalDaysTaken: '19 Days',
          department: 'Lorem ipsum dolar',
        },
        {
          name: 'John Don',
          typeOfLeave: 'Lorem ipsum dolar',
          startDate: '02.08.2024',
          endDate: '21.08.2024',
          totalDaysTaken: '19 Days',
          department: 'Lorem ipsum dolar',
        },
        {
          name: 'John Don',
          typeOfLeave: 'Lorem ipsum dolar',
          startDate: '02.08.2024',
          endDate: '21.08.2024',
          totalDaysTaken: '19 Days',
          department: 'Lorem ipsum dolar',
        },
        {
          name: 'John Don',
          typeOfLeave: 'Lorem ipsum dolar',
          startDate: '02.08.2024',
          endDate: '21.08.2024',
          totalDaysTaken: '19 Days',
          department: 'Lorem ipsum dolar',
        },
        {
          name: 'John Don',
          typeOfLeave: 'Lorem ipsum dolar',
          startDate: '02.08.2024',
          endDate: '21.08.2024',
          totalDaysTaken: '19 Days',
          department: 'Lorem ipsum dolar',
        },
      ],
    });

  const [
    reportingTimeOffTableLeaveBalanceData,
    setReportingTimeOffTableLeaveBalanceData,
  ] = useState<ReportingTableState<ReportingTimeOffTableLeaveBalanceDataTypes>>(
    {
      headers: [
        'Name',
        'Current Leave Entitlement',
        'Leave Taken',
        'Remaining Balance',
        'Department',
      ],

      data: [
        {
          name: 'John Don',
          currentLeaveEntitlement: 'Lorem ipsum dolar',
          leaveTaken: '02.08.2024',
          remainingBalance: '19 Days',
          department: 'Lorem ipsum dolar',
        },
      ],
    }
  );

  const [
    reportingTimeOffTableHistoricalLeaveData,
    setReportingTimeOffTableHistoricalLeaveData,
  ] = useState<
    ReportingTableState<ReportingTimeOffTableHistoricalLeaaveDataTypes>
  >({
    headers: [
      'Year',
      'Name',
      'Total Leave Taken',
      'Types of Leave',
      'Department',
    ],

    data: [
      {
        year: '2024',
        name: 'John Don',
        totalLeaveTaken: '17 Days',
        typesOfLeave: 'Lorem ipsum dolar',
        department: 'Lorem ipsum dolar',
      },
      {
        year: '2024',
        name: 'John Don',
        totalLeaveTaken: '17 Days',
        typesOfLeave: 'Lorem ipsum dolar',
        department: 'Lorem ipsum dolar',
      },
      {
        year: '2024',
        name: 'John Don',
        totalLeaveTaken: '17 Days',
        typesOfLeave: 'Lorem ipsum dolar',
        department: 'Lorem ipsum dolar',
      },
      {
        year: '2024',
        name: 'John Don',
        totalLeaveTaken: '17 Days',
        typesOfLeave: 'Lorem ipsum dolar',
        department: 'Lorem ipsum dolar',
      },
      {
        year: '2024',
        name: 'John Don',
        totalLeaveTaken: '17 Days',
        typesOfLeave: 'Lorem ipsum dolar',
        department: 'Lorem ipsum dolar',
      },
    ],
  });

  return {
    customBarChartData,
    reportingTimeOffTableUsageData,
    reportingTimeOffTableLeaveBalanceData,
    reportingTimeOffTableHistoricalLeaveData,
    setCustomBarChartData,
    setReportingTimeOffTableUsageData,
    setReportingTimeOffTableLeaveBalanceData,
    setReportingTimeOffTableHistoricalLeaveData,
  };
};
