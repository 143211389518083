import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import Cookies from 'universal-cookie';
import { useNotifications } from '../../../../context/notification-provider';

interface NotificationPreferencesProps {
  entityId?: string;
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

const NotificationPreferences: React.FC<NotificationPreferencesProps> = ({
  entityId = 'id was not passed correctly',
  setUser,
}) => {
  const cookies = new Cookies();
  const token = cookies.get('authorization_token');

  console.log('Authorization Token:', token);

  useEffect(() => {
    if (!token) {
      alert('No authorization token found. Please log in.');

      return;
    }
  }, [token]);

  const [receiveEmail, setReceiveEmail] = useState(true);
  const [receiveInApp, setReceiveInApp] = useState(true);
  const [receiveEdit, setReceiveEdit] = useState(true);
  const [receiveTimeOff, setReceiveTimeOff] = useState(true);
  const [loadingPreferences, setLoadingPreferences] = useState(true);
  const [savingPreferences, setSavingPreferences] = useState(false);

  const { refreshPreferences } = useNotifications();

  const fetchPreferences = useCallback(async () => {
    setLoadingPreferences(true);
    try {
      const response = await axios.get(
        `${apiRoute}/${entityId}/notification-preferences`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setReceiveEmail(response.data.receive_email_notifications);
      setReceiveInApp(response.data.receive_in_app_notifications);
      setReceiveEdit(response.data.receive_edit_notifications);
      setReceiveTimeOff(response.data.receive_time_off_notifications);
      console.log('Fetched notification preferences:', response.data);
    } catch (error) {
      console.error('Error fetching notification preferences:', error);
    } finally {
      setLoadingPreferences(false);
    }
  }, [apiRoute, entityId, token]);

  useEffect(() => {
    if (entityId !== 'id was not passed correctly' && token) {
      fetchPreferences();
    }
  }, [entityId, token, fetchPreferences]);

  const handleSavePreferences = async () => {
    const refreshUser = async () => {
      console.log('refreshUser function called.');
      try {
        console.log('Sending request to refresh user data...', {
          endpoint: `${apiRoute}/auth`,
          headers: { Authorization: `Bearer ${token}` },
        });

        const response = await axios.get(`${apiRoute}/auth`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log('Received response from server:', response);
        refreshPreferences(entityId);
        if (response.data && response.data.user) {
          console.log('User data successfully refreshed:', response.data.user);

          setUser(response.data.user);

          setReceiveEmail(response.data.user.receive_email_notifications);
          setReceiveInApp(response.data.user.receive_in_app_notifications);
          setReceiveEdit(response.data.user.receive_edit_notifications);
          setReceiveTimeOff(response.data.user.receive_time_off_notifications);
        } else {
          console.warn('No user data found in response:', response.data);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.error('Error refreshing user data:', {
          message: error.message,
          stack: error.stack,
          config: error.config,
          response: error.response,
        });
      }
    };

    try {
      setSavingPreferences(true);

      const preferences = {
        receive_email_notifications: receiveEmail,
        receive_in_app_notifications: receiveInApp,
        receive_edit_notifications: receiveEdit,
        receive_time_off_notifications: receiveTimeOff,
      };

      console.log('Saving Preferences:', preferences);

      const putResponse = await axios.put(
        `${apiRoute}/${entityId}/notification-preferences`,
        preferences,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('PUT response:', putResponse);

      await refreshUser();
      console.log('Preferences saved and user refetched successfully.');

      await fetchPreferences();

      alert('Preferences updated successfully');
    } catch (error) {
      console.error('Error updating notification preferences:', error);
      alert('Failed to update preferences. Please try again.');
    } finally {
      setSavingPreferences(false);
    }
  };

  if (loadingPreferences) {
    return <div>Loading preferences...</div>;
  }

  return (
    <PreferencesWrapper key={entityId}>
      <Label>
        Receive Email Notifications:
        <Input
          type="checkbox"
          checked={receiveEmail}
          onChange={() => setReceiveEmail(!receiveEmail)}
        />
      </Label>
      <Label>
        Receive In-App Notifications:
        <Input
          type="checkbox"
          checked={receiveInApp}
          onChange={() => setReceiveInApp(!receiveInApp)}
        />
      </Label>
      <Label>
        Receive Edit Notifications:
        <Input
          type="checkbox"
          checked={receiveEdit}
          onChange={() => setReceiveEdit(!receiveEdit)}
        />
      </Label>
      <Label>
        Receive Time Off Notifications:
        <Input
          type="checkbox"
          checked={receiveTimeOff}
          onChange={() => setReceiveTimeOff(!receiveTimeOff)}
        />
      </Label>
      <Button onClick={handleSavePreferences} disabled={savingPreferences}>
        {savingPreferences ? 'Saving...' : 'Save Preferences'}
      </Button>
    </PreferencesWrapper>
  );
};

export default NotificationPreferences;

const PreferencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
`;

const Label = styled.label`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const Input = styled.input`
  margin-left: 10px;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: ${colors.primary_500};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primary_600}; /* Optional: Different hover color */
  }
  &:disabled {
    background-color: ${colors.primary_300};
    cursor: not-allowed;
  }
`;
