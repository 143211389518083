import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';

export const StyledGlobalJobBoardJobDetailsInfoMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledGlobalJobBoardJobDetailsInfoBackBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
`;

export const StyledGlobalJobBoardJobDetailsInfoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
  gap: 24px;
  border-radius: 8px;
  background-color: ${colors.white};
`;

export const StyledGlobalJobBoardJobDetailsCoverImg = styled.div<{
  backgroundImage: string;
}>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 140px;
  border-radius: 10px;
  background-color: #153238;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const StyledGlobalJobBoardJobDetailsCompanyLogo = styled.img`
  box-sizing: border-box;
  position: absolute;
  width: 64px;
  height: 64px;
  bottom: -16px;
  left: 16px;
  border-radius: 8px;
  object-fit: contain;
  background-color: ${colors.base_white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
`;

export const StyledGlobalJobBoardJobDetailsCompanyTitleAndInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 8px;
  border-bottom: 0.2px solid ${colors.neutral_100};
`;

export const StyledGlobalJobBoardJobDetailsCompanyInfoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator = styled.div`
  box-sizing: border-box;
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  line-height: '17.07px';
  color: ${colors.neutral_800};
`;

export const StyledGlobalJobBoardJobDetailsJobPositionAndDate = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledGlobalJobBoardJobDetailsStatusCardMainWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 12px 16px;
  background-color: ${colors.ghostWhite};
  margin: 0 20px;
  gap: 16px;
`;

export const StyledGlobalJobBoardJobDetailsStatusCardWrapper = styled.div<{
  hasRightBorder: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 15%;
    right: 0;
    width: 1px;
    height: 70%;
    background-color: ${({ hasRightBorder }) =>
      hasRightBorder ? colors.secondary_50 : 'transparent'};
  }
`;

export const StyledGlobalJobBoardJobDetailsTitleAndTextWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const StyledGlobalJobBoardJobDetailsShareAndApplyBtnsWrapper = styled.div`
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 12px;
`;

export const StyledGlobalJobBoardJobDetailsShareAndApplyBtn = styled.button<{
  backgroundColor: string;
}>`
  box-sizing: border-box;
  width: fit-content;
  padding: 16px 24px;
  border-style: none;
  border-radius: 40px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
`;
