import { useState } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../../../../constants/api-constants';
import { GlobalJobBoardJobDataTypes } from '../../use-global-job-board-job-and-company-data';

interface UseGlobalJobBoardShareJobReturn {
  email: string;
  emails: string[];
  error: string;
  isSubmitting: boolean;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  addEmail: () => void;
  removeEmail: (emailToRemove: string) => void;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSubmit: (job: GlobalJobBoardJobDataTypes | undefined) => Promise<{
    success: boolean;
    message?: string;
  }>;
}

export const useGlobalJobBoardShareJob =
  (): UseGlobalJobBoardShareJobReturn => {
    const [email, setEmail] = useState<string>('');
    const [emails, setEmails] = useState<string[]>([]);
    const [error, setError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isValidEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailRegex.test(email);
    };

    const addEmail = () => {
      const trimmedEmail = email.trim().toLowerCase();

      if (!trimmedEmail) {
        setError('Email cannot be empty');

        return;
      }

      if (!isValidEmail(trimmedEmail)) {
        setError('Invalid email format');

        return;
      }

      if (
        emails.some(
          (existingEmail) => existingEmail.toLowerCase() === trimmedEmail
        )
      ) {
        setError('Email already added');

        return;
      }

      setEmails((prevEmails) => [...prevEmails, trimmedEmail]);
      setEmail('');
      setError('');
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        addEmail();
      }
    };

    const removeEmail = (emailToRemove: string) => {
      setEmails((prevEmails) =>
        prevEmails.filter((email) => email !== emailToRemove)
      );
    };

    const handleSubmit = async (
      job: GlobalJobBoardJobDataTypes | undefined
    ): Promise<{ success: boolean; message?: string }> => {
      if (emails.length === 0) {
        setError('Please add at least one email before submitting.');

        return { success: false, message: 'No emails provided.' };
      }

      if (error) {
        console.log('Fix the errors before submitting:', error);

        return { success: false, message: 'Validation errors exist.' };
      }

      setIsSubmitting(true);

      try {
        const response = await axios.post(`${apiRoute}/applicant/send-emails`, {
          emails,
          job,
        });

        console.log('Emails sent successfully:', response.data);
        setEmails([]);

        return { success: true, message: 'Emails sent successfully.' };
      } catch (err) {
        const errorMessage =
          axios.isAxiosError(err) && err.response?.data?.message
            ? err.response.data.message
            : 'An error occurred while sending emails.';
        console.error('Error sending emails:', errorMessage);

        return { success: false, message: errorMessage };
      } finally {
        setIsSubmitting(false);
      }
    };

    return {
      email,
      emails,
      error,
      isSubmitting,
      setEmail,
      addEmail,
      removeEmail,
      handleKeyPress,
      handleSubmit,
    };
  };
