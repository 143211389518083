import React from 'react';
import {
  StyledGlobalJobBoardFeelingLuckyMainWrapper,
  StyledGlobalJobBoardFeelingLuckyInnerWrapper,
  StyledGlobalJobBoardFeelingLuckyTitleWrapper,
  StyledGlobalJobBoardFeelingLuckyBtn,
} from './global-job-board-feeling-lucky.styled';
import { Text, font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';

const GlobalJobBoardFeelingLucky: React.FC = () => {
  return (
    <StyledGlobalJobBoardFeelingLuckyMainWrapper>
      <StyledGlobalJobBoardFeelingLuckyInnerWrapper>
        <StyledGlobalJobBoardFeelingLuckyTitleWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={32}
            fontWeight={700}
            lineHeight="39.01px"
            color={colors.secondary_500}
            margin="0"
          >
            Your CV, Our Mission
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={24}
            fontWeight={400}
            lineHeight="29.26px"
            color={colors.base_black}
            margin="0"
          >
            Connecting You with Companies for Free
          </Text>
        </StyledGlobalJobBoardFeelingLuckyTitleWrapper>
        <StyledGlobalJobBoardFeelingLuckyBtn>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={500}
            lineHeight="19.5px"
            color={colors.white}
            margin="0"
          >
            I Am Feeling Lucky!
          </Text>
        </StyledGlobalJobBoardFeelingLuckyBtn>
      </StyledGlobalJobBoardFeelingLuckyInnerWrapper>
    </StyledGlobalJobBoardFeelingLuckyMainWrapper>
  );
};

export default GlobalJobBoardFeelingLucky;
