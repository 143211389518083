import React, { useState } from 'react';
import { StyledTabsMainWrapper } from './tabs-main.styled';
import SelectColorsTab from './components/tabs/change-colors/change-colors';
import SettingsTabSelector from './components/tab-selector/tab-selector';
import JobPositionTab from './components/tabs/job-position/job-position';
import { UserSettings } from './components/tabs/user-settings.tsx/user-settings';
import CompanySettingsOnboardingTab from './components/tabs/onboarding/onboarding';
import NotificationPreferences from '../../../employees/notification-preferences/notification-preferences';
import Cookies from 'universal-cookie';
import { ChangePlanSettings } from '../../../change-plan/change-plan-settings';
import { useAuthForms } from '../../../../hooks/use-auth-forms';
interface SettingsTabsProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null | undefined) => void;
  cookies?: Cookies;
  refetchUser: () => Promise<void>;
}

const SettingsTabs: React.FC<SettingsTabsProps> = ({
  user,
  cookies,
  setUser,
  refetchUser,
}) => {
  const [activeTabId, setActiveTabId] = useState<number>(2);

  const handleTabChange = (tabId: number) => {
    setActiveTabId(tabId);
  };

  const { signUpData, setSignUpData } = useAuthForms();

  const tabs = [
    null,
    <ChangePlanSettings
      signUpData={signUpData}
      setSignUpData={setSignUpData}
      refetchUser={refetchUser}
      key={1}
      user={user}
      setUser={setUser}
    />,
    <SelectColorsTab key={2} user={user} cookies={cookies} />,
    <UserSettings key={3} user={user} />,
    <JobPositionTab key={4} user={user} />,
    <CompanySettingsOnboardingTab key={5} user={user} />,
    <NotificationPreferences
      user={user}
      setUser={setUser}
      key={6}
      entityId={user.id}
    />,
  ];

  return (
    <StyledTabsMainWrapper>
      <SettingsTabSelector
        onTabChange={handleTabChange}
        activeTabId={activeTabId}
      />
      {tabs[activeTabId]}
    </StyledTabsMainWrapper>
  );
};

export default SettingsTabs;
