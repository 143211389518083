import React from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
  ContentWrapperCandidates,
} from '../../../layout/layout-container.styled';
// import { Text, font } from '../../../style-utils/text';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { CandidatesTable } from './candidates-table';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { Chat } from '../../../chat/chat';

interface CandidatesComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const Candidates: React.FC<CandidatesComponentProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapperCandidates>
          <NavbarAndContentContainer>
            <CandidatesTable company={user} navigate={navigate} />
          </NavbarAndContentContainer>
        </ContentWrapperCandidates>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
