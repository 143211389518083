import styled from 'styled-components';
import { font } from '../../../../../style-utils/text';

export const StyledStepIndicatorWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const StyledStep = styled.div<{
  isActive: boolean;
  isPrevStep: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive, isPrevStep }) =>
    isActive || isPrevStep ? '#56AE57' : 'lightgray'};
  font-family: ${font.family};
  font-size: 24px;
  font-weight: 700;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  scale: ${({ isActive }) => (isActive ? 1.3 : 1)};
  opacity: ${({ isActive }) => (isActive ? '100%' : '70%')};
`;
