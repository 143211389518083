import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  StyledReportingTabBackBtn,
  StyledReportingTabsCenterWrapper,
  StyledReportingTabsMainWrapper,
  StyledReportingTabTitleAndBackBtnWrapper,
} from '../tabs.styled';
import { font, Text } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import TabSelector from '../../components/tab-selector/tab-selector';
import { employeesTabData } from '../../components/tab-selector/tab-data';
import ReportingEmployeeDistribution from './tabs/employee-distribution/reporting-employee-distribution';
import ReportingEmployeeTurnover from './tabs/turnover/turnover';
import ReportingEmployeePerformaceOverview from './tabs/performace-overview/performance-overview';
import { BackArrow } from '../../../../icons/back-arrow';

interface ReportingEmployeesMainProps {
  setActiveTabIdToParent: Dispatch<SetStateAction<number>>;
}

const ReportingEmployeesMain: React.FC<ReportingEmployeesMainProps> = ({
  setActiveTabIdToParent,
}) => {
  const [activeTabId, setActiveTabId] = useState<number>(0);

  const tabs = [
    <ReportingEmployeeDistribution key={0} />,
    <ReportingEmployeeTurnover key={1} />,
    <ReportingEmployeePerformaceOverview key={2} />,
  ];

  return (
    <StyledReportingTabsMainWrapper>
      <StyledReportingTabsCenterWrapper>
        <StyledReportingTabTitleAndBackBtnWrapper>
          <Text
            fontSize={38}
            fontFamily={font.family}
            color={colors.base_black}
            fontWeight={700}
            margin="0"
          >
            Reporting - Employees
          </Text>
          <StyledReportingTabBackBtn onClick={() => setActiveTabIdToParent(0)}>
            <BackArrow />
            <Text
              fontSize={16}
              fontFamily={font.family}
              color={colors.base_black}
              fontWeight={700}
              margin="0"
            >
              Back
            </Text>
          </StyledReportingTabBackBtn>
        </StyledReportingTabTitleAndBackBtnWrapper>
        <TabSelector
          setActiveTabId={setActiveTabId}
          activeTabId={activeTabId}
          numberOfTabs={3}
          tabsData={employeesTabData}
        />
        {tabs[activeTabId]}
      </StyledReportingTabsCenterWrapper>
    </StyledReportingTabsMainWrapper>
  );
};

export default ReportingEmployeesMain;
