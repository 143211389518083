import styled from 'styled-components';

interface ProgressProps {
  fillPercent: number;
  fillColor: string;
  bgColor: string;
}

export const EmployeePerformanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

export const TopHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 29px;
`;

export const Heading2 = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

export const PerformanceButtons = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 32px;
  cursor: pointer;
`;

export const SortButton = styled.button`
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
  background-color: #f26954;
  color: white;
  font-weight: 400;
  line-height: 15px;
  border: none;
  border-radius: 32px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: transparent;
  border: none;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

export const PerformanceOverviewCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
`;

export const PerformancesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
`;

export const PerformanceSection = styled.div`
  display: flex;
  flex: 1;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 95%;
  height: auto;
  border-radius: 30px;
  overflow: hidden;
`;

export const ProgressBar = styled.div<ProgressProps>`
  height: 100%;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgressFill = styled.div<ProgressProps>`
  width: ${({ fillPercent }) => fillPercent}%;
  height: 40px;
  background-color: ${({ fillColor }) => fillColor};
  border-radius: 30px;
  margin: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressText = styled.span`
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  color: white;
  font-weight: bold;
`;

export const Heading3 = styled.h3`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 24px;
`;

export const Heading4 = styled.h4`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ActivityTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Headlines = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`;

export const Headline = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #010101;
  text-align: center;
  margin: 0;
`;

export const ActivityList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding-inline-start: 0px;
`;

export const ActivityItem = styled.li`
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f3f2f2;
  border-radius: 8px;
  border: none;
`;

export const ActivityText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

export const ActivityStatus = styled.div`
  padding: 0px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #fdfdfd;
  background-color: #5789c2;
  border: none;
  border-radius: 60px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const FeedbackContainer = styled.div`
  margin: 24px 0px;
  padding: 16px 0px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`;

export const TabButtons = styled.div`
  display: flex;
  gap: 16px;
`;

export const TabBtn = styled.button<{ active: boolean }>`
  padding: 12px 16px;
  border: none;
  border-radius: 32px;
  background-color: ${({ active }) => (active ? '#f26954' : '#f3f2f2')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  cursor: pointer;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline-start: 0px;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  gap: 10px;
  border-radius: 8px;
  background-color: #f3f2f2;
`;

export const NotesItem = styled(Item)`
  background-color: #e4eaf5;
`;

export const ItemUpContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading6 = styled.h6`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #010101;
  margin: 0;
`;

export const Date = styled.p`
  color: #808080;
  font-size: 12px;
  font-weight: 400;
`;

export const ViewBtn = styled.button`
  gap: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const RecentActivityContainer = styled.div`
  padding: 16px 0px;
`;

export const ManagerNotesContainer = styled.div`
  margin: 24px 0px;
  padding: 16px 0px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100px;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const BoxWrapper = styled.div`
  width: 95%;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;
