import React from 'react';
export const FileIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M8.66699 1.83337H4.00033C3.6467 1.83337 3.30756 1.97385 3.05752 2.2239C2.80747 2.47395 2.66699 2.81309 2.66699 3.16671V13.8334C2.66699 14.187 2.80747 14.5261 3.05752 14.7762C3.30756 15.0262 3.6467 15.1667 4.00033 15.1667H12.0003C12.3539 15.1667 12.6931 15.0262 12.9431 14.7762C13.1932 14.5261 13.3337 14.187 13.3337 13.8334V6.50004L8.66699 1.83337Z"
          stroke="#E94E3C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.66699 1.83337V6.50004H13.3337"
          stroke="#E94E3C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
