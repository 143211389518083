import React, { useState } from 'react';
import {
  Button,
  ButtonsContainer,
  InputsContainer,
  ModalBackground,
  ModalContent,
} from '../../../../super-admin/super-admin-main/modal/modal.styled';
import { font, Text } from '../../../../../../style-utils/text';
import {
  DeleteIconWrapper,
  DropDown,
  DropDownWrapper,
} from '../../create-survey.styled';
import { DownArrow } from '../../../../../icons/down-arrow';
import {
  ChooseEmployeesWrapper,
  ChooseEmployeesHeader,
  SearchInput,
  EmployeesStyledList,
  StyledItem,
  SearchContainer,
} from './survey-publish-modal.styled';
import { SearchIcon } from '../../../../../icons/search';

interface ModalProps {
  onClose: () => void;
}

export const SurveyPublishModal: React.FC<ModalProps> = ({ onClose }) => {
  const [selectedOption, setSelectedOption] = useState<string>('0');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const exampleEmployees = [
    { id: 1, name: 'Angela' },
    { id: 2, name: 'Andrej' },
    { id: 3, name: 'Kostadin' },
    { id: 4, name: 'Vasil' },
    { id: 5, name: 'Marija' },
    { id: 6, name: 'Kristijan' },
  ];

  const filteredEmployees = exampleEmployees.filter((employee) =>
    employee.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent
        onClick={(e) => e.stopPropagation()}
        style={{ maxWidth: 640 }}
      >
        <form>
          <Text
            mt={0}
            mb={16}
            fontSize={28}
            fontFamily={font.family}
            fontWeight={700}
          >
            Survey Publish
          </Text>
          <InputsContainer isOnlyRole={false}>
            <Text
              mb={0}
              mt={0}
              fontSize={18}
              fontFamily={font.family}
              fontWeight={700}
            >
              Choice publish options
            </Text>
            <DropDownWrapper width="100%" flexDirection="column">
              <DropDown value={selectedOption} onChange={handleDropdownChange}>
                <option value="0" disabled>
                  Select Options
                </option>
                <option value="1">Send to all employees</option>
                <option value="2">Choose employees</option>
              </DropDown>

              {/* Choose employees part */}
              {selectedOption === '2' && (
                <ChooseEmployeesWrapper>
                  <ChooseEmployeesHeader>
                    <Text
                      mb={0}
                      mt={0}
                      fontSize={12}
                      fontWeight={400}
                      fontFamily={font.family}
                    >
                      Employees list
                    </Text>
                    <SearchContainer>
                      <SearchInput
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <DeleteIconWrapper>
                        <SearchIcon width="14" />
                      </DeleteIconWrapper>
                    </SearchContainer>
                  </ChooseEmployeesHeader>
                  <EmployeesStyledList>
                    {filteredEmployees.length > 0 ? (
                      filteredEmployees.map((employee) => (
                        <StyledItem key={employee.id}>
                          <input
                            type="checkbox"
                            name="selectedEmployee"
                            value={employee.id}
                          />
                          <label>{employee.name}</label>
                        </StyledItem>
                      ))
                    ) : (
                      <Text>No employees found.</Text>
                    )}
                  </EmployeesStyledList>
                </ChooseEmployeesWrapper>
              )}
              <DeleteIconWrapper style={{ top: 15 }}>
                <DownArrow />
              </DeleteIconWrapper>
            </DropDownWrapper>
          </InputsContainer>
          <ButtonsContainer>
            <Button isBack={true} onClick={onClose}>
              Back to Survey
            </Button>
            <Button type="submit" isBack={false}>
              Publish
            </Button>
          </ButtonsContainer>
        </form>
      </ModalContent>
    </ModalBackground>
  );
};
