import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  StyledReportingTabBackBtn,
  StyledReportingTabsCenterWrapper,
  StyledReportingTabsMainWrapper,
  StyledReportingTabTitleAndBackBtnWrapper,
} from '../tabs.styled';
import { font, Text } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import TabSelector from '../../components/tab-selector/tab-selector';
import { timeOffTabData } from '../../components/tab-selector/tab-data';
import { BackArrow } from '../../../../icons/back-arrow';
import ReportingTimeOffUsage from './tabs/time-off-usage/time-off-usage';
import ReportingLeaveBalance from './tabs/leave-balance/reporting-leave-balance';
import ReportingHistoricalLeaveData from './tabs/historical-leave-data/historical-leave-data';

interface ReportingTimeOffMainProps {
  setActiveTabIdToParent: Dispatch<SetStateAction<number>>;
}

const ReportingTimeOffMain: React.FC<ReportingTimeOffMainProps> = ({
  setActiveTabIdToParent,
}) => {
  const [activeTabId, setActiveTabId] = useState<number>(0);

  const tabs = [
    <ReportingTimeOffUsage key={0} />,
    <ReportingLeaveBalance key={1} />,
    <ReportingHistoricalLeaveData key={2} />,
  ];

  return (
    <StyledReportingTabsMainWrapper>
      <StyledReportingTabsCenterWrapper>
        <StyledReportingTabTitleAndBackBtnWrapper>
          <Text
            fontSize={38}
            fontFamily={font.family}
            color={colors.base_black}
            fontWeight={700}
            margin="0"
          >
            Reporting - Time Off/Vacations
          </Text>
          <StyledReportingTabBackBtn onClick={() => setActiveTabIdToParent(0)}>
            <BackArrow />
            <Text
              fontSize={16}
              fontFamily={font.family}
              color={colors.base_black}
              fontWeight={700}
              margin="0"
            >
              Back
            </Text>
          </StyledReportingTabBackBtn>
        </StyledReportingTabTitleAndBackBtnWrapper>
        <TabSelector
          setActiveTabId={setActiveTabId}
          activeTabId={activeTabId}
          numberOfTabs={3}
          tabsData={timeOffTabData}
        />
        {tabs[activeTabId]}
      </StyledReportingTabsCenterWrapper>
    </StyledReportingTabsMainWrapper>
  );
};

export default ReportingTimeOffMain;
