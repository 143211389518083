import React from 'react';
import { StyledReportingTabWrapper } from '../../../tabs.styled';
import { useReportingEmployeesData } from '../../use-reporting-employees-data';
import ReportingBarChart from '../../../../components/charts/bar-chart/bar-chart';
import ReportingEmployeesTable from '../../../../components/tables/reporting-employees-table/reporting-employees-table';

const ReportingEmployeeDistribution: React.FC = () => {
  const { barChartData, reportingEmployeeTableData } =
    useReportingEmployeesData();

  return (
    <StyledReportingTabWrapper>
      <ReportingBarChart name="Average employee" barChartData={barChartData} />
      <ReportingEmployeesTable
        name="Employee distribution"
        tableData={reportingEmployeeTableData}
      />
    </StyledReportingTabWrapper>
  );
};

export default ReportingEmployeeDistribution;
