import { NewJobOfferData } from '../../../../../interfaces/job-interfaces';

export const initialJobOfferData: NewJobOfferData = {
  general_company_info: '',
  job_position: '',
  job_description: '',
  job_category: 'Full Time',
  specialty: '',
  skills: [],
  seniority_level: '',
  industry: '',
  job_duration: '',
  responsibilities: '',
  work_location: '',
  location: '',
  benefits: '',
  status: '',
  job_aplications: 0,
  company_id: '',
  aiText: '',
  expiration_date: '',
};
