import styled from 'styled-components';

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  padding-top: 15px;
`;

export const Heading = styled.div`
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
`;

export const SurveyBtn = styled.button`
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  background-color: #e94e3c;
  color: white;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;

export const PieChartsWrapper = styled.div`
  max-height: 208px;
  padding: 8px 24px;
  background-color: white;
  border-radius: 8px;
  border: none;
`;
