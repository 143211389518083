import React from 'react';

import {
  Ellipsis,
  PageButton,
  PageNumber,
  PaginationContainer,
} from './new-pagination.styled';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  // eslint-disable-next-line
  filteredData: any[];
}

const NewPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  filteredData,
}) => {
  if (!filteredData || filteredData.length === 0) return null;

  const getPaginationButtons = () => {
    const pages = [];
    const maxVisiblePages = 5;
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(currentPage - halfVisiblePages, 1);
    let endPage = Math.min(currentPage + halfVisiblePages, totalPages);

    if (currentPage <= halfVisiblePages) {
      endPage = Math.min(maxVisiblePages, totalPages);
    } else if (currentPage + halfVisiblePages >= totalPages) {
      startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
    }

    if (startPage > 1) {
      pages.push(
        <PageNumber key={1} onClick={() => onPageChange(1)}>
          1
        </PageNumber>
      );
      if (startPage > 2) {
        pages.push(<Ellipsis key="start-ellipsis">...</Ellipsis>);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <PageNumber
          key={page}
          isActive={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page}
        </PageNumber>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<Ellipsis key="end-ellipsis">...</Ellipsis>);
      }
      pages.push(
        <PageNumber key={totalPages} onClick={() => onPageChange(totalPages)}>
          {totalPages}
        </PageNumber>
      );
    }

    return pages;
  };

  return (
    <PaginationContainer>
      <PageButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &#9664;
      </PageButton>
      {getPaginationButtons()}
      <PageButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &#9654;
      </PageButton>
    </PaginationContainer>
  );
};

export default NewPagination;
