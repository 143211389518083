import React from 'react';

export const EnvelopeIconCyan = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M2.66659 3.16663H13.3333C14.0666 3.16663 14.6666 3.76663 14.6666 4.49996V12.5C14.6666 13.2333 14.0666 13.8333 13.3333 13.8333H2.66659C1.93325 13.8333 1.33325 13.2333 1.33325 12.5V4.49996C1.33325 3.76663 1.93325 3.16663 2.66659 3.16663Z"
          stroke="#5789C2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14.6666 4.5L7.99992 9.16667L1.33325 4.5"
          stroke="#5789C2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
