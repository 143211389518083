// EmployeesTimeoff.tsx
import React from 'react';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../../layout/layout-container.styled';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  StyledEmployeesTimeoffMainWrapper,
  StyledEmployeesTimeoffSearchAndFilterWrapper,
  StyledEmployeesTimeoffTitleAndSearchWrapper,
} from './employees-timeoff.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import TimeOffEmployeesSearch from './components/search/search';
import TimeOffEmployeesFilter from './components/filter/filter';
import TimeOffEmployeesTable from './components/table/table';
import { useTimeOff, TimeOffProvider } from './context/timeoff-context';
import Pagination from './components/pagination/pagination';
import { Chat } from '../../../chat/chat';
import { NavigateFunction } from 'react-router-dom';

const EmployeesTimeoffContent: React.FC = () => {
  const { currentPage, totalPages, setPage } = useTimeOff();

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <StyledEmployeesTimeoffMainWrapper>
      <StyledEmployeesTimeoffTitleAndSearchWrapper>
        <Text
          fontFamily={font.family}
          fontSize={38}
          fontWeight={700}
          color={colors.base_black}
          margin="0"
        >
          Day Off
        </Text>
        <StyledEmployeesTimeoffSearchAndFilterWrapper>
          <TimeOffEmployeesSearch />
          <TimeOffEmployeesFilter />
        </StyledEmployeesTimeoffSearchAndFilterWrapper>
      </StyledEmployeesTimeoffTitleAndSearchWrapper>
      <TimeOffEmployeesTable />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </StyledEmployeesTimeoffMainWrapper>
  );
};

interface EmployeesTimeoffProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

const EmployeesTimeoff: React.FC<EmployeesTimeoffProps> = ({
  user,
  navigate,
  setUser,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentTransparentContainer>
            <TimeOffProvider user={user}>
              <EmployeesTimeoffContent />
            </TimeOffProvider>
          </NavbarAndContentTransparentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};

export default EmployeesTimeoff;
