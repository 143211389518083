import React from 'react';

interface ShareIconProps {
  strokeColor?: string;
}

export const ShareIcon: React.FC<ShareIconProps> = ({
  strokeColor = 'black',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.66666 8V13.3333C2.66666 13.687 2.80713 14.0261 3.05718 14.2761C3.30723 14.5262 3.64637 14.6667 3.99999 14.6667H12C12.3536 14.6667 12.6927 14.5262 12.9428 14.2761C13.1928 14.0261 13.3333 13.687 13.3333 13.3333V8"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 4.00016L8.00001 1.3335L5.33334 4.00016"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.3335V10.0002"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
