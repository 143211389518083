// import React from 'react';
// import {
//   StyledGlobalJobBoardJobsInnerWrapper,
//   StyledGlobalJobBoardJobsMainWrapper,
// } from './global-job-board-jobs.styled';
// import GlobalJobBoardActiveJobs from './components/active-jobs/global-job-board-active-jobs';
// import GlobalJobBoardJustLanded from './components/just-landed-and-fetured/global-job-board-just-landed';
// import GlobalJobBoardFeatured from './components/just-landed-and-fetured/global-job-board-featured';
// import { useJobListings } from '../../hooks/job-listings-context';
// import { NavigateFunction } from 'react-router-dom';
// import { Spinner } from '../../global-job-board.styled';
// import { StyledGlobalJobBoardFeaturedJobsWrapper } from '../../../components/featured-jobs/global-job-board-featured-jobs.styled';

// interface GlobalJobBoardJobsProps {
//   navigate: NavigateFunction;
// }

// const GlobalJobBoardJobs: React.FC<GlobalJobBoardJobsProps> = ({
//   navigate,
// }) => {
//   const { jobListings } = useJobListings();

//   return (
//     <StyledGlobalJobBoardJobsMainWrapper>
//       <StyledGlobalJobBoardJobsInnerWrapper>
//         {jobListings.length > 0 ? (
//           <>
//             <GlobalJobBoardActiveJobs navigate={navigate} />
//             <StyledGlobalJobBoardFeaturedJobsWrapper>
//               <GlobalJobBoardJustLanded navigate={navigate} />
//               <GlobalJobBoardFeatured navigate={navigate} />
//             </StyledGlobalJobBoardFeaturedJobsWrapper>
//           </>
//         ) : (
//           <Spinner />
//         )}
//       </StyledGlobalJobBoardJobsInnerWrapper>
//     </StyledGlobalJobBoardJobsMainWrapper>
//   );
// };

// export default GlobalJobBoardJobs;

import React from 'react';
import {
  StyledGlobalJobBoardJobsInnerWrapper,
  StyledGlobalJobBoardJobsMainWrapper,
} from './global-job-board-jobs.styled';
import GlobalJobBoardActiveJobs from './components/active-jobs/global-job-board-active-jobs';
import GlobalJobBoardJustLanded from './components/just-landed-and-fetured/global-job-board-just-landed';
import GlobalJobBoardFeatured from './components/just-landed-and-fetured/global-job-board-featured';
import { useJobListings } from '../../hooks/job-listings-context';
import { NavigateFunction } from 'react-router-dom';
import { StyledGlobalJobBoardFeaturedJobsWrapper } from '../../../components/featured-jobs/global-job-board-featured-jobs.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { RotatingSpinner } from '../../../../../spinner-new/spinner-new';

interface GlobalJobBoardJobsProps {
  navigate: NavigateFunction;
}

const GlobalJobBoardJobs: React.FC<GlobalJobBoardJobsProps> = ({
  navigate,
}) => {
  const { noResults, jobListings } = useJobListings();

  return (
    <StyledGlobalJobBoardJobsMainWrapper>
      <StyledGlobalJobBoardJobsInnerWrapper>
        {noResults ? (
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={18}
            fontWeight={500}
            color={colors.neutral_400}
            margin="0"
          >
            No listings found. Please check back later!
          </Text>
        ) : jobListings.length === 0 ? (
          <RotatingSpinner />
        ) : (
          <>
            <GlobalJobBoardActiveJobs navigate={navigate} />
            <StyledGlobalJobBoardFeaturedJobsWrapper>
              <GlobalJobBoardJustLanded navigate={navigate} />
              <GlobalJobBoardFeatured navigate={navigate} />
            </StyledGlobalJobBoardFeaturedJobsWrapper>
          </>
        )}
      </StyledGlobalJobBoardJobsInnerWrapper>
    </StyledGlobalJobBoardJobsMainWrapper>
  );
};

export default GlobalJobBoardJobs;
