import React, { forwardRef } from 'react';
import heroImg from '../../lp-images/heroImg.png';
import {
  HeroBoxContainer,
  HeroBox,
  HeroContent,
  HeroHeadline,
  HeroParagraph,
  ImgContainer,
  HeroImg,
  HeroBtn,
  Linked,
} from './hero-section.styled';
import { LandingPageProps } from '../../landing-page';

export const HeroSection: React.FC<LandingPageProps> = forwardRef<
  HTMLDivElement,
  LandingPageProps
>((props, ref) => {
  return (
    <HeroBoxContainer id="home" ref={ref}>
      <HeroBox>
        <HeroContent>
          <HeroHeadline>
            Transform Your Hiring Process With Our Cutting-Edge SAAS Platform!
          </HeroHeadline>
          <HeroParagraph>
            Transform your hiring process with our AI recruiting solutions! Our
            AI-powered SaaS platform offers a personalized job dashboard and
            advanced AI resume screening to enhance candidate experience and
            streamline recruitment.
          </HeroParagraph>
          <Linked href="#pricing">
            <HeroBtn>Get Started</HeroBtn>
          </Linked>
        </HeroContent>
        <ImgContainer>
          <HeroImg src={heroImg} alt="hero-image" />
        </ImgContainer>
      </HeroBox>
    </HeroBoxContainer>
  );
});

HeroSection.displayName = 'HeroSection';
