import React from 'react';

export const PlaneIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87249 9.62839L10.1033 6.39763M13.4082 4.42595L10.6811 13.289C10.4367 14.0833 10.3144 14.4806 10.1036 14.6123C9.92078 14.7266 9.69441 14.7457 9.49528 14.663C9.26567 14.5678 9.07931 14.1957 8.70754 13.4522L6.98024 9.99756C6.92125 9.87957 6.89172 9.82082 6.85231 9.7697C6.81734 9.72433 6.77698 9.68346 6.73162 9.64849C6.68166 9.60998 6.62389 9.5811 6.51116 9.52474L3.0486 7.79346C2.30506 7.42169 1.93326 7.23563 1.83799 7.00602C1.75536 6.80689 1.77421 6.58031 1.88844 6.39748C2.02017 6.18666 2.41747 6.06418 3.21201 5.81971L12.0751 3.09261C12.6997 2.90041 13.0122 2.80439 13.2231 2.88185C13.4069 2.94932 13.5518 3.09406 13.6193 3.27783C13.6967 3.48872 13.6006 3.80101 13.4086 4.42502L13.4082 4.42595Z"
        stroke="#2E77B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
