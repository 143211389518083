import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { StyledSettingsJobPositionMainWrapper } from './job-position.styled';
import { DefaultComponentProps } from '../../../../../../../app-router/component-props';
import JobPositionTabHeading from './components/heading/job-position-tab-heading';
import JobPositionTabTable from './components/table/job-position-tab-table';
import { apiRoute } from '../../../../../../../../constants/api-constants';
import EditEmployeeJobPosition from './components/create-edit-delete-job-position/edit-job-position';
import DeleteEmployeeJobPosition from './components/create-edit-delete-job-position/delete-job-position';
import NewPagination from '../../../../../../../new-pagination/new-pagination';

interface JobPosition {
  id: number;
  createdAt: string;
  job_position: string;
}

const JobPositionTab: React.FC<DefaultComponentProps> = ({ user }) => {
  const [data, setData] = useState<JobPosition[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editJobPositionVisible, setEditJobPositionVisible] = useState(false);
  const [createNewJobPositionVisible, setCreateNewJobPositionVisible] =
    useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpType, setPopUpType] = useState<'success' | 'error'>('success');
  const [selectedJobPosition, setSelectedJobPosition] =
    useState<JobPosition | null>(null);
  const [deleteJobPositionVisible, setDeleteJobPositionVisible] =
    useState(false);

  const baseURL = apiRoute;

  const fetchData = () => {
    axios
      .get(`${baseURL}/employee_job_positions/${user.id}/${currentPage}`)
      .then((response) => {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, user.id]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCloseCreateNewJobPosition = (
    success: boolean,
    message?: string
  ) => {
    setCreateNewJobPositionVisible(false);
    if (message) {
      setPopUpMessage(message);
      setPopUpType(success ? 'success' : 'error');
      setPopUpVisible(true);
      if (success) {
        fetchData();
      }
      setTimeout(() => {
        setPopUpVisible(false);
      }, 3000);
    }
  };

  const handleEditJobPosition = (jobPosition: JobPosition) => {
    setSelectedJobPosition(jobPosition);
    setEditJobPositionVisible(true);
  };

  const handleCloseEditJobPosition = (success: boolean, message?: string) => {
    setEditJobPositionVisible(false);
    setSelectedJobPosition(null);
    if (message) {
      setPopUpMessage(message);
      setPopUpType(success ? 'success' : 'error');
      setPopUpVisible(true);
      if (success) {
        fetchData();
      }
      setTimeout(() => {
        setPopUpVisible(false);
      }, 3000);
    }
  };

  const handleDeleteJobPosition = (jobPosition: JobPosition) => {
    setSelectedJobPosition(jobPosition);
    setDeleteJobPositionVisible(true);
  };

  const handleCloseDeleteJobPosition = (success: boolean, message?: string) => {
    setDeleteJobPositionVisible(false);
    setSelectedJobPosition(null);
    if (message) {
      setPopUpMessage(message);
      setPopUpType(success ? 'success' : 'error');
      setPopUpVisible(true);
      if (success) {
        fetchData();
      }
      setTimeout(() => {
        setPopUpVisible(false);
      }, 3000);
    }
  };

  const closePopUp = () => {
    setCreateNewJobPositionVisible(false);
  };

  return (
    <StyledSettingsJobPositionMainWrapper>
      <JobPositionTabHeading
        user={user}
        createNewJobPositionVisible={createNewJobPositionVisible}
        popUpVisible={popUpVisible}
        popUpMessage={popUpMessage}
        popUpType={popUpType}
        onOpenCreateNewJobPosition={() => setCreateNewJobPositionVisible(true)}
        onCloseCreateNewJobPosition={handleCloseCreateNewJobPosition}
        closePopUp={closePopUp}
      />
      <JobPositionTabTable
        data={data}
        onEdit={handleEditJobPosition}
        onDelete={handleDeleteJobPosition}
      />
      <NewPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        filteredData={data}
      />
      {editJobPositionVisible && selectedJobPosition && (
        <EditEmployeeJobPosition
          onClose={handleCloseEditJobPosition}
          user={user}
          jobPosition={selectedJobPosition}
        />
      )}
      {deleteJobPositionVisible && selectedJobPosition && (
        <DeleteEmployeeJobPosition
          onClose={handleCloseDeleteJobPosition}
          user={user}
          jobPosition={selectedJobPosition}
        />
      )}
    </StyledSettingsJobPositionMainWrapper>
  );
};

export default JobPositionTab;
