import React, { useEffect, useState } from 'react';
import {
  LogInWrapper,
  LogInFormContainer,
  TextContainer,
  CompanyTitle,
  CompanySubtitle,
  Description,
  LogInFormWrapper,
} from '../../login/login.styled';
import logoTeam from '../../../logo_team.png';
import {
  SubTitle,
  Title,
  Wrapper,
  //   Button,
  //   ButtonsWrapper,
} from '../change-plan/change-plan.styled';
import { Xicon } from '../../icons/x-skill';

export const NotFoundPage = () => {
  const [secondsLeft, setSecondsLeft] = useState(10);

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          window.location.href = '/';
        }

        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
          alt="Company Logo"
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer maxWidth="929px" overflow="none">
          <Wrapper padding="40px" id="wrapper" isSignUp={true}>
            <Title>
              <Xicon />
              We cannot find the page you are looking for!
            </Title>
            <SubTitle>
              You will be redirected to the homepage in {secondsLeft} second
              {secondsLeft !== 1 ? 's' : ''}
            </SubTitle>
            {/* <ButtonsWrapper>
              <Button disabled={false} onClick={handleLogout}>
                Logout Now
              </Button>
            </ButtonsWrapper> */}
          </Wrapper>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
