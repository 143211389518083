import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

export const StyledReportingTabsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  background-color: ${colors.base_white};
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;

  .swiper-slide {
    border-radius: 16px;
  }

  .swiper-slide-active {
    /* background-color: ${colors.secondary_200}; // Change color of the active slide */
  }

  .swiper-pagination-bullet {
    background-color: ${colors.base_black}; // Customize bullet colors (if pagination is enabled)
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${colors.base_black}; // Customize navigation arrow colors
  }
`;
