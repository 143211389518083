interface GenericObject {
  // eslint-disable-next-line
  [key: string]: any;
}

export const combineData = (
  original: GenericObject,
  modified: GenericObject,
  keysToIgnore: string[]
): GenericObject => {
  const updatedObject: GenericObject = { ...original };

  Object.keys(modified).forEach((key) => {
    if (!keysToIgnore.includes(key)) {
      updatedObject[key] = modified[key];
    }
  });

  return updatedObject;
};

export const extractJsonFromString = (inputText: string) => {
  const regex = /{[\s\S]*?}|\[[\s\S]*?]/g;

  let match;
  while ((match = regex.exec(inputText)) !== null) {
    try {
      const potentialJson = JSON.parse(match[0]);

      return potentialJson;
    } catch (e) {
      console.error('Invalid JSON found:', match[0]);
    }
  }

  return null;
};
