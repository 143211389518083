import React from 'react';
import {
  IconWrapper,
  SearchInputContainer,
  StyledInput,
} from './search.styled';
import { SearchIcon } from '../../../../../icons/search';
import { useTimeOff } from '../../context/timeoff-context';

const TimeOffEmployeesSearch: React.FC = () => {
  const { setSearchTerm } = useTimeOff();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <SearchInputContainer>
      <StyledInput
        placeholder="Enter Name"
        type="text"
        onChange={handleSearchChange}
      />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </SearchInputContainer>
  );
};

export default TimeOffEmployeesSearch;
