import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Text, font } from '../../../../../style-utils/text';
import {
  Company,
  DefaultComponentProps,
} from '../../../../app-router/component-props';
import { colors } from '../../../../../style-utils/colors';
import { apiRoute } from '../../../../../constants/api-constants';
import {
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
} from '../../../employees/employees.styled';
import { ButtonContainer } from '../../../applicants/candidates/candidates.styled';
import { Wrapper } from './company-details';

const CompanyDetails: React.FC<DefaultComponentProps> = ({ user }) => {
  const [companies, setCompanies] = useState<Company[] | null>([]);
  // eslint-disable-next-line
  console.log(companies);
  console.log('user ');
  console.log(user);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(`${apiRoute}/all-companies`);
        console.log('RESSSSS:');
        console.log(response);

        setCompanies(response.data);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [user.id]);

  console.log(companies);

  return (
    <Wrapper>
      <div style={{ overflowX: 'auto' }}>
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh background={colors.primary_300} columnWidth={'120px'}>
                Start Date
              </StyledTh>
              <StyledTh columnWidth={'120px'}>End Date</StyledTh>
              <StyledTh columnWidth={'324px'}>Subscription Plan</StyledTh>
              <StyledTh columnWidth={'120px'}>Amount</StyledTh>
              <StyledTh columnWidth={'120px'}>Status</StyledTh>
              <StyledTh columnWidth={'120px'}>Action</StyledTh>
            </StyledTr>
          </StyledThead>
          <tbody>
            {companies?.map((company, index) => (
              <StyledTr key={index}>
                <StyledTd>Nema Data</StyledTd>
                <StyledTd>Nema Data</StyledTd>
                <StyledTd>{company?.payment_plan}</StyledTd>
                <StyledTd>Nema Data</StyledTd>
                <StyledTd>
                  <Text color={company?.status === 'Active' ? 'green' : 'red'}>
                    {company?.status}
                  </Text>
                </StyledTd>
                <StyledTd>
                  <ButtonContainer
                    backgroundColor={colors.secondary_400}
                    // onClick={() => handleEditUser(user.id)}
                  >
                    <Text
                      mt={0}
                      mb={0}
                      align="center"
                      pl={20}
                      pr={20}
                      fontFamily={font.family}
                      fontWeight={700}
                    >
                      Edit
                    </Text>
                  </ButtonContainer>
                </StyledTd>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </Wrapper>
  );
};

export default CompanyDetails;
