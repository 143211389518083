import React, { useState } from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Sidebar } from '../../layout/sidebar/sidebar';
import { Chat } from '../../chat/chat';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { NavigateFunction } from 'react-router-dom';
import ReportingMain from './tabs/main/reporting-main';
import ReportingEmployeesMain from './tabs/employees/reporting-employees-main';
import ReportingContractorsMain from './tabs/contractors/reporting-contractors-main';
import ReportingTimeOffMain from './tabs/time-off/reporting-time-off-main';
import ReportingSalariesMain from './tabs/salaries/reporting-salaries-main';

interface ReportingProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

const Reporting: React.FC<ReportingProps> = ({
  company,
  navigate,
  setUser,
}) => {
  const height = useWindowHeight();

  const [activeTabId, setActiveTabId] = useState<number>(0);

  const tabs = [
    <ReportingMain
      key={0}
      setActiveTabId={setActiveTabId}
      activeTabId={activeTabId}
    />,
    <ReportingEmployeesMain key={1} setActiveTabIdToParent={setActiveTabId} />,
    <ReportingContractorsMain
      key={2}
      setActiveTabIdToParent={setActiveTabId}
    />,
    <ReportingTimeOffMain key={3} setActiveTabIdToParent={setActiveTabId} />,
    <ReportingSalariesMain key={4} setActiveTabIdToParent={setActiveTabId} />,
  ];

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} setUser={setUser} user={company} />
      <MainContentWrapper>
        <Sidebar
          company={company}
          selectedOption={''}
          navigate={navigate}
          setUser={setUser}
        />
        <ContentWrapper>{tabs[activeTabId]}</ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};

export default Reporting;
