import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../../../constants/api-constants';
import { JobListing } from '../../../../components/featured-jobs/global-job-board-featured-jobs-types';

interface JobListingsResponse {
  jobListings: JobListing[];
}

const useGlobalJobBoardSimilarJobs = (
  jobId: string | undefined,
  jobTitle: string | undefined
) => {
  const [jobListings, setJobListings] = useState<JobListing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!jobId || !jobTitle) return;

    axios
      .get<JobListingsResponse>(
        `${apiRoute}/global-job-board/similar-job-listings?jobId=${jobId}&jobTitle=${encodeURIComponent(
          jobTitle
        )}`
      )
      .then((response) => {
        setJobListings(response.data.jobListings);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching similar job listings:', err);
        setError('Failed to fetch similar job listings');
        setLoading(false);
      });
  }, [jobId, jobTitle]);

  return { jobListings, loading, error };
};

export default useGlobalJobBoardSimilarJobs;
