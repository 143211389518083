import React from 'react';
import { StyledReportingTabWrapper } from '../../../tabs.styled';
import ReportingEmployeesTable from '../../../../components/tables/reporting-employees-table/reporting-employees-table';
import ReportingLineChart from '../../../../components/charts/line-chart/line-chart';
import { useReportingContractorsData } from '../../use-reporting-contractors-data';

const ReportingContractRenewal: React.FC = () => {
  const { lineChartData, reportingContractorsTableContractRenewalData } =
    useReportingContractorsData();

  return (
    <StyledReportingTabWrapper>
      <ReportingLineChart
        name="Average contractors"
        lineChartData={lineChartData}
      />
      <ReportingEmployeesTable
        name="Contract Renewal"
        tableData={reportingContractorsTableContractRenewalData}
      />
    </StyledReportingTabWrapper>
  );
};

export default ReportingContractRenewal;
