// import axios from 'axios';
import React from 'react';
// import React, { useEffect, useState } from 'react';
// import { apiRoute } from '../../../../../constants/api-constants';
import {
  StyledInfoCircleWrapper,
  StyledInfoInnerCircle,
} from '../../../../super-admin/home.styled';
import { Text, font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';

export const InfoCharts = () => {
  //   const baseURL = apiRoute;

  const circlesData = [
    {
      id: 1,
      text: 'Active',
      number: '13',
      percentage: 13,
      backgroundColor: colors.secondary_500,
    },
    {
      id: 2,
      text: 'Completed',
      number: '15',
      percentage: 15,
      backgroundColor: colors.primary_500,
    },
  ];

  //!! This is when we have a route from back-end

  //   const [circlesData, setCirclesData] = useState([
  //     {
  //       id: 1,
  //       text: 'Active',
  //       number: '/',
  //       percentage: 0,
  //     },
  //     {
  //       id: 2,
  //       text: 'Completed',
  //       number: '/',
  //       percentage: 0,
  //     },
  //   ]);

  //   useEffect(() => {
  //     const fetchCirclesData = () => {
  //       axios
  //         .get(`${baseURL}/survey/circles-data`)
  //         .then((response) => {
  //           const { totalActiveSurveys, totalCompletedSurveys } = response.data;

  //           setCirclesData([
  //             {
  //               id: 1,
  //               text: 'Active',
  //               number: totalActiveSurveys,
  //               percentage: totalActiveSurveys / 100,
  //             },
  //             {
  //               id: 2,
  //               text: 'Completed',
  //               number: totalCompletedSurveys,
  //               percentage: totalCompletedSurveys / 100,
  //             },
  //           ]);
  //         })
  //         .catch((error) => {
  //           console.error(
  //             'There is an error for fetching circles survey data!',
  //             error
  //           );
  //         });
  //     };
  //   }, []);

  return (
    <div
      style={{
        display: 'flex',
        boxSizing: 'border-box',
        padding: '32px 48px',
        gap: '24px',
        background: 'white',
        borderRadius: '8px',
      }}
    >
      {circlesData.map((circle) => (
        <StyledInfoCircleWrapper
          key={circle.id}
          backgroundColor={circle.backgroundColor}
          percentage={circle.percentage}
        >
          <StyledInfoInnerCircle>
            <Text
              fontFamily={font.family}
              fontSize={16}
              fontWeight={500}
              color={colors.secondary_900}
              margin="0"
            >
              {circle.number}
            </Text>
            <Text
              fontFamily={font.family}
              fontSize={14}
              fontWeight={500}
              color={colors.secondary_900}
              margin="0"
              align="center"
            >
              {circle.text.split(' ')[0]}
              <br />
              {circle.text.split(' ')[1]}
            </Text>
          </StyledInfoInnerCircle>
        </StyledInfoCircleWrapper>
      ))}
    </div>
  );
};
