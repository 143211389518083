import React from 'react';

import { StyledEmployeesTimeoffFilter } from './filter.styled';
import { useTimeOff } from '../../context/timeoff-context';

const TimeOffEmployeesFilter: React.FC = () => {
  const { setStatus } = useTimeOff();

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value);
  };

  return (
    <StyledEmployeesTimeoffFilter onChange={handleStatusChange}>
      <option value="All">All</option>
      <option value="Pending">Pending</option>
      <option value="Confirmed">Confirmed</option>
      <option value="Denied">Denied</option>
    </StyledEmployeesTimeoffFilter>
  );
};

export default TimeOffEmployeesFilter;
