import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';

export const StyledGlobalJobBoardHeroRightComponentMainWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  border-radius: 50px;
`;

export const StyledGlobalJobBoardHeroRightComponentImage = styled.img`
  box-sizing: border-box;
`;

export const StyledGlobalJobBoardHeroRightComponentTooltip = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  background-color: ${colors.white};
  opacity: 0.9;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  ${({ top }) => top && `top: ${top};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ left }) => left && `left: ${left};`}
`;

export const StyledGlobalJobBoardHeroRightComponentBottomTooltip = styled(
  StyledGlobalJobBoardHeroRightComponentTooltip
)`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 32px;
`;

export const StyledGlobalJobBoardHeroRightComponentBottomTooltipItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledGlobalJobBoardHeroRightComponentBottomTooltipItemSeparator = styled.div`
  box-sizing: border-box;
  height: 38px;
  width: 1px;
  background-color: ${colors.secondary_50};
`;
