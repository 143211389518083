import React from 'react';

interface ReportingIconProps {
  iconType: string;
  fill?: string;
}

const ReportingIcon: React.FC<ReportingIconProps> = ({ iconType, fill }) => {
  switch (iconType) {
    case 'rightArrow':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 5.5L21 12.5M21 12.5L14 19.5M21 12.5L3 12.5"
            stroke={fill ?? '#111827'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'mainTabEmployees':
      return (
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.6665 28.5V25.8333C30.6656 24.6516 30.2723 23.5037 29.5483 22.5698C28.8243 21.6358 27.8107 20.9688 26.6665 20.6733"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6668 28.5V25.8333C22.6668 24.4188 22.1049 23.0623 21.1047 22.0621C20.1045 21.0619 18.748 20.5 17.3335 20.5H6.66683C5.25234 20.5 3.89579 21.0619 2.89559 22.0621C1.8954 23.0623 1.3335 24.4188 1.3335 25.8333V28.5"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.3335 4.67334C22.4807 4.96707 23.4975 5.63427 24.2237 6.56975C24.9498 7.50523 25.344 8.65578 25.344 9.84001C25.344 11.0242 24.9498 12.1748 24.2237 13.1103C23.4975 14.0457 22.4807 14.7129 21.3335 15.0067"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.9998 15.1667C14.9454 15.1667 17.3332 12.7789 17.3332 9.83333C17.3332 6.88781 14.9454 4.5 11.9998 4.5C9.05432 4.5 6.6665 6.88781 6.6665 9.83333C6.6665 12.7789 9.05432 15.1667 11.9998 15.1667Z"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'mainTabContractors':
      return (
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3335 28.5V25.8333C21.3335 24.4188 20.7716 23.0623 19.7714 22.0621C18.7712 21.0619 17.4147 20.5 16.0002 20.5H6.66683C5.25234 20.5 3.89579 21.0619 2.89559 22.0621C1.8954 23.0623 1.3335 24.4188 1.3335 25.8333V28.5"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6665 15.1667L25.3332 17.8333L30.6665 12.5"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3333 15.1667C14.2789 15.1667 16.6667 12.7789 16.6667 9.83333C16.6667 6.88781 14.2789 4.5 11.3333 4.5C8.38781 4.5 6 6.88781 6 9.83333C6 12.7789 8.38781 15.1667 11.3333 15.1667Z"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'mainTabVacations':
      return (
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9998 29.8332C23.3636 29.8332 29.3332 23.8636 29.3332 16.4998C29.3332 9.13604 23.3636 3.1665 15.9998 3.1665C8.63604 3.1665 2.6665 9.13604 2.6665 16.4998C2.6665 23.8636 8.63604 29.8332 15.9998 29.8332Z"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 8.5V16.5L21.3333 19.1667"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'mainTabSalaries':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.0002 5.3335H4.00016C2.5274 5.3335 1.3335 6.5274 1.3335 8.00016V24.0002C1.3335 25.4729 2.5274 26.6668 4.00016 26.6668H28.0002C29.4729 26.6668 30.6668 25.4729 30.6668 24.0002V8.00016C30.6668 6.5274 29.4729 5.3335 28.0002 5.3335Z"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.3335 13.3335H30.6668"
            stroke={fill ?? 'black'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'mainTabStatusUp':
      return (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4355_2544)">
            <path
              d="M15.3332 4.5L8.99984 10.8333L5.6665 7.5L0.666504 12.5"
              stroke="#059669"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3335 4.5H15.3335V8.5"
              stroke="#059669"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4355_2544">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 'mainTabStatusDown':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4355_2575)">
            <path
              d="M15.3332 12L8.99984 5.66667L5.6665 9L0.666504 4"
              stroke="#E94E3C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3335 12H15.3335V8"
              stroke="#E94E3C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4355_2575">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'employeesTableActionSearch':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.9999 21.5004L16.6499 17.1504"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'employeesTableActionFilter':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 3.5H2L10 12.96V19.5L14 21.5V12.96L22 3.5Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'employeesTableActionDownload':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 15.5V19.5C21 20.0304 20.7893 20.5391 20.4142 20.9142C20.0391 21.2893 19.5304 21.5 19 21.5H5C4.46957 21.5 3.96086 21.2893 3.58579 20.9142C3.21071 20.5391 3 20.0304 3 19.5V15.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 10.5L12 15.5L17 10.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 15.5V3.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'employeeTabTurnover':
      return (
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4411_2699)">
            <path
              d="M31.0003 8L18.3337 20.6667L11.667 14L1.66699 24"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 8H31V16"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4411_2699">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(0.333496)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 'employeeTabPerformanceOverview':
      return (
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3333 2.6665H8.66667C7.95942 2.6665 7.28115 2.94746 6.78105 3.44755C6.28095 3.94765 6 4.62593 6 5.33317V26.6665C6 27.3737 6.28095 28.052 6.78105 28.5521C7.28115 29.0522 7.95942 29.3332 8.66667 29.3332H24.6667C25.3739 29.3332 26.0522 29.0522 26.5523 28.5521C27.0524 28.052 27.3333 27.3737 27.3333 26.6665V10.6665L19.3333 2.6665Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.9997 22.6665H11.333"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.9997 17.3335H11.333"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9997 12H12.6663H11.333"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.333 2.6665V10.6665H27.333"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'reportingTabContractUtilization':
      return (
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3335 28.5V25.8333C21.3335 24.4188 20.7716 23.0623 19.7714 22.0621C18.7712 21.0619 17.4147 20.5 16.0002 20.5H6.66683C5.25234 20.5 3.89579 21.0619 2.89559 22.0621C1.8954 23.0623 1.3335 24.4188 1.3335 25.8333V28.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6665 15.1667L25.3332 17.8333L30.6665 12.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3333 15.1667C14.2789 15.1667 16.6667 12.7789 16.6667 9.83333C16.6667 6.88781 14.2789 4.5 11.3333 4.5C8.38781 4.5 6 6.88781 6 9.83333C6 12.7789 8.38781 15.1667 11.3333 15.1667Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'reportingTabContractRenewal':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3334 28V25.3333C21.3334 23.9188 20.7715 22.5623 19.7713 21.5621C18.7711 20.5619 17.4145 20 16 20H6.66671C5.25222 20 3.89567 20.5619 2.89547 21.5621C1.89528 22.5623 1.33337 23.9188 1.33337 25.3333V28"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6666 10.6665V18.6665"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.6666 14.6665H22.6666"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3333 14.6667C14.2789 14.6667 16.6667 12.2789 16.6667 9.33333C16.6667 6.38781 14.2789 4 11.3333 4C8.38781 4 6 6.38781 6 9.33333C6 12.2789 8.38781 14.6667 11.3333 14.6667Z"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'salariesTabPayrollOverview':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 28H4"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M5.3335 21.3332V18.6665"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M16 16V12"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10.6665 21.3335V13.3335"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M21.3335 17.3332V14.6665"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M26.6665 19.9998V6.6665"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
    case 'salariesTabSalaryTrend':
      return (
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 14L17 18L14.3333 15.3333L10.3333 19.3333"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.0003 6.6665H8.86699C7.74689 6.6665 7.18683 6.6665 6.75901 6.88449C6.38269 7.07624 6.07673 7.3822 5.88498 7.75852C5.66699 8.18635 5.66699 8.7464 5.66699 9.8665V23.4665C5.66699 24.5866 5.66699 25.1467 5.88498 25.5745C6.07673 25.9508 6.38269 26.2568 6.75901 26.4485C7.18683 26.6665 7.74689 26.6665 8.86699 26.6665H22.467C23.5871 26.6665 24.1472 26.6665 24.575 26.4485C24.9513 26.2568 25.2573 25.9508 25.449 25.5745C25.667 25.1467 25.667 24.5866 25.667 23.4665V13.3332"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <circle cx="25.6667" cy="6.66667" r="2.66667" fill="#010101" />
        </svg>
      );
    case 'salariesTabCompensationComparation':
      return (
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2664 10.4001C14.994 7.19371 17.8615 4.8001 21.288 4.8001C25.2644 4.8001 28.488 8.02365 28.488 12.0001C28.488 15.6834 25.7221 18.7208 22.154 19.1485M9.46641 8.0001L12.6664 4.8001L9.46641 1.6001M3.06641 11.2001V9.06676C3.06641 6.71035 4.97666 4.8001 7.33307 4.8001H10.5331M23.8664 24.0001L20.6664 27.2001L23.8664 30.4001M30.2664 20.8001V22.9334C30.2664 25.2898 28.3562 27.2001 25.9997 27.2001H22.7997M17.4664 21.6001C17.4664 25.5765 14.2429 28.8001 10.2664 28.8001C6.28996 28.8001 3.06641 25.5765 3.06641 21.6001C3.06641 17.6236 6.28996 14.4001 10.2664 14.4001C14.2429 14.4001 17.4664 17.6236 17.4664 21.6001Z"
            stroke="#010101"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'employeesTableSuccess':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3333 4L5.99996 11.3333L2.66663 8"
            stroke="#059669"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default ReportingIcon;
