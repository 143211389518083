import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useCheckAuth = (
  // eslint-disable-next-line
  user: any,
  refetchUser: () => Promise<void>,
  loading: boolean
) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyUser = async () => {
      if (!loading) {
        if (!user) {
          try {
            await refetchUser();
            if (!user) {
              if (location.pathname !== '/employee/blocked') {
                navigate('/login');
              }
            }
          } catch (error) {
            console.error('Error verifying user:', error);
            if (location.pathname !== '/employee/blocked') {
              navigate('/login');
            }
          }
        }
      }
    };
    if (user) verifyUser();
  }, [user, loading, refetchUser, navigate, location.pathname]);
};
