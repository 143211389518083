import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledReportingTabMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px;
  height: 170.5px;
`;

export const StyledReportingTabIconAndArrow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledReportingTabTitleAndData = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledReportingTabDescriptionAndValue = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledValueWrapper = styled.div<{ backgroundColor?: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor || colors.light_green};
`;
