import React from 'react';

// eslint-disable-next-line

interface UpArrowProps {
  color?: string;
}

export const UpArrow: React.FC<UpArrowProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M3 11L8 6L13 11"
        stroke={color ? color : '#ffffff'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
