import React from 'react';

export const GendersIcon = () => {
  return (
    <svg
      width="90"
      height="94"
      viewBox="0 0 100 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3579_6643)" filter="url(#filter0_d_3579_6643)">
        <path
          d="M58.5768 36.7381C61.6603 36.7381 64.1599 34.2384 64.1599 31.1549C64.1599 28.0714 61.6603 25.5718 58.5768 25.5718C55.4933 25.5718 52.9937 28.0714 52.9937 31.1549C52.9937 34.2384 55.4933 36.7381 58.5768 36.7381Z"
          fill="#E94E3C"
        />
        <path
          d="M69.3831 54.3162L66.2023 41.8357C65.6331 39.6092 63.6325 38.0522 61.339 38.0522H55.8145C53.5209 38.0522 51.512 39.6092 50.9512 41.8357L47.7704 54.3162C47.3016 56.1661 48.6995 57.9657 50.608 57.9657H52.9852V65.9178C52.9852 67.3073 54.1069 68.4289 55.4964 68.4289H61.6487C63.0382 68.4289 64.1599 67.3073 64.1599 65.9178V57.9657H66.5455C68.454 57.9657 69.8518 56.1661 69.3831 54.3162Z"
          fill="#E94E3C"
        />
        <path
          d="M41.1408 38.0605H35.8255C33.0549 38.0605 30.8032 40.3122 30.8032 43.0829V52.1063C30.8032 53.3535 31.7072 54.3831 32.8959 54.584V65.9177C32.8959 67.3072 34.0175 68.4289 35.407 68.4289H41.5594C42.9489 68.4289 44.0705 67.3072 44.0705 65.9177V54.584C45.2591 54.3831 46.1632 53.3535 46.1632 52.1063V43.0829C46.1632 40.3039 43.9199 38.0605 41.1408 38.0605Z"
          fill="#2E77B8"
        />
        <path
          d="M38.4874 36.7381C41.5709 36.7381 44.0706 34.2384 44.0706 31.1549C44.0706 28.0714 41.5709 25.5718 38.4874 25.5718C35.404 25.5718 32.9043 28.0714 32.9043 31.1549C32.9043 34.2384 35.404 36.7381 38.4874 36.7381Z"
          fill="#2E77B8"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3579_6643"
          x="-1.28955"
          y="0.571777"
          width="102.857"
          height="102.857"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3579_6643"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3579_6643"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3579_6643">
          <rect
            width="42.8571"
            height="42.8571"
            fill="white"
            transform="translate(28.7104 25.5718)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
