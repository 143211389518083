import React, { useEffect, useState } from 'react';
import { SignUpData } from '../../../hooks/use-auth-forms';
import { InputContainer } from '../../../login/login.styled';
import { SubscriptionPlan } from '../../super-admin/subscription-plans/interface';
import { loadSubscriptionPlans } from '../../../../function-utils/subscription-plan-utils';
import { getCurrencySymbol } from '../../../../function-utils/currency-converter';
import {
  WarningMessage,
  Container,
  PlansWrapper,
  Header,
  HeaderItem,
  FirstHeaderItem,
  FeatureRow,
  FeatureName,
  FeatureItem,
  PriceRow,
  PriceItem,
  PriceLabel,
  SelectRow,
  SelectLabel,
  SelectItem,
  SelectButton,
} from './payment-plans.styled';
import { CheckOnIcon } from '../../../icons/check-on';
import { CheckOffIcon } from '../../../icons/check-off';
import { RotatingSpinner } from '../../../spinner-new/spinner-new';

interface PaymentPlansTableProps {
  setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData?: SignUpData;
  setSelectedPlan?: (planId: string, amount: number) => void;
  plans?: SubscriptionPlan[];
  isSignUp: boolean;
  defaultSelectedPlanId: string | null;
  // eslint-disable-next-line
  user: any;
  isMonthly: boolean;
  selectedPlanId?: string | null;
}

export const PaymentPlansTable: React.FC<PaymentPlansTableProps> = ({
  setSignUpData,
  signUpData,
  setSelectedPlan,
  plans: initialPlans,
  isSignUp,
  defaultSelectedPlanId,
  user,
  isMonthly,
  selectedPlanId,
}) => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>(
    initialPlans?.filter((plan) => plan.status === 'active') || []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultPlanSet, setDefaultPlanSet] = useState<boolean>(false); // To prevent multiple sets

  // Fetch subscription plans if not provided
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const fetchedPlans = await loadSubscriptionPlans();
        const activePlans = fetchedPlans.filter(
          (plan) => plan.status === 'active'
        );
        const sortedActivePlans = activePlans.sort(
          (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
        );
        setPlans(sortedActivePlans);
        console.log('Fetched and sorted active plans:', sortedActivePlans);
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!initialPlans) {
      fetchPlans();
    } else {
      const sortedInitialPlans = initialPlans
        .filter((plan) => plan.status === 'active')
        .sort(
          (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
        );
      setPlans(sortedInitialPlans);
      console.log('Sorted initial plans:', sortedInitialPlans);
    }
  }, [initialPlans]);

  useEffect(() => {
    console.log('useEffect triggered');
    console.log('Current signUpData:', signUpData);
    console.log('DefaultSelectedPlanId:', defaultSelectedPlanId);
    console.log('SelectedPlanId:', selectedPlanId);
    console.log('Loaded plans:', plans);

    if (!setSignUpData || !signUpData) {
      console.warn('setSignUpData or signUpData is not provided.');

      return;
    }

    // Check if payment_plan_id is already set
    if (!signUpData.payment_plan_id && !defaultPlanSet) {
      let planToSet: string | null = null;

      if (defaultSelectedPlanId) {
        // Enhanced Comparison with Detailed Logging
        const defaultPlanExists = plans.some((plan) => {
          const planIdStr = plan.id.toString().trim();
          const defaultIdStr = defaultSelectedPlanId?.toString().trim();
          console.log(
            `Comparing plan.id.toString(): '${planIdStr}' with defaultSelectedPlanId: '${defaultIdStr}'`
          );

          return planIdStr === defaultIdStr;
        });

        if (defaultPlanExists) {
          planToSet = defaultSelectedPlanId;
          console.log(`DefaultSelectedPlanId ${defaultSelectedPlanId} exists.`);
        } else {
          console.warn(
            `DefaultSelectedPlanId ${defaultSelectedPlanId} does not exist in the loaded plans.`
          );
        }
      } else if (selectedPlanId) {
        // Fallback to selectedPlanId if default is not provided
        const selectedPlanExists = plans.some(
          (plan) => plan.id.toString() === selectedPlanId
        );
        if (selectedPlanExists) {
          planToSet = selectedPlanId;
          console.log(`SelectedPlanId ${selectedPlanId} exists.`);
        } else {
          console.warn(
            `SelectedPlanId ${selectedPlanId} does not exist in the loaded plans.`
          );
        }
      }

      if (planToSet !== null) {
        console.log('planToSet:::::::');
        console.log(planToSet);
        setSignUpData((prev) => ({
          ...prev,
          payment_plan_id: planToSet as string,
        }));
        setDefaultPlanSet(true); // Prevent re-setting
        console.log(`Payment_plan_id set to ${planToSet}`);
      } else {
        console.warn('No valid plan to set as payment_plan_id.');
      }
    } else if (signUpData.payment_plan_id) {
      console.log(
        `payment_plan_id is already set to ${signUpData.payment_plan_id}`
      );
    }
  }, [
    defaultSelectedPlanId,
    selectedPlanId,
    setSignUpData,
    signUpData,
    plans,
    defaultPlanSet,
  ]);

  // Handle plan selection
  const handlePlanSelect = (
    event: React.MouseEvent<HTMLButtonElement>,
    plan: SubscriptionPlan
  ) => {
    event.preventDefault();
    const selectedPrice = isMonthly
      ? parseFloat(plan.price.amount) * 100
      : plan.annual_price
      ? parseFloat(plan.annual_price.amount) * 100
      : parseFloat(plan.price.amount) * 100;

    if (setSignUpData && signUpData) {
      setSignUpData({
        ...signUpData,
        payment_plan_id: plan.id.toString(),
      });
      console.log(`Plan ${plan.id} selected by user.`);
    }
    if (setSelectedPlan) {
      setSelectedPlan(plan.id.toString(), selectedPrice);
      console.log(
        `SelectedPlan function called with id ${plan.id} and amount ${selectedPrice}`
      );
    }
  };

  console.log('Plans:', plans);
  console.log('Sign Up Data Payment Plan:', signUpData?.payment_plan_id);
  console.log('Default Selected Plan ID:', defaultSelectedPlanId);

  // Determine feature availability
  const featureAvailability: { [feature: string]: boolean } = {};

  const allFeatures = Array.from(
    new Set(plans.flatMap((plan) => plan.features))
  );

  allFeatures.forEach((feature) => {
    featureAvailability[feature] = plans.every((plan) =>
      plan.features.includes(feature)
    );
  });

  // Sort features based on availability
  const sortedFeatures = allFeatures.sort((a, b) => {
    const aAvailableInAll = featureAvailability[a] ? 1 : 0;
    const bAvailableInAll = featureAvailability[b] ? 1 : 0;

    if (aAvailableInAll === bAvailableInAll) {
      return a.localeCompare(b);
    }

    return bAvailableInAll - aAvailableInAll;
  });

  if (loading) {
    return <RotatingSpinner />;
  }

  if (plans.length === 0) {
    return (
      <WarningMessage>
        No active subscription plans are available at the moment.
      </WarningMessage>
    );
  }

  return (
    <>
      <InputContainer>
        {!isSignUp && !user?.subscription_plan_id && (
          <WarningMessage>
            This user is not subscribed to any plan.
          </WarningMessage>
        )}
      </InputContainer>
      <Container>
        <PlansWrapper>
          {/* Header */}
          <Header>
            <FirstHeaderItem>Plans Features</FirstHeaderItem>
            {plans.map((plan) => (
              <HeaderItem key={plan.id}>
                <div>{plan.title}</div>
              </HeaderItem>
            ))}
          </Header>

          {/* Features */}
          {sortedFeatures.map((feature) => (
            <FeatureRow key={feature}>
              <FeatureName>{feature}</FeatureName>
              {plans.map((plan) => (
                <FeatureItem
                  key={`${plan.id}-${feature}`}
                  isAvailable={plan.features.includes(feature)}
                >
                  {plan.features.includes(feature) ? (
                    <CheckOnIcon />
                  ) : (
                    <CheckOffIcon />
                  )}
                </FeatureItem>
              ))}
            </FeatureRow>
          ))}

          {/* Price Row */}
          <PriceRow>
            <PriceLabel></PriceLabel>
            {plans.map((plan) => {
              const displayPrice = isMonthly
                ? plan.price
                : plan.annual_price || plan.price;

              return (
                <PriceItem key={`${plan.id}-price`}>
                  {`${getCurrencySymbol(displayPrice.currency)} ${
                    displayPrice.amount
                  } ${isMonthly ? '/Month' : '/Year'}`}
                </PriceItem>
              );
            })}
          </PriceRow>

          {/* Select Row */}
          <SelectRow>
            <SelectLabel></SelectLabel>
            {plans.map((plan) => (
              <SelectItem key={`${plan.id}-select`}>
                <SelectButton
                  onClick={(e) => handlePlanSelect(e, plan)}
                  selected={
                    signUpData?.payment_plan_id.toString() ===
                    plan.id.toString()
                  }
                >
                  {signUpData?.payment_plan_id.toString() === plan.id.toString()
                    ? 'Selected Plan'
                    : 'Select Plan'}
                </SelectButton>
              </SelectItem>
            ))}
          </SelectRow>
        </PlansWrapper>
      </Container>
    </>
  );
};
