import styled, { CSSProperties } from 'styled-components';
import { colors } from '../../style-utils/colors';
import { font } from '../../style-utils/text';

export const SignUpFormInputs = styled.div`
  height: 100%;
`;

export const PasswordReset = styled.div`
  color: var(--Primary-700, #aa000d);
  text-align: right;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const ShowHideText = styled.div`
  position: absolute;
  right: 16px;
  top: 33.33%;
`;

export const BlueOutlineButton = styled.button`
  padding: 8px 40px;
  gap: 8px;
  border-radius: 4px;
  border: 2px solid ${colors.secondary_600};
  cursor: pointer;
  border-radius: 4px;
`;

export const BlueButton = styled.button<{ disabled?: boolean }>`
  padding: 8px 40px;
  gap: 8px;
  border: 2px solid ${colors.secondary_600};
  background: ${({ disabled }) =>
    disabled ? colors.grey_light : colors.secondary_600};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  margin-left: 10px;
  color: ${({ disabled }) => (disabled ? colors.grey_light : 'white')};
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    background: ${({ disabled }) =>
      disabled ? colors.grey_light : colors.secondary_800};
  }
`;

export const InvisibleButton = styled.button`
  margin-left: 10px;
  padding: 8px 40px;
  gap: 8px;
  cursor: pointer;
  background: none;
  border: none;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 32px;
`;

export const LoginSignupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.primary_500};
  border-radius: 17px;
`;

export const LoginOrSignupButton = styled.div<{ isSelected: boolean }>`
  border-radius: 16px;
  flex: 1;
  background: ${({ isSelected }) => isSelected && `${colors.primary_500};`};
  cursor: pointer;
`;

export const eyeIconStyles: CSSProperties = {
  position: 'absolute',
  zIndex: '1',
  bottom: '10px',
  cursor: 'pointer',
  width: '20px',
  height: '20px',
  right: '15px',
  color: '#637083',
};

export const SignUpFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const LogInFormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LogInWrapper = styled.div<{ justifyContent?: string }>`
  display: flex;
  ${({ justifyContent }) =>
    justifyContent
      ? `justify-content: ${justifyContent};`
      : `justify-content: center;`}
  align-items: center;
  height: 100svh;
  width: 100%;
  background: ${colors.primary_400};
  /* this margin is added for the space between footer and the backgroud */
  margin-bottom: -80px;
`;

export const LogInFormContainer = styled.div<{
  overflow: string;
  width?: number;
  justifyContent?: string;
  maxWidth?: string;
  padding?: string;
  minWidth?: string;
}>`
  width: ${({ width }) => (width ? `${width}%;` : `80%;`)};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth};` : `750px;`)};
  padding: ${({ padding }) => (padding ? `${padding}px;` : `32px;`)};
  min-width: 600px;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth};` : `600px;`)};

  max-height: 100%;
  overflow: ${({ overflow }) => overflow && overflow};
  flex-direction: row;
  justify-content: center;
  border-radius: 16px;
`;

export const LogInFormContainer2 = styled.div<{
  overflow: string;
  width?: number;
  justifyContent?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? `${width}%` : `80%`)};
  max-width: 750px;
  padding: 32px;
  overflow: ${({ overflow }) => overflow};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;

export const LogInForm = styled.form<{ isStep4?: boolean }>`
  border-radius: 16px;
  width: 80%;
  width: ${({ isStep4 }) => (isStep4 ? '100%' : '80%')};

  max-width: 100%;
  padding: 40px;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: white;

  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const LogInTitle = styled.p<{ isSelected?: boolean }>`
  padding: 16px;
  font-family: ${font.family};
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  text-align: center;
  color: ${({ isSelected }) =>
    isSelected ? `white;` : `${colors.grey_dark};`};
`;

export const LogInSubTitle = styled.p`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  color: ${colors.black};
`;

export const TextContainer = styled.div`
  background: ${colors.creme_50};
  height: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  color: ${colors.white};
  align-items: flex-start;
  border-radius: 0 8px 8px 0;
`;

export const CompanyTitle = styled.h4`
  font-family: ${font.family};
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  color: black;
  margin-top: 110px;
`;

export const CompanySubtitle = styled.h2`
  font-family: ${font.family};
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  color: black;
`;

export const Description = styled.p`
  max-width: 300px;
  font-family: ${font.family};
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  color: black;
`;
export const ButtonContainer = styled.button<{
  mb?: number;
  mr?: number;
  ml?: number;
  mt?: number;
  backgroundColor?: string;
  isFontWeight?: boolean;
  display?: string;
  borderRadius?: string;
  color?: string;
  isDashboardReview?: boolean;
  padding?: string;
  width?: string;
  justifyContent?: string;
}>`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'teal'};
  width: ${({ width }) =>
    width ? width : 'fit-content'}; // Use width prop or default to fit-content
  height: 48px;
  border: none;
  transition: all 0.3s ease-out;
  color: ${({ color }) => (color ? color : '#fff')};
  cursor: pointer;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '8px'};
  display: ${({ display }) => (display ? `${display}` : 'flex')};
  flex-direction: row;
  justify-content: ${({ justifyContent }) =>
    justifyContent
      ? justifyContent
      : 'space-between'}; // Use justifyContent prop or default to space-between
  align-items: center;
  margin-top: ${({ mt }) => mt}px;
  margin-bottom: ${({ mb }) => mb}px;
  margin-right: ${({ mr }) => mr}px;
  margin-left: ${({ ml }) => ml}px;
  box-shadow: 2px 2px 8px 0px #0000001a;
  font-weight: ${({ isFontWeight }) => (isFontWeight ? '700' : '400')};
  padding: ${({ padding, isDashboardReview }) =>
    isDashboardReview
      ? '12px 32px'
      : padding || '16px'}; // Use padding prop or default to 16px
  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p<{ fontColor?: string }>`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 600;
  color: ${({ fontColor }) => fontColor && fontColor};
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

export const InputContainer = styled.div<{ mb?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  margin-bottom: ${({ mb }) => (mb !== undefined ? `${mb}px` : '16px')};
`;

export const Label = styled.label`
  font-family: ${font.family};
  font-size: 16px;
  font-weight: 500;
  color: var(--Shadow-00, #010101);
  margin-bottom: 8px;
`;

export const StyledInput = styled.input<{ bgColor?: string; w?: string }>`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${({ bgColor }) => bgColor || `${colors.white}`};
  width: ${({ w }) => `${w}%` || '100%'};
  font-family: ${font.family};

  ::placeholder {
    color: ${colors.neutral_700};
  }

  &:hover {
    outline: 1px solid ${colors.secondary_50};
  }

  &:focus {
    outline: none;
    background: ${colors.base_white};
  }
`;

export const StyledTextarea = styled.textarea<{ bgColor?: string; w?: string }>`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${({ bgColor }) => bgColor || `${colors.white}`};
  width: ${({ w }) => (w ? `${w}%` : '100%')};
  height: 160px;
  font-family: ${font.family};

  ::placeholder {
    color: ${colors.neutral_700};
  }

  &:hover {
    outline: 1px solid ${colors.secondary_50};
  }

  &:focus {
    outline: none;
    background: ${colors.base_white};
  }
`;

export const ErrorMessage = styled.p`
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: ${colors.error};
`;
