import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

type TypeToColorMap = {
  [key: string]: {
    backgroundColor: string;
    textColor: string;
  };
};

const typeToColor: TypeToColorMap = {
  success: {
    backgroundColor: colors.success_green,
    textColor: colors.base_white,
  },
  error: {
    backgroundColor: colors.error,
    textColor: colors.base_white,
  },
  info: {
    backgroundColor: colors.secondary_100,
    textColor: colors.base_white,
  },
};

export const StyledMessagePopupWrapper = styled.div<{
  type?: keyof TypeToColorMap;
}>`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  z-index: 9999;
  padding: 20px 75px;
  font-family: ${font.family};
  font-weight: 700;
  font-size: 18px;
  ${({ type }) => {
    const selectedType = type && typeToColor[type as keyof TypeToColorMap];
    if (selectedType) {
      return `
        background-color: ${selectedType.backgroundColor};
        color: ${selectedType.textColor};
      `;
    } else {
      return `
        background-color: ${colors.secondary_100};
        color: ${colors.base_black};
      `;
    }
  }};
`;
