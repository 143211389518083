import React from 'react';
import {
  StyledJobBoardShareJobCloseIcon,
  StyledJobBoardShareJobDescAndInputWrapper,
  StyledJobBoardShareJobEmailChip,
  StyledJobBoardShareJobEmailChipWrapper,
  StyledJobBoardShareJobForm,
  StyledJobBoardShareJobInput,
  StyledJobBoardShareJobInputAddIcon,
  StyledJobBoardShareJobInputWrapper,
  StyledJobBoardShareJobMainWrapper,
  StyledJobBoardShareJobSubmitBtn,
  StyledJobBoardShareJobTitleAndCloseBtnWrapper,
} from './global-job-board-share-job.styled';
import { font, Text } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import { CloseIcon } from '../../../../../../../icons/close-icon';
import AddCircleIcon from '../../../../../../../icons/add-circle-icon';
import { useGlobalJobBoardShareJob } from './use-global-job-board-share-job';
import { GlobalJobBoardJobDataTypes } from '../../use-global-job-board-job-and-company-data';

interface GlobalJobBoardShareJobTypes {
  setSharePupup: React.Dispatch<React.SetStateAction<boolean>>;
  job: GlobalJobBoardJobDataTypes | undefined;
  setPopUp: React.Dispatch<
    React.SetStateAction<{
      text: string;
      type: 'success' | 'error';
    } | null>
  >;
}

const GlobalJobBoardShareJob: React.FC<GlobalJobBoardShareJobTypes> = ({
  setSharePupup,
  job,
  setPopUp,
}) => {
  const {
    email,
    emails,
    error,
    setEmail,
    addEmail,
    removeEmail,
    handleKeyPress,
    handleSubmit,
  } = useGlobalJobBoardShareJob();

  const handleFormSubmit = async () => {
    try {
      const response = await handleSubmit(job);

      if (response.success) {
        setPopUp({
          text: 'Job shared successfully!',
          type: 'success',
        });
        setSharePupup(false);
      } else {
        setPopUp({
          text: response.message || 'An error occurred while sharing the job.',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error while sharing the job:', error);
      alert('Something went wrong. Please try again later.');
      setPopUp({
        text: 'Something went wrong. Please try again later.',
        type: 'error',
      });
    }
  };

  return (
    <StyledJobBoardShareJobMainWrapper
      onClick={() => {
        setSharePupup(false);
      }}
    >
      <StyledJobBoardShareJobForm
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <StyledJobBoardShareJobTitleAndCloseBtnWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={18}
            fontWeight={600}
            lineHeight="21.94px"
            color={colors.black}
            margin="0"
          >
            Share This Job
          </Text>
          <StyledJobBoardShareJobCloseIcon
            onClick={() => {
              setSharePupup(false);
            }}
          >
            <CloseIcon />
          </StyledJobBoardShareJobCloseIcon>
        </StyledJobBoardShareJobTitleAndCloseBtnWrapper>
        <StyledJobBoardShareJobDescAndInputWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={14}
            fontWeight={400}
            lineHeight="18px"
            color={colors.base_black}
            margin="0"
          >
            Your referral could help someone find their dream
            <br />
            job!
          </Text>
          <StyledJobBoardShareJobInputWrapper>
            <StyledJobBoardShareJobInputAddIcon onClick={addEmail}>
              <AddCircleIcon color={colors.neutral_100} />
            </StyledJobBoardShareJobInputAddIcon>
            <StyledJobBoardShareJobInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Enter email address"
            />
          </StyledJobBoardShareJobInputWrapper>
          {error && (
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={12}
              fontWeight={400}
              lineHeight="16px"
              color={colors.error}
              margin="5px 0 0"
            >
              {error}
            </Text>
          )}
          <StyledJobBoardShareJobEmailChipWrapper>
            {emails.map((email, index) => (
              <React.Fragment key={index}>
                <StyledJobBoardShareJobEmailChip
                  onClick={() => removeEmail(email)}
                  hoverColor={colors.error}
                >
                  {email}
                </StyledJobBoardShareJobEmailChip>
                {index < emails.length - 1 && (
                  <span style={{ margin: '0 5px' }}>,</span>
                )}
              </React.Fragment>
            ))}
          </StyledJobBoardShareJobEmailChipWrapper>
        </StyledJobBoardShareJobDescAndInputWrapper>
        <StyledJobBoardShareJobSubmitBtn
          type="button"
          onClick={handleFormSubmit}
        >
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={500}
            lineHeight="19.5px"
            color={colors.white}
            margin="0"
          >
            Share
          </Text>
        </StyledJobBoardShareJobSubmitBtn>
      </StyledJobBoardShareJobForm>
    </StyledJobBoardShareJobMainWrapper>
  );
};

export default GlobalJobBoardShareJob;
