import React, { useEffect, useState } from 'react';
import {
  StyledColorCircle,
  StyledColorTemplate,
  StyledColorTemplateName,
  StyledColorTemplatePreview,
  StyledColorTemplatesWrapper,
  StyledFirstColorCircle,
} from '../change-colors.styled';
import { ColorTemplate } from '../../../../../../../../../style-utils/color-templates';

// Helper function to normalize color values to lowercase
const normalizeColor = (color: string) => color.toLowerCase();

interface SelectColorTemplatesPropsTypes {
  templates: { [key: string]: ColorTemplate };
  colorComponents: {
    label: string;
    color: string;
    name: string;
    hasError: boolean;
  }[];
  setColorComponents: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        color: string;
        name: string;
        hasError: boolean;
      }[]
    >
  >;
  selectedTemplate: string;
  setSelectedTemplate: (selectedTemplate: string) => void;
}

const SelectColorTemplates: React.FC<SelectColorTemplatesPropsTypes> = ({
  templates,
  colorComponents,
  setColorComponents,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const [localSelectedTemplate, setLocalSelectedTemplate] = useState<
    string | null
  >(null);

  useEffect(() => {
    let matchedTemplate: string | null = null;

    for (const templateName of Object.keys(templates)) {
      const template = templates[templateName];
      const isMatch = colorComponents.every(
        (component) =>
          normalizeColor(component.color) ===
          (normalizeColor(
            template[component.name as keyof ColorTemplate] || ''
          ) || normalizeColor(component.color))
      );

      if (isMatch) {
        matchedTemplate = templateName;
        break;
      }
    }

    setLocalSelectedTemplate(matchedTemplate || 'custom');

    // Update parent state only if the local state changes
    if (matchedTemplate && selectedTemplate !== matchedTemplate) {
      setSelectedTemplate(matchedTemplate);
    } else if (!matchedTemplate) {
      setSelectedTemplate('custom');
    }
  }, [colorComponents, templates, selectedTemplate, setSelectedTemplate]);

  const handleTemplateSelect = (templateName: string) => {
    const selectedTemplate = templates[templateName];

    const updatedColorComponents = colorComponents.map((component) => ({
      ...component,
      color:
        selectedTemplate[component.name as keyof ColorTemplate] ||
        component.color,
      selectedTemplate: templateName,
    }));

    setColorComponents(updatedColorComponents);
    setSelectedTemplate(templateName); // Ensure parent state updates
  };

  return (
    <StyledColorTemplatesWrapper>
      {Object.keys(templates).map((templateName) => (
        <StyledColorTemplate
          key={templateName}
          onClick={() => handleTemplateSelect(templateName)}
          isTemplateSelected={localSelectedTemplate === templateName}
        >
          <StyledColorTemplateName>{templateName}</StyledColorTemplateName>
          <StyledColorTemplatePreview>
            <StyledFirstColorCircle
              color={templates[templateName]['text_color']}
            />
            <StyledColorCircle
              color={templates[templateName]['background_fill_color']}
            />
            <StyledColorCircle
              color={templates[templateName]['button_color']}
            />
          </StyledColorTemplatePreview>
        </StyledColorTemplate>
      ))}
    </StyledColorTemplatesWrapper>
  );
};

export default SelectColorTemplates;
