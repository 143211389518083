import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';
import { font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';

export const ContentAndImageContainer = styled.div<{
  backgroundColor?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.backgroundColor || '#f7f8fa'};
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 360px;
  overflow: hidden;
  ${media.laptop(`
    height: 180px;
  `)}
`;

export const CoverImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -40%);
  object-position: center;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.797);
  pointer-events: none;
  z-index: 1;
`;

export const JobPostingsContainer = styled.div`
  flex: 1;
`;

export const JobPostInfoContainer = styled.div`
  flex: 1;
  ${media.laptop(`
    display: none;
    visibility: hidden;
  `)}
`;

export const JobPostInfoContainerMobile = styled.div`
  flex: 1;
  display: block;
`;

export const JobPostingAndInfoWrapper = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.laptop(`
  display: block;
  padding: 16px;
  `)}
`;

export const JobPostingAndInfoContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
`;

export const PaginationButton = styled.button<{
  backgroundColor?: string;
  textColor?: string;
}>`
  background-color: ${(props) => props.backgroundColor || '#2e8bda'};
  color: ${(props) => props.textColor || '#FFFFFF'};
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;

  &:hover:enabled {
    background-color: ${(props) => props.backgroundColor || '#2e8bda'};
    color: ${(props) => props.textColor || '#FFFFFF'};
  }

  &:disabled {
    background-color: var(--Neutral-400, #cccccc);
    color: white;
    cursor: not-allowed;
  }
`;

export const CenteredText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--Shadow-00, #010101);
  font-family: Lato;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  z-index: 2;
  ${media.laptop(`
    font-size: 18px;  
  `)}
`;

export const CareerText = styled.span`
  color: var(--Primary-500, #e94e3c);
  font-family: Lato;
  font-size: inherit;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;
`;

export const NoActiveJobsAvailableMSgMainWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
`;

export const NoActiveJobsAvailableMSg = styled.div`
  box-sizing: border-box;
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-family: ${font.family};
  font-size: 28px;
  font-weight: 700;
  color: ${colors.base_black};
  text-align: center;
`;

export const NoActiveJobsAvailableMSgCompanyName = styled.span`
  color: ${colors.primary_500};
`;
