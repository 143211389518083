import React from 'react';
import {
  StyledPreviewColorTemplateMainWrapper,
  StyledPreviewColorTemplateWrapper,
} from '../change-colors.styled';
import { JobPostings } from '../../../../../../../company-job-board/job-postings';
import { JobOfferWithCompanyData } from '../../../../../../../../../interfaces/job-interfaces';
import { Company } from '../../../../../../../../app-router/component-props';
import { useNavigate } from 'react-router-dom';
import { ColorTemplate } from '../../../../../../../company-job-board/company-job-board';

const jobOfferWithCompanyData: JobOfferWithCompanyData = {
  id: 'job_12345',
  general_company_info:
    'We are a leading company in the tech industry, specializing in software development.',
  job_position: 'Software Engineer',
  responsibilities:
    'Develop and maintain web applications, collaborate with cross-functional teams, and ensure code quality.',
  job_requirements:
    '3+ years of experience in software development, strong knowledge of JavaScript, React, and Node.js.',
  benefits:
    'Health insurance, 401(k) matching, remote work options, and professional development opportunities.',
  status: 'open',
  job_aplications: 50,
  company_id: 'company_67890',
  company_name: 'Tech Innovators Inc.',
  company_address: '1234 Innovation Drive, Tech City, TX 75001',
  company_website: 'https://www.techinnovators.com',
  company_email: 'hr@techinnovators.com',
  company_phone_number: '+1-555-1234-567',
  company_country: 'United States',
  createdAt: '2024-08-19T12:00:00Z',
  industry: 'Information Technology',
  job_category: 'Full Time',
  job_description:
    'We are looking for a Software Engineer to join our dynamic team to build innovative products.',
  job_duration: 'Full-time',
  location: 'Remote',
  seniority_level: 'Mid-level',
  skills: ['JavaScript', 'React', 'Node.js', 'Git', 'REST APIs'],
  speciality: 'Front-end Development',
  updatedAt: '2024-08-19T12:00:00Z',
  work_location: 'Remote',
};

const company: Company = {
  id: 'company_67890',
  name: 'Tech Innovators Inc.',
  country: 'United States',
  address: '1234 Innovation Drive, Tech City, TX 75001',
  description:
    'A leading company in the tech industry, specializing in innovative software solutions.',
  location: 'Tech City, TX',
  website: 'https://www.techinnovators.com',
  email: 'info@techinnovators.com',
  phone_number: '+1-555-1234-567',
  tax_number: 'TX1234567890',
  color_template: '#E94E3C',
  entityType: 'Corporation',
  createdAt: '2023-01-15T09:30:00Z',
  account_type: 'Premium',
  status: 'active',
  payment_plan: 'Yearly',
};

interface PreviewColorTemplatePropsTypes {
  colorComponents: {
    label: string;
    color: string;
    name: string;
    hasError: boolean;
  }[];
}

const PreviewColorTemplate: React.FC<PreviewColorTemplatePropsTypes> = ({
  colorComponents,
}) => {
  const navigate = useNavigate();

  const selectJob = (job: JobOfferWithCompanyData) => {
    console.log(job);
  };

  const getColorTemplate = (
    components: PreviewColorTemplatePropsTypes['colorComponents']
  ): ColorTemplate => {
    return components.reduce((acc, component) => {
      acc[component.name as keyof ColorTemplate] = component.color;

      return acc;
    }, {} as ColorTemplate);
  };

  const colorTemplate = getColorTemplate(colorComponents);

  return (
    <StyledPreviewColorTemplateMainWrapper
      backgroundColor={colorTemplate.background_color}
    >
      <StyledPreviewColorTemplateWrapper>
        <JobPostings
          job={jobOfferWithCompanyData}
          selectJob={selectJob}
          company={company}
          navigate={navigate}
          jobPostMobilePath={''}
          colorTemplate={colorTemplate}
          fromPage={'settings'}
          openFormJobId={null}
          setOpenFormJobId={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      </StyledPreviewColorTemplateWrapper>
    </StyledPreviewColorTemplateMainWrapper>
  );
};

export default PreviewColorTemplate;
