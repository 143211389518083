import { useEffect, useState } from 'react';

const ScreenSize = {
  xxs: 'xxs',
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  xxl: 'xxl',
  unknown: 'unknown',
} as const;

type ScreenSizeType = keyof typeof ScreenSize;

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizeType>('unknown');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const isTablet =
    screenSize === 'xs' ||
    screenSize === 'xxs' ||
    screenSize === 's' ||
    screenSize === 'm' ||
    screenSize === 'l';

  const isMobile =
    screenSize === 'xs' ||
    screenSize === 'xxs' ||
    screenSize === 's' ||
    screenSize === 'm';

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setWindowWidth(screenWidth);
      if (screenWidth < 320) {
        setScreenSize('xxs');
      } else if (screenWidth < 480) {
        setScreenSize('xs');
      } else if (screenWidth < 560) {
        setScreenSize('s');
      } else if (screenWidth < 768) {
        setScreenSize('m');
      } else if (screenWidth < 1024) {
        setScreenSize('l');
      } else if (screenWidth < 1280) {
        setScreenSize('xl');
      } else if (screenWidth < 1440) {
        setScreenSize('xxl');
      } else {
        setScreenSize('unknown');
      }
    };

    handleResize(); // Get initial screen size
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener on unmount
    };
  }, []);

  return { screenSize, isTablet, isMobile, windowWidth };
};

export default useScreenSize;
