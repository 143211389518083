import React from 'react';

// eslint-disable-next-line
export const BulletPoint = ({ color }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill={color ? `${color}` : 'none'} />
    </svg>
  );
};
