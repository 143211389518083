import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const Title = styled.div<{ titleColor?: string }>`
  color: ${({ titleColor }) => titleColor || '#000000'};
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.745);
  z-index: 59;
`;

export const LogInWrapper = styled.div<{ bgColor?: string }>`
  display: flex;
  min-height: 100vh;
  background: ${({ bgColor }) => bgColor || 'none'};
`;

export const TextContainer = styled.div<{
  bgColor?: string;
  color?: string;
  jobBoard?: boolean;
}>`
  min-height: 100%;
  background: ${({ bgColor }) => (bgColor ? `${bgColor}` : 'teal')};
  display: flex;
  padding-right: 50px;
  padding-left: 50px;
  gap: 50px;
  flex-basis: 20%;
  flex-direction: column;
  color: ${({ color }) => (color ? `${color}` : `${colors.base_white}`)};
  border-radius: ${({ jobBoard }) =>
    jobBoard ? '0 40px 40px 0' : '0 8px 8px 0'};
`;

export const ContentWrapper = styled.div<{
  dashboard?: boolean;
  bgColor?: string;
}>`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  // overflow: auto;
  align-self: center;
  padding: ${({ dashboard }) => (dashboard ? '0' : '32px')};
  background: ${({ bgColor }) => bgColor || 'none'};
  position: relative;
  align-items: center;
`;

export const CompanyTitle = styled.h4`
  font-family: ${font.family};
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 25px;
`;

export const CompanySubTitle = styled.h5`
  font-family: ${font.family};
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 50px;
`;

export const Description = styled.p`
  max-width: 300px;
  font-family: ${font.family};
  font-size: 14px;
  line-height: 20px;
`;

export const StyledTable = styled.table<{ width?: string }>`
  // table-layout: fixed;
  background: ${colors.creme_50};
  border-collapse: separate;
  border-spacing: 5px 10px;
  padding: 16px;
  border-radius: 8px 8px 0 0;
  ${({ width }) => width && `width: ${width}`};

  @media (max-width: 600px) {
    border: 0;
  }
`;

export const StyledThead = styled.thead`
  @media (max-width: 600px) {
    display: none; // Hide header on small screens
  }
`;

export const StyledTr = styled.tr`
  /* width: fit-content; */

  @media (max-width: 600px) {
    display: block;
    margin-bottom: 0.625em;
  }
`;

export const StyledTh = styled.th<{
  columnWidth?: string;
  backgroundColor?: string;
}>`
  /* box-sizing: border-box; */
  /* padding: 10px 1em; */
  text-align: center;
  height: 54px;
  padding: 0 10px;
  background-color: teal;
  color: white;
  width: ${({ columnWidth }) => columnWidth && columnWidth};
  border-radius: 4px;
  font-size: 16px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'white'};
`;

export const StyledTd = styled.td<{
  tdWidth?: string;
  color?: string;
  display?: string;
}>`
  padding: 0 1em;
  border-radius: 8px;
  background: ${colors.creme_100};
  min-height: 70px;
  color: ${({ color }) => (color ? `${color}` : `${colors.base_black}`)};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 80px;
  text-align: center;
  ${({ tdWidth }) => tdWidth && `width: ${tdWidth};`};
  ${({ display }) => display && `display: ${display};`};

  @media (max-width: 600px) {
    display: block;
    text-align: right;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  }
`;

export const PaginationContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
  background-color: ${colors.creme_50};
  border-radius: 0 0 8px 8px;
  margin-top: auto;
`;

export const JobBoardWrapper = styled.div<{ bgColor?: string }>`
  min-height: 100vh;
  background: ${({ bgColor }) => bgColor || 'none'};
  /* this margin is added for the space between footer and the backgroud */
  margin-bottom: -70px;
`;

export const JobBoardContainer = styled.div<{
  bgColor?: string;
  color?: string;
}>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  background: ${({ bgColor }) => (bgColor ? `${bgColor}` : 'teal')};
  color: ${({ color }) => (color ? `${color}` : `${colors.base_white}`)};
  z-index: 10;
`;

export const StyledSpan = styled.span`
  font-weight: 700;
`;

export const JobStyledButton = styled.button<{
  borderRadius?: string;
  padding?: string;
  bgColor?: string;
  color?: string;
  mb?: string;
}>`
  border: 0;
  border-style: none;
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`};
  ${({ mb }) => mb && `margin-bottom: ${mb};`};
  font-family: ${font.family};
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
  color: ${colors.white};
  cursor: pointer;
`;

export const JobBoardTableDiv = styled.div<{
  marginLeft?: string;
  width?: number;
  alignSelf?: string;
}>`
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  ${({ width }) => width && `width: ${width}px;`};
  margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft};`};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`};
  /* height: calc(100vh - 175px); */
  min-height: 530px;
  max-height: 710px;

  @media (min-width: 1440px) {
    justify-content: center;
  }

  // @media (min-width: 1920px) {
  //   width: auto;
  // }
`;

export const JobBoardTableWrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${colors.white};
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  ${({ width }) => width && `width: ${width};`};
  overflow-x: auto;
  position: relative;

  @media (min-width: 1920px) {
    max-width: 1320px;
  }
`;

export const JobBoardTableHeadersContainer = styled.div`
  display: flex;
  gap: 8px;
  box-sizing: border-box;
  width: 72.6%;
`;

export const JobBoardTableHeader = styled.div<{
  bgColor?: string;
  padding?: string;
  width?: string;
}>`
  box-sizing: border-box;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ width }) => width && `width: ${width};`};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  // flex: ${({ width }) => (width ? `0 0 ${width};` : `0 0 auto`)};
`;

export const JobBoardTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
`;

export const JobBoardTableRow = styled.div`
  display: flex;
  gap: 8px;
  box-sizing: border-box;
  position: relative;
`;

export const JobBoardTableRowWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 72.6%;
`;

export const JobBoardTableColumn = styled.div<{
  width?: string;
  padding?: string;
  bgColor?: string;
  textColor?: string;
}>`
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor || 'var(--Creme-100, #F5F4F0)'};
  color: ${({ textColor }) => textColor || 'var(--Base-black, #010101)'};
  border-radius: 8px;
  padding: ${({ padding }) => (padding ? `${padding};` : `8px 24px;`)};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ width }) => width && `width: ${width};`};
  position: relative;
  // flex: ${({ width }) => (width ? `0 0 ${width};` : `0 0 auto`)};
`;

export const JobBoardTableButton = styled.button`
  box-sizing: border-box;
  border: 0;
  border-style: none;
  padding: 8px 24px;
  height: 50px;
  background-color: ${colors.secondary_500};
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  width: 94px;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;
export const SharePopupContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  width: 340px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 69;
  background: white;
  border-radius: 16px;
  background: ${({ backgroundColor }) =>
    backgroundColor || 'var(--Shadow-0, #fdfdfd)'};
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const InputAndErrorWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
`;

export const CustomInputField = styled.input`
  box-sizing: border-box;
  border-style: none;
  height: 50px;
  background-color: #f3f2f2;
  color: #5e5a57;
  border-radius: 8px;
  padding: 0 18px;
  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid ${colors.primary_300};
    box-shadow: none;
  }
`;

export const AddBtn = styled.button`
  background-color: #5789c2;
  color: white;
  padding: 5px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  top: 13px;
  right: 15px;
  position: absolute;
  cursor: pointer;
`;

export const EmailsListWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const StyledAddedEmail = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  box-sizing: border-box;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  border-radius: 8px;
  border: ${({ borderColor }) =>
    borderColor || `1px solid ${colors.grey_light}`};
  word-wrap: anywhere;
`;

export const CustomButton = styled.button<{
  bgColor?: string;
  color?: string;
  padding?: string;
  borderRadius?: string;
}>`
  color: var(--Neutral-50, #f6f7f7);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-style: none;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`};
  ${({ color }) => color && `color: ${color};`};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
`;

export const JobBoardOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const JobBoardLogoContainer = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const JobBoardStyledSelect = styled.select<{
  borderColor?: string;
}>`
  -webkit-appearance: none;
  display: flex;
  padding: 14px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid
    ${({ borderColor }) => borderColor || 'var(--Primary-500, #e94e3c)'};
  background: ${colors.white};
  font-family: ${font.family};
  color: ${({ borderColor }) => borderColor || 'var(--Primary-800, #e94e3c)'};
  width: 124px;
  height: 54px;
  cursor: pointer;

  a .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const JobBoardContentWrapper = styled.div<{
  dashboard?: boolean;
  bgColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  padding: ${({ dashboard }) => (dashboard ? '0' : '32px')};
  background: ${({ bgColor }) => bgColor || 'none'};
  position: relative;
  min-height: 100vh;
`;

export const JobBoardHero = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  gap: 40px;
  padding: 120px 60px;
  width: 424px;
  // min-height: 1024px;
  background-color: ${colors.white};
  border-radius: 0 40px 40px 0;

  @media (min-width: 1560px) {
    height: 1080px;
  }
`;

export const JobBoardHeroLogoContainer = styled.div`
  width: 140px;
  box-sizing: border-box;

  & > img {
    display: block;
    width: 100%;
  }
`;

export const JobBoardHeroTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
