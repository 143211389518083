import React from 'react';
import { colors } from '../../style-utils/colors';

interface TableStatusColorCircleIconProps {
  status: string;
}

export const TableEmployeeStatusColorCircleIcon: React.FC<
  TableStatusColorCircleIconProps
> = ({ status }) => {
  const getColor = (status: string) => {
    switch (status) {
      case 'active':
        return colors.secondary_500;
      case 'inactive':
        return colors.error;
      default:
        return colors.neutral_50;
    }
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill={getColor(status)} />
    </svg>
  );
};
