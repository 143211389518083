import React from 'react';
import {
  StyledGlobalJobBoardJobListingCompanyLogo,
  StyledGlobalJobBoardJobListingDateAddedWrapper,
  StyledGlobalJobBoardJobListingDetailsWrapper,
  StyledGlobalJobBoardJobListingInformationsSeparator,
  StyledGlobalJobBoardJobListingInformationsWrapper,
  StyledGlobalJobBoardJobListingMainWrapper,
} from './global-job-board-job-listing.styled';
import { JobListing } from '../../../../../components/featured-jobs/global-job-board-featured-jobs-types';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import MissingLogoPlaceholder from '../../../../../../../../assets/images/no_logo.jpg';
import { NavigateFunction } from 'react-router-dom';

interface GlobalJobBoardJobListingProps {
  jobListing: JobListing;
  navigate: NavigateFunction;
}

const GlobalJobBoardJobListing: React.FC<GlobalJobBoardJobListingProps> = ({
  jobListing,
  navigate,
}) => {
  return (
    <StyledGlobalJobBoardJobListingMainWrapper
      onClick={() => navigate(`/global/${jobListing.id}`)}
    >
      <StyledGlobalJobBoardJobListingCompanyLogo
        src={
          jobListing.companyLogo && jobListing.companyLogo.trim() !== ''
            ? jobListing.companyLogo
            : MissingLogoPlaceholder
        }
      />
      <StyledGlobalJobBoardJobListingDetailsWrapper>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={18}
          fontWeight={600}
          lineHeight="21.94px"
          color={colors.base_black}
          margin="0"
        >
          {jobListing.title}
        </Text>
        <StyledGlobalJobBoardJobListingInformationsWrapper>
          {jobListing.company && jobListing.company.trim() !== '' && (
            <>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={14}
                fontWeight={400}
                lineHeight="17.07px"
                color={colors.neutral_800}
                margin="0"
              >
                {jobListing.company}
              </Text>
              <StyledGlobalJobBoardJobListingInformationsSeparator>
                |
              </StyledGlobalJobBoardJobListingInformationsSeparator>
            </>
          )}
          {jobListing.location && jobListing.location.trim() !== '' && (
            <>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={14}
                fontWeight={400}
                lineHeight="17.07px"
                color={colors.persianGreen}
                margin="0"
              >
                {jobListing.location}
              </Text>
              <StyledGlobalJobBoardJobListingInformationsSeparator>
                |
              </StyledGlobalJobBoardJobListingInformationsSeparator>
            </>
          )}
          {jobListing.salary && jobListing.salary.trim() !== '' && (
            <>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={14}
                fontWeight={400}
                lineHeight="17.07px"
                color={colors.neutral_800}
                margin="0"
              >
                {jobListing.salary}
              </Text>
              <StyledGlobalJobBoardJobListingInformationsSeparator>
                |
              </StyledGlobalJobBoardJobListingInformationsSeparator>
            </>
          )}
          {jobListing.city && jobListing.city.trim() !== '' && (
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.neutral_800}
              margin="0"
            >
              {jobListing.city}
            </Text>
          )}
        </StyledGlobalJobBoardJobListingInformationsWrapper>
      </StyledGlobalJobBoardJobListingDetailsWrapper>
      <StyledGlobalJobBoardJobListingDateAddedWrapper>
        {jobListing.date}
      </StyledGlobalJobBoardJobListingDateAddedWrapper>
    </StyledGlobalJobBoardJobListingMainWrapper>
  );
};

export default GlobalJobBoardJobListing;
