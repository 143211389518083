import React from 'react';
import styled from 'styled-components';

const StepHeaderContainer = styled.div<{ removePadding?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-top: ${({ removePadding }) => (removePadding ? '0' : '64px')};
`;

const StepTitle = styled.h2`
  color: var(--Shadow-00, #010101);
  font-family: Lato;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const StepIndicatorContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StepCircle = styled.div<{ active: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? `#E94E3C` : '#FFC2B5')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const StepHeader: React.FC<{
  currentStep: number;
  totalSteps: number;
  title: string;
}> = ({ currentStep, totalSteps, title }) => (
  <StepHeaderContainer removePadding={currentStep !== 1 ? true : false}>
    <StepTitle>{title}</StepTitle>
    <StepIndicatorContainer>
      {Array.from({ length: totalSteps }, (_, index) => (
        <StepCircle key={index} active={index + 1 === currentStep}>
          {index + 1}
        </StepCircle>
      ))}
    </StepIndicatorContainer>
  </StepHeaderContainer>
);
