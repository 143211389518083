import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  StyledInfoCircleWrapper,
  StyledInfoCirclesMainWrapper,
  StyledInfoInnerCircle,
} from '../home.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { apiRoute } from '../../../../constants/api-constants';

const InfoCircles: React.FC = () => {
  const baseURL = apiRoute;
  const [circlesData, setCirclesData] = useState([
    {
      id: 1,
      number: '/',
      text: 'Total Company',
      backgroundColor: colors.secondary_400,
      percentage: 0,
    },
    {
      id: 2,
      number: '/',
      text: 'Total Employee',
      backgroundColor: colors.primary_500,
      percentage: 0,
    },
    {
      id: 3,
      number: '/',
      text: 'Active Company',
      backgroundColor: colors.secondary_500,
      percentage: 0,
    },
    {
      id: 4,
      number: '/',
      text: 'Block Company',
      backgroundColor: colors.primary_200,
      percentage: 0,
    },
  ]);

  const fetchCirclesData = () => {
    axios
      .get(`${baseURL}/super-admin/dashboard/circles-data`)
      .then((response) => {
        const { totalCompanies, totalEmployees } = response.data;

        setCirclesData([
          {
            id: 1,
            number: totalCompanies,
            text: 'Total Company',
            backgroundColor: colors.secondary_400,
            percentage: totalCompanies / 100,
          },
          {
            id: 2,
            number: totalEmployees,
            text: 'Total Employee',
            backgroundColor: colors.primary_500,
            percentage: totalEmployees / 100,
          },
          {
            id: 3,
            number: totalCompanies,
            text: 'Active Company',
            backgroundColor: colors.secondary_500,
            percentage: totalCompanies / 100, // Adjust the logic later
          },
          {
            id: 4,
            number: 0,
            text: 'Block Company',
            backgroundColor: colors.primary_200,
            percentage: 0, // Adjust the logic later
          },
        ]);
      })
      .catch((error) => {
        console.error('Error fetching circles data:', error);
      });
  };

  useEffect(() => {
    fetchCirclesData();
  }, []);

  return (
    <StyledInfoCirclesMainWrapper>
      {circlesData.map((circle) => (
        <StyledInfoCircleWrapper
          key={circle.id}
          backgroundColor={circle.backgroundColor}
          percentage={circle.percentage}
        >
          <StyledInfoInnerCircle>
            <Text
              fontFamily={font.family}
              fontSize={16}
              fontWeight={500}
              color={colors.secondary_900}
              margin="0"
            >
              {circle.number}
            </Text>
            <Text
              fontFamily={font.family}
              fontSize={14}
              fontWeight={500}
              color={colors.secondary_900}
              margin="0"
              align="center"
            >
              {circle.text.split(' ')[0]}
              <br />
              {circle.text.split(' ')[1]}
            </Text>
          </StyledInfoInnerCircle>
        </StyledInfoCircleWrapper>
      ))}
    </StyledInfoCirclesMainWrapper>
  );
};

export default InfoCircles;
