import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';

export const StyledSettingsJobPositionMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 24px 32px 24px;
  background-color: ${colors.base_white};
  border-radius: 16px;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
`;
