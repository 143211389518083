import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const FilterWrapper = styled.div<{
  width?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  -webkit-appearance: none;
  width: ${({ width }) => width && `${width};`};
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 2px solid ${colors.primary_500};
  background: white;

  a .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const StyledSelectFilter = styled.select`
  -webkit-appearance: none;
  width: 100%;
  border: none;
  background: white;
  font-family: Lato;
  color: #8b0000;
  font-weight: 600;
  font-size: 14px;

  a .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;
