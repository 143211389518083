import React from 'react';

export const CheckOnIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 7.33317L8.5 9.33317L15.1667 2.6665"
        stroke="#0061A0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 8V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V3.33333C2.5 2.97971 2.64048 2.64057 2.89052 2.39052C3.14057 2.14048 3.47971 2 3.83333 2H11.1667"
        stroke="#0061A0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
