import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledGlobalJobBoardFeelingLuckyMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 140px;
  background-color: ${`${colors.cloudless}26`}; /* 15% opacity spored figma dizajnot*/
`;

export const StyledGlobalJobBoardFeelingLuckyInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledGlobalJobBoardFeelingLuckyTitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledGlobalJobBoardFeelingLuckyBtn = styled.button`
  box-sizing: border-box;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 32px;
  border-radius: 40px;
  border-style: none;
  background-color: ${colors.persianGreen};
  cursor: pointer;
`;
