import React from 'react';

export const WhiteCircleIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
    >
      <path
        d="M8 4.5C8 6.71875 6.20312 8.5 4 8.5C1.78125 8.5 0 6.71875 0 4.5C0 2.29688 1.78125 0.5 4 0.5C6.20312 0.5 8 2.29688 8 4.5Z"
        fill="#E4EAF5"
      />
    </svg>
  );
};
