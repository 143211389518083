import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';
import { font } from '../../../../../style-utils/text';

export const StyledCompanyInfoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base_white};
  border-radius: 8px;
  padding: 16px 24px 32px 24px;
`;

export const StyledEditBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: transparent;
  border-style: none;
  margin-left: auto;
  cursor: pointer;
  align-items: center;
`;

export const StyledCompanyInfoAndImageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const StyledCompanyInfoAndImageFormWrapper = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const StyledCompanyImage = styled.img<{ fromPage?: string }>`
  box-sizing: border-box;
  height: 162px;
  width: 162px;
  border-radius: 50%;
  object-fit: cover;
  cursor: ${({ fromPage }) => (fromPage === 'edit' ? 'pointer' : 'default')};
`;

export const StyledCompanyDataWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
`;

export const StyledCompanyDataGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 64px;
`;

export const StyledCompanyDataGridItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const StyledEditCompanyInput = styled.input<{ isName?: boolean }>`
  box-sizing: border-box;
  width: ${({ isName }) => (isName ? '50%' : '100%')};
  border-style: none;
  background-color: transparent;
  font-family: ${font.family};
  font-size: ${({ isName }) => (isName ? '36px' : '16px')};
  font-weight: ${({ isName }) => (isName ? 700 : 400)};
  color: ${({ isName }) => (isName ? colors.neutral_900 : colors.neutral_700)};
  margin: 0;
  padding: 8px 16px;
  border-radius: 8px;
  outline: none;
  background-color: ${colors.neutral_50};

  &:focus {
    outline: none;
  }
`;

export const StyledInputAndErrorMsgWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledSubmitBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  padding: 8px 40px;
  background-color: ${colors.primary_600};
  cursor: pointer;
  border-radius: 4px;
  margin-left: auto;
  border-style: none;
`;

export const StyledCompanyProfilePictureAndTextWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledUploadProfilePictureHiddenInput = styled.input`
  display: none;
`;
