import React, { useState } from 'react';
import axios from 'axios';
import {
  ToggleSwitchInput,
  ToggleSwitchSlider,
  ToggleSwitchWrapper,
} from './jobs.styled';
import { JobOfferData } from '../../../interfaces/job-interfaces';
import { apiRoute } from '../../../constants/api-constants';
import { Tooltip } from 'react-tooltip';

interface JobOfferToggleAiProps {
  job: JobOfferData;
}

const JobOfferToggleAi: React.FC<JobOfferToggleAiProps> = ({ job }) => {
  const [isToggled, setIsToggled] = useState<boolean>(
    job.analyze_candidates_with_ai
  );

  const handleToggleChange = () => {
    const newValue = !isToggled;
    setIsToggled(newValue);

    axios
      .put(`${apiRoute}/company/jobs/${job.id}/analyze-ai`, {
        analyze_candidates_with_ai: newValue,
      })
      .then((response) => {
        console.log('Job updated successfully:', response.data);
      })
      .catch((err) => {
        console.error('Error updating job:', err);
        setIsToggled(!newValue);
      });
  };

  return (
    <ToggleSwitchWrapper className="my-anchor-element">
      <ToggleSwitchInput
        type="checkbox"
        checked={isToggled}
        onChange={handleToggleChange}
      />
      <ToggleSwitchSlider className="slider" />
      <Tooltip anchorSelect=".my-anchor-element" place="bottom">
        Toggle AI analysis of candidate CVs for this position
      </Tooltip>
    </ToggleSwitchWrapper>
  );
};

export default JobOfferToggleAi;
