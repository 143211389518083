import React from 'react';
import { StyledReportingTabWrapper } from '../../../tabs.styled';
import ReportingEmployeesTable from '../../../../components/tables/reporting-employees-table/reporting-employees-table';
import ReportingCustomBarChart from '../../../../components/charts/custom-bar-chart/custom-bar-chart';
import { useReportingTimeOffData } from '../../use-reporting-time-off-data';

const ReportingHistoricalLeaveData: React.FC = () => {
  const { customBarChartData, reportingTimeOffTableHistoricalLeaveData } =
    useReportingTimeOffData();

  return (
    <StyledReportingTabWrapper>
      <ReportingCustomBarChart
        name="Balance"
        customBarChartData={customBarChartData}
      />
      <ReportingEmployeesTable
        name="Historical Leave Data"
        tableData={reportingTimeOffTableHistoricalLeaveData}
      />
    </StyledReportingTabWrapper>
  );
};

export default ReportingHistoricalLeaveData;
