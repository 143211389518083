import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const StyledEmployeeTimeoffRequestMain = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

export const StyledEmployeeTimeOffRequestFormWrapper = styled.form`
  overflow: hidden;
  border-radius: 16px;
`;

export const StyledEmployeeTimeOffRequestForm = styled.div`
  box-sizing: border-box;
  width: 670px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background-color: ${colors.creme_100};
`;

export const StyledEmployeeTimeOffRequestFormItemsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 8px;
  background-color: ${colors.base_white};
`;

export const StyledEmployeeTimeOffRequestFormItemsColumnWrapper = styled(
  StyledEmployeeTimeOffRequestFormItemsWrapper
)`
  flex-direction: column;
`;

export const StyledEmployeeTimeOffRequestFormItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledEmployeeTimeOffRequestFormItemFullWidth = styled(
  StyledEmployeeTimeOffRequestFormItem
)`
  width: 100%;
`;

export const StyledEmployeeTimeOffRequestTypeSelectAndIconWrapper = styled.div`
  position: relative;
`;

export const StyledEmployeeTimeOffRequestTypeIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
`;

export const StyledEmployeeTimeOffRequestTypeSelect = styled.select`
  box-sizing: border-box;
  width: 230px;
  border-radius: 8px;
  background-color: ${colors.neutral_50};
  border-style: none;
  padding: 12px 16px;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const StyledEmployeeTimeOffRequestInput = styled.input`
  box-sizing: border-box;
  padding: 12px 16px;
  border-style: none;
  background-color: ${colors.neutral_50};
  width: 160px;
`;

export const StyledEmployeeTimeOffDateErrorWrapper = styled.div`
  box-sizing: border-box;
  width: 160px;
`;

export const StyledEmployeeTimeOffRequestTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  resize: none;
  background-color: ${colors.neutral_50};
  border-radius: 8px;
  padding: 12px 16px;
  border-style: none;

  &:focus {
    outline: 2px solid ${colors.primary_100};
  }
`;

export const StyledEmployeeTimeOffRequestFilesInput = styled.input`
  display: none;
`;

export const StyledEmployeeTimeOffRequestFilesLabel = styled.label`
  box-sizing: border-box;
  background-color: ${colors.secondary_50};
  border-radius: 8px;
  padding: 12px 16px;
  border-style: none;
`;

export const StyledEmployeeTimeOffRequestAttachedFiles = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledEmployeeTimeOffRequestAttachedFile = styled.div`
  box-sizing: border-box;
  padding: 8px 16px;
  background-color: ${colors.neutral_50};
  border-radius: 8px;
`;

export const DeleteButton = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;

export const StyledEmployeeTimeOffRequestBtnsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

export const StyledEmployeeTimeOffRequestSubmitBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  padding: 16px 24px;
  background-color: ${colors.primary_500};
  font-family: ${font.family};
  color: ${colors.base_white};
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
`;

export const StyledEmployeeTimeOffRequestBackBtn = styled(
  StyledEmployeeTimeOffRequestSubmitBtn
)`
  background-color: ${colors.primary_100};
  color: ${colors.base_black};
`;
