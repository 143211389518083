import React, { useEffect, useRef, useState } from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Text, font } from '../../../style-utils/text';
import { ButtonContainer, ButtonTitle } from '../../login/login.styled';
import {
  ActiveJobOffers,
  CancelConfirmButton,
  DeleteContainer,
  DeleteModal,
  DeleteModalBackground,
  DraftJobOffers,
  EditContainer,
  IconsDiv,
  JobNameDiv,
  JobOffer,
  JobOffersContainer,
  JobOffersDetailsContainer,
  JobOffersParent,
  OfferCount,
  OffersButtonContainer,
  TitleButtonContainer,
  WrapperDiv,
} from './jobs.styled';
import { colors } from '../../../style-utils/colors';
import { EditIcon } from '../../icons/edit-icon';
import { DeleteIcon } from '../../icons/delete-icon';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { JobOfferData } from '../../../interfaces/job-interfaces';
import { createItem, updateJobOffer } from '../../requests/requests';
import { useSelectedJob } from '../../../context/selected-job-context';
import {
  CloseIconWrapper,
  JobStyledButton,
  StyledSpan,
} from '../job-board/job-board.styled';
import { CloseIcon } from '../../icons/close-icon';
import { DashboardIcon } from '../../icons/dashboard';
import { extractDateAndTime } from '../../../function-utils/date-time-extractor';
import { normalizeCompanyName } from '../../../function-utils/slug-normalizer';
import { Chat } from '../../chat/chat';
import JobOfferToggleAi from './job-offer-toggle-ai';

//tuka da dodadam interface za JobOfferData
interface JobOffersProps {
  // updateEditableJobData: (newValue: JobOfferData) => void;
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  updateEditableJobData: (newValue: JobOfferData) => void;
  cookies?: Cookie;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const offerColors = [
  '#FFE7E1',
  '#F9836F',
  '#F26954',
  '#BCCCE6',
  '#93AFD7',
  '#779CCC',
];
export const JobOffers: React.FC<JobOffersProps> = ({
  navigate,
  company,
  updateEditableJobData,
  setUser,
}) => {
  const [jobOffersData, setJobOffersData] = useState<JobOfferData[]>([]);
  const [toggleJobDetail, setToggleJobDetail] = useState<string | null>(null);
  const [jobOfferToBeDeleted, setJobOfferToBeDeleted] =
    useState<JobOfferData | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [selectedJobOffer, setSelectedJobOffer] = useState<JobOfferData>();
  const [parentHeight, setParentHeight] = useState('');
  const parentRef = useRef<HTMLDivElement>(null);

  const {
    name,
    // id
  } = company;

  const {
    setJob,
  }: {
    setJob: (job: JobOfferData | null) => void;
  } = useSelectedJob();

  let offersColorCounter = 0;
  let draftsColorCounter = 0;
  let activeJobsCount = 0;
  let draftJobsCount = 0;

  const getJobOffersFromDB = async () => {
    let response;

    //This is a post request (but it's acctually a get req) to get only the job offers that are assigned to a specific company
    if (company.id) {
      response = await createItem(`${name}/hiring/job-offers`, company);
    }

    setJobOffersData(response as JobOfferData[]);
  };

  useEffect(() => {
    getJobOffersFromDB();
  }, [company]);

  useEffect(() => {
    if (parentRef.current) {
      const currentParentHeight = parentRef.current
        .clientHeight as unknown as string;
      setParentHeight(currentParentHeight);
    }
  }, [toggleJobDetail]);

  const height = useWindowHeight();

  const editJobOffer = (jobId: string) => {
    const filteredJobById = jobOffersData?.filter((job) => job.id === jobId);

    updateEditableJobData(filteredJobById[0]);
    navigate(`/${name}/jobs/edit-job-offer`);
  };

  const handleDeleteButtonClick = (jobId: string) => {
    console.log(jobId);
    setIsDeleteModalVisible(true);
    const filteredJobById = jobOffersData?.filter((job) => job.id === jobId);

    setJobOfferToBeDeleted(filteredJobById[0]);
  };

  const handleCancelDelete = () => {
    setJobOfferToBeDeleted(null);
    setIsDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (jobOfferToBeDeleted) {
        jobOfferToBeDeleted.status = 'inactive';

        await updateJobOffer(`${name}/hiring/job-offers`, jobOfferToBeDeleted);
      }

      getJobOffersFromDB();
    } catch (error) {
      console.error(error);
    } finally {
      setJobOfferToBeDeleted(null);
    }
    setIsDeleteModalVisible(false);
  };

  const selectJobToViewCandidates = (job: JobOfferData) => {
    setJob(job);
    navigate(`/${name}/applicants`);
  };

  const handleToggleDetails = (job: JobOfferData) => {
    setToggleJobDetail((previousToggled) =>
      previousToggled === job.id ? null : job.id
    );
    setSelectedJobOffer(job);

    if (parentRef.current) {
      parentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const displayDate = (dateString: string) => {
    const { formattedDate } = extractDateAndTime(dateString);

    return formattedDate;
  };

  const handleCloseClick = () => {
    setToggleJobDetail(null);
  };

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={company} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={company}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer bgColor={colors.creme_100}>
              <TitleButtonContainer>
                <Text
                  fontSize={30}
                  mb={0}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Job Offers
                </Text>
                <OffersButtonContainer>
                  <ButtonContainer
                    backgroundColor={colors.blue}
                    type="button"
                    mt={32}
                  >
                    <a
                      href={`/${normalizeCompanyName(name)}`}
                      style={{ textDecoration: 'none' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ButtonTitle fontColor="white">
                        <DashboardIcon />
                        Career Dashboard
                      </ButtonTitle>
                    </a>
                  </ButtonContainer>
                  <ButtonContainer
                    backgroundColor={colors.primary_500}
                    type="button"
                    onClick={() => navigate(`/${name}/jobs/create-job-offer`)}
                    mt={32}
                  >
                    <ButtonTitle>+ Create New Job Offer</ButtonTitle>
                  </ButtonContainer>
                </OffersButtonContainer>
              </TitleButtonContainer>
              <ActiveJobOffers>
                <Text fontSize={18} fontWeight={700} fontFamily={font.family}>
                  Active Job Offers
                </Text>
                <JobOffersParent ref={parentRef}>
                  <JobOffersContainer width={toggleJobDetail ? '60%' : '100%'}>
                    {jobOffersData.map((jobOffer, index) => {
                      // const colorIndex =
                      //   index < offerColors.length
                      //     ? index
                      //     : Math.floor(Math.random() * offerColors.length);

                      if (jobOffer.status === 'active') {
                        offersColorCounter += 1;
                        activeJobsCount += 1;

                        if (offersColorCounter > offerColors.length) {
                          offersColorCounter = 1;
                        }

                        return (
                          <React.Fragment key={index}>
                            <JobOffer
                              // w={toggleJobDetail ? '50%' : '95%'}
                              // h={toggleJobDetail ? '88px' : '70px'}
                              onClick={() => handleToggleDetails(jobOffer)}
                            >
                              <JobNameDiv>
                                <OfferCount
                                  backgroundColor={
                                    offerColors[offersColorCounter - 1]
                                  }
                                >
                                  <Text
                                    mt={0}
                                    mb={0}
                                    fontSize={12}
                                    fontWeight={400}
                                    fontFamily={font.family}
                                  >
                                    {/* Offer {index + 1} */}
                                    Offer {activeJobsCount}
                                  </Text>
                                </OfferCount>

                                <Text
                                  mt={0}
                                  mb={0}
                                  fontSize={18}
                                  fontWeight={400}
                                  fontFamily={font.family}
                                >
                                  {jobOffer.job_position}
                                </Text>
                              </JobNameDiv>

                              <IconsDiv>
                                <ButtonContainer
                                  borderRadius="24"
                                  backgroundColor={colors.primary_500}
                                  type="button"
                                  onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                  ) => {
                                    e.stopPropagation();
                                    selectJobToViewCandidates(jobOffer);
                                  }}
                                  mt={1}
                                >
                                  <ButtonTitle>View Candidates</ButtonTitle>
                                </ButtonContainer>
                                <JobOfferToggleAi job={jobOffer} />
                                <EditContainer
                                  onClick={(
                                    e: React.MouseEvent<HTMLDivElement>
                                  ) => {
                                    e.stopPropagation();
                                    editJobOffer(jobOffer.id);
                                  }}
                                >
                                  <EditIcon />
                                </EditContainer>
                                <DeleteContainer
                                  onClick={(
                                    e: React.MouseEvent<HTMLDivElement>
                                  ) => {
                                    e.stopPropagation();
                                    handleDeleteButtonClick(jobOffer.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </DeleteContainer>
                              </IconsDiv>
                            </JobOffer>
                          </React.Fragment>
                        );
                      }
                    })}
                  </JobOffersContainer>
                  {selectedJobOffer &&
                    toggleJobDetail === selectedJobOffer.id && (
                      <JobOffersDetailsContainer height={`${parentHeight}px`}>
                        <Text
                          fontSize={36}
                          fontWeight={700}
                          fontFamily={font.family}
                          mt={0}
                          mb={8}
                        >
                          {selectedJobOffer.job_position}
                        </Text>
                        <Text
                          fontSize={14}
                          fontFamily={font.family}
                          color={colors.creme_500}
                        >
                          {displayDate(selectedJobOffer.createdAt)}
                        </Text>
                        {company && (
                          <>
                            <Text
                              fontSize={16}
                              lineHeight="19.2"
                              fontFamily={font.family}
                            >
                              <StyledSpan>Company:&nbsp;</StyledSpan>
                              {company.name}
                            </Text>
                            <Text
                              fontSize={16}
                              lineHeight="19.2"
                              fontFamily={font.family}
                            >
                              <StyledSpan>Web:&nbsp;</StyledSpan>
                              {company.website}
                            </Text>
                            <Text
                              fontSize={16}
                              lineHeight="19.2"
                              fontFamily={font.family}
                            >
                              <StyledSpan>Address:&nbsp;</StyledSpan>
                              {company.address}
                            </Text>
                            <Text
                              fontSize={16}
                              lineHeight="19.2"
                              fontFamily={font.family}
                            >
                              <StyledSpan>Email:&nbsp;</StyledSpan>
                              {company.email}
                            </Text>
                            <Text
                              fontSize={16}
                              lineHeight="19.2"
                              fontFamily={font.family}
                            >
                              <StyledSpan>Phone:&nbsp;</StyledSpan>
                              {company.phone_number}
                            </Text>
                          </>
                        )}
                        <WrapperDiv gap="8px" mb="25px" mt="25px" flexWrap>
                          <JobStyledButton
                            bgColor={colors.secondary_500}
                            color={colors.white}
                            borderRadius="32px"
                            padding="8px 16px"
                          >
                            {selectedJobOffer.job_category}
                          </JobStyledButton>
                          <JobStyledButton
                            bgColor={colors.secondary_500}
                            color={colors.white}
                            borderRadius="32px"
                            padding="8px 16px"
                          >
                            {selectedJobOffer.work_location}
                          </JobStyledButton>
                          <JobStyledButton
                            bgColor={colors.secondary_500}
                            color={colors.white}
                            borderRadius="32px"
                            padding="8px 16px"
                          >
                            {selectedJobOffer.seniority_level}
                          </JobStyledButton>
                          <JobStyledButton
                            bgColor={colors.secondary_500}
                            color={colors.white}
                            borderRadius="32px"
                            padding="8px 16px"
                          >
                            {selectedJobOffer.job_duration}
                          </JobStyledButton>
                        </WrapperDiv>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          mt={5}
                          mb={8}
                          fontFamily={font.family}
                          color={colors.black}
                          lineHeight="21.6px"
                        >
                          Job Description:
                        </Text>
                        <Text
                          display="block"
                          mt={5}
                          mb={5}
                          fontFamily={font.family}
                          dangerouslySetInnerHTML={{
                            __html: selectedJobOffer.job_description,
                          }}
                        />
                        <Text
                          fontFamily={font.family}
                          fontSize={18}
                          fontWeight={700}
                          lineHeight="21.6px"
                        >
                          Skill Required:
                        </Text>
                        {selectedJobOffer.skills.map((_e, i) => {
                          return (
                            <Text
                              display="block"
                              key={i}
                              mt={5}
                              mb={5}
                              fontFamily={font.family}
                              dangerouslySetInnerHTML={{
                                __html: selectedJobOffer.skills[i],
                              }}
                            />
                          );
                        })}

                        <Text
                          fontFamily={font.family}
                          fontSize={18}
                          fontWeight={700}
                        >
                          Key Responsibilities
                        </Text>
                        <Text
                          display="block"
                          mt={5}
                          mb={5}
                          fontFamily={font.family}
                          dangerouslySetInnerHTML={{
                            __html: selectedJobOffer.responsibilities,
                          }}
                        />
                        <Text
                          fontFamily={font.family}
                          fontSize={18}
                          fontWeight={700}
                        >
                          Benefits
                        </Text>
                        <Text
                          display="block"
                          mt={5}
                          mb={5}
                          fontFamily={font.family}
                          dangerouslySetInnerHTML={{
                            __html: selectedJobOffer.benefits,
                          }}
                        />
                        <CloseIconWrapper onClick={handleCloseClick}>
                          <CloseIcon />
                        </CloseIconWrapper>
                      </JobOffersDetailsContainer>
                    )}
                </JobOffersParent>
              </ActiveJobOffers>

              <DraftJobOffers>
                <Text fontSize={18} fontWeight={700} fontFamily={font.family}>
                  Drafts
                </Text>
                <JobOffersContainer>
                  {jobOffersData.map((jobOffer, index) => {
                    // const draftColorIndex =
                    //   index < offerColors.length
                    //     ? index
                    //     : Math.floor(Math.random() * offerColors.length);
                    if (jobOffer.status === 'draft') {
                      draftsColorCounter += 1;
                      draftJobsCount += 1;

                      if (draftsColorCounter > offerColors.length) {
                        draftsColorCounter = 1;
                      }

                      return (
                        <JobOffer key={index}>
                          <JobNameDiv>
                            <OfferCount
                              backgroundColor={
                                offerColors[draftsColorCounter - 1]
                              }
                            >
                              <Text
                                mt={0}
                                mb={0}
                                fontSize={12}
                                fontWeight={400}
                                fontFamily={font.family}
                              >
                                {/* Offer {index + 1} */}
                                Offer {draftJobsCount}
                              </Text>
                            </OfferCount>

                            <Text
                              mt={0}
                              mb={0}
                              fontSize={18}
                              fontWeight={400}
                              fontFamily={font.family}
                            >
                              {jobOffer.job_position}
                            </Text>
                          </JobNameDiv>

                          <IconsDiv>
                            <EditContainer
                              onClick={() => editJobOffer(jobOffer.id)}
                            >
                              <EditIcon />
                            </EditContainer>
                            <DeleteContainer
                              onClick={() =>
                                handleDeleteButtonClick(jobOffer.id)
                              }
                            >
                              <DeleteIcon />
                            </DeleteContainer>
                          </IconsDiv>
                        </JobOffer>
                      );
                    }
                  })}
                </JobOffersContainer>
              </DraftJobOffers>

              {isDeleteModalVisible && (
                <DeleteModalBackground
                  id="delete-modal-background"
                  onClick={() => setIsDeleteModalVisible(false)}
                >
                  <DeleteModal
                    id="delete-modal"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Text fontSize={28} fontWeight={700} mb={0}>
                      Confirm delete
                    </Text>
                    <Text fontSize={16} mt={4} mb={40}>
                      Are you sure you want to delete?
                    </Text>
                    <CancelConfirmButton
                      isConfirm={true}
                      onClick={() => handleConfirmDelete()}
                    >
                      Confirm
                    </CancelConfirmButton>
                    <CancelConfirmButton onClick={handleCancelDelete}>
                      Cancel
                    </CancelConfirmButton>
                  </DeleteModal>
                </DeleteModalBackground>
              )}
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
