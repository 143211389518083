import React from 'react';
import { StyledMessagePopupWrapper } from './message-popup.styled';

interface MessageProps {
  text: string;
  type?: string;
}

const MessagePopup: React.FC<MessageProps> = ({ text, type }) => {
  return (
    <StyledMessagePopupWrapper type={type}>{text}</StyledMessagePopupWrapper>
  );
};

export default MessagePopup;
