import React, { useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import {
  LogInWrapper,
  LogInFormContainer,
  LogInForm,
  LogInTitle,
  ButtonTitle,
  InputContainer,
  Label,
  TextContainer,
  StyledInput,
  CompanyTitle,
  CompanySubtitle,
  Description,
  ErrorMessage,
  LogInFormWrapper,
  LoginSignupWrapper,
  LoginOrSignupButton,
  ButtonsContainer,
  BlueOutlineButton,
  BlueButton,
  ShowHideText,
  PasswordReset,
} from './login.styled';
import toast from 'react-hot-toast';
import { apiRoute } from '../../constants/api-constants';
import { useAuthForms } from '../hooks/use-auth-forms';
import logoTeam from '../../logo_team.png';
import { colors } from '../../style-utils/colors';
import { Text, font } from '../../style-utils/text';
import { ErrorLabel } from '../pages/jobs/jobs.styled';
import { Cookie } from 'universal-cookie';
import { NavigateFunction } from 'react-router-dom';
import { RotatingSpinner } from '../spinner-new/spinner-new';

interface LogInProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  cookies: Cookie;
}

export const LogIn: React.FC<LogInProps> = ({ navigate, cookies, setUser }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState<boolean>(false);

  const { logInData, handleInputChange } = useAuthForms();
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (checkEmptyFields()) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(apiRoute as string, logInData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      cookies && cookies.set('authorization_token', response.data.token);
      toast.success('You have successfully logged in');
      setInvalidCredentials(false);

      setUser(response.data.entity);
    } catch (error) {
      console.error(error);
      console.log(error);
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 401:
            setInvalidCredentials(true);
            toast.error('Invalid email or password');
            break;
          case 500:
            setInvalidCredentials(true);
            toast.error('Invalid email or password');
            break;
          default:
            toast.error(
              'An unexpected error occurred. Please try again later.'
            );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  const checkEmptyFields = () => {
    let isEmpty = false;

    if (!logInData.email) {
      setIsEmailEmpty(true);
      isEmpty = true;
    } else {
      setIsEmailEmpty(false);
    }

    if (!logInData.password) {
      setIsPasswordEmpty(true);
      isEmpty = true;
    } else {
      setIsPasswordEmpty(false);
    }

    return isEmpty;
  };

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer overflow="none">
          {isLoading ? (
            <RotatingSpinner />
          ) : (
            <LogInForm onSubmit={onSubmit}>
              <LoginSignupWrapper>
                <LoginOrSignupButton
                  onClick={() => navigate && navigate('/login')}
                  isSelected
                >
                  <LogInTitle isSelected>Log In</LogInTitle>
                </LoginOrSignupButton>
                <LoginOrSignupButton
                  onClick={() => navigate && navigate('/register-company')}
                  isSelected={false}
                >
                  <LogInTitle isSelected={false}>Sign Up</LogInTitle>
                </LoginOrSignupButton>
              </LoginSignupWrapper>
              <InputContainer>
                <Label>Email address</Label>
                <StyledInput
                  ref={emailInputRef}
                  bgColor={colors.neutral_50}
                  type="text"
                  name="email"
                  value={logInData.email}
                  placeholder={'Enter address'}
                  onChange={handleInputChange}
                />
                {isEmailEmpty && (
                  <ErrorLabel>Please enter an email address</ErrorLabel>
                )}
              </InputContainer>
              <InputContainer mb={8}>
                <Label>Password</Label>
                <StyledInput
                  bgColor={colors.neutral_50}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={logInData.password}
                  placeholder={'Enter Password'}
                  onChange={handleInputChange}
                  style={{ position: 'relative' }}
                />
                <ShowHideText>
                  <Text
                    onClick={() => setShowPassword(!showPassword)}
                    color={colors.primary_700}
                    fontFamily={font.family}
                    fontSize={14}
                    fontWeight={700}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Text>
                </ShowHideText>
                {isPasswordEmpty && (
                  <ErrorLabel>Please enter a password</ErrorLabel>
                )}
              </InputContainer>
              <PasswordReset
                onClick={() => {
                  navigate && navigate(`/forgot-password`);
                }}
              >
                Forgot password?
              </PasswordReset>
              {invalidCredentials && (
                <ErrorMessage>
                  Invalid email or password. Please try again.
                </ErrorMessage>
              )}
              <ButtonsContainer>
                <BlueOutlineButton
                  type="button"
                  onClick={() => navigate && navigate('/register-company')}
                >
                  <ButtonTitle fontColor={colors.secondary_600}>
                    Create an account
                  </ButtonTitle>
                </BlueOutlineButton>
                <BlueButton type="submit">
                  <ButtonTitle fontColor={'white'}>Log In </ButtonTitle>
                </BlueButton>
              </ButtonsContainer>
            </LogInForm>
          )}
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
