import React from 'react';
import { StyledReportingTabWrapper } from '../../../tabs.styled';
import ReportingEmployeesTable from '../../../../components/tables/reporting-employees-table/reporting-employees-table';
import ReportingMultiLineChart from '../../../../components/charts/multi-line-chart/multi-line-chart';
import { useReportingSalariesData } from '../../use-reporting-salaries-data';

const ReportingPayrollOverview: React.FC = () => {
  const { multiLineChartData, reportingSalariesTablePayrollOverviewData } =
    useReportingSalariesData();

  return (
    <StyledReportingTabWrapper>
      <ReportingMultiLineChart
        name="Salary"
        multiLineChartData={multiLineChartData}
      />
      <ReportingEmployeesTable
        name="Payroll Overview"
        tableData={reportingSalariesTablePayrollOverviewData}
      />
    </StyledReportingTabWrapper>
  );
};

export default ReportingPayrollOverview;
