import styled from 'styled-components';

export const ChooseEmployeesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-bottom: 2px solid #e4eaf5;
  border-left: 2px solid #e4eaf5;
  border-right: 2px solid #e4eaf5;
  border-radius: 8px;
`;

export const ChooseEmployeesHeader = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 0.5px solid black;
`;

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchInput = styled.input`
  border: 1px solid #2e77b8;
  border-radius: 4px;
  padding: 6px;

  ::placeholder {
    font-size: 12px;
    color: #2e2926;
    font-weight: 300;
    font-family: Lato;
  }
`;

export const EmployeesStyledList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

export const StyledItem = styled.li`
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 16.8px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
