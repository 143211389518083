import React from 'react';
import {
  StyledNameAndLastnameWrapper,
  StyledNoResultsFoundWrapper,
  StyledSearchResultItem,
  StyledSearchResultsWrapper,
  StyledStatusInfo,
} from './search-results.styled';
import { Data } from '../search';
import { font, Text } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { useNavigate } from 'react-router-dom';
import { Company } from '../../app-router/component-props';

interface SearchResultsProps {
  data: Data[] | null;
  user: Company;
}

const SearchResults: React.FC<SearchResultsProps> = ({ data, user }) => {
  const navigate = useNavigate();

  const handleSelectItem = (item: Data) => {
    if (item.type === 'Employee') {
      navigate && navigate(`/${user.name}/employees/${item.data.id}`);
    } else {
      navigate && navigate(`/${user.name}/applicants/${item.data.id}`);
    }
  };

  return (
    <StyledSearchResultsWrapper>
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <StyledSearchResultItem
            key={index}
            onClick={() => handleSelectItem(item)}
          >
            <StyledNameAndLastnameWrapper>
              <Text
                fontSize={14}
                fontWeight={500}
                fontFamily={font.family}
                margin="0"
                color={colors.grey}
              >
                {item.data.first_name}
              </Text>
              <Text
                fontSize={14}
                fontWeight={500}
                fontFamily={font.family}
                margin="0"
                color={colors.grey}
              >
                {item.data.last_name}
              </Text>
            </StyledNameAndLastnameWrapper>
            <StyledStatusInfo type={item.type}>
              <Text
                fontSize={10}
                fontWeight={600}
                fontFamily={font.family}
                margin="0"
              >
                {item.type}
              </Text>
            </StyledStatusInfo>
          </StyledSearchResultItem>
        ))
      ) : (
        <StyledNoResultsFoundWrapper>
          <Text
            fontSize={14}
            fontWeight={600}
            fontFamily={font.family}
            margin="0"
            color={colors.error}
            align="center"
          >
            We couldn&apos;t find any results matching your search.
          </Text>
        </StyledNoResultsFoundWrapper>
      )}
    </StyledSearchResultsWrapper>
  );
};

export default SearchResults;
