import styled from 'styled-components';

export const ChatButton = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  gap: 5px;
  bottom: 20px;
  right: 30px;
  font-size: 14px;
  font-weight: 300;
  background-color: #f3f2f2;
  color: #2e77b8;
  padding: 8px 15px;
  border: 5px solid #fdfdfd;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const ChatContainer = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  width: 350px;
  height: 480px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 0.5px solid #5e5a57;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ChatHeader = styled.div`
  color: black;
  width: 87%;
  height: 35px;
  padding-top: 8px;
  font-weight: 550;
  letter-spacing: 0.5px;
  font-size: 17px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #5e5a57;
`;

export const LeftChatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CloseChatBtn = styled.button`
  background: none;
  border: none;
  color: black;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
`;

export const ChatContent = styled.div`
  padding: 10px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const AutoMessage = styled.div`
  background-color: #e4eaf5;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  display: block;
  width: 50%;
  font-size: 14px;
  font-weight: 400;
`;

export const ChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
`;

export const Message = styled.div<{ sent?: boolean }>`
  background-color: ${({ sent }) => (sent ? '#eaeaea' : '#dddddd')};
  padding: 10px;
  text-align: end;
  line-height: 18px;
  border-radius: 5px;
  margin-bottom: 5px;
  max-width: 70%;
  color: #333;
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const QuickQuestions = styled.div`
  margin-top: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 7px;
`;

export const QuestionBtn = styled.button`
  background-color: transparent;
  padding: 8px;
  font-size: 13px;
  display: block;
  border-radius: 8px;
  border: 1px solid #e94e3c;
  cursor: pointer;

  &:hover {
    background-color: #e94e3c;
    border: none;
    color: white;
  }
`;

export const ChatInput = styled.div`
  margin: 10px 15px;
  padding: 5px;
  border-radius: 8px;
  border: none;
  background-color: #f3f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserMessage = styled.input`
  border: none;
  width: 90%;
  height: 100%;
  padding: 5px;
  background-color: transparent;
`;

export const SendBtn = styled.button`
  border: none;
  cursor: pointer;
`;
