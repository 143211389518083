import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Text, font } from '../../../../../style-utils/text';
import {
  Company,
  DefaultComponentProps,
} from '../../../../app-router/component-props';
import { colors } from '../../../../../style-utils/colors';
import { apiRoute } from '../../../../../constants/api-constants';
import {
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
} from '../../../employees/employees.styled';
import { formatDateToLocal } from '../../../../../function-utils/utc-date-converter';
import styled from 'styled-components';
import { ButtonContainer } from '../../../applicants/candidates/candidates.styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompanyDetails: React.FC<DefaultComponentProps> = ({ user }) => {
  const [company, setCompany] = useState<Company | null>(null);
  // eslint-disable-next-line
  const [users, setUsers] = useState<any[]>([]);
  console.log(company);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.post(`${apiRoute}/employees-by-company`, {
          companyId: user.id,
        });
        setCompany(response.data.company);
        setUsers(response.data.employees);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [user.id]);

  return (
    <Wrapper>
      <div style={{ overflowX: 'auto' }}>
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh background={colors.primary_300} columnWidth={'120px'}>
                Date Created
              </StyledTh>
              <StyledTh columnWidth={'232px'}>User</StyledTh>
              <StyledTh columnWidth={'232px'}>Email</StyledTh>
              <StyledTh columnWidth={'120px'}>Role</StyledTh>
              <StyledTh columnWidth={'120px'}>Status</StyledTh>
              <StyledTh columnWidth={'120px'}>Action</StyledTh>
            </StyledTr>
          </StyledThead>
          <tbody>
            {users?.map((user, index) => (
              <StyledTr key={index}>
                <StyledTd data-label="created-at">
                  {formatDateToLocal(user.createdAt)}
                </StyledTd>
                <StyledTd data-label="name">
                  {user?.first_name + ' ' + user?.last_name}
                </StyledTd>
                <StyledTd data-label="email">{user?.email}</StyledTd>
                <StyledTd data-label="role">{user?.account_type}</StyledTd>
                <StyledTd data-label="status">
                  <Text color={user?.status === 'Active' ? 'green' : 'red'}>
                    {user?.status}
                  </Text>
                </StyledTd>
                <StyledTd>
                  <ButtonContainer
                    backgroundColor={colors.secondary_400}
                    // onClick={() => handleEditUser(user.id)}
                  >
                    <Text
                      mt={0}
                      mb={0}
                      align="center"
                      pl={20}
                      pr={20}
                      fontFamily={font.family}
                      fontWeight={700}
                    >
                      Edit
                    </Text>
                  </ButtonContainer>
                </StyledTd>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </Wrapper>
  );
};

export default CompanyDetails;
