import React from 'react';

interface GlobalJobBoardSuccessStoriesArrowsProps {
  direction: 'left' | 'right';
}

const GlobalJobBoardSuccessStoriesArrows: React.FC<
  GlobalJobBoardSuccessStoriesArrowsProps
> = ({ direction }) => {
  return (
    <div>
      {direction === 'left' ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="#DAD9D9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="#DAD9D9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

export default GlobalJobBoardSuccessStoriesArrows;
