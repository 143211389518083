import React from 'react';
import {
  StyledGlobalJobBoardNavbarBtn,
  StyledGlobalJobBoardNavbarBtnsWrapper,
  StyledGlobalJobBoardNavbarLogo,
  StyledGlobalJobBoardNavbarLogoAndMenuWrapper,
  StyledGlobalJobBoardNavbarMainWrapper,
  StyledGlobalJobBoardNavbarMenuItem,
  StyledGlobalJobBoardNavbarMenuItemsWrapper,
} from './global-job-board-navbar.styled';
import WorwiseLogo from '../../../../../assets/images/logo_full_big.png';
import { GlobalJobBoardNavbarMenuItemsData } from './global-job-board-navbar-data';
import { NavigateFunction, useLocation } from 'react-router-dom';

interface GlobalJobBoardNavbarTypes {
  // eslint-disable-next-line
  user: any; 
  navigate: NavigateFunction;
}

const GlobalJobBoardNavbar: React.FC<GlobalJobBoardNavbarTypes> = ({
  navigate,
  user,
}) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop();

  return (
    <StyledGlobalJobBoardNavbarMainWrapper>
      <StyledGlobalJobBoardNavbarLogoAndMenuWrapper>
        <StyledGlobalJobBoardNavbarLogo
          src={WorwiseLogo}
          alt="Worwise Logo"
          onClick={() => navigate('/')}
        />
        <StyledGlobalJobBoardNavbarMenuItemsWrapper>
          {GlobalJobBoardNavbarMenuItemsData.map((menuItem, index) => (
            <StyledGlobalJobBoardNavbarMenuItem
              key={index}
              onClick={() => navigate(menuItem.linkTo)}
              selected={menuItem.linkTo === `/${currentPath}`}
            >
              {menuItem.name}
            </StyledGlobalJobBoardNavbarMenuItem>
          ))}
        </StyledGlobalJobBoardNavbarMenuItemsWrapper>
      </StyledGlobalJobBoardNavbarLogoAndMenuWrapper>
      {!user && (
        <StyledGlobalJobBoardNavbarBtnsWrapper>
          <StyledGlobalJobBoardNavbarBtn
            isSignUpBtn={false}
            onClick={() => navigate('/login')}
          >
            Log In
          </StyledGlobalJobBoardNavbarBtn>
          <StyledGlobalJobBoardNavbarBtn
            isSignUpBtn={true}
            onClick={() => navigate('/register-company')}
          >
            Sign Up
          </StyledGlobalJobBoardNavbarBtn>
        </StyledGlobalJobBoardNavbarBtnsWrapper>
      )}
    </StyledGlobalJobBoardNavbarMainWrapper>
  );
};

export default GlobalJobBoardNavbar;
