import { useState } from 'react';
import { ReportingLineChartDataTypes } from '../../components/charts/charts-data-types';
import { ReportingTableState } from '../../components/tables/reporting-employees-table/reporting-employees-table';

export interface ReportingContractorsTableContractorUtilizationDataTypes {
  name: string;
  projectAssigned: string;
  hoursLogged: string;
  outputQuality: string;
  startDate: string;
  endDate: string;
}

interface ReportingContractorsTableContractRenewalDataTypes {
  name: string;
  contractStartDate: string;
  contractEndDate: string;
  performanceRating: string;
  renewalStatus: string;
}

export const useReportingContractorsData = () => {
  const [lineChartData, setLineChartData] = useState<
    ReportingLineChartDataTypes[]
  >([
    { name: 'Jan', value: 40 },
    { name: 'Feb', value: 45 },
    { name: 'Mar', value: 50 },
    { name: 'Apr', value: 60 },
    { name: 'May', value: 65 },
    { name: 'Jun', value: 55 },
    { name: 'Jul', value: 50 },
    { name: 'Aug', value: 58 },
    { name: 'Sep', value: 62 },
    { name: 'Oct', value: 50 },
    { name: 'Nov', value: 48 },
    { name: 'Dec', value: 55 },
  ]);

  const [
    reportingContractorsTableContractorUtilizationData,
    setReportingContractorsTableContractorUtilizationData,
  ] = useState<
    ReportingTableState<ReportingContractorsTableContractorUtilizationDataTypes>
  >({
    headers: [
      'Name and Surname',
      'Project Assigned',
      'Hours Logged',
      'Output Quality',
      'Start Date',
      'End Date',
    ],

    data: [
      {
        name: 'John Don',
        projectAssigned: 'Lorem ipsum dolor',
        hoursLogged: '18 h.',
        outputQuality: 'Lorem ipsum dolor',
        startDate: '12.05.2019',
        endDate: '12.05.2024',
      },
      {
        name: 'John Don',
        projectAssigned: 'Lorem ipsum dolor',
        hoursLogged: '18 h.',
        outputQuality: 'Lorem ipsum dolor',
        startDate: '12.05.2019',
        endDate: '12.05.2024',
      },
      {
        name: 'John Don',
        projectAssigned: 'Lorem ipsum dolor',
        hoursLogged: '18 h.',
        outputQuality: 'Lorem ipsum dolor',
        startDate: '12.05.2019',
        endDate: '12.05.2024',
      },
      {
        name: 'John Don',
        projectAssigned: 'Lorem ipsum dolor',
        hoursLogged: '18 h.',
        outputQuality: 'Lorem ipsum dolor',
        startDate: '12.05.2019',
        endDate: '12.05.2024',
      },
      {
        name: 'John Don',
        projectAssigned: 'Lorem ipsum dolor',
        hoursLogged: '16 h.',
        outputQuality: 'Lorem ipsum dolor',
        startDate: '12.05.2019',
        endDate: '12.05.2024',
      },
      {
        name: 'John Don',
        projectAssigned: 'Lorem ipsum dolor',
        hoursLogged: '18 h.',
        outputQuality: 'Lorem ipsum dolor',
        startDate: '12.05.2019',
        endDate: '12.05.2024',
      },
    ],
  });

  const [
    reportingContractorsTableContractRenewalData,
    setReportingContractorsTableContractRenewalData,
  ] = useState<
    ReportingTableState<ReportingContractorsTableContractRenewalDataTypes>
  >({
    headers: [
      'Name and Surname',
      'Contract Start Date',
      'Contract End Date',
      'Performance Rating',
      'Renewal Status',
    ],

    data: [
      {
        name: 'John Don',
        contractStartDate: '12.05.2024',
        contractEndDate: '12.05.2025',
        performanceRating: 'Lorem ipsum dolor',
        renewalStatus: 'Automatic',
      },
      {
        name: 'John Don',
        contractStartDate: '12.05.2024',
        contractEndDate: '12.05.2025',
        performanceRating: 'Lorem ipsum dolor',
        renewalStatus: 'Automatic',
      },
      {
        name: 'John Don',
        contractStartDate: '12.05.2024',
        contractEndDate: '12.05.2025',
        performanceRating: 'Lorem ipsum dolor',
        renewalStatus: 'Automatic',
      },
      {
        name: 'John Don',
        contractStartDate: '12.05.2024',
        contractEndDate: '12.05.2025',
        performanceRating: 'Lorem ipsum dolor',
        renewalStatus: 'Automatic',
      },
      {
        name: 'John Don',
        contractStartDate: '12.05.2024',
        contractEndDate: '12.05.2025',
        performanceRating: 'Lorem ipsum dolor',
        renewalStatus: 'Automatic',
      },
      {
        name: 'John Don',
        contractStartDate: '12.05.2024',
        contractEndDate: '12.05.2025',
        performanceRating: 'Lorem ipsum dolor',
        renewalStatus: 'Automatic',
      },
    ],
  });

  return {
    lineChartData,
    reportingContractorsTableContractorUtilizationData,
    reportingContractorsTableContractRenewalData,
    setLineChartData,
    setReportingContractorsTableContractorUtilizationData,
    setReportingContractorsTableContractRenewalData,
  };
};
