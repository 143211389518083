import React, { createContext, useState, useContext, ReactNode } from 'react';
import axios from 'axios';
import { NewJobOfferData } from '../../../../../interfaces/job-interfaces';
import { initialJobOfferData } from './initial-data';
import { createItem } from '../../../../requests/requests';
import {
  combineData,
  extractJsonFromString,
} from '../../../../../function-utils/object-merger';
import { apiRoute } from '../../../../../constants/api-constants';
import { useNavigate } from 'react-router-dom';
import { industries } from '../../../../../constants/industries';

interface GPTData {
  text: string;
}

interface JobOfferContextType {
  jobOffer: NewJobOfferData;
  // eslint-disable-next-line
  updateJobOffer: (key: keyof NewJobOfferData, value: any) => void;
  submitJobOffer: (name: string) => Promise<void>;
  // eslint-disable-next-line
  generateJobOfferWithAi: (user: any) => Promise<void>;
  key: number;
}

const JobOfferContext = createContext<JobOfferContextType | undefined>(
  undefined
);

interface JobOfferProviderProps {
  children: ReactNode;
}

export const JobOfferProvider: React.FC<JobOfferProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [jobOffer, setJobOffer] =
    useState<NewJobOfferData>(initialJobOfferData);
  const [key, setKey] = useState(Math.random());
  // eslint-disable-next-line
  const updateJobOffer = (key: keyof NewJobOfferData, value: any) => {
    setJobOffer((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const submitJobOffer = async (name: string) => {
    try {
      const currentDate = new Date().toISOString();
      const jobData = {
        ...jobOffer,
        expiration_date:
          jobOffer.expiration_date ||
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // Set to 30 days from now if not set
        createdAt: currentDate,
        updatedAt: currentDate,
      };

      const response = await axios.post(
        `${apiRoute}/${name}/hiring/create-job-offer`,
        jobData
      );
      if (response.status === 200) {
        console.log('Job successfully added:', response.data);
        navigate && navigate(`/${name}/jobs/job-offers`);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Failed to submit job offer:', error);
    }
  };
  // eslint-disable-next-line
  const generateJobOfferWithAi = async (user: any): Promise<void> => {
    const preservedJobPosition = { job_position: jobOffer.job_position };
    const resetJobOffer = { ...initialJobOfferData, ...preservedJobPosition };

    const generatedOffer = (await createItem('generate-job-offer', {
      companyInfo: {
        name: user.name,
        description: user.description,
        location: user.location,
        address: user.address,
        country: user.country,
      },
      newJobOfferData: resetJobOffer,
      industries,
    })) as GPTData;

    const data = extractJsonFromString(generatedOffer.text as string);
    const combined = combineData(resetJobOffer, data, [
      'company_id',
      'id',
      'seniority_level',
      'status',
      'work_location',
    ]);

    setJobOffer(combined as NewJobOfferData);
    setKey(Math.random());
  };

  return (
    <JobOfferContext.Provider
      value={{
        jobOffer,
        updateJobOffer,
        submitJobOffer,
        generateJobOfferWithAi,
        key,
      }}
    >
      {children}
    </JobOfferContext.Provider>
  );
};

export const useJobOffer = (): JobOfferContextType => {
  const context = useContext(JobOfferContext);
  if (context === undefined) {
    throw new Error('useJobOffer must be used within a JobOfferProvider');
  }

  return context;
};
