import React, { useState, useEffect, useRef } from 'react';
import {
  StyledGlobalJobBoardFilterInnerWrapper,
  StyledGlobalJobBoardFilterItemWrapper,
  StyledGlobalJobBoardFilterMainWrapper,
  StyledDropdownMenu,
  StyledDropdownOption,
  StyledDropdownSearchInput,
} from './global-job-board-filter.styled';
import { Text, font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { DownArrow } from '../../../../../icons/arrow-down';
import { CheckOnIcon } from '../../../../../icons/check-on';
import { useJobListings } from '../../hooks/job-listings-context';
import { industries } from '../../../../../../constants/industries';

const filterItemsData = [
  {
    name: 'Industry',
    options: industries,
  },
  {
    name: 'Type of Employment',
    options: ['Full Time', 'Part Time', 'Contractor based'],
  },
  { name: 'Worksite', options: ['Office', 'Remote', 'Hybrid'] },
  {
    name: 'Experience',
    options: ['Internship', 'Junior', 'Intermediate', 'Senior', 'Lead'],
  },
  {
    name: 'Salary Range',
    options: ['$0-$50k', '$50k-$100k', '$100k-$150k', '$150k+'],
  },
];

const GlobalJobBoardFilter: React.FC = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, string[]>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const debounceTimeout = useRef<number | null>(null);

  const { applyFilters } = useJobListings();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex((prev) => (prev === index ? null : index));
    setSearchQuery('');
    setFilteredOptions(filterItemsData[index].options);
  };

  const handleOptionSelect = (category: string, option: string) => {
    setSelectedOptions((prev) => {
      const currentSelected = prev[category] || [];
      const updated = currentSelected.includes(option)
        ? currentSelected.filter((item) => item !== option)
        : [...currentSelected, option];

      const newSelectedOptions = { ...prev, [category]: updated };

      applyFilters(newSelectedOptions);

      return newSelectedOptions;
    });
  };

  const handleSearchChange = (query: string, options: string[]) => {
    setSearchQuery(query);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = window.setTimeout(() => {
      const lowerCaseQuery = query.toLowerCase();
      setFilteredOptions(
        options.filter((option) =>
          option.toLowerCase().includes(lowerCaseQuery)
        )
      );
    }, 300);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setOpenDropdownIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <StyledGlobalJobBoardFilterMainWrapper ref={wrapperRef}>
      <StyledGlobalJobBoardFilterInnerWrapper>
        {filterItemsData.map((filterItem, index) => (
          <StyledGlobalJobBoardFilterItemWrapper
            key={filterItem.name}
            isLast={index === filterItemsData.length - 1}
            onClick={() => toggleDropdown(index)}
          >
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.neutral_800}
              margin="0"
            >
              {filterItem.name}
            </Text>
            <DownArrow />
            {openDropdownIndex === index && (
              <StyledDropdownMenu>
                {filterItem.name === 'Industry' && (
                  <StyledDropdownSearchInput
                    type="text"
                    placeholder={`Search ${filterItem.name} ...`}
                    value={searchQuery}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      handleSearchChange(e.target.value, filterItem.options)
                    }
                  />
                )}
                {filteredOptions.map((option, optIndex) => (
                  <StyledDropdownOption
                    key={`${filterItem.name}-${optIndex}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOptionSelect(filterItem.name, option);
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                  >
                    <Text
                      fontFamily={font.familyMontserrat}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.neutral_800}
                      margin="0"
                    >
                      {option}
                    </Text>
                    {selectedOptions[filterItem.name]?.includes(option) && (
                      <CheckOnIcon />
                    )}
                  </StyledDropdownOption>
                ))}
              </StyledDropdownMenu>
            )}
          </StyledGlobalJobBoardFilterItemWrapper>
        ))}
      </StyledGlobalJobBoardFilterInnerWrapper>
    </StyledGlobalJobBoardFilterMainWrapper>
  );
};

export default GlobalJobBoardFilter;
