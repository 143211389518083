import styled from 'styled-components';
import { font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';

export const StyledGlobalJobBoardApplyMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${colors.alabaster};
  font-family: ${font.familyMontserrat};
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledGlobalJobBoardApplyCenterComponentsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 140px 120px 140px;
  background-color: ${colors.alabaster};
`;

export const StyledGlobalJobBoardApplyCenterComponentsInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;
