import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import YourSvgImage from '../../../../../../assets/images/global-job-board-subscribe-bg.svg';
import { font } from '../../../../../../style-utils/text';

export const StyledGlobalJobBoardSubscribeMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 140px;
  background-color: ${colors.powderBlue};
  background-image: url(${YourSvgImage});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
`;

export const StyledGlobalJobBoardSubscribeInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const StyledGlobalJobBoardSubscribeTitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledGlobalJobBoardSubscribeInputAndBtnWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  max-width: 740px;
`;

export const StyledGlobalJobBoardSubscribeInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 24px 168px 24px 32px;
  border-radius: 40px;
  border-style: none;
  font-family: ${font.familyMontserrat};
  font-size: 16px;
  font-weight: 400;
  line-height: '19.5px';
  color: ${colors.base_black};

  ::placeholder {
    font-family: ${font.familyMontserrat};
    font-size: 16px;
    font-weight: 400;
    line-height: '19.5px';
    color: ${colors.neutral_200};
  }

  &:focus {
    outline: 2px solid ${colors.primary_200};
    outline-offset: 0;
  }
`;

export const StyledGlobalJobBoardSubscribeSubmitBtn = styled.button`
  box-sizing: border-box;
  position: absolute;
  width: fit-content;
  top: 8px;
  right: 5px;
  padding: 16px 36px;
  background-color: ${colors.secondary_500};
  border-style: none;
  border-radius: 40px;
  cursor: pointer;
`;
