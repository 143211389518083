import React from 'react';
import {
  PerformanceOverviewCont,
  PerformancesWrapper,
  PerformanceSection,
  Heading3,
  Heading4,
  ProgressContainer,
  ProgressBar,
  ProgressFill,
  ProgressText,
} from '../employee-performance-tab.styled';
import { ProgressIcon } from '../../../../icons/progress-icon';
import { ProgressBlueIcon } from '../../../../icons/progress-icon-blue';

export const PerformanceOverviewComponent = () => {
  return (
    <PerformanceOverviewCont>
      <Heading3>Performance Overview</Heading3>
      <PerformancesWrapper>
        <PerformanceSection>
          <Heading4>Overall Performance</Heading4>
          <ProgressContainer>
            <ProgressBar fillPercent={85} fillColor="#f26954" bgColor="#ffe7e1">
              <ProgressFill
                fillPercent={85}
                fillColor="#f26954"
                bgColor="#ffe7e1"
              >
                <ProgressText>
                  85%
                  <ProgressIcon />
                </ProgressText>
              </ProgressFill>
            </ProgressBar>
          </ProgressContainer>
        </PerformanceSection>
        <PerformanceSection>
          <Heading4>Achievements</Heading4>
          <ProgressContainer>
            <ProgressBar fillPercent={98} fillColor="#5789C2" bgColor="#E4EAF5">
              <ProgressFill
                fillPercent={98}
                fillColor="#5789C2"
                bgColor="#E4EAF5"
              >
                <ProgressText>
                  98%
                  <ProgressBlueIcon />
                </ProgressText>
              </ProgressFill>
            </ProgressBar>
          </ProgressContainer>
        </PerformanceSection>
      </PerformancesWrapper>
    </PerformanceOverviewCont>
  );
};
