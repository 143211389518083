import React, { useEffect, useState } from 'react';
import { NewEmployeeDataTypes } from './add-employee';
import {
  EditWrapper,
  EmployeeDetailStyledInput,
  EmployeeDetailStyledLabelAndInputWrapper,
  EmployeeDetailsContainer,
  EmployeeDetailsWrapper,
} from './employee.styled';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { EditIcon } from '../../icons/edit-icon';
import {
  AddEmployeeStyledButton,
  DownArrowContainer,
  StyledIndefiniteContractCheckbox,
  StyledIndefiniteContractCheckboxWrapper,
  StyledSelect,
} from './add-employee.styled';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { DownArrow } from '../../icons/down-arrow';
import { ErrorLabel, WrapperDiv } from '../jobs/jobs.styled';
import { SelectDate } from '../applicants/candidates/date-picker';

interface EmployeeContractDetailsTabProps {
  // eslint-disable-next-line
  company: any;
  employee: NewEmployeeDataTypes;
  handleToggleEditMode: (toggleFor: string) => void;
  contractDetailsEditMode: boolean;
}

export const EmployeeContractDetailsTab: React.FC<
  EmployeeContractDetailsTabProps
> = ({ company, employee, handleToggleEditMode, contractDetailsEditMode }) => {
  const [employeeEditInitialValues, setEmployeeEditInitialValues] =
    useState<NewEmployeeDataTypes>();
  const [wrongEndOfContract, setWrongEndOfContract] = useState<string>('');
  const [wrongDateOfEmployment, setWrongDateOfEmployment] =
    useState<string>('');
  const [invalidFieldsInputArrayContract, setInvalidFieldsInputArrayContract] =
    useState<string[]>([]);

  useEffect(() => {
    setEmployeeEditInitialValues(employee);
    setInvalidFieldsInputArrayContract([]);
  }, [contractDetailsEditMode, employee]);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;

    if (employeeEditInitialValues) {
      if (name === 'date_of_employment') {
        const selectedDate = new Date(value);
        const endOfContractDate = new Date(
          employeeEditInitialValues.end_of_contract
        );
        selectedDate.setHours(0, 0, 0, 0);
        endOfContractDate.setHours(0, 0, 0, 0);

        if (selectedDate.getFullYear() > 9999) {
          setWrongDateOfEmployment('Please enter a valid date.');
        } else {
          setWrongDateOfEmployment('');
        }

        if (selectedDate >= endOfContractDate) {
          setWrongEndOfContract(
            'End of contract date cannot be before or the same as date of employment.'
          );
        } else {
          setWrongEndOfContract('');
        }
      }
      if (name === 'end_of_contract') {
        // console.log(employeeEditInitialValues.date_of_employment);
        if (employeeEditInitialValues.date_of_employment === '') {
          setWrongEndOfContract('Please select a date of employment first.');
        } else {
          setWrongEndOfContract('');
          const selectedDate = new Date(value);
          const employmentDate = new Date(
            employeeEditInitialValues.date_of_employment
          );
          selectedDate.setHours(0, 0, 0, 0);
          employmentDate.setHours(0, 0, 0, 0);

          if (selectedDate.getFullYear() > 9999) {
            setWrongEndOfContract('Please enter a valid date.');
          } else {
            setWrongEndOfContract('');
          }

          if (selectedDate <= employmentDate) {
            setWrongEndOfContract(
              'End of contract date cannot be before or the same as date of employment.'
            );
          } else {
            setWrongEndOfContract('');
          }
        }
      }
      setEmployeeEditInitialValues({
        ...employeeEditInitialValues,
        [name]: value,
      });
    }
  };

  const handleDateChange = (value: Date | null, name: string) => {
    console.log('Selected expiration date: ', value);
    if (value) {
      if (employeeEditInitialValues) {
        if (name === 'date_of_employment') {
          const selectedDate = new Date(value);
          const endOfContractDate = new Date(
            employeeEditInitialValues.end_of_contract
          );
          selectedDate.setHours(0, 0, 0, 0);
          endOfContractDate.setHours(0, 0, 0, 0);

          if (selectedDate.getFullYear() > 9999) {
            setWrongDateOfEmployment('Please enter a valid date.');
          } else {
            setWrongDateOfEmployment('');
          }

          if (selectedDate >= endOfContractDate) {
            setWrongEndOfContract(
              'End of contract date cannot be before or the same as date of employment.'
            );
          } else {
            setWrongEndOfContract('');
          }
        }
        if (name === 'end_of_contract') {
          // console.log(employeeEditInitialValues.date_of_employment);
          if (employeeEditInitialValues.date_of_employment === '') {
            setWrongEndOfContract('Please select a date of employment first.');
          } else {
            setWrongEndOfContract('');
            const selectedDate = new Date(value);
            const employmentDate = new Date(
              employeeEditInitialValues.date_of_employment
            );
            selectedDate.setHours(0, 0, 0, 0);
            employmentDate.setHours(0, 0, 0, 0);

            if (selectedDate.getFullYear() > 9999) {
              setWrongEndOfContract('Please enter a valid date.');
            } else {
              setWrongEndOfContract('');
            }

            if (selectedDate <= employmentDate) {
              setWrongEndOfContract(
                'End of contract date cannot be before or the same as date of employment.'
              );
            } else {
              setWrongEndOfContract('');
            }
          }
        }
        const formattedDate = formatDate(value);
        setEmployeeEditInitialValues({
          ...employeeEditInitialValues,
          [name]: formattedDate,
        });
      }
    } else {
      setWrongEndOfContract('');
      setEmployeeEditInitialValues((prevValues) => {
        if (prevValues) {
          return {
            ...prevValues,
            [name]: '',
          };
        }

        return prevValues;
      });
    }
  };

  function formatDate(value: Date) {
    if (value) {
      const dateObject = new Date(value);

      return dateObject.toISOString().split('T')[0];
    }

    return null;
  }

  const handleSubmitContract = async () => {
    // e.preventDefault();

    const invalidFields = validateNewJobOfferDataContract(
      employeeEditInitialValues
    );
    setInvalidFieldsInputArrayContract(invalidFields);

    console.log('invalid Fiels array = ', invalidFields);

    if (invalidFields.length !== 0) {
      // const refKey = invalidFields[0];
      // inputRefsPersonal[
      //   refKey as keyof InputRefsPersonal
      // ].current?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      // });

      return;
    }

    if (employeeEditInitialValues) {
      if (wrongDateOfEmployment || wrongEndOfContract) return;

      try {
        const formData = new FormData();

        formData.append('id', employeeEditInitialValues.id);
        formData.append('salary', employeeEditInitialValues.salary);
        formData.append(
          'contract_type',
          employeeEditInitialValues.contract_type
        );
        formData.append('seniority', employeeEditInitialValues.seniority);
        formData.append(
          'date_of_employment',
          employeeEditInitialValues.date_of_employment
        );
        formData.append(
          'end_of_contract',
          employeeEditInitialValues.end_of_contract
        );
        formData.append(
          'indefinite_contract',
          employeeEditInitialValues.indefinite_contract ? 'true' : 'false'
        );
        // formData.append('job_position', newEmployee.job_position);

        console.log('formData = ', formData);

        const response = await axios.post(
          `${apiRoute}/edit-employee`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        if (response.status === 200) {
          console.log('Employee successfully edited:', response.data);
          // employeeFromDB();
          // handleTogglePopup('Employee successfully created.');
        } else {
          console.error('Unexpected response status:', response.status);
          // handleTogglePopup('Unexpected error occured, please try again');
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          console.error('Axios error:', axiosError.message);
          if (axiosError.response) {
            console.error('Response error:', axiosError.response.data);
          } else if (axiosError.request) {
            console.error('Request error:', axiosError.request);
          }
        } else {
          console.error('Non-Axios error:', error);
        }
      } finally {
        handleToggleEditMode('contract');
        // navigate && navigate(`/${name}/hiring/job-offers`);
      }
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  // const validateNewJobOfferDataContract = (data: any) => {
  //   const requiredFields = ['date_of_employment', 'end_of_contract'];
  //   const emptyFields: string[] = [];

  //   if (wrongEndOfContract) {
  //     emptyFields.push('end_of_contract');
  //   }

  //   requiredFields.forEach((field) => {
  //     if (data[field].length === 0) {
  //       emptyFields.push(field);
  //     }
  //   });

  //   return emptyFields;
  // };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewJobOfferDataContract = (data: any) => {
    const emptyFields: string[] = [];

    // Check if date_of_employment is provided
    if (data.date_of_employment && data.date_of_employment.length > 0) {
      // If date_of_employment is provided, end_of_contract must also be provided
      if (!data.end_of_contract || data.end_of_contract.length === 0) {
        // emptyFields.push('end_of_contract'); Iskluci validacija da mora da se vnese end of contract date ako ima Date of Employment zaradi infinite contract
      }
    }

    return emptyFields;
  };

  return (
    <EmployeeDetailsWrapper>
      <EditWrapper
        onClick={() => {
          handleToggleEditMode('contract');
        }}
      >
        <Text
          mt={0}
          mb={0}
          fontWeight={700}
          color={colors.base_black}
          fontFamily={font.family}
        >
          Edit
        </Text>
        <EditIcon />
      </EditWrapper>
      <EmployeeDetailsContainer>
        <Text
          mt={1}
          mb={1}
          fontWeight={700}
          width="20"
          color={colors.base_black}
          fontFamily={font.family}
        >
          Company:&nbsp;
        </Text>
        <Text
          mt={1}
          mb={1}
          width="80"
          color={colors.base_black}
          fontFamily={font.family}
        >
          {company.name && company.name !== 'null'
            ? company.name
            : 'No Company Name'}
        </Text>
      </EmployeeDetailsContainer>
      <EmployeeDetailsContainer>
        <Text
          mt={1}
          mb={1}
          fontWeight={700}
          width="20"
          color={colors.base_black}
          fontFamily={font.family}
        >
          Contract Type:&nbsp;
        </Text>
        {!contractDetailsEditMode && (
          <Text
            mt={1}
            mb={1}
            width="80"
            color={colors.base_black}
            fontFamily={font.family}
          >
            {employee.contract_type && employee.contract_type !== 'null'
              ? employee.contract_type
              : 'No Contract Type Information'}
          </Text>
        )}
        {contractDetailsEditMode && (
          <EmployeeDetailStyledLabelAndInputWrapper
            position="relative"
            width="300px"
          >
            <StyledSelect
              name="contract_type"
              id="contract_type"
              onChange={handleInputChange}
              value={employeeEditInitialValues?.contract_type}
            >
              {/* <option
                defaultValue={employeeEditInitialValues?.contract_type}
                disabled
              >
                {employeeEditInitialValues?.contract_type}
              </option> */}
              <option value="">No Contract Type Selected</option>
              <option value="Full time">Full time</option>
              <option value="Part time">Part time</option>
              <option value="Contractor based">Contractor based</option>
            </StyledSelect>
            <DownArrowContainer right="20px" top="15px">
              <DownArrow />
            </DownArrowContainer>
          </EmployeeDetailStyledLabelAndInputWrapper>
        )}
      </EmployeeDetailsContainer>
      <EmployeeDetailsContainer>
        <Text
          mt={1}
          mb={1}
          fontWeight={700}
          width="20"
          color={colors.base_black}
          fontFamily={font.family}
        >
          Date of employment:&nbsp;
        </Text>
        {!contractDetailsEditMode && (
          <Text
            mt={1}
            mb={1}
            width="80"
            color={colors.base_black}
            fontFamily={font.family}
          >
            {employee.date_of_employment
              ? employee.date_of_employment
              : 'No Date of Employment Information'}
          </Text>
        )}
        {contractDetailsEditMode && (
          <EmployeeDetailStyledLabelAndInputWrapper width="300px">
            <SelectDate
              defaultValue={
                employeeEditInitialValues?.date_of_employment
                  ? new Date(employeeEditInitialValues.date_of_employment)
                  : undefined
              }
              // eslint-disable-next-line
              onChange={(value: any) =>
                handleDateChange(value, 'date_of_employment')
              }
              onClean={() => handleDateChange(null, 'date_of_employment')}
              style={{
                width: '100%',
                borderRadius: '8px',
                borderStyle: 'none',
                height: '48px',
                padding: '0 12px',
                backgroundColor: colors.neutral_50,
              }}
            />
            {invalidFieldsInputArrayContract.includes('date_of_employment') && (
              <ErrorLabel>Please enter a valid date of employment</ErrorLabel>
            )}
            {wrongDateOfEmployment && (
              <ErrorLabel>Please enter a valid date of employment</ErrorLabel>
            )}
          </EmployeeDetailStyledLabelAndInputWrapper>
        )}
      </EmployeeDetailsContainer>
      <EmployeeDetailsContainer>
        <Text
          mt={1}
          mb={1}
          fontWeight={700}
          width="20"
          color={colors.base_black}
          fontFamily={font.family}
        >
          End of Contract:&nbsp;
        </Text>
        {!contractDetailsEditMode && (
          <Text
            mt={1}
            mb={1}
            width="80"
            color={colors.base_black}
            fontFamily={font.family}
          >
            {employee.indefinite_contract
              ? 'Indefinite contract'
              : employee.end_of_contract
              ? employee.end_of_contract
              : 'No End of Contract Information'}
          </Text>
        )}
        {contractDetailsEditMode && (
          <EmployeeDetailStyledLabelAndInputWrapper width="300px">
            <SelectDate
              disabled={employeeEditInitialValues?.indefinite_contract}
              defaultValue={
                employeeEditInitialValues?.end_of_contract
                  ? new Date(employeeEditInitialValues.end_of_contract)
                  : undefined
              }
              // eslint-disable-next-line
              onChange={(value: any) =>
                handleDateChange(value, 'end_of_contract')
              }
              onClean={() => handleDateChange(null, 'end_of_contract')}
              style={{
                width: '100%',
                borderRadius: '8px',
                borderStyle: 'none',
                height: '48px',
                padding: '0 12px',
                backgroundColor: colors.neutral_50,
              }}
            />
            {wrongEndOfContract && (
              <ErrorLabel>{wrongEndOfContract}</ErrorLabel>
            )}
            {invalidFieldsInputArrayContract.includes('end_of_contract') && (
              <ErrorLabel>Please enter a valid end of contract date</ErrorLabel>
            )}
          </EmployeeDetailStyledLabelAndInputWrapper>
        )}
      </EmployeeDetailsContainer>
      {contractDetailsEditMode && (
        <EmployeeDetailsContainer>
          <Text
            mt={1}
            mb={1}
            fontWeight={700}
            width="20"
            color={colors.base_black}
            fontFamily={font.family}
          >
            Indefinite contract:&nbsp;
          </Text>
          <EmployeeDetailStyledLabelAndInputWrapper width="300px">
            <StyledIndefiniteContractCheckboxWrapper>
              <StyledIndefiniteContractCheckbox
                type="checkbox"
                name="indefinite_contract"
                checked={employeeEditInitialValues?.indefinite_contract}
                onChange={(e) =>
                  setEmployeeEditInitialValues((prev) =>
                    prev
                      ? { ...prev, indefinite_contract: e.target.checked }
                      : prev
                  )
                }
              />
            </StyledIndefiniteContractCheckboxWrapper>
          </EmployeeDetailStyledLabelAndInputWrapper>
        </EmployeeDetailsContainer>
      )}
      <EmployeeDetailsContainer>
        <Text
          mt={1}
          mb={1}
          fontWeight={700}
          width="20"
          color={colors.base_black}
          fontFamily={font.family}
        >
          Seniority:&nbsp;
        </Text>
        {!contractDetailsEditMode && (
          <Text
            mt={1}
            mb={1}
            width="80"
            color={colors.base_black}
            fontFamily={font.family}
          >
            {employee.seniority && employee.seniority !== 'null'
              ? employee.seniority
              : 'No Seniority Level Information'}
          </Text>
        )}
        {contractDetailsEditMode && (
          <EmployeeDetailStyledLabelAndInputWrapper
            position="relative"
            width="300px"
          >
            <StyledSelect
              value={employeeEditInitialValues?.seniority}
              name="seniority"
              onChange={handleInputChange}
            >
              {/* <option
                defaultValue={employeeEditInitialValues?.seniority}
                disabled
              >
                {employeeEditInitialValues?.seniority}
              </option> */}
              <option value="">No level selected</option>
              <option value="Senior">Senior</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Junior">Junior</option>
              <option value="Intern">Intern</option>
            </StyledSelect>
            <DownArrowContainer right="20px" top="15px">
              <DownArrow />
            </DownArrowContainer>
          </EmployeeDetailStyledLabelAndInputWrapper>
        )}
      </EmployeeDetailsContainer>
      <EmployeeDetailsContainer>
        <Text
          mt={1}
          mb={1}
          fontWeight={700}
          width="20"
          color={colors.base_black}
          fontFamily={font.family}
        >
          Salary:&nbsp;
        </Text>
        {!contractDetailsEditMode && (
          <Text
            mt={1}
            mb={1}
            width="80"
            color={colors.base_black}
            fontFamily={font.family}
          >
            {employee.salary && employee.salary !== 'null'
              ? employee.salary
              : 'No Salary Information'}
          </Text>
        )}
        {contractDetailsEditMode && (
          <EmployeeDetailStyledLabelAndInputWrapper width="300px">
            <EmployeeDetailStyledInput
              type="text"
              name="salary"
              id="salary"
              value={employeeEditInitialValues?.salary}
              onChange={handleInputChange}
            />
          </EmployeeDetailStyledLabelAndInputWrapper>
        )}
      </EmployeeDetailsContainer>
      {contractDetailsEditMode && (
        <WrapperDiv justifyContent="end" gap="8px" mt="16px">
          <AddEmployeeStyledButton
            bgColor={colors.primary_400}
            borderRadius="4px"
            padding="8px 40px"
            color={colors.base_white}
            type="button"
            onClick={() => handleToggleEditMode('contract')}
          >
            Cancel
          </AddEmployeeStyledButton>
          <AddEmployeeStyledButton
            bgColor={colors.primary_600}
            borderRadius="4px"
            padding="8px 40px"
            color={colors.base_white}
            type="button"
            onClick={handleSubmitContract}
          >
            Add
          </AddEmployeeStyledButton>
        </WrapperDiv>
      )}
    </EmployeeDetailsWrapper>
  );
};
