import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';
import { font } from '../../../../../../../../style-utils/text';

export const StyledJobBoardShareJobMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledJobBoardShareJobForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 440px;
  height: fit-content;
  background-color: ${colors.base_white};
  padding: 24px;
  gap: 32px;
  border-radius: 16px;
`;

export const StyledJobBoardShareJobTitleAndCloseBtnWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledJobBoardShareJobCloseIcon = styled.button`
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
`;

export const StyledJobBoardShareJobDescAndInputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledJobBoardShareJobInputWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: fit-content;
`;

export const StyledJobBoardShareJobInputAddIcon = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 18px;
  right: 12px;
  cursor: pointer;
`;

export const StyledJobBoardShareJobInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  background-color: ${colors.offWhite};
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  color: ${colors.base_black};
  border-radius: 8px;
  border-style: none;

  &::placeholder {
    font-family: ${font.familyMontserrat};
    font-size: 14px;
    font-weight: 300;
    color: ${colors.neutral_700};
  }
`;

export const StyledJobBoardShareJobSubmitBtn = styled.button`
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.persianGreen};
  border-style: none;
  border-radius: 40px;
  padding: 16px 24px;
  cursor: pointer;
`;

export const StyledJobBoardShareJobEmailChipWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledJobBoardShareJobEmailChip = styled.span<{
  hoverColor: string;
}>`
  display: inline-block;
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  color: ${colors.neutral_400};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;
