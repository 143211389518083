// src/components/grouped-notifications/GroupedNotifications.tsx

import React from 'react';
import { Notification } from '../hooks/use-notifications/interfaces';
import { NOTIFICATION_TYPES } from '../pages/employees/notification-preferences/notification-types';
import {
  NotificationContainer,
  NotificationDescriptionDiv,
  ButtonIconDiv,
  DeleteContainer,
  ApproveButton,
  DenyButton,
  DateHeader,
} from './dashboard-notification.styled';
import { DeleteIcon } from '../icons/delete-icon';
import { font, Text } from '../../style-utils/text';
import { colors } from '../../style-utils/colors';
import { RotatingSpinner } from '../spinner-new/spinner-new';

interface DashboardNotifications {
  groupedNotifications: Record<string, Notification[]>;
  userAccountType: string;
  handleApprove: (notification: Notification) => void;
  handleDeny: (notification: Notification) => void;
  handleDeleteNotification: (id: number) => void;
  loading: { [key: number]: boolean };
}

export const DashboardNotifications: React.FC<DashboardNotifications> = ({
  groupedNotifications,
  userAccountType,
  handleApprove,
  handleDeny,
  handleDeleteNotification,
  loading,
}) => {
  return (
    <>
      {Object.keys(groupedNotifications).map((date, index) => (
        <React.Fragment key={index}>
          <DateHeader>
            <Text
              mt={16}
              mb={1}
              fontSize={14}
              fontWeight={400}
              fontFamily={font.family}
              color={colors.neutral_400}
            >
              {date}
            </Text>
          </DateHeader>
          {groupedNotifications[date].map((notification) => (
            <NotificationContainer
              key={notification.id}
              margin={'12px 0px'}
              backgroundColor="rgb(255, 231, 225)"
            >
              <NotificationDescriptionDiv>
                <Text
                  mt={0}
                  mb={0}
                  fontSize={18}
                  fontWeight={400}
                  fontFamily={font.family}
                  color={'rgba(0, 0, 0, 0.70)'}
                  dangerouslySetInnerHTML={{
                    __html: notification.message,
                  }}
                />
                <Text
                  mt={8}
                  mb={0}
                  fontSize={14}
                  fontWeight={400}
                  fontFamily={font.family}
                  color={colors.neutral_400}
                >
                  {new Date(notification?.createdAt).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Text>
              </NotificationDescriptionDiv>

              <ButtonIconDiv>
                {notification.type === NOTIFICATION_TYPES.TIME_OFF_REQUEST &&
                  userAccountType === 'hr' && (
                    <>
                      <ApproveButton
                        onClick={() => handleApprove(notification)}
                        disabled={loading[notification.id]}
                      >
                        {loading[notification.id] ? (
                          <RotatingSpinner />
                        ) : (
                          'Approve'
                        )}
                      </ApproveButton>
                      <DenyButton
                        onClick={() => handleDeny(notification)}
                        disabled={loading[notification.id]}
                      >
                        {loading[notification.id] ? (
                          <RotatingSpinner />
                        ) : (
                          'Deny'
                        )}
                      </DenyButton>
                    </>
                  )}
                <DeleteContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNotification(notification.id);
                  }}
                >
                  <DeleteIcon />
                </DeleteContainer>
              </ButtonIconDiv>
            </NotificationContainer>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};
