// eslint-disable-next-line
export const formatDateToLocal = (utcDate: any) => {
  const date = new Date(utcDate);

  return date.toLocaleDateString('en-GB');
};

export const replaceSlashWithDot = (inputString: string) => {
  return inputString.replace(/\//g, '.');
};
