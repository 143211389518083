import React from 'react';
import {
  StyledGlobalJobBoardSuccessStoriesInnerWrapper,
  StyledGlobalJobBoardSuccessStoriesMainWrapper,
  StyledGlobalJobBoardSuccessStoriesRightComponentArrowsWrapper,
  StyledGlobalJobBoardSuccessStoriesRightComponentMainWrapper,
  StyledGlobalJobBoardSuccessStoriesRightComponentTextWrapper,
  StyledGlobalJobBoardSuccessStoriesRightComponentTitleAndArrowsWrapper,
  StyledGlobalJobBoardSuccessStoriesRightComponentTitleWrapper,
  StyledGlobalJobBoardSuccessStoriesSlideImage,
} from './global-job-board-success-stories.styled';
import SlideImg from '../../../../../../assets/images/global-job-board-success-stories.png';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import GlobalJobBoardSuccessStoriesArrows from '../../../../../icons/global-job-board-success-stories-arrows';

const GlobalJobBoardSuccessStories: React.FC = () => {
  return (
    <StyledGlobalJobBoardSuccessStoriesMainWrapper>
      <StyledGlobalJobBoardSuccessStoriesInnerWrapper>
        <StyledGlobalJobBoardSuccessStoriesSlideImage src={SlideImg} />
        <StyledGlobalJobBoardSuccessStoriesRightComponentMainWrapper>
          <StyledGlobalJobBoardSuccessStoriesRightComponentTitleAndArrowsWrapper>
            <StyledGlobalJobBoardSuccessStoriesRightComponentTitleWrapper>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={18}
                fontWeight={600}
                lineHeight="21.94px"
                color={colors.neutral_800}
                margin="0"
              >
                Success Stories
              </Text>
              <div>
                <Text
                  fontFamily={font.familyMontserrat}
                  fontSize={42}
                  fontWeight={600}
                  lineHeight="51.2px"
                  color={colors.neutral_800}
                  margin="0"
                >
                  Real Experiences
                </Text>
                <Text
                  fontFamily={font.familyMontserrat}
                  fontSize={42}
                  fontWeight={600}
                  lineHeight="51.2px"
                  color={colors.neutral_800}
                  margin="0"
                >
                  from&nbsp;{' '}
                  <span
                    style={{ color: colors.secondary_400, fontWeight: 700 }}
                  >
                    Our
                  </span>
                  &nbsp;Users
                </Text>
              </div>
            </StyledGlobalJobBoardSuccessStoriesRightComponentTitleWrapper>
            <StyledGlobalJobBoardSuccessStoriesRightComponentArrowsWrapper>
              <GlobalJobBoardSuccessStoriesArrows direction="left" />
              <GlobalJobBoardSuccessStoriesArrows direction="right" />
            </StyledGlobalJobBoardSuccessStoriesRightComponentArrowsWrapper>
          </StyledGlobalJobBoardSuccessStoriesRightComponentTitleAndArrowsWrapper>
          <StyledGlobalJobBoardSuccessStoriesRightComponentTextWrapper>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={18}
              fontWeight={500}
              lineHeight="26px"
              color={colors.base_black}
              margin="0"
            >
              &quot;WorWise Portal transformed my job search. The personalized
              job recommendations and expert advice made all the difference. I
              secured a position that perfectly fits my skills and interests.
              <br />
              Thank you!&quot;
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={500}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              Samantha Lee - &nbsp;
              <span style={{ color: colors.persianGreen }}>
                Software Engineer
              </span>
            </Text>
          </StyledGlobalJobBoardSuccessStoriesRightComponentTextWrapper>
        </StyledGlobalJobBoardSuccessStoriesRightComponentMainWrapper>
      </StyledGlobalJobBoardSuccessStoriesInnerWrapper>
    </StyledGlobalJobBoardSuccessStoriesMainWrapper>
  );
};

export default GlobalJobBoardSuccessStories;
