import React from 'react';
import {
  StyledGlobalJobBoardHeroRightComponentBottomTooltip,
  StyledGlobalJobBoardHeroRightComponentBottomTooltipItem,
  StyledGlobalJobBoardHeroRightComponentBottomTooltipItemSeparator,
  StyledGlobalJobBoardHeroRightComponentImage,
  StyledGlobalJobBoardHeroRightComponentMainWrapper,
  StyledGlobalJobBoardHeroRightComponentTooltip,
} from './global-job-board-hero-right-component.styled';
import backgroundImage from '../../../../../../../../assets/images/global-job-board-hero-right-component-image.png';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import useGlobalJobBoardHeroStatistics from './use-global-job-board-hero-statistics';

const GlobalJobBoardHeroRightComponent: React.FC = () => {
  const { data, loading } = useGlobalJobBoardHeroStatistics();

  return (
    <StyledGlobalJobBoardHeroRightComponentMainWrapper>
      <StyledGlobalJobBoardHeroRightComponentImage
        src={backgroundImage}
        alt="Backgound image"
      />
      <StyledGlobalJobBoardHeroRightComponentTooltip top="275px" right="-62px">
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={18}
          fontWeight={600}
          lineHeight="21.94px"
          color={colors.base_black}
          margin="0"
        >
          UI/UX Designer
        </Text>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={400}
          lineHeight="19.5px"
          color={colors.neutral_600}
          margin="0"
        >
          Part - Time
        </Text>
      </StyledGlobalJobBoardHeroRightComponentTooltip>
      <StyledGlobalJobBoardHeroRightComponentTooltip top="75px" left="-22px">
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={18}
          fontWeight={600}
          lineHeight="21.94px"
          color={colors.base_black}
          margin="0"
        >
          Software Engineer
        </Text>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={400}
          lineHeight="19.5px"
          color={colors.neutral_600}
          margin="0"
        >
          Remote
        </Text>
      </StyledGlobalJobBoardHeroRightComponentTooltip>
      {!loading && (
        <StyledGlobalJobBoardHeroRightComponentBottomTooltip
          bottom="-39px"
          left="-22px"
        >
          <StyledGlobalJobBoardHeroRightComponentBottomTooltipItem>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={400}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              User Registered
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={22}
              fontWeight={700}
              lineHeight="26.82px"
              color={colors.base_black}
              margin="0"
            >
              {data?.totalEmployeesCount}
            </Text>
          </StyledGlobalJobBoardHeroRightComponentBottomTooltipItem>
          <StyledGlobalJobBoardHeroRightComponentBottomTooltipItemSeparator />
          <StyledGlobalJobBoardHeroRightComponentBottomTooltipItem>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={400}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              Company Exists
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={22}
              fontWeight={700}
              lineHeight="26.82px"
              color={colors.base_black}
              margin="0"
            >
              {data?.activeCompaniesCount}
            </Text>
          </StyledGlobalJobBoardHeroRightComponentBottomTooltipItem>
        </StyledGlobalJobBoardHeroRightComponentBottomTooltip>
      )}
    </StyledGlobalJobBoardHeroRightComponentMainWrapper>
  );
};

export default GlobalJobBoardHeroRightComponent;
