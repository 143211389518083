interface GlobalJobBoardFooterMenuItemsDataTypes {
  name: string;
  linkTo: string;
}

export const GlobalJobBoardFooterMenuItemsData: GlobalJobBoardFooterMenuItemsDataTypes[] =
  [
    {
      name: 'Find a Job',
      linkTo: '/global',
    },
    {
      name: 'Hire Me',
      linkTo: '#',
    },
    {
      name: 'How it Works',
      linkTo: '#',
    },
    {
      name: 'Blog',
      linkTo: '#',
    },
    {
      name: 'Contact',
      linkTo: '#',
    },
  ];
