import React, { useEffect, useState } from 'react';
import { DefaultComponentProps } from '../../../../../../../app-router/component-props';
import {
  StyledChangePasswordWrapper,
  StyledUserSettingsMainWrapper,
} from './user-settings.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AddEmployeeStyledButton } from '../../../../../../employees/add-employee.styled';
import {
  EmployeeDetailStyledInput,
  EmployeeDetailStyledLabel,
  EmployeeDetailStyledLabelAndInputWrapper,
} from '../../../../../../employees/employee.styled';
import { ErrorLabel } from '../../../../../../jobs/jobs.styled';
import axios from 'axios';
import { apiRoute } from '../../../../../../../../constants/api-constants';
import MessagePopup from '../../../../../../../messages/message-popup/message-popup';

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm Password is required'),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UserSettings: React.FC<DefaultComponentProps> = ({ user }) => {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (popUpVisible) {
      timer = setTimeout(() => {
        setPopUpVisible(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [popUpVisible]);

  //console.log(user);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: async (values) => {
      // Handle form submission
      console.log('Form data', values);
      if (user.entityType) {
        try {
          const result = await updatePassword(
            user.entityType,
            user.id,
            values.newPassword
          );
          setPopUpVisible(true);
          formik.resetForm();
          console.log('Password updated successfully:', result);
        } catch (error) {
          console.error('Failed to update password:', error);
        }
      }
    },
  });

  const updatePassword = async (
    entityType: string,
    entityId: string,
    newPassword: string
  ) => {
    try {
      const response = await axios.put(
        `${apiRoute}/update-password/${entityType}/${entityId}`,
        { password: newPassword },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  };

  return (
    <StyledUserSettingsMainWrapper>
      <Text
        margin="0 0 32px 0"
        fontFamily={font.family}
        fontSize={20}
        fontWeight={700}
        color={colors.base_black}
      >
        Change password
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <StyledChangePasswordWrapper>
          <EmployeeDetailStyledLabelAndInputWrapper>
            <EmployeeDetailStyledLabel htmlFor={'newPassword'}>
              Enter new password
            </EmployeeDetailStyledLabel>
            <EmployeeDetailStyledInput
              id="newPassword"
              name="newPassword"
              type="password"
              placeholder="New password"
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              style={{ width: '500px' }}
            />
            {formik.errors.newPassword && (
              <ErrorLabel>{formik.errors.newPassword}</ErrorLabel>
            )}
          </EmployeeDetailStyledLabelAndInputWrapper>
          <EmployeeDetailStyledLabelAndInputWrapper>
            <EmployeeDetailStyledLabel htmlFor="confirmPassword">
              Confirm password
            </EmployeeDetailStyledLabel>
            <EmployeeDetailStyledInput
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Confirm password"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              style={{ width: '500px' }}
            />
            {formik.errors.confirmPassword && (
              <ErrorLabel>{formik.errors.confirmPassword}</ErrorLabel>
            )}
          </EmployeeDetailStyledLabelAndInputWrapper>
          <AddEmployeeStyledButton
            type="submit"
            bgColor={colors.primary_600}
            borderRadius="4px"
            padding="8px 40px"
            width="114px"
            alignSelf="center"
            color={colors.base_white}
            onClick={() => setValidateOnChange(true)}
          >
            Save
          </AddEmployeeStyledButton>
        </StyledChangePasswordWrapper>
      </form>
      {popUpVisible && (
        <MessagePopup text="Password changed successfully" type="success" />
      )}
    </StyledUserSettingsMainWrapper>
  );
};
