import React from 'react';
import {
  EmployeeDetailDocumentTable,
  EmployeeDetailDocumentTableHeader,
  EmployeeDetailDocumentTableHeaders,
} from '../../../employees/employee.styled';
import { colors } from '../../../../../style-utils/colors';
import { Text, font } from '../../../../../style-utils/text';

export const EmployeeTableSurvey: React.FC = () => {
  const employeesResponses = [
    {
      id: 1,
      name: 'Alex Ludkee',
      email: 'ludkee.aleks@gmail.com',
      response: 'No',
    },
    {
      id: 2,
      name: 'John Brown',
      email: 'john.brown@gmail.com',
      response: 'Yes',
    },
    {
      id: 3,
      name: 'Maria Johnson',
      email: 'maria.johnson@gmail.com',
      response: 'Yes',
    },
    {
      id: 4,
      name: 'Lucas Waine',
      email: 'lucas.waine@gmail.com',
      response: 'No',
    },
  ];

  return (
    <EmployeeDetailDocumentTable mt="24px" mb="30px">
      <EmployeeDetailDocumentTableHeaders>
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_500}
          padding="8px 16px"
          width="45%"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Name
          </Text>
        </EmployeeDetailDocumentTableHeader>
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_500}
          padding="8px 16px"
          width="45%"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Email
          </Text>
        </EmployeeDetailDocumentTableHeader>
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_500}
          padding="8px 16px"
          width="10%"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Response
          </Text>
        </EmployeeDetailDocumentTableHeader>
      </EmployeeDetailDocumentTableHeaders>
      {employeesResponses.map((employee) => (
        <EmployeeDetailDocumentTableHeaders key={employee.id}>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.creme_100}
            padding="8px 16px"
            width="45%"
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
              style={{ whiteSpace: 'nowrap' }}
            >
              {employee.name}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.creme_100}
            padding="8px 16px"
            width="45%"
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {employee.email}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={
              employee.response === 'Yes'
                ? colors.secondary_50
                : colors.primary_50
            }
            padding="8px 16px"
            width="10%"
          >
            {employee.response}
          </EmployeeDetailDocumentTableHeader>
        </EmployeeDetailDocumentTableHeaders>
      ))}
    </EmployeeDetailDocumentTable>
  );
};
