import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../function-utils/log-out';
import {
  LogInWrapper,
  LogInFormContainer,
  TextContainer,
  CompanyTitle,
  CompanySubtitle,
  Description,
  LogInFormWrapper,
} from '../../login/login.styled';
import logoTeam from '../../../logo_team.png';
import {
  SubTitle,
  Title,
  Wrapper,
  Button,
  ButtonsWrapper,
} from '../change-plan/change-plan.styled';
import { Xicon } from '../../icons/x-skill';

interface BlockedPageProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  //   refetchUser?: () => Promise<void>;
  //   setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  //   signUpData?: SignUpData;
  //   handlePlanSelection?: (plan: string) => void;
}

export const BlockedPage: React.FC<BlockedPageProps> = ({ user, setUser }) => {
  const navigate = useNavigate();
  const [secondsLeft, setSecondsLeft] = useState(30);

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  const handleLogout = async () => {
    if (setUser) {
      await logOut(setUser, navigate);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (secondsLeft <= 0) {
      handleLogout();
    }

    return () => clearInterval(timer);
  }, [secondsLeft]);

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
          alt="Company Logo"
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer maxWidth="929px" overflow="none">
          <Wrapper padding="40px" id="wrapper" isSignUp={true} key={user?.id}>
            <Title>
              <Xicon />
              Your Account has been blocked!
            </Title>
            <SubTitle>
              To continue using our services, please contact us. You will be
              logged out in {secondsLeft} second{secondsLeft !== 1 ? 's' : ''}
            </SubTitle>
            <ButtonsWrapper>
              <Button disabled={false} onClick={handleLogout}>
                Logout Now
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
