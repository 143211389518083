import React, { useState } from 'react';
import { SearchIcon } from '../../../../../icons/search';
import {
  IconWrapper,
  SkillsSearchInputContainer,
  SkillsStyledInput,
} from './search.styled';
import { SkillMapSearchProps } from '../table/skill-map-table';

export const SkillMapSearch: React.FC<SkillMapSearchProps> = ({
  employees,
  setFilteredEmployees,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = employees.filter((employee) => {
      const hasSkill = Object.keys(employee.skills).some((skill) =>
        skill.toLowerCase().includes(searchValue)
      );

      return employee.name.toLowerCase().includes(searchValue) || hasSkill;
    });

    setFilteredEmployees(filtered);
  };

  return (
    <SkillsSearchInputContainer>
      <SkillsStyledInput
        placeholder="Search employee by name"
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </SkillsSearchInputContainer>
  );
};
