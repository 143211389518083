import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';

export const StyledGlobalJobBoardFilterMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 140px;
  background-color: ${colors.aliceBlue};
`;

export const StyledGlobalJobBoardFilterInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledGlobalJobBoardFilterItemWrapper = styled.div<{
  isLast: boolean;
}>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;

  ${({ isLast }) =>
    !isLast &&
    `
      border-right: 0.5px solid #ccc;
      height: 70%;
    `}
`;

export const StyledDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${colors.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  z-index: 10;
  padding: 0 0 8px 0;
  min-width: 150px;
  max-height: 300px;
  overflow-y: auto;
`;

export const StyledDropdownSearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 8px 16px;
  border-style: none;
  border-bottom: 0.5px solid ${colors.neutral_50};
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.neutral_800};
  outline: none;
`;

export const StyledDropdownOption = styled.div`
  padding: 8px 16px;
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.neutral_800};
  cursor: pointer;
  &:hover {
    background-color: ${colors.neutral_200};
  }
`;
