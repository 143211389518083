import React from 'react';
import { colors } from '../../style-utils/colors';

interface TableStatusColorCircleIconProps {
  status: string;
}

export const TableStatusColorCircleIcon: React.FC<
  TableStatusColorCircleIconProps
> = ({ status }) => {
  const getColor = (status: string) => {
    switch (status) {
      case 'Pending':
        return colors.pending_yellow;
      case 'Confirmed':
        return colors.success_green;
      case 'Denied':
        return colors.error;
      default:
        return colors.neutral_50; // Default color if status doesn't match any case
    }
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill={getColor(status)} />
    </svg>
  );
};
