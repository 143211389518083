import styled from 'styled-components';

export const ParentContainer = styled.div`
  /* margin-top: auto; */
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// export const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

// export const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
// `;
