import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  StyledGlobalJobBoardJustLandedMainWrapper,
  StyledGlobalJobBoardJustLandedTitleAndIcon,
} from '../../../../components/featured-jobs/global-job-board-featured-jobs.styled';
import { Text, font } from '../../../../../../../style-utils/text';
import { colors } from '../../../../../../../style-utils/colors';
import GlobalJobBoardJustLandedListing from '../../../../components/featured-jobs/listing/global-job-board-featured-jobs-listing';
import useGlobalJobBoardSimilarJobs from './use-global-job-board-similar-jobs';

interface GlobalJobBoardSimilarJobsProps {
  navigate: NavigateFunction;
  jobId: string | undefined;
  jobTitle: string | undefined;
}

const GlobalJobBoardSimilarJobs: React.FC<GlobalJobBoardSimilarJobsProps> = ({
  navigate,
  jobId,
  jobTitle,
}) => {
  const { jobListings } = useGlobalJobBoardSimilarJobs(jobId, jobTitle);

  return (
    <StyledGlobalJobBoardJustLandedMainWrapper>
      <StyledGlobalJobBoardJustLandedTitleAndIcon>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={500}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          Similar Jobs
        </Text>
      </StyledGlobalJobBoardJustLandedTitleAndIcon>
      {jobListings.map((job, index) => (
        <GlobalJobBoardJustLandedListing
          key={index}
          jobListing={job}
          backgroundColor={colors.white}
          navigate={navigate}
        />
      ))}
    </StyledGlobalJobBoardJustLandedMainWrapper>
  );
};

export default GlobalJobBoardSimilarJobs;
