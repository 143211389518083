import React, { useRef, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { Text, font } from '../../style-utils/text';

interface TextEditorProps {
  changeHandler: (value: string) => void;
  defaultValue: string;
  minHeight?: string;
  height?: string;
  backgroundColor?: string;
  placeholder?: string;
}

export const StyledQuill = styled(ReactQuill)<{
  minHeight: string;
  height: string;
  backgroundColor: string;
}>`
  .ql-container {
    border: none !important;
    border-radius: 15px;
    min-height: ${({ minHeight }) => minHeight};
    height: ${({ height }) => height};
    background-color: ${({ backgroundColor }) => backgroundColor};
  }

  .ql-toolbar {
    border: none !important;
  }

  display: block;
  overflow: hidden;
  border-radius: 15px;
`;

export const TextEditor: React.FC<TextEditorProps> = ({
  changeHandler,
  defaultValue,
  minHeight = '300px',
  height = '300px',
  backgroundColor = 'white',
  placeholder = '',
}) => {
  const quillRef = useRef<ReactQuill>(null);
  const [value, setValue] = useState(defaultValue);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const constrictText = (content: string) => {
    if (content.length > 30000) {
      setError('Max characters: 30000');
      const quill = quillRef.current?.getEditor();
      quill?.deleteText(30000, content.length);

      return false;
    } else {
      setError(null);

      return true;
    }
  };

  return (
    <>
      <StyledQuill
        ref={quillRef}
        value={value}
        onChange={(value) => {
          if (constrictText(value)) {
            setValue(value);
            changeHandler(value);
          }
        }}
        minHeight={minHeight}
        height={height}
        backgroundColor={backgroundColor}
        placeholder={placeholder} // Pass the placeholder prop to the editor
      />
      {error && (
        <div
          style={{
            display: 'flex',
            position: 'relative',
            background: 'lightgray',
          }}
        >
          <Text
            pl={12}
            pr={12}
            width="100%"
            backgroundColour={'lightgray'}
            fontFamily={font.family}
            color={'red'}
            fontSize={font.size.xs}
          >
            {error}
          </Text>
        </div>
      )}
    </>
  );
};
