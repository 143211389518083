import styled from 'styled-components';

export const StyledGlobalJobBoardJustLandedMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledGlobalJobBoardJustLandedTitleAndIcon = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledGlobalJobBoardFeaturedJobsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
