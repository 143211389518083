import React from 'react';
import {
  ContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../../layout/layout-container.styled';
import BackToDashboard from '../../settings/components/back-btn/back-btn';
import { NavigateFunction } from 'react-router-dom';
import { EditCompanyModal } from './edit-company-modal';
import { Company } from './edit-company-interface';

interface EditCompanyPageProps {
  navigate: NavigateFunction;
  handleBack: () => void;
  editingCompany: Company;
}

export const EditCompanyPage: React.FC<EditCompanyPageProps> = ({
  navigate,
  handleBack,
  editingCompany,
}) => {
  console.log('editing company');
  console.log(editingCompany);

  return (
    <ContentWrapper>
      <NavbarAndContentTransparentContainer>
        <BackToDashboard
          user={undefined}
          handleBack={handleBack}
          navigate={navigate}
        />
        <EditCompanyModal
          handleBack={handleBack}
          editingCompany={editingCompany}
        />
      </NavbarAndContentTransparentContainer>
    </ContentWrapper>
  );
};
