import React, { useState } from 'react';
import {
  RecentActivityContainer,
  Heading3,
  TabButtons,
  TabBtn,
  Headlines,
  Headline,
  ActivityTable,
  ButtonsContainer,
  ViewBtn,
  ActivityList,
  ActivityItem,
  ActivityText,
  ActivityStatus,
  BoxWrapper,
  ButtonContainer,
  SortButton,
  Paragraph,
} from '../employee-performance-tab.styled';
import { DownArrow } from '../../../../icons/down-arrow';
import { ArrowUp } from '../../../../icons/arrow-up';
import { TrendingUpIcon } from '../../../../icons/trending-up';

type ActivitiesType = {
  title: string;
  project_name: string;
  status: string;
};

export const RecentActivity = () => {
  const [selectedTab, setSelectedTab] = useState<string>('projects_completed');
  const [showAllActivities, setShowAllActivities] = useState<boolean>(false);
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const handleSelectedTab = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const handleSortToggle = () => {
    setSortAscending(!sortAscending);
  };

  const recentActivities = (): ActivitiesType[] => {
    const activities: Record<string, ActivitiesType[]> = {
      projects_completed: [
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '85%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
      ],
      achievements: [
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '90%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
        {
          title: 'Company Name',
          project_name: 'Project Name',
          status: '100%',
        },
      ],
    };

    if (selectedTab && activities[selectedTab]) {
      const selectedFeedback = activities[selectedTab];

      return sortAscending ? selectedFeedback : [...selectedFeedback].reverse();
    }

    return [];
  };

  const visibleActivities = showAllActivities
    ? recentActivities()
    : recentActivities().slice(0, 4);

  return (
    <RecentActivityContainer>
      <Heading3>Recent Activity</Heading3>
      <BoxWrapper>
        <ButtonsContainer>
          <TabButtons>
            <TabBtn
              active={selectedTab === 'projects_completed'}
              onClick={() => handleSelectedTab('projects_completed')}
            >
              Projects Completed
            </TabBtn>
            <TabBtn
              active={selectedTab === 'achievements'}
              onClick={() => handleSelectedTab('achievements')}
            >
              Achievements
            </TabBtn>
          </TabButtons>
          <ButtonContainer>
            <Paragraph>Sort by:</Paragraph>
            <SortButton onClick={handleSortToggle}>
              {sortAscending ? <>Date Descending</> : <>Date Ascending</>}
            </SortButton>
          </ButtonContainer>
        </ButtonsContainer>
        <ActivityTable>
          <Headlines>
            <Headline>Company Name</Headline>
            <Headline>Project Name</Headline>
            <Headline>Status</Headline>
          </Headlines>
          {recentActivities().length > 0 && (
            <>
              <ActivityList>
                {visibleActivities.map((activity, i) => (
                  <ActivityItem key={i}>
                    <ActivityText>{activity.title}</ActivityText>
                    <ActivityText>{activity.project_name}</ActivityText>
                    <ActivityStatus>
                      {activity.status} <TrendingUpIcon />
                    </ActivityStatus>
                  </ActivityItem>
                ))}
              </ActivityList>
              {recentActivities().length > 2 && (
                <ViewBtn
                  onClick={() => setShowAllActivities(!showAllActivities)}
                >
                  {showAllActivities ? 'Show less' : 'View all'}
                  {showAllActivities ? <ArrowUp /> : <DownArrow />}
                </ViewBtn>
              )}
            </>
          )}
        </ActivityTable>
      </BoxWrapper>
    </RecentActivityContainer>
  );
};
