import styled from 'styled-components';
import { colors } from '../../../../../../../../../../style-utils/colors';

export const StyledJobPositionHeadingMainWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
`;

export const StyledJobPositionHeadingAddPositionBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 12px;
  width: fit-content;
  background-color: ${colors.secondary_400};
  border-style: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
