import React from 'react';
export interface PaginationArrowProps {
  color: string;
}

export const PaginationLeftArrow: React.FC<PaginationArrowProps> = ({
  color,
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5 6L9.5 12L15.5 18" stroke={color} />
    </svg>
  );
};
