import React from 'react';
import { StyledBackToDashboardBtn } from './back-btn.styled';
import { LeftArrowSettings } from '../../../../icons/arrow-left-settings';
import { DefaultComponentProps } from '../../../../app-router/component-props';
import { Text, font } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';

const BackToDashboard: React.FC<DefaultComponentProps> = ({
  navigate,
  handleBack,
}) => {
  const onClickHandler = () => {
    if (handleBack) {
      handleBack();
    } else if (navigate) {
      navigate(`/admin`);
    }
  };

  return (
    <StyledBackToDashboardBtn onClick={onClickHandler}>
      <LeftArrowSettings />
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={16}
        fontWeight={400}
        color={colors.neutral_400}
      >
        Back to Dashboard
      </Text>
    </StyledBackToDashboardBtn>
  );
};

export default BackToDashboard;
