import React, { useEffect, useState, useRef } from 'react';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import { fetchAllItems } from '../../requests/requests';
import { useNavigate } from 'react-router-dom';
import {
  ContentAndImageContainer,
  ImageContainer,
  CoverImage,
  Overlay,
  CenteredText,
  JobPostingsContainer,
  JobPostInfoContainer,
  JobPostingAndInfoContainer,
  JobPostingAndInfoWrapper,
  CareerText,
  NoActiveJobsAvailableMSgMainWrapper,
  NoActiveJobsAvailableMSg,
  NoActiveJobsAvailableMSgCompanyName,
} from './company-job-board-table.styled';
import { JobPostings } from './job-postings';
import { JobPostInfo } from './job-post-info';
import { Company } from '../../app-router/component-props';
import { Empty } from './job-post-info.styled';
import { ColorTemplate } from './company-job-board';
import NewPagination from '../../new-pagination/new-pagination';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';

interface CompanyJobBoardTableProps {
  company?: Company | null;
  openFormJobId: string | null;
  handleToggleShareForm: (job: JobOfferWithCompanyData | null) => void;
  colorTemplate: ColorTemplate;
  fromPage?: string;
}

export const CompanyJobBoardTable: React.FC<CompanyJobBoardTableProps> = ({
  company,
  openFormJobId,
  handleToggleShareForm,
  colorTemplate,
  fromPage,
}) => {
  const [companyJobs, setCompanyJobs] = useState<JobOfferWithCompanyData[]>([]);
  const [selectedJob, setSelectedJob] =
    useState<JobOfferWithCompanyData | null>(null);
  const [isJobClicked, setIsJobClicked] = useState(false);
  const navigate = useNavigate();
  const mobileContainerRef = useRef<HTMLDivElement | null>(null);
  const [jobsLoaded, setJobsLoaded] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  const jobOffersFromDB = async (companyId: string) => {
    try {
      const response = await fetchAllItems(`company/jobs/${companyId}`);
      const jobs = response as JobOfferWithCompanyData[];
      setCompanyJobs(jobs);
      setSelectedJob(jobs[0]);
      setIsJobClicked(false);
    } catch (error) {
      console.error('Failed to fetch job offers:', error);
    } finally {
      setJobsLoaded(true);
    }
  };

  const allJobOffersFromDB = async () => {
    try {
      const response = await fetchAllItems(`/job-board`);
      const jobs = response as JobOfferWithCompanyData[];
      setCompanyJobs(jobs);
      setSelectedJob(jobs[0]);
      setIsJobClicked(false);
      console.log(jobs);
    } catch (error) {
      console.error('Failed to fetch job offers:', error);
    } finally {
      setJobsLoaded(true);
    }
  };

  useEffect(() => {
    if (company) {
      jobOffersFromDB(company.id);
    } else if (fromPage === 'global' || location.pathname === '/global') {
      allJobOffersFromDB();
    }
  }, [company, fromPage, location.pathname]);

  useEffect(() => {
    if (
      selectedJob &&
      isJobClicked &&
      mobileContainerRef.current &&
      mobileContainerRef.current.offsetWidth > 0
    ) {
      navigate(
        `/job-board/mobile-job-post/${selectedJob.company_id}/${selectedJob.id}`
      );
    }
  }, [selectedJob, isJobClicked, navigate]);

  const selectJob = (job: JobOfferWithCompanyData) => {
    setSelectedJob(job);
    setIsJobClicked(true);
  };

  const jobsPerPage = 4;
  const totalPages = Math.ceil(companyJobs.length / jobsPerPage);

  const paginatedJobs = companyJobs.slice(
    (currentPage - 1) * jobsPerPage,
    currentPage * jobsPerPage
  );

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderJobPostings = () =>
    paginatedJobs.map((job, index) => (
      <JobPostings
        openFormJobId={openFormJobId}
        setOpenFormJobId={handleToggleShareForm}
        navigate={navigate}
        jobPostMobilePath={`/job-board/mobile-job-post/${job.company_id}/${job.id}`}
        company={company}
        key={index}
        job={job}
        selectJob={selectJob}
        toggleApplyForm={() => navigate(`/apply/${job.company_id}/${job.id}`)}
        colorTemplate={colorTemplate}
      />
    ));

  if (!jobsLoaded) {
    return <FullScreenSpinner />;
  }

  if (!selectedJob) {
    return (
      <NoActiveJobsAvailableMSgMainWrapper>
        <NoActiveJobsAvailableMSg>
          Currently, there are no active job openings available at{' '}
          <NoActiveJobsAvailableMSgCompanyName>
            {company?.name}
          </NoActiveJobsAvailableMSgCompanyName>
        </NoActiveJobsAvailableMSg>
      </NoActiveJobsAvailableMSgMainWrapper>
    );
  }

  return (
    <ContentAndImageContainer backgroundColor={colorTemplate.background_color}>
      <ImageContainer>
        <CoverImage src="/coverImage.jpeg" alt="Cover" />
        <Overlay />
        <CenteredText>
          Your Best way to Exciting&nbsp;<CareerText>Career</CareerText>
          &nbsp;Opportunities
        </CenteredText>
      </ImageContainer>
      <JobPostingAndInfoContainer>
        <JobPostingAndInfoWrapper>
          <JobPostingsContainer>
            {renderJobPostings()}
            <NewPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              filteredData={companyJobs}
            />
          </JobPostingsContainer>
          <Empty ref={mobileContainerRef}>
            {/* Marked component that is visible on specific sizes */}
          </Empty>
          <JobPostInfoContainer>
            <JobPostInfo
              job={selectedJob}
              company={company || undefined}
              toggleApplyForm={() =>
                navigate(`/apply/${selectedJob.company_id}/${selectedJob.id}`)
              }
              colorTemplate={colorTemplate}
            />
          </JobPostInfoContainer>
        </JobPostingAndInfoWrapper>
      </JobPostingAndInfoContainer>
    </ContentAndImageContainer>
  );
};
