import React, { Dispatch, SetStateAction, useState } from 'react';
import { NewEmployeeDataTypes } from '../../add-employee';
import {
  StyledAddEmployeeEducationBackBtn,
  StyledAddEmployeeEducationBtnsWrapper,
  StyledAddEmployeeEducationForm,
  StyledAddEmployeeEducationFormItem,
  StyledAddEmployeeEducationFormItemsWrapper,
  StyledAddEmployeeEducationInput,
  StyledAddEmployeeEducationMain,
  StyledAddEmployeeEducationSubmitBtn,
} from './add-employee-education.styled';
import { font, Text } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EmployeeEducationDetailsData } from '../../../../../interfaces/employee-interfaces';
import axios from 'axios';
import { apiRoute } from '../../../../../constants/api-constants';

interface AddEmployeeEducationProps {
  setAddNewEducationDetail: Dispatch<SetStateAction<boolean>>;
  employee: NewEmployeeDataTypes;
  setPopUp: Dispatch<
    SetStateAction<{ text: string; type: 'success' | 'error' } | null>
  >;
  fetchEmployeeEducationDetails: () => void;
}

const addEmployeeEducationValidationSchema = yup.object().shape({
  type: yup.string().required('Type is required!'),
  institution_name: yup.string().required('Institution name is required!'),
  duration: yup.string().required('Duration is required!'),
});

const AddEmployeeEducation: React.FC<AddEmployeeEducationProps> = ({
  setAddNewEducationDetail,
  employee,
  setPopUp,
  fetchEmployeeEducationDetails,
}) => {
  const addEmployeeTimeoffRequestInitValues: Omit<
    EmployeeEducationDetailsData,
    'id'
  > = {
    type: '',
    institution_name: '',
    duration: '',
  };

  const [validateOnChange, setValidateOnChange] = useState(false);

  const formik = useFormik({
    initialValues: addEmployeeTimeoffRequestInitValues,
    validationSchema: addEmployeeEducationValidationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: (values) => {
      console.log('Submitted values', values);
      postEmployeeEducation(employee.id, values);
    },
  });

  const postEmployeeEducation = (
    employeeId: string,
    educationData: { type: string; institution_name: string; duration: string }
  ) => {
    axios
      .post(`${apiRoute}/employee/${employeeId}/education`, educationData)
      .then((response) => {
        console.log('Education details added successfully!', response.data);

        setPopUp({
          text: 'Education details added successfully!',
          type: 'success',
        });
        fetchEmployeeEducationDetails();
        setAddNewEducationDetail(false);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          console.error('Axios error:', error.response?.data || error.message);
          const errorMessage =
            error.response?.data?.message || 'Failed to add education details';

          setPopUp({ text: errorMessage, type: 'error' });
        } else {
          console.error('Unknown error:', error);
          setPopUp({
            text: 'An unexpected error occurred while adding education details',
            type: 'error',
          });
        }
      });
  };

  const handleClickOutside = () => {
    setAddNewEducationDetail(false);
  };

  const handleFormClick = (
    e: React.MouseEvent<HTMLFormElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  return (
    <StyledAddEmployeeEducationMain onClick={handleClickOutside}>
      <StyledAddEmployeeEducationForm
        onClick={handleFormClick}
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <Text
          fontFamily={font.family}
          margin="0"
          color={colors.base_black}
          fontSize={28}
          fontWeight={700}
        >
          Add Education
        </Text>
        <StyledAddEmployeeEducationFormItemsWrapper>
          <StyledAddEmployeeEducationFormItem>
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={18}
              fontWeight={700}
            >
              Institution name
            </Text>
            <StyledAddEmployeeEducationInput
              type="text"
              name="institution_name"
              placeholder="Institution name"
              value={formik.values.institution_name}
            />
            {formik.errors.institution_name && (
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.error}
                fontSize={12}
                fontWeight={700}
              >
                {formik.errors.institution_name}
              </Text>
            )}
          </StyledAddEmployeeEducationFormItem>
          <StyledAddEmployeeEducationFormItem>
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={18}
              fontWeight={700}
            >
              Education type
            </Text>
            <StyledAddEmployeeEducationInput
              type="text"
              name="type"
              placeholder="Education type"
              value={formik.values.type}
            />
            {formik.errors.type && (
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.error}
                fontSize={12}
                fontWeight={700}
              >
                {formik.errors.type}
              </Text>
            )}
          </StyledAddEmployeeEducationFormItem>
          <StyledAddEmployeeEducationFormItem>
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={18}
              fontWeight={700}
            >
              Duration
            </Text>
            <StyledAddEmployeeEducationInput
              type="text"
              name="duration"
              placeholder="Duration"
              value={formik.values.duration}
            />
            {formik.errors.duration && (
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.error}
                fontSize={12}
                fontWeight={700}
              >
                {formik.errors.duration}
              </Text>
            )}
          </StyledAddEmployeeEducationFormItem>
        </StyledAddEmployeeEducationFormItemsWrapper>
        <StyledAddEmployeeEducationBtnsWrapper>
          <StyledAddEmployeeEducationBackBtn
            type="button"
            onClick={() => setAddNewEducationDetail(false)}
          >
            Back to Education
          </StyledAddEmployeeEducationBackBtn>
          <StyledAddEmployeeEducationSubmitBtn
            type="submit"
            onClick={() => setValidateOnChange(true)}
          >
            Add
          </StyledAddEmployeeEducationSubmitBtn>
        </StyledAddEmployeeEducationBtnsWrapper>
      </StyledAddEmployeeEducationForm>
    </StyledAddEmployeeEducationMain>
  );
};

export default AddEmployeeEducation;
