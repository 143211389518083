export interface ColorTemplate {
  title_color: string;
  company_title_color: string;
  text_color: string;
  filter_text_color: string;
  filter_color: string;
  button_text_color: string;
  button_color: string;
  background_fill_color: string;
  background_color: string;
}

const templates: { [key: string]: ColorTemplate } = {
  'Color Template 01': {
    title_color: '#010101',
    company_title_color: '#E94E3C',
    text_color: '#010101',
    filter_text_color: '#5E5A57',
    filter_color: '#F3F2F2',
    button_text_color: '#FDFDFD',
    button_color: '#E94E3C',
    background_fill_color: '#FDFDFD',
    background_color: '#F7F8FA',
  },
  'Color Template 02': {
    title_color: '#FFFFFF',
    company_title_color: '#2E77B8',
    text_color: '#FFFFFF',
    filter_text_color: '#FFFFFF',
    filter_color: '#2E77B8',
    button_text_color: '#FDFDFD',
    button_color: '#2E77B8',
    background_fill_color: '#18191A',
    background_color: '#242526',
  },
  'Color Template 03': {
    title_color: '#010101',
    company_title_color: '#000000',
    text_color: '#000000',
    filter_text_color: '#010101',
    filter_color: '#FDFDFD',
    button_text_color: '#FFFFFF',
    button_color: '#56BB8E',
    background_fill_color: '#BEE9D6',
    background_color: '#F7F8FA',
  },
  'Color Template 04': {
    title_color: '#010101',
    company_title_color: '#010101',
    text_color: '#010101',
    filter_text_color: '#010101',
    filter_color: '#FDFDFD',
    button_text_color: '#FDFDFD',
    button_color: '#8F79DF',
    background_fill_color: '#D6CFF0',
    background_color: '#F7F8FA',
  },
  'Color Template 05': {
    title_color: '#010101',
    company_title_color: '#010101',
    text_color: '#010101',
    filter_text_color: '#FDFDFD',
    filter_color: '#2E77B8',
    button_text_color: '#FDFDFD',
    button_color: '#2E77B8',
    background_fill_color: '#C2E4EE',
    background_color: '#F7F8FA',
  },
  'Color Template 06': {
    title_color: '#010101',
    company_title_color: '#010101',
    text_color: '#010101',
    filter_text_color: '#010101',
    filter_color: '#FDFDFD',
    button_text_color: '#FDFDFD',
    button_color: '#F2BD3A',
    background_fill_color: '#FEE3A0',
    background_color: '#F7F8FA',
  },
};

export default templates;
