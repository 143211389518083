import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledGlobalJobBoardSuccessStoriesMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 140px;
  background-color: ${colors.alabaster};
`;

export const StyledGlobalJobBoardSuccessStoriesInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;
`;

export const StyledGlobalJobBoardSuccessStoriesSlideImage = styled.img`
  box-sizing: border-box;
`;

export const StyledGlobalJobBoardSuccessStoriesRightComponentMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  gap: 24px;
`;

export const StyledGlobalJobBoardSuccessStoriesRightComponentTitleAndArrowsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledGlobalJobBoardSuccessStoriesRightComponentTitleWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledGlobalJobBoardSuccessStoriesRightComponentArrowsWrapper = styled.div`
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  cursor: pointer;
`;

export const StyledGlobalJobBoardSuccessStoriesRightComponentTextWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 64px 16px 16px 16px;
  gap: 16px;
  background-color: ${colors.lightGrayishBlue};
  border-radius: 16px;
`;
