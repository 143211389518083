import styled from 'styled-components';
import { font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';

export const WarningMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
  overflow: auto;
  max-height: 500px;
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 8px 8px 0 0;
  gap: 4px;
`;

export const HeaderItem = styled.div`
  display: flex;
  flex: 1;
  font-weight: bold;
  color: white;
  text-align: center;
  background-color: ${colors.primary_500};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-family: ${font.family};
`;

export const FirstHeaderItem = styled.div`
  flex: 1;
  font-weight: bold;
  color: white;
  text-align: center;
  background-color: ${colors.secondary_500};
  padding: 16px;
  border-radius: 4px;
  min-width: 204px;
  font-family: ${font.family};
`;

export const FeatureRow = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  font-family: ${font.family};
  font-size: 14px;
`;

export const FeatureName = styled.div`
  flex: 1;
  text-align: center;
  color: black;
  background-color: #e4eaf5;
  position: relative;
  padding: 16px;
  border-radius: 4px;
  min-width: 204px;
  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${colors.secondary_500};
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FeatureItem = styled.div<{ isAvailable?: boolean }>`
  flex: 1;
  text-align: center;
  color: ${({ isAvailable }) => (isAvailable ? 'green' : 'red')};
  background: #fdfdfd;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PriceRow = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  background-color: #fdfdfd;
  gap: 8px;
`;

export const PriceLabel = styled.div`
  flex: 1;
  min-width: 204px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const PriceItem = styled.div`
  flex: 1;
  text-align: center;
  color: black;
  font-weight: 700;
  font-family: ${font.family};
`;

export const SelectRow = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  gap: 8px;
`;

export const SelectLabel = styled.div`
  flex: 1;
  min-width: 204px;
  padding: 16px;
`;

export const SelectItem = styled.div`
  flex: 1;
  text-align: center;
`;

export const SelectButton = styled.button<{ selected: boolean }>`
  background-color: ${({ selected }) =>
    selected ? `${colors.secondary_500}` : `${colors.secondary_200}`};
  color: white;
  padding: 8px 16px 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
`;
