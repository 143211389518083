import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';

export const StyledSearchResultsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 60px;
  right: 0px;
  box-sizing: border-box;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  z-index: 9999;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid var(--Primary-500, #e94e3c);
`;

export const StyledSearchResultItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
`;

export const StyledNameAndLastnameWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const StyledStatusInfo = styled.div<{
  type: string;
}>`
  box-sizing: border-box;
  height: 100%;
  width: auto;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ type }) =>
    type === 'Applicant'
      ? colors.primary_500
      : type === 'Employee'
      ? colors.secondary_500
      : colors.grey};
  color: ${({ type }) =>
    type === 'Applicant'
      ? colors.primary_500
      : type === 'Employee'
      ? colors.secondary_500
      : colors.grey};
`;

export const StyledNoResultsFoundWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
