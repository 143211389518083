import React from 'react';
import {
  SurveyDetailsMainWrapper,
  StyledHeadingFlex,
  Wrapper,
  CirclesWrapper,
  SurveyActivityCirclesData,
} from './survey-details.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { useParams } from 'react-router-dom';
import {
  FilterWrapper,
  StyledSelectFilter,
} from '../../../../employees/employees-skills-table/components/filter/filter.styled';
import { DownArrow } from '../../../../../icons/down-arrow';
import { colors } from '../../../../../../style-utils/colors';
import {
  StyledInfoCircleWrapper,
  StyledInfoInnerCircle,
} from '../../../../super-admin/home.styled';
import { SurveyResponsesChart } from '../survey-chart';
import { QuestionResults } from '../question-results';
import { EmployeeTableSurvey } from '../employee-table-survey';

export const SurveyDetails: React.FC = () => {
  const { id } = useParams();

  // Example data
  const circlesData = [
    {
      id: 1,
      text: 'Complete',
      number: '75',
      percentage: 75,
      backgroundColor: colors.secondary_300,
    },
    {
      id: 2,
      text: 'Not response yet',
      number: '15',
      percentage: 15,
      backgroundColor: colors.primary_100,
    },
  ];

  // Example answer structure with options and how many employees selected each option
  const results = [
    {
      questionText: '1. Question title',
      options: [
        { text: 'Option name 1', votes: 55 },
        { text: 'Option name 2', votes: 15 },
        { text: 'Option name 3', votes: 30 },
      ],
    },
    {
      questionText: '2. Question title',
      options: [{ text: 'Text response example', votes: 100 }],
    },
    {
      questionText: '3. Question title',
      options: [
        { text: 'Option name 1', votes: 55 },
        { text: 'Option name 2', votes: 15 },
        { text: 'Option name 3', votes: 30 },
      ],
    },
  ];

  return (
    <SurveyDetailsMainWrapper>
      <>
        <Text margin="0" fontSize={22} fontFamily="Lato" fontWeight={700}>
          Survey Name no.{id}
        </Text>
        <Text fontSize={12} fontFamily="Lato" fontWeight={300} mt={8} mb={4}>
          Create date: 07.10.2024
        </Text>
        <Text fontSize={12} fontFamily="Lato" fontWeight={300} mt={0}>
          Complete date: 09.10.2024
        </Text>
      </>
      <SurveyActivityCirclesData>
        <StyledHeadingFlex>
          <Wrapper>
            <Text
              mt={25}
              mb={4}
              fontSize={18}
              fontFamily="Lato"
              fontWeight={700}
            >
              Activity
            </Text>
            <Text fontSize={14} fontFamily="Lato" fontWeight={400} margin="0">
              Survey responses
            </Text>
          </Wrapper>
          <FilterWrapper width="124px">
            <StyledSelectFilter>
              <option value={'filter_by'}>{'Filter by'} </option>
              <option value={'date'}>{'Date'} </option>
            </StyledSelectFilter>
            <DownArrow />
          </FilterWrapper>
        </StyledHeadingFlex>
        <CirclesWrapper>
          {circlesData.map((circle) => (
            <StyledInfoCircleWrapper
              key={circle.id}
              backgroundColor={circle.backgroundColor}
              percentage={circle.percentage}
            >
              <StyledInfoInnerCircle>
                <Text
                  fontFamily={font.family}
                  fontSize={16}
                  fontWeight={500}
                  color={colors.secondary_900}
                  margin="0"
                >
                  {circle.number}
                </Text>
                <Text
                  fontFamily={font.family}
                  fontSize={14}
                  fontWeight={500}
                  color={colors.secondary_900}
                  margin="0"
                  align="center"
                >
                  {circle.text.split(' ')[0]}
                  <br />
                  {circle.text.split(' ')[1]}
                </Text>
              </StyledInfoInnerCircle>
            </StyledInfoCircleWrapper>
          ))}
        </CirclesWrapper>
      </SurveyActivityCirclesData>
      <StyledHeadingFlex>
        <Wrapper>
          <Text mt={25} mb={4} fontSize={18} fontFamily="Lato" fontWeight={700}>
            Activity
          </Text>
          <Text fontSize={14} fontFamily="Lato" fontWeight={400} margin="0">
            Survey responses
          </Text>
        </Wrapper>
        <FilterWrapper width="124px">
          <StyledSelectFilter>
            <option value={'filter_by'}>{'Filter by'} </option>
            <option value={'date'}>{'Date'} </option>
          </StyledSelectFilter>
          <DownArrow />
        </FilterWrapper>
      </StyledHeadingFlex>
      <div style={{ marginTop: '20px', marginBottom: '32px' }}>
        <SurveyResponsesChart />
      </div>
      <>
        <Text
          margin="0"
          fontSize={18}
          fontWeight={700}
          lineHeight="21.6px"
          fontFamily="Lato"
        >
          Questions summary
        </Text>
        {results.map((question, i) => (
          <QuestionResults
            key={i}
            questionText={question.questionText}
            options={question.options}
          />
        ))}
        <Wrapper mt="30px">
          <StyledHeadingFlex>
            <Text
              fontSize={18}
              fontWeight={700}
              lineHeight="21.6px"
              fontFamily="Lato"
            >
              Employees
            </Text>
            <FilterWrapper width="124px">
              <StyledSelectFilter>
                <option value={'filter_by'}>{'Filter by'} </option>
                <option value={'response'}>{'Response'} </option>
              </StyledSelectFilter>
              <DownArrow />
            </FilterWrapper>
          </StyledHeadingFlex>
          <EmployeeTableSurvey />
        </Wrapper>
      </>
    </SurveyDetailsMainWrapper>
  );
};
