import React from 'react';
import {
  EmployeeDetailDocumentTable,
  EmployeeDetailDocumentTableHeader,
  EmployeeDetailDocumentTableHeaders,
} from '../../../employee.styled';
import { colors } from '../../../../../../style-utils/colors';
import { Text, font } from '../../../../../../style-utils/text';
import { useTimeOff } from '../../context/timeoff-context';
import { TableStatusColorCircleIcon } from '../../../../../icons/table-status-color-circle-icon';
import EmployeesTimeoffUpdateStatusSelect from './components/update-status';

const TimeOffEmployeesTable: React.FC = () => {
  const { filteredData } = useTimeOff();

  if (!filteredData || filteredData.length === 0) {
    return (
      <Text
        fontFamily={font.family}
        margin="0"
        color={colors.base_black}
        fontSize={24}
        fontWeight={700}
        lineHeight="28.8px"
      >
        No Applications
      </Text>
    );
  }

  return (
    <EmployeeDetailDocumentTable>
      <EmployeeDetailDocumentTableHeaders>
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_500}
          padding="8px 16px"
          width="220px"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Employee Name
          </Text>
        </EmployeeDetailDocumentTableHeader>
        {[
          'Date of Application',
          'Start Date',
          'End Date',
          'Duration',
          'Status',
          'Action',
        ].map((header) => (
          <EmployeeDetailDocumentTableHeader
            key={header}
            bgColor={colors.primary_500}
            padding="8px 16px"
            fillWidth
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_white}
              fontSize={16}
              fontWeight={700}
              lineHeight="19.2px"
              align="center"
            >
              {header}
            </Text>
          </EmployeeDetailDocumentTableHeader>
        ))}
      </EmployeeDetailDocumentTableHeaders>
      {filteredData.map((application, index) => (
        <EmployeeDetailDocumentTableHeaders key={index}>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            width="220px"
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {application.first_name + ' ' + application.last_name}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            fillWidth
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {application.application_date}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            fillWidth
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {application.start_date}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            fillWidth
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {application.end_date}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            fillWidth
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {application.duration}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            gap="4px"
            fillWidth
          >
            <TableStatusColorCircleIcon status={application.status} />
            <Text
              fontFamily={font.family}
              margin="0"
              color={
                application.status === 'Pending'
                  ? colors.pending_yellow
                  : application.status === 'Confirmed'
                  ? colors.success_green
                  : colors.error
              }
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {application.status}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            fillWidth
          >
            <EmployeesTimeoffUpdateStatusSelect application={application} />
          </EmployeeDetailDocumentTableHeader>
        </EmployeeDetailDocumentTableHeaders>
      ))}
    </EmployeeDetailDocumentTable>
  );
};

export default TimeOffEmployeesTable;
