import styled from 'styled-components';

export const GenderBalanceStyledWrapper = styled.div`
  box-sizing: border-box;
  max-width: 303px;
  background-color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;

export const GenderBalanceHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GenderBalanceContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 20px auto 0;
`;

export const CircleContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
`;

export const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InnerCircle = styled.div`
  width: 70%;
  height: 70%;
  background: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 18px;
  font-weight: bold;
`;

export const GenderBalanceIconWrapper = styled.div`
  position: absolute;
  top: 28%;
  left: 28%;
`;

export const CircleLabelsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledChartLegendColoredCircle = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#2E77B8'};
`;

export const LegendGenderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
