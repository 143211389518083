import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import axios from 'axios';
import { apiRoute } from '../constants/api-constants';
import Cookies from 'universal-cookie';
import { NavigateFunction } from 'react-router-dom';
import { logOut } from '../function-utils/log-out';

interface AuthContextType {
  // eslint-disable-next-line
  setUser: (user: any | null | undefined) => void;
  // eslint-disable-next-line
  user: any | null | undefined;
  cookies: Cookies;
  loading: boolean;
  refetchUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

interface AuthProviderProps {
  children: ReactNode;
  navigate?: NavigateFunction;
  isTesting?: boolean;
}

export const AuthProvider = ({
  children,
  navigate,
  //
  // Always set to false on production
  //
  isTesting = false,
}: AuthProviderProps) => {
  // eslint-disable-next-line
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();
  const token = cookies.get('authorization_token');

  // Adjust refresh time based on isTesting
  const refreshTime = isTesting ? 5 * 1000 : 55 * 60 * 1000; // 5 seconds for testing, 55 minutes for production

  const refetchUser = async () => {
    try {
      console.log(
        isTesting ? '[TESTING] Fetching user data...' : 'Fetching user data...'
      );
      const response = await axios.get(`${apiRoute}/auth`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.user) {
        setUser(response.data.user);
        console.log(
          isTesting ? '[TESTING] User data fetched:' : 'User data fetched:',
          response.data.user
        );
      } else {
        console.warn(
          isTesting
            ? '[TESTING] No user data returned, logging out.'
            : 'No user data returned, logging out.'
        );
        await logOut(setUser, navigate);
      }
    } catch (error) {
      console.error(
        isTesting
          ? '[TESTING] Error fetching user data:'
          : 'Error fetching user data:',
        error
      );
      await logOut(setUser, navigate);
    }
  };

  const checkAuth = async () => {
    console.log(
      isTesting
        ? '[TESTING] Running authentication check...'
        : 'Running authentication check...'
    );
    try {
      if (token) {
        console.log(
          isTesting
            ? '[TESTING] Token exists, refetching user...'
            : 'Token exists, refetching user...'
        );
        await refetchUser();
      } else {
        console.warn(
          isTesting
            ? '[TESTING] No token found, logging out.'
            : 'No token found, logging out.'
        );
        await logOut(setUser, navigate);
      }
    } catch (error) {
      console.error(
        isTesting
          ? '[TESTING] Authentication check failed, logging out:'
          : 'Authentication check failed, logging out:',
        error
      );
      await logOut(setUser, navigate);
    } finally {
      setLoading(false);
      console.log(
        isTesting ? '[TESTING] Loading complete:' : 'Loading complete:',
        loading
      );
    }
  };

  const refreshToken = async () => {
    try {
      console.log(
        isTesting
          ? '[TESTING] Attempting to refresh token...'
          : 'Attempting to refresh token...'
      );
      const response = await axios.post(`${apiRoute}/refresh-token`);
      const newToken = response.data.token;
      cookies.set('authorization_token', newToken);
      setUser(response.data.user); // Update user on successful token refresh
      console.log(
        isTesting
          ? '[TESTING] Token refreshed successfully. New user data:'
          : 'Token refreshed successfully. New user data:',
        response.data.user
      );
    } catch (error) {
      console.error(
        isTesting
          ? '[TESTING] Token refresh failed, logging out user:'
          : 'Token refresh failed, logging out user:',
        error
      );
      await logOut(setUser, navigate); // Log out directly if refresh fails
    }
  };

  useEffect(() => {
    if (token) {
      checkAuth();
      const refreshTimer = setInterval(refreshToken, refreshTime); // Adjust interval based on isTesting

      return () => clearInterval(refreshTimer);
    } else {
      setLoading(false);
      console.log(
        isTesting
          ? '[TESTING] No token found, setting loading to false.'
          : 'No token found, setting loading to false.'
      );
    }
  }, [token]);

  const authContextValue: AuthContextType = {
    setUser,
    user,
    cookies,
    loading,
    refetchUser,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
