import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';

// Registering the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

// Example data
const activityResponses = [
  {
    date: '07.12',
    responsesCount: 2,
  },
  {
    date: '08.12',
    responsesCount: 2,
  },
  {
    date: '09.12',
    responsesCount: 3,
  },
  {
    date: '10.12',
    responsesCount: 3,
  },
  {
    date: '11.12',
    responsesCount: 4,
  },
  {
    date: '12.12',
    responsesCount: 8,
  },
  {
    date: '07.12',
    responsesCount: 6,
  },
  {
    date: '08.12',
    responsesCount: 5,
  },
  {
    date: '09.12',
    responsesCount: 5,
  },
  {
    date: '10.12',
    responsesCount: 4,
  },
  {
    date: '11.12',
    responsesCount: 3,
  },
  {
    date: '12.12',
    responsesCount: 1,
  },
];

export const SurveyResponsesChart: React.FC = () => {
  const labels = activityResponses.map((response) => response.date);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: activityResponses.map((response) => response.responsesCount),
        backgroundColor: '#779CCC',
        barThickness: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<'bar'>) => `${context.raw} responses`,
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove grid lines on Y-axis
        },
        ticks: {
          display: false, // Hide Y-axis numbers
        },
        border: {
          display: false, // Remove Y-axis line
        },
      },
      x: {
        grid: {
          display: false, // Remove grid lines on X-axis
        },
        ticks: {
          display: true, // Show X-axis labels (dates)
        },
        border: {
          display: false, // Remove X-axis line
        },
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: 5,
    },
    elements: {
      bar: {
        borderRadius: 5,
      },
    },
  };

  return <Bar data={data} options={options} />;
};
