import React, { useState } from 'react';
import {
  ActiveSurvey,
  ActiveSurveysMainWrapper,
  SurveyName,
  SurveysWrapper,
  ResponsesPart,
  Wrapper,
  DateParagraph,
  ContentWrapper,
  ViewBtn,
} from './surveys.styled';
import { NavigateFunction } from 'react-router-dom';

interface SurveyComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
}

export const Surveys: React.FC<SurveyComponentProps> = ({ user, navigate }) => {
  const { name } = user;

  // This is just example data until we create back-end logic
  const exampleSurveyData = [
    {
      id: 1,
      title: 'Survey Name',
      responses: 25,
      createdDate: '05.12.2024',
      completeDate: '07.12.2024',
    },
    {
      id: 2,
      title: 'Survey Name',
      responses: 25,
      createdDate: '05.12.2024',
      completeDate: '07.12.2024',
    },
    {
      id: 3,
      title: 'Survey Name',
      responses: 25,
      createdDate: '05.12.2024',
      completeDate: '07.12.2024',
    },
  ];
  const [activeSurveys] = useState(exampleSurveyData);
  const [completedSurveys] = useState(exampleSurveyData);

  return (
    <>
      {/* Active surveys container */}
      <ActiveSurveysMainWrapper>
        <h2>Active Surveys</h2>
        <SurveysWrapper>
          {activeSurveys.map((survey) => (
            <ActiveSurvey key={survey.id}>
              <ContentWrapper>
                <Wrapper>
                  <SurveyName>{survey.title}</SurveyName>
                  <ResponsesPart>{survey.responses} responses</ResponsesPart>
                </Wrapper>
                <Wrapper>
                  <DateParagraph>
                    Date Created: {survey.createdDate}
                  </DateParagraph>
                  <DateParagraph>
                    Date Completed: {survey.completeDate}
                  </DateParagraph>
                </Wrapper>
              </ContentWrapper>
              <ViewBtn
                backgroundColor="#2E77B8"
                onClick={() =>
                  navigate && navigate(`/${name}/survey/${survey.id}`)
                }
              >
                View
              </ViewBtn>
            </ActiveSurvey>
          ))}
        </SurveysWrapper>
      </ActiveSurveysMainWrapper>
      {/* Completed surveys container */}
      <ActiveSurveysMainWrapper>
        <h2>Completed Surveys</h2>
        <SurveysWrapper>
          {completedSurveys.map((survey) => (
            <ActiveSurvey key={survey.id} backgroundColor={'#FFE7E1'}>
              <ContentWrapper>
                <Wrapper>
                  <SurveyName>{survey.title}</SurveyName>
                  <ResponsesPart>{survey.responses} responses</ResponsesPart>
                </Wrapper>
                <Wrapper>
                  <DateParagraph>
                    Date Created: {survey.createdDate}
                  </DateParagraph>
                  <DateParagraph>
                    Date Completed: {survey.completeDate}
                  </DateParagraph>
                </Wrapper>
              </ContentWrapper>
              <ViewBtn backgroundColor="#E94E3C">View</ViewBtn>
            </ActiveSurvey>
          ))}
        </SurveysWrapper>
      </ActiveSurveysMainWrapper>
    </>
  );
};
