import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../lp-images/logo_team.png';
import homeIcon from '../../lp-images/home.png';
import {
  GeneralStyle,
  HeaderContainer,
  Header,
  Logo,
  HomeButton,
  ContentContainer,
  MainHeading,
  SmallHeading,
  Paragraph,
  SmallestH,
  List,
  Item,
} from './terms-of-use.styled';

export const TermsOfUse: React.FC = () => {
  return (
    <GeneralStyle>
      <HeaderContainer>
        <Header>
          <Logo src={logo} alt="logo icon" />
          <Link to="/">
            <HomeButton src={homeIcon} alt="home icon" />
          </Link>
        </Header>
      </HeaderContainer>
      <ContentContainer>
        <MainHeading>Terms of Use</MainHeading>
        <Paragraph>
          These Terms of Use govern your access to and use of the WorWise
          website, mobile application, and related services (collectively,
          Services). The Services are owned and operated by Moxelle (we, us,
          our), and WorWise is a brand under the Moxelle company. By accessing
          or using the Services, you agree to be bound by these Terms. If you do
          not agree to these Terms, please refrain from using our Services.
        </Paragraph>
        <SmallHeading>1. Use of the Services</SmallHeading>
        <SmallestH>1.1. Eligibility</SmallestH>
        <Paragraph>
          You must be at least 18 years old to use our Services. By accessing or
          using the Services, you represent and warrant that you meet this age
          requirement and have the legal capacity to enter into this agreement.
        </Paragraph>
        <SmallestH>1.2. Account Registration</SmallestH>
        <Paragraph>
          To access certain features of the Services, you may be required to
          register for an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information as necessary to keep it accurate, current, and
          complete. You are responsible for maintaining the confidentiality of
          your account credentials and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorised use of
          your account or any other breach of security.
        </Paragraph>
        <SmallestH>1.3. User Responsibilities</SmallestH>
        <Paragraph>
          You agree to use the Services only for lawful purposes and in
          compliance with these Terms and all applicable laws and regulations.
          You are responsible for all content that you upload, post, or
          otherwise transmit through the Services. You agree not to use the
          Services in any manner that could damage, disable, overburden, or
          impair the Services or interfere with any other partys use of the
          Services.
        </Paragraph>
        <SmallHeading>2. Prohibited Conduct</SmallHeading>
        <Paragraph>
          You agree not to engage in any of the following prohibited activities:
        </Paragraph>
        <List>
          <Item>
            Violating Laws: Engaging in any activity that violates any
            applicable local, state, national, or international law or
            regulation.
          </Item>
          <Item>
            Unauthorised Access: Attempting to gain unauthorised access to our
            systems, servers, or networks, or any part thereof, including any
            accounts, computer systems, or networks connected to any of our
            servers, by hacking, password mining, or any other illegitimate
            means.
          </Item>
          <Item>
            Disruption: Interfering with or disrupting the integrity or
            performance of the Services or the data contained therein, including
            transmitting any worms, viruses, spyware, malware, or any other code
            of a destructive or disruptive nature.
          </Item>
          <Item>
            Misrepresentation: Impersonating any person or entity, or falsely
            stating or otherwise misrepresenting your affiliation with a person
            or entity, including creating false accounts or using the Services
            in a deceptive manner.
          </Item>
          <Item>
            Harmful Behaviour: Engaging in any activity that is harmful,
            fraudulent, deceptive, threatening, harassing, defamatory, obscene,
            or otherwise objectionable.
          </Item>
          <Item>
            Data Mining: Using any automated system, such as bots, spiders, or
            scrapers, to extract, harvest, or otherwise collect information or
            data from the Services without our express written consent.
          </Item>
        </List>
        <SmallHeading>3. Intellectual Property</SmallHeading>
        <SmallestH>3.1. Ownership</SmallestH>
        <Paragraph>
          All content, features, and functionality available through the
          Services, including but not limited to text, graphics, logos, icons,
          images, audio clips, video clips, digital downloads, data
          compilations, and software, are the exclusive property of Moxelle or
          its licensors and are protected by international copyright, trademark,
          patent, trade secret, and other intellectual property or proprietary
          rights laws.
        </Paragraph>
        <SmallestH>3.2. Licence Grant</SmallestH>
        <Paragraph>
          Subject to your compliance with these Terms, Moxelle grants you a
          limited, non-exclusive, non-transferable, and revocable license to
          access and use the Services for your personal, non-commercial use.
          This license does not include any resale or commercial use of the
          Services or their contents; any collection and use of any product
          listings, descriptions, or prices; any derivative use of the Services
          or their contents; or any use of data mining, robots, or similar data
          gathering and extraction tools.
        </Paragraph>
        <SmallestH>3.3. Licence Restrictions</SmallestH>
        <Paragraph>You agree that you will not:</Paragraph>
        <List>
          <Item>
            Copy or Reproduce: Copy, reproduce, distribute, publish, or create
            derivative works based on the Services or any part thereof, except
            as expressly permitted by these Terms.
          </Item>
          <Item>
            Modify or Alter: Modify, adapt, translate, reverse engineer,
            decompile, disassemble, or create derivative works based on any part
            of the Services.
          </Item>
          <Item>
            Redistribute: Redistribute, sublicense, rent, publish, sell, assign,
            lease, market, transfer, or otherwise make the Services available to
            third parties.
          </Item>
          <Item>
            Unauthorised Access: Access or use the Services in any way intended
            to avoid incurring fees or exceeding usage limits or quotas.
          </Item>
          <Item>
            Remove Notices: Remove, obscure, or alter any proprietary rights
            notice that may be affixed to or contained within the Services.
          </Item>
          <Item>
            Public Display or Performance: Publicly display, perform, or
            broadcast the Services, or any part thereof, without our prior
            written consent.
          </Item>
          <Item>
            Data Extraction: Use any automated means, including bots, spiders,
            or scrapers, to access the Services for any purpose without our
            express written permission.
          </Item>
          <Item>
            Interfere with Security: Attempt to interfere with or compromise the
            integrity or security of the Services, including, without
            limitation, by probing, scanning, or testing the vulnerability of
            any system or network.
          </Item>
        </List>
        <SmallestH>3.4. Trademarks</SmallestH>
        <Paragraph>
          The WorWise name, logo, and all related names, logos, product and
          service names, designs, and slogans are trademarks of Moxelle or its
          affiliates or licensors. You must not use such marks without the prior
          written permission of Moxelle. All other names, logos, product and
          service names, designs, and slogans on the Services are the trademarks
          of their respective owners.
        </Paragraph>
        <SmallHeading>4. User Content</SmallHeading>
        <SmallestH>4.1. Responsibility for Content</SmallestH>
        <Paragraph>
          You are solely responsible for any content you upload, post, or
          otherwise transmit through the Services (User Content). This includes,
          but is not limited to, job listings, resumes, messages, or any other
          information you provide. You agree not to upload, post, or otherwise
          transmit any User Content that:
        </Paragraph>
        <List>
          <Item>
            Is unlawful, defamatory, obscene, pornographic, indecent, lewd,
            harassing, threatening, invasive of privacy, abusive, inflammatory,
            or otherwise objectionable.
          </Item>
          <Item>
            Infringes on the intellectual property rights or other proprietary
            rights of any party.
          </Item>
          <Item>
            Contains viruses, corrupted files, or any other similar software or
            programs that may damage or interfere with the operation of the
            Services.
          </Item>
          <Item>Violates any applicable law or regulation.</Item>
        </List>
        <SmallestH>4.2. Licence to User Content</SmallestH>
        <Paragraph>
          By posting or submitting User Content to the Services, you grant
          Moxelle a non-exclusive, worldwide, royalty-free, perpetual,
          irrevocable, and fully sublicensable right to use, reproduce, modify,
          adapt, publish, translate, create derivative works from, distribute,
          perform, and display such User Content in any media format. You
          represent and warrant that you own or otherwise control all rights to
          the User Content and that the public posting and use of your content
          by Moxelle will not infringe or violate the rights of any third party.
        </Paragraph>
        <SmallHeading>5. Termination</SmallHeading>
        <SmallestH>5.1. Termination by Moxelle</SmallestH>
        <Paragraph>
          Moxelle reserves the right to terminate or suspend your account and
          access to the Services, without notice and for any reason, including
          but not limited to, if we believe you have violated these Terms,
          engaged in fraudulent activity, or posed a security risk.
        </Paragraph>
        <SmallestH>5.2. Termination by You</SmallestH>
        <Paragraph>
          You may terminate your account at any time by following the
          instructions on the Services or by contacting us at
          worwise@moxelle.com. Upon termination, your right to use the Services
          will immediately cease, and Moxelle may delete your User Content and
          any other information associated with your account.
        </Paragraph>
        <SmallestH>5.3. Effect of Termination</SmallestH>
        <Paragraph>
          Upon termination of your account, whether by you or Moxelle, your
          right to access and use the Services will cease immediately. Moxelle
          will have no obligation to retain or provide you with any of your User
          Content or any other information, except as required by applicable
          law.
        </Paragraph>
        <SmallHeading>6. Disclaimer of Warranties</SmallHeading>
        <SmallestH>6.1. As Is and As Available</SmallestH>
        <Paragraph>
          The Services are provided on an as is and as available basis, without
          any warranties of any kind, either express or implied. Moxelle
          disclaims all warranties, including but not limited to, implied
          warranties of merchantability, fitness for a particular purpose,
          title, and non-infringement.
        </Paragraph>
        <SmallestH>6.2. No Guarantee of Results</SmallestH>
        <Paragraph>
          Moxelle does not warrant that the Services will meet your
          requirements, be uninterrupted, timely, secure, or error-free, or that
          the results obtained from the use of the Services will be accurate or
          reliable.
        </Paragraph>
        <SmallHeading>7. Limitation of Liability</SmallHeading>
        <SmallestH>7.1. Exclusion of Certain Damages</SmallestH>
        <Paragraph>
          To the fullest extent permitted by applicable law, Moxelle shall not
          be liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from:
        </Paragraph>
        <List>
          <Item>Your use of or inability to use the Services.</Item>
          <Item>
            Any unauthorised access to or use of our servers and/or any personal
            information stored therein.
          </Item>
          <Item>
            Any interruption or cessation of transmission to or from the
            Services.
          </Item>
          <Item>
            Any bugs, viruses, Trojan horses, or the like that may be
            transmitted to or through our Services by any third party.
          </Item>
          <Item>
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the Services.
          </Item>
        </List>
        <SmallestH>7.2. Maximum Liability</SmallestH>
        <Paragraph>
          In no event shall Moxelles aggregate liability for all claims relating
          to the Services exceed the amount you paid to Moxelle, if any, in the
          12 months preceding the event giving rise to liability.
        </Paragraph>
        <SmallHeading>8. Indemnification</SmallHeading>
        <Paragraph>
          You agree to protect, compensate, and absolve Moxelle, its affiliates,
          licensors, and service providers, including their respective officers,
          directors, employees, and agents, from and against any and all claims,
          liabilities, damages, judgments, awards, losses, costs, expenses, or
          fees (including reasonable attorneys fees) arising out of or relating
          to your violation of these Terms, your misuse of the Services, or your
          violation of any law or the rights of a third party.
        </Paragraph>
        <SmallHeading>9. Governing Law</SmallHeading>
        <Paragraph>
          These Terms and any disputes arising out of or related to them or the
          Services will be governed by and construed in accordance with the laws
          of North Macedonia, without regard to its conflict of law principles.
          You agree to submit to the personal and exclusive jurisdiction of the
          courts located within North Macedonia to resolve any legal matter
          arising from these Terms or the Services.
        </Paragraph>
        <SmallHeading>10. Changes to These Terms</SmallHeading>
        <Paragraph>
          Moxelle reserves the right to modify these Terms at any time in its
          sole discretion. If we make material changes to these Terms, we will
          provide notice through the Services or by other means to give you the
          opportunity to review the changes before they become effective. Your
          continued use of the Services following the posting of revised Terms
          means that you accept and agree to the changes.
        </Paragraph>
        <SmallHeading>11. Termination</SmallHeading>
        <Paragraph>
          Moxelle may suspend or terminate your access to the Services, without
          prior notice or liability, for any reason, including if you breach
          these Terms. Upon termination, your right to use the Services will
          immediately cease. The provisions of these Terms that by their nature
          should survive termination shall survive, including but not limited
          to, ownership provisions, warranty disclaimers, indemnity, and
          limitations of liability.
        </Paragraph>
        <SmallHeading>12. Entire Agreement</SmallHeading>
        <Paragraph>
          These Terms constitute the entire agreement between you and Moxelle
          regarding your use of the Services and supersede all prior and
          contemporaneous understandings, agreements, representations, and
          warranties, both written and oral, regarding such subject matter.
        </Paragraph>
        <SmallHeading>13. Contact Information</SmallHeading>
        <Paragraph>
          If you have any questions about these Terms, please contact us at
          worwise@moxelle.com
        </Paragraph>
      </ContentContainer>
    </GeneralStyle>
  );
};
