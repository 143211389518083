import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const StyledEmployeeEducationTabDetailsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px 64px 32px 64px;
`;

export const StyledImageAndTitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const StyledTitleImage = styled.div`
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${colors.primary_500};
`;

export const StyledEducationDetailWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const StyledEducationDetailLeftItemWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 40px;
  gap: 4px;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
`;

export const StyledVerticalLine = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 1px;
  background-color: ${colors.primary_500};
`;

export const StyledEducationDetailCircle = styled.div`
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  background-color: ${colors.primary_500};
  border-radius: 50%;
`;

export const StyledEducationRightSideItemWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

export const StyledEducationRightSideItemEducationRangeWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledEducationRightSideItemEducationDetailsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 36px;
`;
