import styled from 'styled-components';

export const FooterContainer = styled.div`
  width: 100%;
  background-color: white;
  margin-top: 80px;
`;

export const FooterElements = styled.div`
  height: 64px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5e5a57;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
