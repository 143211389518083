import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid ${colors.primary_500};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

export const StyledCandidateCommentsMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledCandidateCommentsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.neutral_50};
  width: 540px;
  height: 100%;
  margin-left: auto;
  padding: 32px 32px 32px 32px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  gap: 24px;

  /* Media query to handle specific tablet/smaller screen sizes */
  @media (max-height: 1200px) {
    height: calc(100vh - 10px); /* Adjust height slightly for shorter screens */
  }
`;

export const StyledCloseButtonWrapper = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  cursor: pointer;
`;

export const StyledCandidateAddNewCommentWrapper = styled.div`
  box-sizing: border-box;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledCandidateCommentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base_white};
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  width: 100%;
`;

export const StyledCandidateCommentHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const StyledTextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledCandidateCommentTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid ${colors.neutral_200};
  height: '65px';
  resize: none;
  padding: 12px;
`;

export const StyledCandidateCommentText = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid ${colors.neutral_200};
  height: auto;
  padding: 12px;
`;

export const StyledSubmitNewCommentBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 12px 12px;
  background-color: ${colors.secondary_400};
  border-radius: 8px;
  border-style: none;
  width: fit-content;
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: ${colors.base_white};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledCandidateListOfCommentsWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  overflow: scroll;

  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
