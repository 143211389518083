import React from 'react';
import {
  StyledSettingsTabSelectorMainWrapper,
  StyledTabSelectButton,
} from './tab-selector.styled';
import { Text, font } from '../../../../../../../style-utils/text';

interface SettingsTabSelectorProps {
  onTabChange: (tabId: number) => void;
  activeTabId: number;
}

const SettingsTabSelector: React.FC<SettingsTabSelectorProps> = ({
  onTabChange,
  activeTabId,
}) => {
  return (
    <StyledSettingsTabSelectorMainWrapper>
      <StyledTabSelectButton
        isActive={activeTabId === 0}
        onClick={() => onTabChange(0)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          About company
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 1}
        onClick={() => onTabChange(1)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Subscription Plan
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 2}
        onClick={() => onTabChange(2)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Color template
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 3}
        onClick={() => onTabChange(3)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          User settings
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 4}
        onClick={() => onTabChange(4)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Job position
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 5}
        onClick={() => onTabChange(5)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Employee Onboarding
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 6}
        onClick={() => onTabChange(6)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Notifications
        </Text>
      </StyledTabSelectButton>
    </StyledSettingsTabSelectorMainWrapper>
  );
};

export default SettingsTabSelector;
