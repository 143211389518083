import { useState } from 'react';
import axios from 'axios';
import {
  Education,
  EmployeeCVData,
  WorkExperience,
} from './employee-company-cv-data-types';
// eslint-disable-next-line
// import { employeeDummyCVData } from './dummy-data';
import { apiRoute } from '../../../../constants/api-constants';

export const useEmployeeCVData = () => {
  const initialEmployeeCVData: EmployeeCVData = {
    candidate_id: '',
    name: '',
    jobTitle: '',
    company: '',
    professionalSummary: '',
    workExperience: [],
    education: [],
    skills: [],
    tools: [],
    profilePicture: '',
  };

  const [employeeCVData, setEmployeeCVData] = useState<EmployeeCVData>(
    initialEmployeeCVData
  );
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCVData = (cvUrl: string, userId: string, save: boolean) => {
    setLoading(true);
    setError(null);

    return axios
      .post(`${apiRoute}/generate-company-cv-data?save=${save}`, {
        cvUrl,
        userId,
      })
      .then((response) => {
        if (response.data.success) {
          setEmployeeCVData({
            ...initialEmployeeCVData,
            ...response.data.data,
          });
          console.log('Generated CV Data', response.data.data);
        } else {
          setError('Failed to generate CV data.');
        }
      })
      .catch(() => {
        setError('An error occurred while generating CV data.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveCVData = async (): Promise<boolean> => {
    setLoading(true);
    setError(null);

    try {
      const formData = new FormData();

      if (newProfilePicture) {
        formData.append('profile_picture', newProfilePicture);
      }

      formData.append('candidate_id', employeeCVData.candidate_id);
      formData.append('name', employeeCVData.name || '');
      formData.append('jobTitle', employeeCVData.jobTitle || '');
      formData.append('company', employeeCVData.company || '');
      formData.append(
        'professionalSummary',
        employeeCVData.professionalSummary || ''
      );
      formData.append(
        'workExperience',
        JSON.stringify(employeeCVData.workExperience || [])
      );
      formData.append(
        'education',
        JSON.stringify(employeeCVData.education || [])
      );
      formData.append('skills', JSON.stringify(employeeCVData.skills || []));
      formData.append('tools', JSON.stringify(employeeCVData.tools || []));

      const response = await axios.post(
        `${apiRoute}/add-employee-cv`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (response.status === 201) {
        console.log(
          'Employee CV data added successfully:',
          response.data.employeeCV
        );

        return true;
      } else {
        setError('Failed to save CV data.');

        return false;
      }
    } catch (err) {
      setError('An error occurred while saving CV data.');
      console.error('Save CV Data Error:', err);

      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchCVDataById = async (id: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${apiRoute}/company-cv/${id}`);

      if (response.status === 200) {
        setEmployeeCVData(response.data.data);
        console.log('Fetched CV Data', response.data.data);
      } else {
        setError('Failed to fetch CV data.');
      }
    } catch (err) {
      setError('An error occurred while fetching CV data.');
      console.error('Fetch CV Data Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const updateField = <K extends keyof EmployeeCVData>(
    field: K,
    value: EmployeeCVData[K]
  ) => {
    setEmployeeCVData((prevData) => {
      if (!prevData) return prevData;

      return {
        ...prevData,
        [field]: value,
      };
    });
  };

  const updateWorkExperience = (
    index: number,
    updatedExperience: WorkExperience
  ) => {
    setEmployeeCVData((prevData) => {
      if (!prevData || !prevData.workExperience) return prevData;
      const newWorkExperience = [...prevData.workExperience];
      newWorkExperience[index] = updatedExperience;

      return { ...prevData, workExperience: newWorkExperience };
    });
  };

  const addNewWorkExperience = () => {
    setEmployeeCVData((prevData) => ({
      ...prevData,
      workExperience: [
        ...prevData.workExperience,
        { company: '', role: '', dateRange: '', responsibilities: [] },
      ],
    }));
  };

  const addWorkExperienceResponsibility = (
    index: number,
    newResponsibility: string
  ) => {
    const updatedWorkExperience = [...employeeCVData.workExperience];
    const experienceToUpdate = updatedWorkExperience[index];

    experienceToUpdate.responsibilities = [
      ...experienceToUpdate.responsibilities,
      newResponsibility,
    ];

    setEmployeeCVData((prevData) => ({
      ...prevData,
      workExperience: updatedWorkExperience,
    }));
  };

  const editWorkExperienceResponsibility = (
    experienceIndex: number,
    responsibilityIndex: number,
    updatedResponsibility: string
  ) => {
    const updatedWorkExperience = [...employeeCVData.workExperience];
    updatedWorkExperience[experienceIndex].responsibilities[
      responsibilityIndex
    ] = updatedResponsibility;

    setEmployeeCVData((prevData) => ({
      ...prevData,
      workExperience: updatedWorkExperience,
    }));
  };

  const deleteResponsibility = (
    experienceIndex: number,
    responsibilityIndex: number
  ) => {
    const updatedExperiences = [...employeeCVData.workExperience];
    updatedExperiences[experienceIndex].responsibilities.splice(
      responsibilityIndex,
      1
    );
    setEmployeeCVData((prevData) => ({
      ...prevData,
      workExperience: updatedExperiences,
    }));
  };

  const deleteWorkExperience = (index: number) => {
    const updatedExperiences = [...employeeCVData.workExperience];
    updatedExperiences.splice(index, 1);
    setEmployeeCVData((prevData) => ({
      ...prevData,
      workExperience: updatedExperiences,
    }));
  };

  const addSkill = (newSkill: string) => {
    setEmployeeCVData((prevData) => ({
      ...prevData,
      skills: [...prevData.skills, newSkill],
    }));
  };

  const deleteSkill = (index: number) => {
    const updatedSkills = [...employeeCVData.skills];
    updatedSkills.splice(index, 1);
    setEmployeeCVData((prevData) => ({
      ...prevData,
      skills: updatedSkills,
    }));
  };

  const addTool = (newTool: string) => {
    setEmployeeCVData((prevData) => ({
      ...prevData,
      tools: [...prevData.tools, newTool],
    }));
  };

  const deleteTool = (index: number) => {
    const updatedTools = [...employeeCVData.tools];
    updatedTools.splice(index, 1);
    setEmployeeCVData((prevData) => ({
      ...prevData,
      tools: updatedTools,
    }));
  };

  const addEducation = () => {
    const newEducation = {
      institution: '',
      degree: '',
      dateRange: '',
      details: [],
    };
    setEmployeeCVData((prevData) => ({
      ...prevData,
      education: [...prevData.education, newEducation],
    }));
  };

  const updateEducation = (index: number, updatedEducation: Education) => {
    setEmployeeCVData((prevData) => {
      const newEducationList = [...prevData.education];
      newEducationList[index] = updatedEducation;

      return { ...prevData, education: newEducationList };
    });
  };

  const deleteEducation = (index: number) => {
    setEmployeeCVData((prevData) => {
      const newEducationList = prevData.education.filter(
        (_, eduIndex) => eduIndex !== index
      );

      return { ...prevData, education: newEducationList };
    });
  };

  const addEducationDetail = (index: number, newDetail: string) => {
    setEmployeeCVData((prevData) => {
      const updatedEducation = { ...prevData.education[index] };
      updatedEducation.details = [...updatedEducation.details, newDetail];
      const newEducationList = [...prevData.education];
      newEducationList[index] = updatedEducation;

      return { ...prevData, education: newEducationList };
    });
  };

  const updateEducationDetail = (
    educationIndex: number,
    detailIndex: number,
    updatedDetail: string
  ) => {
    setEmployeeCVData((prevData) => {
      const updatedEducation = { ...prevData.education[educationIndex] };
      updatedEducation.details[detailIndex] = updatedDetail;
      const newEducationList = [...prevData.education];
      newEducationList[educationIndex] = updatedEducation;

      return { ...prevData, education: newEducationList };
    });
  };

  const deleteEducationDetail = (
    educationIndex: number,
    detailIndex: number
  ) => {
    setEmployeeCVData((prevData) => {
      const updatedEducation = { ...prevData.education[educationIndex] };
      updatedEducation.details = updatedEducation.details.filter(
        (_, index) => index !== detailIndex
      );
      const newEducationList = [...prevData.education];
      newEducationList[educationIndex] = updatedEducation;

      return { ...prevData, education: newEducationList };
    });
  };

  const deleteProfilePicture = () => {
    setNewProfilePicture(null);
    setEmployeeCVData((prevData) => ({
      ...prevData,
      profilePicture: '',
    }));
  };

  return {
    employeeCVData,
    loading,
    error,
    fetchCVData,
    updateField,
    updateWorkExperience,
    addNewWorkExperience,
    addWorkExperienceResponsibility,
    editWorkExperienceResponsibility,
    deleteWorkExperience,
    deleteResponsibility,
    addSkill,
    deleteSkill,
    addTool,
    deleteTool,
    addEducation,
    updateEducation,
    deleteEducation,
    addEducationDetail,
    updateEducationDetail,
    deleteEducationDetail,
    newProfilePicture,
    setNewProfilePicture,
    deleteProfilePicture,
    saveCVData,
    fetchCVDataById,
  };
};
