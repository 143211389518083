import styled from 'styled-components';

export const StyledSkillMapTableMainWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

export const SearchFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const ContentWrapperSkills = styled.div<{
  dashboard?: boolean;
  bgColor?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: ${({ dashboard }) => (dashboard ? '0' : '40px')};
  background: ${({ bgColor }) => bgColor || 'none'};

  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const SkillMapTableWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: auto;

  /* WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;
`;

export const StyledSkillsTable = styled.table`
  width: 100%;
  table-layout: fixed; /* Ensure fixed layout */
  border-collapse: separate; /* Use separate to allow spacing between cells */
  border-spacing: 4px;

  @media (max-width: 600px) {
    border: 0;
  }
`;

export const StyledHeaders = styled.thead`
  width: 100%;
`;

export const StyledFirstTh = styled.th<{
  backgroundColor?: string;
}>`
  padding: 16px 24px;
  width: 196px;
  text-align: center;
  color: white;
  border-radius: 4px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'white'};
`;

export const StyledTh = styled.th<{
  columnWidth?: string;
  backgroundColor?: string;
}>`
  padding: 16px 24px;
  width: ${({ columnWidth }) => columnWidth || '180px'};
  border-radius: 4px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'white'};
`;

// Styles for table rows in the tbody
export const StyledBodyRow = styled.tr`
  height: auto;
`;

// Styles for individual data cells (for employee skill levels)
export const StyledTd = styled.td<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  text-align: center;
  font-size: 14px;
  font-family: Lato;
  border-radius: 4px;
  padding: 14px 16px;
  text-align: center;
  vertical-align: middle;
`;

// Styles for the feature names, rotated 90 degrees
export const StyledFeatureTd = styled.td<{
  backgroundColor?: string;
  transform?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#000'};
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;

  /* box-sizing: border-box; */
  /* display: inline-block; */
  /* Helps with Safari 3D rendering quirks */
`;

export const SkillText = styled.p<{
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
  lineHeight?: string;
  color?: string;
  backgroundColour?: string;
  margin?: string;
}>`
  margin: ${({ margin }) => margin && `${margin}`};
  font-family: ${({ fontFamily }) => fontFamily || 'Inter'};
  font-weight: ${({ fontWeight }) => fontWeight || '300'};
  font-size: ${({ fontSize }) => `${fontSize}px` || '20px'};
  color: ${({ color }) => color || 'inherit'};
  background-color: ${({ backgroundColour }) =>
    backgroundColour || 'transparent'};
  line-height: ${({ lineHeight }) => lineHeight && `${lineHeight}px;`};
`;

export const FeatureRotatedText = styled.p<{
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
}>`
  font-family: ${({ fontFamily }) => fontFamily || 'Inter'};
  font-weight: ${({ fontWeight }) => fontWeight || '300'};
  font-size: ${({ fontSize }) => `${fontSize}px` || '20px'};
  color: ${({ color }) => color || 'inherit'};
  transform: rotate(90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
`;
