import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  StyledGlobalJobBoardJustLandedMainWrapper,
  StyledGlobalJobBoardJustLandedTitleAndIcon,
} from '../../../../components/featured-jobs/global-job-board-featured-jobs.styled';
import { Text, font } from '../../../../../../../style-utils/text';
import { colors } from '../../../../../../../style-utils/colors';
import GlobalJobBoardJustLandedListing from '../../../../components/featured-jobs/listing/global-job-board-featured-jobs-listing';
import useGlobalJobBoardOtherJobs from './use-global-job-board-other-jobs';

interface GlobalJobBoardOtherJobsProps {
  navigate: NavigateFunction;
  jobId: string | undefined;
}

const GlobalJobBoardOtherJobs: React.FC<GlobalJobBoardOtherJobsProps> = ({
  navigate,
  jobId,
}) => {
  const { jobListings } = useGlobalJobBoardOtherJobs(jobId);

  return (
    <StyledGlobalJobBoardJustLandedMainWrapper>
      <StyledGlobalJobBoardJustLandedTitleAndIcon>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={500}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          Other Jobs From Company
        </Text>
      </StyledGlobalJobBoardJustLandedTitleAndIcon>
      {jobListings.map((job, index) => (
        <GlobalJobBoardJustLandedListing
          key={index}
          jobListing={job}
          backgroundColor={colors.ghostWhite}
          navigate={navigate}
        />
      ))}
    </StyledGlobalJobBoardJustLandedMainWrapper>
  );
};

export default GlobalJobBoardOtherJobs;
