import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const StyledFlowStepWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 48px;
`;

export const StyledInputsScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-y: scroll;
`;

export const StyledTitleAndPageIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 56px 96px 0 96px;
`;

export const StyledPageTitleAndDesctiptionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledStepIndicatorWrapper = styled.div`
  margin-left: auto;
`;

export const StyledCreateNewJobForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding: 0 96px 56px 96px;
  overflow-y: scroll;
`;

export const StyledJobTitleAndAIButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const StyledInputAndLabelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledTextAreaInputAndLabelWrapper = styled(
  StyledInputAndLabelWrapper
)`
  flex: auto;
`;

export const StyledTextInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background-color: white;
  border-style: none;
  border-radius: 16px;
  padding: 0 24px;

  &:focus {
    outline: ${colors.primary_100};
    outline-style: solid;
    outline-width: 1.5px;
    box-shadow: none;
  }
`;

export const StyledGenerateWithAiBtn = styled.button<{ disabled: boolean }>`
  box-sizing: border-box;
  height: 48px;
  width: 200px;
  background-color: ${({ disabled }) =>
    disabled ? colors.primary_100 : colors.primary_500};
  color: white;
  border-style: none;
  border-radius: 12px;
  margin-top: auto;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledTextAreaInput = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  flex: auto;
  background-color: white;
  border-style: none;
  border-radius: 16px;
  padding: 16px 24px;
  resize: none;

  &:focus {
    outline: ${colors.primary_100};
    outline-style: solid;
    outline-width: 1.5px;
    box-shadow: none;
  }
`;

export const StyledTextAreaFixedHeightInput = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 144px;
  background-color: white;
  border-style: none;
  border-radius: 16px;
  padding: 16px 24px;
  resize: none;

  &:focus {
    outline: ${colors.primary_100};
    outline-style: solid;
    outline-width: 1.5px;
    box-shadow: none;
  }
`;

export const StyledSubmitBtn = styled(StyledGenerateWithAiBtn)`
  background-color: ${({ disabled }) =>
    disabled ? colors.secondary_100 : colors.secondary_500};
  margin-left: auto;
  margin-top: -10px;
  height: 35px;
  width: 115px;
  padding: 9px 8px;
  border-radius: 4px;
  font-weight: 600;
`;

export const StyledPublishBtn = styled(StyledSubmitBtn)`
  background-color: ${colors.primary_500};
`;

export const StyledPublishDraftBtn = styled(StyledSubmitBtn)`
  background-color: ${colors.primary_500};
`;

export const StyledPublishedAndDraftBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledFormInputAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const StyledFormTextAreaInputAndErrorWrapper = styled(
  StyledFormInputAndErrorWrapper
)`
  flex: auto;
`;

export const InputErrorMsgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const StyledGridInputsWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 16px;
`;

export const StyledSelectInputAndArrowIconWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  overflow: hidden;
`;

export const StyledDropDownMenuSelectInput = styled.select`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background-color: white;
  border-style: none;
  padding: 0 24px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
`;

export const StyledDropDownMenuSelectCustomInput = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: white;
  border-style: none;
  padding: 0 24px;
  font-size: 13px;
  font-weight: 500;
`;

export const StyledDropDownMenuSelectCustomDropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  background-color: ${colors.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 42px;
  z-index: 10;
  padding: 0 0 8px 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

export const StyledDropDownMenuSelectCustomSearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 8px 16px;
  border-style: none;
  border-bottom: 0.5px solid ${colors.neutral_50};
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.neutral_800};
  outline: none;
`;

export const StyledDropDownMenuSelectCustomOption = styled.div`
  padding: 8px 16px;
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.neutral_800};
  cursor: pointer;
  &:hover {
    background-color: ${colors.neutral_200};
  }
`;

export const StyledArrowIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 24px;
`;

export const StyledSelectedSkillsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledSelectedSkill = styled.div`
  box-sizing: border-box;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background-color: white;
  border-radius: 8px;
`;

export const DeleteButton = styled.span`
  cursor: pointer;
`;

export const StyledCustomSkillInput = styled.input`
  box-sizing: border-box;
  width: 200px;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: white;
  border-color: ${colors.primary_300};
  border-style: solid;
  border-width: 1.5px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledNavButtonsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 48px;
  width: 100%;
`;

export const StyledBackBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 48px;
  width: fit-content;
  padding: 0 24px;
  border-style: none;
  gap: 8px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
