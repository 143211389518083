import React, { useState } from 'react';
import {
  StyledGlobalJobBoardActiveJobsFilterAndTotalResults,
  StyledGlobalJobBoardActiveJobsMainWrapper,
  StyledGlobalJobBoardActiveJobsShowMoreBtn,
  StyledGlobalJobBoardActiveJobsSortyBy,
  StyledGlobalJobBoardActiveJobsSortyByArrow,
  StyledGlobalJobBoardActiveJobsSortyByInputAndArrow,
  StyledGlobalJobBoardActiveJobsSortyByMainWrapper,
} from './global-job-board-active-jobs.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import { useJobListings } from '../../../../hooks/job-listings-context';
import GlobalJobBoardJobListing from './global-job-board-job-listing';
import { NavigateFunction } from 'react-router-dom';
import { DownArrow } from '../../../../../../../icons/down-arrow';
import { RotatingSpinner } from '../../../../../../../spinner-new/spinner-new';

interface GlobalJobBoardActiveJobsProps {
  navigate: NavigateFunction;
}

const sortOptions = [
  { label: 'Date Added (Newest First)', value: 'date_added_desc' },
  { label: 'Date Added (Oldest First)', value: 'date_added_asc' },
  { label: 'Alphabetical (A to Z)', value: 'alphabetical_asc' },
  { label: 'Alphabetical (Z to A)', value: 'alphabetical_desc' },
];

const GlobalJobBoardActiveJobs: React.FC<GlobalJobBoardActiveJobsProps> = ({
  navigate,
}) => {
  const { jobListings, jobCount, isLoading, loadMoreJobs } = useJobListings();
  const [selectedSort, setSelectedSort] = useState(sortOptions[0].value);

  const { setSort } = useJobListings();

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedSort(selectedValue);
    setSort(selectedValue);
  };

  return (
    <StyledGlobalJobBoardActiveJobsMainWrapper>
      <StyledGlobalJobBoardActiveJobsFilterAndTotalResults>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={400}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          Search results: {jobCount} Jobs
        </Text>
        <StyledGlobalJobBoardActiveJobsSortyByMainWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={400}
            lineHeight="19.5px"
            color={colors.base_black}
            margin="0"
          >
            Sort By:
          </Text>
          <StyledGlobalJobBoardActiveJobsSortyByInputAndArrow>
            <StyledGlobalJobBoardActiveJobsSortyBy
              value={selectedSort}
              onChange={handleSortChange}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </StyledGlobalJobBoardActiveJobsSortyBy>
            <StyledGlobalJobBoardActiveJobsSortyByArrow>
              <DownArrow />
            </StyledGlobalJobBoardActiveJobsSortyByArrow>
          </StyledGlobalJobBoardActiveJobsSortyByInputAndArrow>
        </StyledGlobalJobBoardActiveJobsSortyByMainWrapper>
      </StyledGlobalJobBoardActiveJobsFilterAndTotalResults>
      {jobListings.map((job, index) => (
        <GlobalJobBoardJobListing
          key={index}
          jobListing={job}
          navigate={navigate}
        />
      ))}
      {isLoading && <RotatingSpinner />}
      {!isLoading && jobCount > 0 && (
        <StyledGlobalJobBoardActiveJobsShowMoreBtn onClick={loadMoreJobs}>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={400}
            lineHeight="19.5px"
            color={colors.secondary_400}
            margin="0"
          >
            Show More Jobs
          </Text>
        </StyledGlobalJobBoardActiveJobsShowMoreBtn>
      )}
    </StyledGlobalJobBoardActiveJobsMainWrapper>
  );
};

export default GlobalJobBoardActiveJobs;
