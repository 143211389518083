import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = <F extends (...args: any[]) => any>(
  func: F,
  delay: number
) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Parameters<F>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

interface UseDebouncedApiCallResult<T> {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  error: Error | null;
  data: T | null;
}

const useDebouncedApiCall = <T>(
  delay = 400,
  companyId?: string
): UseDebouncedApiCallResult<T> => {
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(null);

  const handleSearch = useCallback(async (companyId: string, name: string) => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get<T>(
        `${apiRoute}/search/${companyId}/${name}`
      );
      setData(response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedSearch = useCallback(debounce(handleSearch, delay), [
    handleSearch,
    delay,
  ]);

  useEffect(() => {
    if (companyId && name.trim().length > 0) {
      // Ensure name is non-empty
      debouncedSearch(companyId, name);
    } else {
      setData(null); // Clear data when name is empty
    }
  }, [companyId, name, debouncedSearch]);

  return { name, setName, loading, error, data };
};

export default useDebouncedApiCall;
