import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const StyledEmployeeAttachCVPortfolioModalMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledEmployeeAttachCVPortfolioModalForm = styled.form`
  box-sizing: border-box;
  width: 400px;
  max-height: 90vh;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  padding: 32px;
`;

export const StyledEmployeeAttachCVPortfolioModalFileInput = styled.input`
  display: none;
`;

export const StyledEmployeeAttachCVPortfolioModalFileInputBtn = styled.button`
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondary_50};
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledFileInputAndErrorMsgWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledButtonsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
  padding: 0;
`;

export const StyledSubmitButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  background-color: ${colors.primary_600};
  border-radius: 4px;
  padding: 8px 40px;
  cursor: pointer;
`;

export const StyledCloseButton = styled(StyledSubmitButton)`
  background-color: ${colors.primary_400};
`;

export const StyledDeleteBtnAndFilenameWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDeleteBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
`;
