import React, { useEffect, useState } from 'react';
import { SignUpData } from '../../hooks/use-auth-forms';
import { loadSubscriptionPlans } from '../../../function-utils/subscription-plan-utils';
import { SubscriptionPlan } from '../super-admin/subscription-plans/interface';
import { LogInFormContainer, LogInFormWrapper } from '../../login/login.styled';
import {
  Wrapper,
  SubscriptionPlansAndToggleButtonsWrapper,
  ToggleButtonsWrapper,
} from './change-plan.styled';
import { Toggle } from './toggle/toggle';
import { PaymentPlansTable } from './payment-plans-table/payment-plans-table';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';

interface ChangePlanSignUpProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser?: (user: any | null) => void;
  refetchUser?: () => Promise<void>;
  setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData?: SignUpData;
  handlePlanSelection?: (plan: string) => void;
}

export const ChangePlanSignUp: React.FC<ChangePlanSignUpProps> = ({
  user,
  setSignUpData,
  signUpData,
  handlePlanSelection,
}) => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plans = await loadSubscriptionPlans();
        if (isMounted) {
          setPlans(plans);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching subscription plans:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchPlans();

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectedPlan = (planId: string, amount: number) => {
    handlePlanSelection && handlePlanSelection(planId);
    console.log(`Selected Plan ID: ${planId}, Amount: ${amount}`);
  };

  const [isMonthly, setIsMonthly] = useState(true);

  const handleToggle = (toggleState: boolean) => {
    setIsMonthly(toggleState);
  };

  const defaultSelectedPlanId = user?.subscription_plan_id;

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <LogInFormWrapper id="LoginFormWrapper">
      <LogInFormContainer
        width={100}
        padding={'0'}
        maxWidth="929px"
        overflow="none"
      >
        <Wrapper padding="0px" isSignUp={true} key={user?.id}>
          <SubscriptionPlansAndToggleButtonsWrapper isSignUp>
            <ToggleButtonsWrapper>
              <Toggle isMonthly={isMonthly} handleToggle={handleToggle} />
            </ToggleButtonsWrapper>
          </SubscriptionPlansAndToggleButtonsWrapper>
          <PaymentPlansTable
            user={user}
            isSignUp={true}
            setSignUpData={setSignUpData}
            signUpData={signUpData}
            plans={plans}
            setSelectedPlan={setSelectedPlan}
            defaultSelectedPlanId={defaultSelectedPlanId || null}
            isMonthly={isMonthly}
          />
        </Wrapper>
      </LogInFormContainer>
    </LogInFormWrapper>
  );
};
