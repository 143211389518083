import React, { useEffect, useState } from 'react';
import {
  GenderBalanceStyledWrapper,
  GenderBalanceHeading,
  GenderBalanceContainer,
  GenderBalanceIconWrapper,
  CircleLabelsWrapper,
  StyledChartLegendColoredCircle,
  LegendGenderWrapper,
} from './gender-balance.styled';
import { Text } from '../../style-utils/text';
import { GenderFilter } from './components/filter';
import { Chart, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { GendersIcon } from '../icons/genders-icon';

Chart.register(ArcElement, Tooltip, Legend);

interface GenderBalanceTypes {
  male: number;
  female: number;
}

type GenderData = {
  period: Date;
  male: number;
  female: number;
};

// Dummy data for each month
// mozebi ke treba da se dodade other, i imame not provided gender
const dummyData: GenderData[] = [
  { period: new Date(2023, 0), male: 60, female: 40 },
  { period: new Date(2023, 1), male: 55, female: 45 },
  { period: new Date(2023, 2), male: 62, female: 38 },
  { period: new Date(2023, 3), male: 58, female: 42 },
  { period: new Date(2023, 4), male: 64, female: 36 },
  { period: new Date(2023, 5), male: 59, female: 41 },
  { period: new Date(2023, 6), male: 61, female: 39 },
  { period: new Date(2023, 7), male: 63, female: 37 },
  { period: new Date(2023, 8), male: 56, female: 44 },
  { period: new Date(2023, 9), male: 58, female: 42 },
  { period: new Date(2023, 10), male: 62, female: 38 },
  { period: new Date(2023, 11), male: 61, female: 39 },
  { period: new Date(2024, 0), male: 60, female: 40 },
  { period: new Date(2024, 1), male: 55, female: 45 },
  { period: new Date(2024, 2), male: 62, female: 38 },
  { period: new Date(2024, 3), male: 58, female: 42 },
  { period: new Date(2024, 4), male: 64, female: 36 },
  { period: new Date(2024, 5), male: 59, female: 41 },
  { period: new Date(2024, 6), male: 61, female: 39 },
  { period: new Date(2024, 7), male: 63, female: 37 },
  { period: new Date(2024, 8), male: 56, female: 44 },
  { period: new Date(2024, 9), male: 58, female: 42 },
  { period: new Date(2024, 10), male: 62, female: 38 },
  { period: new Date(2024, 11), male: 61, female: 39 },
];

export const GenderBalance: React.FC = () => {
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  // some example initial value for style purpose
  const [genderBalanceData, setGenderBalanceData] =
    useState<GenderBalanceTypes>({ male: 72, female: 28 });

  const getFilteredData = () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      return genderBalanceData;
    }

    const filteredData = dummyData.filter(
      (data) => data.period >= dateRange[0] && data.period <= dateRange[1]
    );

    const totalMale = filteredData.reduce((acc, data) => acc + data.male, 0);
    const totalFemale = filteredData.reduce(
      (acc, data) => acc + data.female,
      0
    );

    setGenderBalanceData({ male: totalMale, female: totalFemale });

    return { male: totalMale, female: totalFemale };
  };

  useEffect(() => {
    getFilteredData();
  }, [dateRange]);

  const filteredData = genderBalanceData;

  const total = filteredData.male + filteredData.female;
  const malePercentage = total > 0 ? (filteredData.male / total) * 100 : 0;
  const femalePercentage = total > 0 ? (filteredData.female / total) * 100 : 0;

  const maleData = {
    labels: ['Male'],
    datasets: [
      {
        data: [malePercentage, 100 - malePercentage],
        backgroundColor: ['#2E77B8', '#E4EAF5'],
        borderRadius: 30,
      },
    ],
  };

  const femaleData = {
    labels: ['Female'],
    datasets: [
      {
        data: [femalePercentage, 100 - femalePercentage],
        backgroundColor: ['#E94E3C', '#FFE7E1'],
        borderRadius: 30,
      },
    ],
  };

  const optionsMale: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '75%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const optionsFemale: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '60%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const formatDateRange = (range: [Date, Date] | null) => {
    if (!range) return '';
    const start = range[0].toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    });
    const end = range[1].toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    });

    return `${start} - ${end}`;
  };

  return (
    <GenderBalanceStyledWrapper>
      <GenderBalanceHeading>
        <Text
          fontFamily="Lato"
          fontSize={18}
          fontWeight={700}
          lineHeight="24px"
          margin="0"
        >
          Gender
        </Text>
        <GenderFilter dateRange={dateRange} setDateRange={setDateRange} />
      </GenderBalanceHeading>
      <Text margin="0" fontFamily="Lato" fontSize={11} pb={20}>
        Range period: {dateRange ? `(${formatDateRange(dateRange)})` : ''}
      </Text>
      {genderBalanceData && (
        <GenderBalanceContainer>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Doughnut data={maleData} options={optionsMale} />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '18%',
              left: '18%',
              width: '65%',
              height: '65%',
            }}
          >
            <Doughnut data={femaleData} options={optionsFemale} />
          </div>
          <GenderBalanceIconWrapper>
            <GendersIcon />
          </GenderBalanceIconWrapper>
        </GenderBalanceContainer>
      )}
      <CircleLabelsWrapper>
        <LegendGenderWrapper>
          <StyledChartLegendColoredCircle></StyledChartLegendColoredCircle>
          <Text
            style={{
              fontFamily: 'Lato',
              fontSize: '12px',
              fontWeight: 400,
            }}
          >
            Man: {malePercentage.toFixed(1)}%
          </Text>
        </LegendGenderWrapper>
        <LegendGenderWrapper>
          <StyledChartLegendColoredCircle backgroundColor="#E94E3C"></StyledChartLegendColoredCircle>

          <Text
            style={{
              fontFamily: 'Lato',
              fontSize: '12px',
              fontWeight: 400,
            }}
          >
            Woman: {femalePercentage.toFixed(1)}%
          </Text>
        </LegendGenderWrapper>
      </CircleLabelsWrapper>
    </GenderBalanceStyledWrapper>
  );
};
