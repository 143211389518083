import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  LogInWrapper,
  LogInFormContainer,
  TextContainer,
  CompanyTitle,
  CompanySubtitle,
  // Description,
  LogInFormWrapper,
} from '../../login/login.styled';
import logoTeam from '../../../logo_team.png';
import { Title, Wrapper } from '../change-plan/change-plan.styled';
import { CheckOnIcon } from '../../icons/check-on';
import { RotatingSpinner } from '../../spinner-new/spinner-new';
import { apiRoute } from '../../../constants/api-constants';
import Cookies from 'universal-cookie';

interface TimeOffApprovedProps {
  cookies: Cookies;
}

export const TimeOffApproved: React.FC<TimeOffApprovedProps> = ({
  cookies,
}) => {
  console.log('cookies');
  console.log(cookies);
  const navigate = useNavigate();
  const { notificationId } = useParams<{ notificationId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleLogoClick = () => {
    navigate('/');
  };

  useEffect(() => {
    const approveTimeOff = async () => {
      if (!notificationId) {
        setError('Invalid notification ID.');
        setLoading(false);

        return;
      }
      try {
        await axios.put(
          `${apiRoute}/timeoff-application/${notificationId}/approve`,
          {},
          { withCredentials: true }
        );
        setSuccess(true);
        // eslint-disable-next-line
      } catch (err: any) {
        const message =
          err.response?.data?.message ||
          'Failed to approve the time-off request.';
        setError(message);
        console.error('Error approving time-off request:', err);
      } finally {
        setLoading(false);
      }
    };

    approveTimeOff();
  }, [notificationId]);

  if (loading) {
    return (
      <LogInWrapper id="log-in-form">
        <TextContainer>
          <img
            onClick={handleLogoClick}
            style={{ marginTop: '60px', cursor: 'pointer' }}
            src={logoTeam}
            width={100}
            alt="Company Logo"
          />
          <CompanyTitle>WorWise Client Board</CompanyTitle>
          <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
          {/* <Description>Processing your approval request...</Description> */}
        </TextContainer>
        <LogInFormWrapper id="LoginFormWrapper">
          <LogInFormContainer maxWidth="929px" overflow="none">
            <Wrapper padding="40px" id="wrapper" isSignUp={true}>
              <RotatingSpinner />
            </Wrapper>
          </LogInFormContainer>
        </LogInFormWrapper>
      </LogInWrapper>
    );
  }

  if (error) {
    return (
      <LogInWrapper id="log-in-form">
        <TextContainer>
          <img
            onClick={handleLogoClick}
            style={{ marginTop: '60px', cursor: 'pointer' }}
            src={logoTeam}
            width={100}
            alt="Company Logo"
          />
          <CompanyTitle>WorWise Client Board</CompanyTitle>
          <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
          {/* <Description>{error}</Description> */}
        </TextContainer>
        <LogInFormWrapper id="LoginFormWrapper">
          <LogInFormContainer maxWidth="929px" overflow="none">
            <Wrapper padding="40px" id="wrapper" isSignUp={true}>
              <Title>Error: {error}</Title> {/* Display Error Message */}
            </Wrapper>
          </LogInFormContainer>
        </LogInFormWrapper>
      </LogInWrapper>
    );
  }

  if (success) {
    return (
      <LogInWrapper id="log-in-form">
        <TextContainer>
          <img
            onClick={handleLogoClick}
            style={{ marginTop: '60px', cursor: 'pointer' }}
            src={logoTeam}
            width={100}
            alt="Company Logo"
          />
          <CompanyTitle>WorWise Client Board</CompanyTitle>
          <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
          {/* <Description>
            Your approval has been processed successfully.
          </Description> */}
        </TextContainer>
        <LogInFormWrapper id="LoginFormWrapper">
          <LogInFormContainer maxWidth="929px" overflow="none">
            <Wrapper padding="40px" id="wrapper" isSignUp={true}>
              <Title>
                You have successfully approved the Time Off Request{' '}
                <CheckOnIcon />
              </Title>
            </Wrapper>
          </LogInFormContainer>
        </LogInFormWrapper>
      </LogInWrapper>
    );
  }

  return null;
};
