import React, { CSSProperties, useEffect, useRef } from 'react';
import { DateRangePicker, PickerHandle } from 'rsuite';

interface GenderFilterProps {
  dateRange: [Date, Date] | null;
  setDateRange: React.Dispatch<React.SetStateAction<[Date, Date] | null>>;
}

export const GenderFilter: React.FC<GenderFilterProps> = ({
  dateRange,
  setDateRange,
}) => {
  const datePickerRef = useRef<PickerHandle>(null);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://unpkg.com/rsuite@5.65.0/dist/rsuite-no-reset.min.css';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const defaultStyle: CSSProperties = {
    boxSizing: 'border-box',
    width: '40px',
    height: 'auto',
  };

  return (
    <>
      <DateRangePicker
        ref={datePickerRef}
        value={dateRange}
        onChange={(range) => setDateRange(range ? [range[0], range[1]] : null)}
        format="MMMM yyyy"
        ranges={[]}
        style={defaultStyle}
      />
    </>
  );
};
