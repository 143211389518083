import * as yup from 'yup';

export const createNewJobFirstStepSchema = yup.object().shape({
  job_position: yup.string().required('Job position is requered!'),
  job_description: yup.string().required('Job description is requered!'),
});

export const createNewJobSecondStepSchema = yup.object().shape({
  specialty: yup.string().required('Speciality is requered!'),
  seniority_level: yup.string().required('Seniority level is requered!'),
  job_duration: yup.string().required('Job duration is requered!'),
  responsibilities: yup.string().required('Responsibilities is requered!'),
  skills: yup
    .array()
    .of(yup.string().required('Each skill must be a string'))
    .required('Skills is required')
    .min(1, 'At least one skill is required'),
});

const today = new Date();
today.setHours(0, 0, 0, 0); // Set the time to the beginning of the day

const threeYearsFromToday = new Date(today);
threeYearsFromToday.setFullYear(today.getFullYear() + 3);

export const createNewJobThirdStepSchema = yup.object().shape({
  general_company_info: yup
    .string()
    .required('General company info is required!'),
  work_location: yup.string().required('Work location is required!'),
  location: yup.string().required('Location is required!'),
  industry: yup.string().required('Industry is required!'),
  benefits: yup.string().required('Benefits are required!'),
  expiration_date: yup
    .date()
    .min(today, 'Date cannot be in the past')
    .max(threeYearsFromToday, 'Date cannot be more than 3 years from today')
    .required('Date is required'),
});
