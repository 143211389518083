import styled from 'styled-components';

export const ActiveSurveysMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 48px;
  gap: 8px;
  justify-content: space-around;
  background-color: white;
  border-radius: 8px;
  margin-top: 32px;
  font-family: Lato;
`;

export const SurveysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActiveSurvey = styled.div<{ backgroundColor?: string }>`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#e4eaf5'};
  border: none;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SurveyName = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  margin: 0;
`;

export const ResponsesPart = styled.div`
  max-width: 97px;
  font-size: 16px;
  line-height: 16.8px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: white;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DateParagraph = styled.p`
  font-size: 12px;
  color: #736f6d;
  margin: 0;
`;

export const ViewBtn = styled.button<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#e4eaf5'};
  padding: 12px 32px;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  cursor: pointer;
`;
