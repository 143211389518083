import React from 'react';

export const SquareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M5.99984 2H3.99984C3.64622 2 3.30708 2.14048 3.05703 2.39052C2.80698 2.64057 2.6665 2.97971 2.6665 3.33333V5.33333M14.6665 5.33333V3.33333C14.6665 2.97971 14.526 2.64057 14.276 2.39052C14.0259 2.14048 13.6868 2 13.3332 2H11.3332M11.3332 14H13.3332C13.6868 14 14.0259 13.8595 14.276 13.6095C14.526 13.3594 14.6665 13.0203 14.6665 12.6667V10.6667M2.6665 10.6667V12.6667C2.6665 13.0203 2.80698 13.3594 3.05703 13.6095C3.30708 13.8595 3.64622 14 3.99984 14H5.99984"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
