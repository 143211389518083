import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { StyledChartsMainWrapper } from '../charts.styled';
import { ReportingBarAndMultiLineChartDataTypes } from '../charts-data-types';

interface BarChartProps {
  name: string;
  barChartData: ReportingBarAndMultiLineChartDataTypes[];
}

const ReportingBarChart: React.FC<BarChartProps> = ({ name, barChartData }) => {
  return (
    <StyledChartsMainWrapper>
      <Text
        fontSize={24}
        fontFamily={font.family}
        color={colors.base_black}
        fontWeight={700}
        margin="0"
      >
        {name}
      </Text>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={barChartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="avg" fill={colors.primary_500} />
          <Bar dataKey="max" fill={colors.secondary_300} />
          <Bar dataKey="min" fill={colors.primary_200} />
        </BarChart>
      </ResponsiveContainer>
    </StyledChartsMainWrapper>
  );
};

export default ReportingBarChart;
