import styled from 'styled-components';
import { colors, primary } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const ApplicantContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 500px; /* Adjust based on your preference */

  @media (max-width: 600px) {
    width: auto;
    margin: 10px;
  }
`;

export const ApplicantField = styled.div`
  margin-bottom: 10px;
`;

export const FieldLabel = styled.strong`
  margin-right: 10px;
`;

export const FieldValue = styled.span`
  color: #333;
`;

export const AIInfoHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 32px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${colors.base_white};
  margin-bottom: 10px;
`;

export const AIInfoProfilePictureContainer = styled.div`
  box-sizing: border-box;
  width: 162px;
  height: 162px;
  align-self: center;

  & > img {
    box-sizing: border-box;
    display: block;
    // width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const AIInfoPercentageNameAndPercentageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const AIInfoPercentageContainer = styled.div`
  box-sizing: border-box;
  padding: 8px 16px 8px 12px;
  background-color: ${colors.primary_50};
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 32px;
`;

export const AIInfoPercentageSpan = styled.span`
  box-sizing: border-box;
  font-family: ${font.family};
  font-weight: 700;
  color: ${colors.base_black};
  font-size: 18px;
  line-height: 27px;
  margin: 0;
`;

export const AIInfoTextContainer = styled.div<{
  gap?: string;
  alignItems?: string;
}>`
  box-sizing: border-box;
  display: flex;
  margin-bottom: 8px;
  ${({ gap }) => gap && `gap: ${gap};`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};
`;

export const AIInfoDocumentAttachmentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Headings = styled.div`
  padding-bottom: 20px;
`;

export const ContainerStrengthsWeaknesses = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StrengthsContainer = styled.div`
  padding: 10px 0;
`;

export const WeaknessesContainer = styled.div`
  padding: 15px 0;
`;

export const AIInfoKeySkillsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const AIInfoKeySkillsTab = styled.div<{
  bgColor?: string;
}>`
  box-sizing: border-box;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`};
  padding: 8px 16px;
  border-radius: 32px;
`;

export const AIInfoKeySkillAndLevelTab = styled.div<{
  borderColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  ${({ borderColor }) => borderColor && `border-color: ${borderColor};`};
  padding: 8px 16px;
  border-radius: 8px;
`;

export const AIInfoStyledUnnorderedList = styled.ul`
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
`;

export const AIInfoStyledListItem = styled.li`
  box-sizing: border-box;
  font-family: ${font.family};
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: ${colors.base_black};

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const AIInfoInterviewQuestionsGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;

  & > div:nth-of-type(1) {
    order: 1;
  }
  & > div:nth-of-type(2) {
    order: 3;
  }
  & > div:nth-of-type(3) {
    order: 5;
  }
  & > div:nth-of-type(4) {
    order: 7;
  }
  & > div:nth-of-type(5) {
    order: 9;
  }
  & > div:nth-of-type(6) {
    order: 2;
  }
  & > div:nth-of-type(7) {
    order: 4;
  }
  & > div:nth-of-type(8) {
    order: 6;
  }
  & > div:nth-of-type(9) {
    order: 8;
  }
  & > div:nth-of-type(10) {
    order: 10;
  }
`;

export const AIInfoInterviewQuestionsGridItem = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 8px;
`;

export const AIInfoInterviewQuestionsNumber = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  color: ${colors.white};
  background-color: ${primary.primary_400};
  font-size: 14px;
  font-family: ${font.family};
  font-weight: 400;
  line-height: 16.8px;
  flex: 0 0 25px;
`;

export const AIInfoKeyProjectsProjectsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AIInfoKeyProjectsProjectAndSkillsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
`;

export const AIInfoKeyProjectsProjectSkillsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

export const AIInfoKeyProjectsProjectWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`;

export const AIInfoSoftSkillWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`;

export const AIInfoSoftSkillsItemsWrapperWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
`;

export const ReadMoreBtn = styled.div`
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Lato';
  position: right;
`;
