import React, { useState } from 'react';
import {
  LogInWrapper,
  LogInFormContainer,
  LogInForm,
  LogInTitle,
  LogInSubTitle,
  TextContainer,
  CompanyTitle,
  Description,
  InputContainer,
  Label,
  StyledInput,
  ButtonTitle,
  BlueButton,
} from '../login/login.styled';
import { DefaultComponentProps } from '../app-router/component-props';
import { useAuthForms } from '../hooks/use-auth-forms';
import logoTeam from '../../logo_team.png';
import { updateItem } from '../requests/requests';
import { ErrorLabel } from '../pages/jobs/jobs.styled';
import { RotatingSpinner } from '../spinner-new/spinner-new';

// import { useAuth } from '../../context/auth-provider';

export const RegisterUser: React.FC<DefaultComponentProps> = ({
  navigate,
  user,
  type,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState<boolean>(false);

  const { signUpData, handleSignUpChange } = useAuthForms();

  const changePassword = async () => {
    console.log('User to update:', user);
    setIsLoading(true);
    await updateItem(
      type === 'company'
        ? `update-password/company`
        : type === 'employee'
        ? `update-employee-password/employee`
        : `update-password/user`,
      signUpData,
      user.id
    );
  };

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  return (
    <LogInWrapper id="log-in-form" justifyContent="none">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
        />
        <div>
          <CompanyTitle>WorWise Client Board</CompanyTitle>
          <Description>
            Welcome! Please enter your details to start optimizing your team’s
            hiring process.
          </Description>
        </div>
      </TextContainer>
      <div
        style={{
          display: 'flex',
          flex: '1',
          justifyContent: 'center',
        }}
      >
        <LogInFormContainer overflow="auto">
          <LogInForm>
            {isLoading && <RotatingSpinner />}
            <LogInTitle>
              {type === 'company'
                ? 'Set Up Your Company Account'
                : 'Set Up Your Admin Account'}
            </LogInTitle>
            <LogInSubTitle>
              Enter the password you want to use to sign up for Worwise
            </LogInSubTitle>
            <InputContainer>
              <Label>Password</Label>
              <StyledInput
                type="password"
                name="password"
                value={signUpData.password}
                placeholder={'Password'}
                onChange={handleSignUpChange}
              />
              {isPasswordEmpty && (
                <ErrorLabel>Please enter a password</ErrorLabel>
              )}
            </InputContainer>
            <BlueButton
              type="button"
              onClick={() => {
                if (!signUpData.password) {
                  setIsPasswordEmpty(true);

                  return;
                } else {
                  setIsPasswordEmpty(false);
                }
                changePassword();
                !isLoading && navigate
                  ? navigate('/login')
                  : console.log('error navigate is not defined');
              }}
            >
              <ButtonTitle fontColor="white">Complete Registration</ButtonTitle>
            </BlueButton>
          </LogInForm>
        </LogInFormContainer>
      </div>
    </LogInWrapper>
  );
};
