import React, { useEffect, useState } from 'react';
import {
  ApplicantsContentWrapper,
  HeadingWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  WrapperDiv,
  StyledSelect,
  StyledSearchInput,
} from '../../employees/employees.styled';
import { ButtonContainer } from '../../../login/login.styled';
import { fetchAllItems } from '../../../requests/requests';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { usePagination } from '../../../hooks/use-pagination';
import { PaginationButtons } from '../../../table-pagination/table-pagination';
import { formatDateToLocal } from '../../../../function-utils/utc-date-converter';

interface SuperAdminsProps {
  openModal: () => void;
  // eslint-disable-next-line
  onEdit: (admin: any) => void;
}

export const SuperAdmins: React.FC<SuperAdminsProps> = ({
  openModal,
  onEdit,
}) => {
  // eslint-disable-next-line
  const [adminsData, setAdminsData] = useState<any[]>([]);
  // eslint-disable-next-line
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filterBy, setFilterBy] = useState('');
  const [searchWord, setSearchWord] = useState('');

  const getUsers = async () => {
    const response = await fetchAllItems(`/all-entities`);
    // eslint-disable-next-line
    setAdminsData(response as any[]);
    // eslint-disable-next-line
    setFilteredData(response as any[]);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const filterAndSearch = () => {
      let filtered = adminsData;

      if (searchWord) {
        filtered = adminsData.filter((admin) => {
          const lowerCaseSearchWord = searchWord.toLowerCase();

          return (
            admin.createdAt?.toLowerCase().includes(lowerCaseSearchWord) ||
            admin.name?.toLowerCase().includes(lowerCaseSearchWord) ||
            admin.email?.toLowerCase().includes(lowerCaseSearchWord) ||
            admin.account_type?.toLowerCase().includes(lowerCaseSearchWord) ||
            admin.status?.toLowerCase().includes(lowerCaseSearchWord)
          );
        });
      }

      if (filterBy && searchWord) {
        filtered = filtered.filter((admin) => {
          const lowerCaseSearchWord = searchWord.toLowerCase();
          switch (filterBy) {
            case 'Create Date':
              return admin.createdAt
                ?.toLowerCase()
                .includes(lowerCaseSearchWord);
            case 'Company':
              return admin.name?.toLowerCase().includes(lowerCaseSearchWord);
            case 'User':
              return admin.email?.toLowerCase().includes(lowerCaseSearchWord);
            case 'Role':
              return admin.account_type
                ?.toLowerCase()
                .includes(lowerCaseSearchWord);
            case 'Status':
              return admin.status?.toLowerCase().includes(lowerCaseSearchWord);
            default:
              return true;
          }
        });
      }

      setFilteredData(filtered);
    };

    filterAndSearch();
  }, [filterBy, searchWord, adminsData]);

  const {
    currentPage,
    displayedData,
    nextPage,
    previousPage,
    hasPreviousPage,
    hasNextPage,
    getTotalPages,
  } = usePagination(filteredData, 10);

  return (
    <ApplicantsContentWrapper>
      <div style={{ overflowX: 'auto' }}>
        <HeadingWrapper>
          <Text mt={0} mb={0} fontSize={32} fontFamily={font.family}>
            Admins
          </Text>
          <WrapperDiv>
            <StyledSelect
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option value="">All</option>
              <option value="Create Date">Create Date</option>
              <option value="Company">Company</option>
              <option value="User">User</option>
              <option value="Role">Role</option>
              <option value="Status">Status</option>
              <option value="Action">Action</option>
            </StyledSelect>
            <StyledSearchInput
              type="text"
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder="Search..."
            />
            <ButtonContainer
              backgroundColor={colors.secondary_400}
              isFontWeight
              onClick={openModal}
            >
              + Add New Admin
            </ButtonContainer>
          </WrapperDiv>
        </HeadingWrapper>
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh columnWidth={'120px'}>Create Date</StyledTh>
              <StyledTh columnWidth={'273px'}>Company</StyledTh>
              <StyledTh columnWidth={'273px'}>User</StyledTh>
              <StyledTh columnWidth={'140px'}>Role</StyledTh>
              <StyledTh columnWidth={'120px'}>Status</StyledTh>
              <StyledTh columnWidth={'120px'}>Action</StyledTh>
            </StyledTr>
          </StyledThead>
          <tbody>
            {displayedData?.map((admin, i) => (
              <StyledTr key={i}>
                <StyledTd data-label="created-at">
                  {formatDateToLocal(admin.createdAt)}
                </StyledTd>
                <StyledTd data-label="name">{admin?.name}</StyledTd>
                <StyledTd data-label="email">{admin?.email}</StyledTd>
                <StyledTd data-label="role">{admin?.account_type}</StyledTd>
                <StyledTd data-label="status">{admin?.status}</StyledTd>
                <StyledTd>
                  <ButtonContainer
                    backgroundColor={colors.secondary_400}
                    onClick={() => onEdit(admin)}
                  >
                    <Text
                      mt={0}
                      mb={0}
                      align="center"
                      pl={20}
                      pr={20}
                      fontFamily={font.family}
                      fontWeight={700}
                    >
                      Edit
                    </Text>
                  </ButtonContainer>
                </StyledTd>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </div>
      <div style={{ width: '60%' }}>
        <PaginationButtons
          currentPage={currentPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalPages={getTotalPages()}
        />
      </div>
    </ApplicantsContentWrapper>
  );
};
