import React, { useEffect, useState } from 'react';
import {
  DeleteButton,
  StyledEmployeeTimeOffDateErrorWrapper,
  StyledEmployeeTimeOffRequestAttachedFile,
  StyledEmployeeTimeOffRequestAttachedFiles,
  StyledEmployeeTimeOffRequestBackBtn,
  StyledEmployeeTimeOffRequestBtnsWrapper,
  StyledEmployeeTimeOffRequestFilesInput,
  StyledEmployeeTimeOffRequestFilesLabel,
  StyledEmployeeTimeOffRequestForm,
  StyledEmployeeTimeOffRequestFormItem,
  StyledEmployeeTimeOffRequestFormItemFullWidth,
  StyledEmployeeTimeOffRequestFormItemsColumnWrapper,
  StyledEmployeeTimeOffRequestFormItemsWrapper,
  StyledEmployeeTimeOffRequestFormWrapper,
  StyledEmployeeTimeOffRequestSubmitBtn,
  StyledEmployeeTimeOffRequestTypeIconWrapper,
  StyledEmployeeTimeOffRequestTypeSelect,
  StyledEmployeeTimeOffRequestTypeSelectAndIconWrapper,
  StyledEmployeeTimeoffRequestMain,
} from './add-employee-timeoff-request.styled';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NewEmployeeDataTypes } from './add-employee';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { SelectFieldDownArrowIcon } from '../../icons/select-field-down-arrow-icon';
import { SelectDate } from '../applicants/candidates/date-picker';
import { TextEditor } from '../../styled-quill/text-editor';
import MessagePopup from '../../messages/message-popup/message-popup';
interface Props {
  onClose: (success: boolean) => void;
  closePopUp: () => void;
  employee: NewEmployeeDataTypes;
}

interface AddEmployeeTimeoffRequestTypes {
  type: string;
  start_date: string;
  end_date: string;
  description: string;
  documents: File[];
}

const today = new Date();
today.setHours(0, 0, 0, 0);

export const addEmployeeTimeoffRequestValidationSchema = yup.object().shape({
  start_date: yup
    .date()
    .min(today, 'Start date cannot be before today')
    .required('Start date is required!'),
  end_date: yup
    .date()
    .min(yup.ref('start_date'), 'End date cannot be before start date')
    .required('End date is required!'),
});

const AddEmployeeTimeoffRequest: React.FC<Props> = ({
  onClose,
  employee,
  closePopUp,
}) => {
  const [validateOnChange] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const addEmployeeTimeoffRequestInitValues: AddEmployeeTimeoffRequestTypes = {
    type: 'Vacation',
    start_date: '',
    end_date: '',
    description: '',
    documents: [],
  };

  const formik = useFormik({
    initialValues: addEmployeeTimeoffRequestInitValues,
    validationSchema: addEmployeeTimeoffRequestValidationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: (values) => {
      console.log('Submitted values', values);
      const duration = calculateDuration(values.start_date, values.end_date);
      handleTimeoffRequestSubmission(values, duration);
    },
  });

  const handleTimeoffRequestSubmission = async (
    values: AddEmployeeTimeoffRequestTypes,
    duration: string
  ) => {
    try {
      const formData = new FormData();
      formData.append('employee_id', employee.id.toString());
      formData.append('company_id', employee.company_id.toString());
      formData.append(
        'application_date',
        new Date().toISOString().split('T')[0]
      );
      formData.append('start_date', values.start_date);
      formData.append('end_date', values.end_date);
      formData.append('duration', duration);
      formData.append('remaining_days', employee.remaining_days.toString());
      formData.append('status', 'Pending');
      formData.append('type', values.type);
      formData.append('description', values.description);
      selectedFiles.forEach((file) => {
        formData.append('documents', file);
      });

      console.log('Submitting time off request:', formData);
      const submissionResponse = await axios.post(
        `${apiRoute}/timeoff-application`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log('Time off request submitted:', submissionResponse.data);

      if (onClose) {
        console.log('Calling onClose with true');
        onClose(true);
      } else {
        console.warn('onClose function is not defined');
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.error(
        'Error creating time off request:',
        error.response || error.message
      );
      setPopUp({
        text: error.response.data.message,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  const calculateDuration = (startDate: string, endDate: string): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    // Calculate the difference in milliseconds and add one day (in milliseconds)
    const difference =
      Math.abs(end.getTime() - start.getTime()) + 1000 * 60 * 60 * 24;
    // Convert milliseconds to days
    const durationInDays = Math.ceil(difference / (1000 * 60 * 60 * 24));

    return durationInDays.toString(); // Convert to string as per your schema definition
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const emptyArray = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        emptyArray.push(files[i]);
      }
      setSelectedFiles(emptyArray);
      formik.setFieldValue('documents', emptyArray);
    }
  };

  const handleFileDelete = (fileToDelete: File) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToDelete);
    setSelectedFiles(updatedFiles);
    formik.setFieldValue('documents', updatedFiles);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    if (date) {
      const formattedDate = formatDate(date);
      formik.setFieldValue(name, formattedDate);
    }
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const handleDescriptionChange = (value: string) => {
    formik.setFieldValue('description', value);
  };

  const handleFormClick = (
    e: React.MouseEvent<HTMLFormElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  return (
    <StyledEmployeeTimeoffRequestMain onClick={closePopUp}>
      <StyledEmployeeTimeOffRequestFormWrapper
        onClick={handleFormClick}
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <StyledEmployeeTimeOffRequestForm>
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_black}
            fontSize={28}
            fontWeight={700}
          >
            Application Request
          </Text>
          <StyledEmployeeTimeOffRequestFormItemsWrapper>
            <StyledEmployeeTimeOffRequestFormItem>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.base_black}
                fontSize={18}
                fontWeight={700}
              >
                Type of day off
              </Text>
              <StyledEmployeeTimeOffRequestTypeSelectAndIconWrapper>
                <StyledEmployeeTimeOffRequestTypeSelect
                  name="type"
                  value={formik.values.type}
                >
                  <option value="Vacation">Vacation</option>
                  <option value="Days off">Days off</option>
                  <option value="Other">Other</option>
                </StyledEmployeeTimeOffRequestTypeSelect>
                <StyledEmployeeTimeOffRequestTypeIconWrapper>
                  <SelectFieldDownArrowIcon />
                </StyledEmployeeTimeOffRequestTypeIconWrapper>
              </StyledEmployeeTimeOffRequestTypeSelectAndIconWrapper>
            </StyledEmployeeTimeOffRequestFormItem>
          </StyledEmployeeTimeOffRequestFormItemsWrapper>
          <StyledEmployeeTimeOffRequestFormItemsWrapper>
            <StyledEmployeeTimeOffRequestFormItem>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.base_black}
                fontSize={18}
                fontWeight={700}
              >
                Start Date
              </Text>
              {/* <StyledEmployeeTimeOffRequestInput
                type="date"
                max="9999-12-31"
                name="start_date"
                value={formik.values.start_date}
              /> */}
              <SelectDate
                onChange={(value) => handleDateChange(value, 'start_date')}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  borderStyle: 'none',
                  height: '48px',
                  padding: '0 12px',
                  backgroundColor: colors.neutral_50,
                }}
              />
              {formik.errors.start_date && (
                <StyledEmployeeTimeOffDateErrorWrapper>
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.error}
                    fontSize={12}
                    fontWeight={700}
                  >
                    {formik.errors.start_date}
                  </Text>
                </StyledEmployeeTimeOffDateErrorWrapper>
              )}
            </StyledEmployeeTimeOffRequestFormItem>
            <StyledEmployeeTimeOffRequestFormItem>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.base_black}
                fontSize={18}
                fontWeight={700}
              >
                End Date
              </Text>
              {/* <StyledEmployeeTimeOffRequestInput
                type="date"
                max="9999-12-31"
                name="end_date"
                value={formik.values.end_date}
              /> */}
              <SelectDate
                onChange={(value) => handleDateChange(value, 'end_date')}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  borderStyle: 'none',
                  height: '48px',
                  padding: '0 12px',
                  backgroundColor: colors.neutral_50,
                }}
              />
              {formik.errors.end_date && (
                <Text
                  fontFamily={font.family}
                  margin="0"
                  color={colors.error}
                  fontSize={12}
                  fontWeight={700}
                >
                  {formik.errors.end_date}
                </Text>
              )}
            </StyledEmployeeTimeOffRequestFormItem>
          </StyledEmployeeTimeOffRequestFormItemsWrapper>
          <StyledEmployeeTimeOffRequestFormItemsWrapper>
            <StyledEmployeeTimeOffRequestFormItemFullWidth>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.base_black}
                fontSize={18}
                fontWeight={700}
              >
                Description (Optional)
              </Text>
              <TextEditor
                minHeight="50px"
                height="50px"
                placeholder="Enter description"
                changeHandler={handleDescriptionChange}
                defaultValue={formik.values.description}
              />
            </StyledEmployeeTimeOffRequestFormItemFullWidth>
          </StyledEmployeeTimeOffRequestFormItemsWrapper>
          <StyledEmployeeTimeOffRequestFormItemsColumnWrapper>
            <StyledEmployeeTimeOffRequestFormItem>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.base_black}
                fontSize={18}
                fontWeight={700}
              >
                Documents
              </Text>
              <StyledEmployeeTimeOffRequestFilesLabel htmlFor="documents">
                Attach File (pdf, png, jpg)
              </StyledEmployeeTimeOffRequestFilesLabel>
              <StyledEmployeeTimeOffRequestFilesInput
                type="file"
                id="documents"
                name="documents"
                placeholder="Attach File (pdf, png, jpg)"
                onChange={handleFileChange}
                multiple
              />
            </StyledEmployeeTimeOffRequestFormItem>
            <StyledEmployeeTimeOffRequestAttachedFiles>
              {selectedFiles.map((file, index) => (
                <StyledEmployeeTimeOffRequestAttachedFile key={index}>
                  {file.name}{' '}
                  <DeleteButton onClick={() => handleFileDelete(file)}>
                    &#10006;
                  </DeleteButton>
                </StyledEmployeeTimeOffRequestAttachedFile>
              ))}
            </StyledEmployeeTimeOffRequestAttachedFiles>
          </StyledEmployeeTimeOffRequestFormItemsColumnWrapper>
          <StyledEmployeeTimeOffRequestBtnsWrapper>
            <StyledEmployeeTimeOffRequestBackBtn
              type="button"
              onClick={() => onClose(false)}
            >
              Back to Application
            </StyledEmployeeTimeOffRequestBackBtn>
            <StyledEmployeeTimeOffRequestSubmitBtn type="submit">
              Take a Request
            </StyledEmployeeTimeOffRequestSubmitBtn>
          </StyledEmployeeTimeOffRequestBtnsWrapper>
        </StyledEmployeeTimeOffRequestForm>
      </StyledEmployeeTimeOffRequestFormWrapper>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </StyledEmployeeTimeoffRequestMain>
  );
};

export default AddEmployeeTimeoffRequest;
