import React from 'react';
import {
  StyledEmployeesErrorGeneratingCompanyCvPopupMainWrapper,
  StyledEmployeesErrorGeneratingCompanyCvPopupModalCloseBtn,
  StyledEmployeesErrorGeneratingCompanyCvPopupModalCreateCVBtn,
  StyledEmployeesErrorGeneratingCompanyCvPopupModalWrapper,
} from './employees-error-generating-company-cv-popup.styled';
import { font, Text } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { CloseIcon } from '../../../icons/close-icon';
import { NavigateFunction } from 'react-router-dom';

interface EmployeesErrorGeneratingCompanyCvPopupProps {
  employeeId: string | undefined;
  companyName: string;
  navigate: NavigateFunction;
  onClose: () => void;
}

const EmployeesErrorGeneratingCompanyCvPopup: React.FC<
  EmployeesErrorGeneratingCompanyCvPopupProps
> = ({ employeeId, companyName, navigate, onClose }) => {
  return (
    <StyledEmployeesErrorGeneratingCompanyCvPopupMainWrapper onClick={onClose}>
      <StyledEmployeesErrorGeneratingCompanyCvPopupModalWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <StyledEmployeesErrorGeneratingCompanyCvPopupModalCloseBtn
          onClick={onClose}
        >
          <CloseIcon />
        </StyledEmployeesErrorGeneratingCompanyCvPopupModalCloseBtn>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={18}
          fontWeight={700}
          color={colors.base_black}
        >
          AI was unable to generate the company CV from the provided CV. Please
          try again or create the CV manually.
        </Text>
        <StyledEmployeesErrorGeneratingCompanyCvPopupModalCreateCVBtn
          onClick={() =>
            navigate(`/${companyName}/${employeeId}/company-cv/new`)
          }
        >
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={18}
            fontWeight={700}
            color={colors.base_white}
          >
            Generate Manually
          </Text>
        </StyledEmployeesErrorGeneratingCompanyCvPopupModalCreateCVBtn>
      </StyledEmployeesErrorGeneratingCompanyCvPopupModalWrapper>
    </StyledEmployeesErrorGeneratingCompanyCvPopupMainWrapper>
  );
};

export default EmployeesErrorGeneratingCompanyCvPopup;
