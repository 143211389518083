import React, { Dispatch, SetStateAction } from 'react';
import { font, Text } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import TabSelector from '../../components/tab-selector/tab-selector';
import ReportingLineChart from '../../components/charts/line-chart/line-chart';
import ReportingBarChart from '../../components/charts/bar-chart/bar-chart';
import ReportingMultiLineChart from '../../components/charts/multi-line-chart/multi-line-chart';
import { useReportingChartData } from './use-reporting-main-component-data';
import ReportingCustomBarChart from '../../components/charts/custom-bar-chart/custom-bar-chart';
import {
  StyledReportingTabsCenterWrapper,
  StyledReportingTabsMainWrapper,
} from '../tabs.styled';
import { reportingMainTabData } from '../../components/tab-selector/tab-data';

interface ReportingMainProps {
  activeTabId: number;
  setActiveTabId: Dispatch<SetStateAction<number>>;
}

const ReportingMain: React.FC<ReportingMainProps> = ({
  setActiveTabId,
  activeTabId,
}) => {
  const {
    barChartData,
    lineChartData,
    multiLineChartData,
    customBarChartData,
  } = useReportingChartData();

  return (
    <StyledReportingTabsMainWrapper>
      <StyledReportingTabsCenterWrapper>
        <Text
          fontSize={38}
          fontFamily={font.family}
          color={colors.base_black}
          fontWeight={700}
          margin="0"
        >
          Reporting
        </Text>
        <TabSelector
          setActiveTabId={setActiveTabId}
          activeTabId={activeTabId}
          fromComponent="reporting-main"
          tabsData={reportingMainTabData}
        />
        <ReportingBarChart
          name="Average employee"
          barChartData={barChartData}
        />
        <ReportingLineChart
          name="Average contractors"
          lineChartData={lineChartData}
        />
        <ReportingMultiLineChart
          name="Salary"
          multiLineChartData={multiLineChartData}
        />
        <ReportingCustomBarChart
          name="Balance"
          customBarChartData={customBarChartData}
        />
      </StyledReportingTabsCenterWrapper>
    </StyledReportingTabsMainWrapper>
  );
};

export default ReportingMain;
