import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const StyledReportingTabsMainWrapper = styled.div`
  box-sizing: border-box;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const StyledReportingTabsCenterWrapper = styled.div`
  box-sizing: border-box;
  width: 1152px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
`;

export const StyledReportingTabWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledReportingComponentMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.base_white};
  border-radius: 8px;
  padding: 24px;
`;

export const StyledReportingTabTitleAndBackBtnWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledReportingTabBackBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: none;
  background-color: transparent;
  gap: 8px;
  cursor: pointer;
`;
