import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../constants/api-constants';
import { JobListing } from '../../components/featured-jobs/global-job-board-featured-jobs-types';

interface JobListingsContextType {
  jobListings: JobListing[];
  jobCount: number;
  isLoading: boolean;
  noResults: boolean;
  loadMoreJobs: () => void;
  searchJobs: (jobTitle: string, location: string) => void;
  applyFilters: (filters: Record<string, string[] | string>) => void;
  setSort: (sort: string) => void;
}

const filterKeyMapping: Record<string, string> = {
  Worksite: 'worksite',
  'Type of Employment': 'employment_type',
  Industry: 'industry',
  Experience: 'experience',
  'Salary Range': 'salary_range',
};

const JobListingsContext = createContext<JobListingsContextType | undefined>(
  undefined
);

export const JobListingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [jobListings, setJobListings] = useState<JobListing[]>([]);
  const [jobCount, setJobCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchParams, setSearchParams] = useState({
    job_title: '',
    location: '',
  });
  const [filters, setFilters] = useState<Record<string, string[] | string>>({});
  const [sort, setSort] = useState<string>('date_added_desc');
  const limit = 10;

  const fetchJobListings = async (
    newOffset = 0,
    search = searchParams,
    activeFilters = filters,
    currentSort = sort
  ) => {
    setIsLoading(true);
    setNoResults(false);

    const queryFilters = Object.entries(activeFilters).reduce(
      (acc, [frontendKey, values]) => {
        const backendKey = filterKeyMapping[frontendKey] || frontendKey;

        if (Array.isArray(values) && values.length > 0) {
          acc[backendKey] = values.join(',');
        } else if (typeof values === 'string' && values.trim() !== '') {
          acc[backendKey] = values;
        }

        return acc;
      },
      {} as Record<string, string>
    );

    console.log('Filters:', filters);

    try {
      const response = await axios.get(
        `${apiRoute}/global-job-board/job-listings`,
        {
          params: {
            ...search,
            ...queryFilters,
            limit,
            offset: newOffset,
            sort: currentSort,
          },
        }
      );

      const { jobListings: fetchedListings, totalJobCount } = response.data;

      if (totalJobCount === 0) {
        setNoResults(true);
      }

      setJobListings(
        newOffset === 0 ? fetchedListings : [...jobListings, ...fetchedListings]
      );
      setJobCount(totalJobCount);
      setOffset(newOffset);
    } catch (error) {
      console.error('Error fetching job listings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('useEffect Triggered:', { searchParams, filters, sort });
    fetchJobListings(0, searchParams, filters, sort);
  }, [searchParams, filters, sort]);

  const loadMoreJobs = () => {
    fetchJobListings(offset + limit);
  };

  const searchJobs = (jobTitle: string, location: string) => {
    console.log('Search Params Updated:', { jobTitle, location });
    setSearchParams({ job_title: jobTitle, location });
    setOffset(0);
  };

  const applyFilters = (newFilters: Record<string, string[] | string>) => {
    console.log('Applying Filters:', newFilters);
    setFilters(newFilters);
    setOffset(0);
  };

  const handleSortChange = (newSort: string) => {
    setSort(newSort);
    setOffset(0);
  };

  return (
    <JobListingsContext.Provider
      value={{
        jobListings,
        jobCount,
        isLoading,
        noResults,
        loadMoreJobs,
        searchJobs,
        applyFilters,
        setSort: handleSortChange,
      }}
    >
      {children}
    </JobListingsContext.Provider>
  );
};

export const useJobListings = (): JobListingsContextType => {
  const context = useContext(JobListingsContext);
  if (!context) {
    throw new Error('useJobListings must be used within a JobListingsProvider');
  }

  return context;
};
