import React from 'react';

export const DownloadIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M5.33325 11.8334L7.99992 14.5L10.6666 11.8334"
          stroke="#E94E3C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8 8.5V14.5"
          stroke="#E94E3C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M13.92 12.56C14.4996 12.1524 14.9342 11.5707 15.1608 10.8994C15.3874 10.2281 15.3942 9.50197 15.1802 8.82651C14.9662 8.15104 14.5425 7.56135 13.9707 7.143C13.3988 6.72464 12.7085 6.4994 12 6.49996H11.16C10.9594 5.71854 10.5843 4.99279 10.0627 4.37734C9.54112 3.7619 8.88672 3.2728 8.14876 2.94686C7.4108 2.62092 6.60852 2.46664 5.80231 2.49563C4.99609 2.52463 4.20696 2.73614 3.49432 3.11424C2.78168 3.49234 2.16411 4.02719 1.68808 4.67851C1.21206 5.32983 0.88999 6.08065 0.746129 6.87446C0.602269 7.66826 0.640362 8.48435 0.857542 9.2613C1.07472 10.0383 1.46533 10.7558 1.99996 11.36"
          stroke="#E94E3C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
