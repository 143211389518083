import React, { useState } from 'react';
import { DownArrow } from '../../../../icons/down-arrow';
import { ArrowUp } from '../../../../icons/arrow-up';
import {
  FeedbackContainer,
  TabButtons,
  TabBtn,
  List,
  Item,
  Heading6,
  Heading3,
  Paragraph,
  ViewBtn,
  ListWrapper,
  SortButton,
  ButtonContainer,
  Date,
  ItemUpContent,
  ButtonsContainer,
  BoxWrapper,
} from '../employee-performance-tab.styled';

type FeedbackType = {
  title: string;
  date: string;
  feedback: string;
};

export const FeedbackComponent = () => {
  const [selectedFeedbackTab, setSelectedFeedbackTab] =
    useState<string>('manager_feedback');
  const [showAllFeedback, setShowAllFeedback] = useState<boolean>(false);
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const handleSelectedTab = (tabName: string) => {
    setSelectedFeedbackTab(tabName);
  };

  const handleSortToggle = () => {
    setSortAscending(!sortAscending);
  };

  const feedbackItems = (): FeedbackType[] => {
    const feedback: Record<string, FeedbackType[]> = {
      manager_feedback: [
        {
          title: 'Name of Manager',
          date: '26.05.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Manager',
          date: '13.04.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Manager',
          date: '15.06.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
      ],
      peer_feedback: [
        {
          title: 'Name of Peer 1',
          date: '15.06.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Peer 2',
          date: '07.07.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Peer 3',
          date: '06.07.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
      ],
      customer_feedback: [
        {
          title: 'Name of Customer 1',
          date: '05.06.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Customer 2',
          date: '15.06.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Customer 3',
          date: '04.06.2024',
          feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
      ],
    };

    if (selectedFeedbackTab && feedback[selectedFeedbackTab]) {
      const selectedFeedback = feedback[selectedFeedbackTab];
      //This is example logic, there is a problem if the date is not a string, i cant use it as new Date, this needs to be implemented when connecting with the backend

      return sortAscending ? selectedFeedback : [...selectedFeedback].reverse();
    }

    return [];
  };

  const visibleFeedbackItems = showAllFeedback
    ? feedbackItems()
    : feedbackItems().slice(0, 2);

  return (
    <FeedbackContainer>
      <Heading3>Feedback and Reviews</Heading3>
      <BoxWrapper>
        <ButtonsContainer>
          <TabButtons>
            <TabBtn
              active={selectedFeedbackTab === 'manager_feedback'}
              onClick={() => handleSelectedTab('manager_feedback')}
            >
              Manager Feedback
            </TabBtn>
            <TabBtn
              active={selectedFeedbackTab === 'peer_feedback'}
              onClick={() => handleSelectedTab('peer_feedback')}
            >
              Peer Feedback
            </TabBtn>
            <TabBtn
              active={selectedFeedbackTab === 'customer_feedback'}
              onClick={() => handleSelectedTab('customer_feedback')}
            >
              Client or Customer Feedback
            </TabBtn>
          </TabButtons>
          <ButtonContainer>
            <Paragraph>Sort by:</Paragraph>
            <SortButton onClick={handleSortToggle}>
              {sortAscending ? <>Date Descending</> : <>Date Ascending</>}
            </SortButton>
          </ButtonContainer>
        </ButtonsContainer>
        {feedbackItems().length > 0 && (
          <ListWrapper>
            <List>
              {visibleFeedbackItems.map((item, index) => (
                <Item key={index}>
                  <ItemUpContent>
                    <Heading6>{item.title}</Heading6>
                    <Date>{item.date}</Date>
                  </ItemUpContent>
                  <Paragraph>{item.feedback}</Paragraph>
                </Item>
              ))}
            </List>

            {feedbackItems().length > 2 && (
              <ViewBtn onClick={() => setShowAllFeedback(!showAllFeedback)}>
                {showAllFeedback ? 'Show less' : 'View all'}
                {showAllFeedback ? <ArrowUp /> : <DownArrow />}
              </ViewBtn>
            )}
          </ListWrapper>
        )}
      </BoxWrapper>
    </FeedbackContainer>
  );
};
