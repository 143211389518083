import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { apiRoute } from './constants/api-constants';

interface Notification {
  id: number;
  entityId: string;
  entityType: string;
  title: string;
  message: string;
  read: boolean;
}

const NotificationTesting: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotification, setNewNotification] = useState<
    Omit<Notification, 'id' | 'read'>
  >({
    entityId: '',
    entityType: '',
    title: '',
    message: '',
  });

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get<Notification[]>(
        `${apiRoute}/notifications`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      toast.error('Error fetching notifications.');
    }
  };

  const createNotification = async () => {
    try {
      const response = await axios.post<Notification>(
        `${apiRoute}/notifications`,
        newNotification
      );
      setNotifications([...notifications, response.data]);
      setNewNotification({
        entityId: '',
        entityType: '',
        title: '',
        message: '',
      });
      toast.success('Notification created successfully.');
    } catch (error) {
      console.error('Error creating notification:', error);
      toast.error('Error creating notification.');
    }
  };

  const updateNotification = async (
    id: number,
    updatedData: Partial<Notification>
  ) => {
    try {
      const response = await axios.put<Notification>(
        `${apiRoute}/notifications/${id}`,
        updatedData
      );
      setNotifications(
        notifications.map((notification) =>
          notification.id === id ? response.data : notification
        )
      );
      toast.success('Notification updated successfully.');
    } catch (error) {
      console.error('Error updating notification:', error);
      toast.error('Error updating notification.');
    }
  };

  const deleteNotification = async (id: number) => {
    try {
      await axios.delete(`${apiRoute}/notifications/${id}`);
      setNotifications(
        notifications.filter((notification) => notification.id !== id)
      );
      toast.success('Notification deleted successfully.');
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast.error('Error deleting notification.');
    }
  };

  const markAsRead = async (id: number) => {
    try {
      await axios.put(`${apiRoute}/notifications/${id}/read`);
      setNotifications(
        notifications.map((notification) =>
          notification.id === id
            ? { ...notification, read: true }
            : notification
        )
      );
      toast.success('Notification marked as read.');
    } catch (error) {
      console.error('Error marking notification as read:', error);
      toast.error('Error marking notification as read.');
    }
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof Omit<Notification, 'id' | 'read'>
  ) => {
    setNewNotification({
      ...newNotification,
      [field]: e.target.value,
    });
  };

  return (
    <div>
      <h1>Notifications</h1>
      <div>
        <h2>Create Notification</h2>
        <input
          type="text"
          placeholder="Entity ID"
          value={newNotification.entityId}
          onChange={(e) => handleInputChange(e, 'entityId')}
        />
        <input
          type="text"
          placeholder="Entity Type"
          value={newNotification.entityType}
          onChange={(e) => handleInputChange(e, 'entityType')}
        />
        <input
          type="text"
          placeholder="Title"
          value={newNotification.title}
          onChange={(e) => handleInputChange(e, 'title')}
        />
        <input
          type="text"
          placeholder="Message"
          value={newNotification.message}
          onChange={(e) => handleInputChange(e, 'message')}
        />
        <button onClick={createNotification}>Create</button>
      </div>

      <ul>
        {notifications.map((notification) => (
          <li key={notification.id}>
            <h3>{notification.title}</h3>
            <p>{notification.message}</p>
            <p>{notification.read ? 'Read' : 'Unread'}</p>
            <button onClick={() => markAsRead(notification.id)}>
              Mark as Read
            </button>
            <button
              onClick={() =>
                updateNotification(notification.id, {
                  title: 'Updated Title',
                  message: 'Updated Message',
                })
              }
            >
              Update
            </button>
            <button onClick={() => deleteNotification(notification.id)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationTesting;
