import React from 'react';
import { JobListing } from '../global-job-board-featured-jobs-types';
import {
  StyledGlobalJobBoardJobListingInformationsSeparator,
  StyledGlobalJobBoardJustLandedListingCompanyInfo,
  StyledGlobalJobBoardJustLandedListingJobDetails,
  StyledGlobalJobBoardJustLandedListingLogo,
  StyledGlobalJobBoardJustLandedListingLogoAndCompanyInfo,
  StyledGlobalJobBoardJustLandedListingMainWrapper,
} from './global-job-board-featured-jobs-listing.styled';
import { Text, font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import MissingLogoPlaceholder from '../../../../../../assets/images/no_logo.jpg';
import { NavigateFunction } from 'react-router-dom';

interface GlobalJobBoardJustLandedListingProps {
  jobListing: JobListing;
  backgroundColor: string;
  navigate: NavigateFunction;
}

const GlobalJobBoardJustLandedListing: React.FC<
  GlobalJobBoardJustLandedListingProps
> = ({ jobListing, backgroundColor, navigate }) => {
  return (
    <StyledGlobalJobBoardJustLandedListingMainWrapper
      backgroundColor={backgroundColor}
      onClick={() => navigate(`/global/${jobListing.id}`)}
    >
      <StyledGlobalJobBoardJustLandedListingLogoAndCompanyInfo>
        <StyledGlobalJobBoardJustLandedListingLogo
          src={
            jobListing.companyLogo && jobListing.companyLogo.trim() !== ''
              ? jobListing.companyLogo
              : MissingLogoPlaceholder
          }
        />
        <StyledGlobalJobBoardJustLandedListingCompanyInfo>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={600}
            lineHeight="19.5px"
            color={colors.base_black}
            margin="0"
          >
            {jobListing.company}
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={14}
            fontWeight={400}
            lineHeight="17.07px"
            color={colors.neutral_900}
            margin="0"
          >
            {jobListing.city}
          </Text>
        </StyledGlobalJobBoardJustLandedListingCompanyInfo>
      </StyledGlobalJobBoardJustLandedListingLogoAndCompanyInfo>
      <StyledGlobalJobBoardJustLandedListingCompanyInfo>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={600}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          {jobListing.title}
        </Text>
        <StyledGlobalJobBoardJustLandedListingJobDetails>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={12}
            fontWeight={500}
            lineHeight="14.63px"
            color={colors.persianGreen}
            margin="0"
          >
            {jobListing.location}
          </Text>
          {jobListing.salary && jobListing.salary.trim() !== '' && (
            <>
              <StyledGlobalJobBoardJobListingInformationsSeparator>
                |
              </StyledGlobalJobBoardJobListingInformationsSeparator>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={12}
                fontWeight={400}
                lineHeight="14.63px"
                color={colors.neutral_800}
                margin="0"
              >
                {jobListing.salary}
              </Text>
            </>
          )}
        </StyledGlobalJobBoardJustLandedListingJobDetails>
      </StyledGlobalJobBoardJustLandedListingCompanyInfo>
    </StyledGlobalJobBoardJustLandedListingMainWrapper>
  );
};

export default GlobalJobBoardJustLandedListing;
