import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Company } from '../../../../app-router/component-props';
import {
  Education,
  EmployeeCVData,
  WorkExperience,
} from '../../hooks/employee-company-cv-data-types';
import {
  StyledCompanyCVEditComponentWrapper,
  StyledCompanyCVEditProfilePictureWrapper,
  StyledCompanyCVEditProfilePicture,
  StyledCompanyCVEditProfilePictureButtonAndDesc,
  StyledCompanyCVEditRemoveProfilePicture,
  StyledCompanyCVEditEmployeeInfoSectionWrapper,
  StyledCompanyCVEditEmployeeInfoSectionGridWrapper,
  StyledCompanyCVEditInputAndLabelWrapper,
  StyledCompanyCVEditInput,
  StyledCompanyCVEditSelect,
  StyledComponentCVEditProfessionalSummaryTextArea,
  StyledComponentCVEditorGridTwoInputsWrapper,
  StyledCompanyCVEditAddNewButton,
  StyledExperienceItemsWrapper,
  StyledCompanyCVEditResponsibilitiesInputAndBtnWrapper,
  StyledCompanyCVEditAddNewResponsibilityButton,
  StyledSkillsAndToolsWrapper,
  StyledSkillsAndToolsListWrapper,
  StyledSkillAndLToolItem,
  StyledSkillAndToolDeleteIconBtn,
  StyledExperienceTitleAndRemoveBtnWrapper,
  StyledCompanyCVEditResponsibilityAndDeleteBtn,
  StyledCompanyCVEditResponsibilityDeleteBtnWrapper,
  StyledUploadProfilePictureHiddenInput,
} from './company-cv-edit-component.styled';
import UserDemoImage from '../../../../../assets/images/img_avatar.png';
import axios from 'axios';
import { apiRoute } from '../../../../../constants/api-constants';
import { EmployeeJobData } from '../../../../../interfaces/employee-job-interfaces';
import { Text, font } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import { PlusButton } from '../../../../icons/plus-icon';
import {
  StyledCategoryContentListItem,
  StyledCategoryContentListItemsWrapper,
} from '../company-cv-component/company-cv-component.styled';
import { CloseIcon } from '../../../../icons/close-icon';
import { DeleteIcon } from '../../../../icons/delete-icon';
import {
  StyledCompanyCVEditAndExportBtnWrapper,
  StyledExportEditBackBtn,
} from '../../company-cv.styled';
import { EditIcon } from '../../../../icons/edit-icon-smaller';

interface CompanyCvEditComponentProps {
  company: Company;
  employeeCVData: EmployeeCVData;
  setEdit: Dispatch<SetStateAction<boolean>>;
  updateField: <K extends keyof EmployeeCVData>(
    field: K,
    value: EmployeeCVData[K]
  ) => void;
  updateWorkExperience: (
    index: number,
    updatedExperience: WorkExperience
  ) => void;
  addNewWorkExperience: () => void;
  addWorkExperienceResponsibility: (
    index: number,
    newResponsibility: string
  ) => void;
  editWorkExperienceResponsibility: (
    experienceIndex: number,
    responsibilityIndex: number,
    updatedResponsibility: string
  ) => void;
  deleteWorkExperience: (index: number) => void;
  deleteResponsibility: (
    experienceIndex: number,
    responsibilityIndex: number
  ) => void;
  addSkill: (newSkill: string) => void;
  deleteSkill: (index: number) => void;
  addTool: (newTool: string) => void;
  deleteTool: (index: number) => void;
  addEducation: () => void;
  updateEducation: (index: number, updatedEducation: Education) => void;
  deleteEducation: (index: number) => void;
  addEducationDetail: (index: number, newDetail: string) => void;
  updateEducationDetail: (
    educationIndex: number,
    detailIndex: number,
    updatedDetail: string
  ) => void;
  deleteEducationDetail: (educationIndex: number, detailIndex: number) => void;
  newProfilePicture: File | null;
  setNewProfilePicture: React.Dispatch<React.SetStateAction<File | null>>;
  deleteProfilePicture: () => void;
}

const CompanyCvEditComponent: React.FC<CompanyCvEditComponentProps> = ({
  employeeCVData,
  company,
  setEdit,
  updateField,
  updateWorkExperience,
  addNewWorkExperience,
  addWorkExperienceResponsibility,
  editWorkExperienceResponsibility,
  deleteWorkExperience,
  deleteResponsibility,
  addSkill,
  deleteSkill,
  addTool,
  deleteTool,
  addEducation,
  updateEducation,
  deleteEducation,
  addEducationDetail,
  updateEducationDetail,
  deleteEducationDetail,
  newProfilePicture,
  setNewProfilePicture,
  deleteProfilePicture,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [jobs, setJobs] = useState<EmployeeJobData[]>([]);
  const [selectedJob, setSelectedJob] = useState<string>(
    employeeCVData.jobTitle || ''
  );
  //   const [responsibilityInput, setResponsibilityInput] = useState<string>('');
  const [responsibilityInputs, setResponsibilityInputs] = useState<string[]>(
    employeeCVData.workExperience.map(() => '')
  );

  const [skillInput, setSkillInput] = useState<string>('');
  const [toolInput, setToolInput] = useState<string>('');
  //   const [educationDetailInput, setEducationDetailInput] = useState<string>('');
  const [educationDetailInputs, setEducationDetailInputs] = useState<string[]>(
    employeeCVData.education.map(() => '')
  );

  const fetchJobs = async () => {
    axios
      .get(`${apiRoute}/employee_job_positions/${company.id}`)
      .then((response) => {
        setJobs(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching company jobs:', error);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, [company.id]);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleJobChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedJob(event.target.value);
    updateField('jobTitle', event.target.value);
  };

  const handleInputChange =
    (field: keyof EmployeeCVData) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateField(field, event.target.value);
    };

  const handleWorkExperienceChange =
    (index: number, field: keyof WorkExperience) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedExperience: WorkExperience = {
        ...employeeCVData.workExperience[index],
        [field]: event.target.value,
      };
      updateWorkExperience(index, updatedExperience);
    };

  const handleResponsibilityInputChange =
    (experienceIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedInputs = [...responsibilityInputs];
      updatedInputs[experienceIndex] = event.target.value;
      setResponsibilityInputs(updatedInputs);
    };

  const handleAddResponsibility = (experienceIndex: number) => {
    const newResponsibility = responsibilityInputs[experienceIndex];
    if (newResponsibility.trim()) {
      addWorkExperienceResponsibility(experienceIndex, newResponsibility);
      const updatedInputs = [...responsibilityInputs];
      updatedInputs[experienceIndex] = '';
      setResponsibilityInputs(updatedInputs);
    }
  };

  const handleEditResponsibility =
    (experienceIndex: number, responsibilityIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedResponsibility = event.target.value;
      editWorkExperienceResponsibility(
        experienceIndex,
        responsibilityIndex,
        updatedResponsibility
      );
    };

  const handleSkillKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && skillInput.trim()) {
      addSkill(skillInput);
      setSkillInput('');
    }
  };

  const handleToolKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && toolInput.trim()) {
      addTool(toolInput);
      setToolInput('');
    }
  };

  const handleDeleteSkill = (index: number) => {
    deleteSkill(index);
  };

  const handleDeleteTool = (index: number) => {
    deleteTool(index);
  };

  const handleAddEducationDetail = (index: number) => {
    if (educationDetailInputs[index].trim()) {
      addEducationDetail(index, educationDetailInputs[index]);
      const updatedInputs = [...educationDetailInputs];
      updatedInputs[index] = '';
      setEducationDetailInputs(updatedInputs);
    }
  };

  const handleEducationDetailInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedInputs = [...educationDetailInputs];
      updatedInputs[index] = event.target.value;
      setEducationDetailInputs(updatedInputs);
    };

  const handleEditEducationDetail =
    (educationIndex: number, detailIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedDetail = event.target.value;
      updateEducationDetail(educationIndex, detailIndex, updatedDetail);
    };

  const handleDeleteEducationDetail = (
    educationIndex: number,
    detailIndex: number
  ) => {
    deleteEducationDetail(educationIndex, detailIndex);
  };

  return (
    <StyledCompanyCVEditComponentWrapper>
      <StyledCompanyCVEditProfilePictureWrapper>
        <StyledCompanyCVEditAndExportBtnWrapper>
          <StyledExportEditBackBtn
            onClick={() => {
              setEdit(false);
            }}
          >
            <Text
              fontFamily={font.family}
              fontSize={16}
              fontWeight={400}
              color={colors.neutral_900}
              margin="0"
            >
              Close Edit
            </Text>
            <EditIcon />
          </StyledExportEditBackBtn>
        </StyledCompanyCVEditAndExportBtnWrapper>
        <StyledCompanyCVEditProfilePicture
          onClick={handleImageClick}
          src={
            newProfilePicture
              ? URL.createObjectURL(newProfilePicture)
              : employeeCVData.profilePicture || UserDemoImage
          }
        />
        <StyledUploadProfilePictureHiddenInput
          type="file"
          name="profile_picture"
          ref={fileInputRef}
          onChange={(e) => {
            if (e.currentTarget.files) {
              setNewProfilePicture(e.currentTarget.files[0]);
            }
          }}
          accept="image/*"
        />
        <StyledCompanyCVEditProfilePictureButtonAndDesc>
          Select or Drop your images here
          <StyledCompanyCVEditRemoveProfilePicture
            onClick={deleteProfilePicture}
          >
            Remove
          </StyledCompanyCVEditRemoveProfilePicture>
        </StyledCompanyCVEditProfilePictureButtonAndDesc>
      </StyledCompanyCVEditProfilePictureWrapper>
      {/* Personal information */}
      <StyledCompanyCVEditEmployeeInfoSectionWrapper>
        <StyledCompanyCVEditEmployeeInfoSectionGridWrapper>
          <StyledCompanyCVEditInputAndLabelWrapper>
            First and Last name
            <StyledCompanyCVEditInput
              type="text"
              placeholder="Enter name"
              value={employeeCVData.name}
              onChange={handleInputChange('name')}
            />
          </StyledCompanyCVEditInputAndLabelWrapper>
          <StyledCompanyCVEditInputAndLabelWrapper>
            Select job
            <StyledCompanyCVEditSelect
              placeholder="Select a job position"
              value={selectedJob}
              onChange={handleJobChange}
            >
              <option value={employeeCVData.jobTitle || ''}>
                {employeeCVData.jobTitle || 'Select a Job Title'}
              </option>
              {jobs.map((job) => (
                <option key={job.id} value={job.job_position}>
                  {job.job_position}
                </option>
              ))}
            </StyledCompanyCVEditSelect>
          </StyledCompanyCVEditInputAndLabelWrapper>
        </StyledCompanyCVEditEmployeeInfoSectionGridWrapper>
        <StyledCompanyCVEditInputAndLabelWrapper>
          Professional Summary
          <StyledComponentCVEditProfessionalSummaryTextArea
            value={employeeCVData.professionalSummary}
            placeholder="Enter Professional Summary"
            onChange={handleInputChange('professionalSummary')}
          />
        </StyledCompanyCVEditInputAndLabelWrapper>
      </StyledCompanyCVEditEmployeeInfoSectionWrapper>
      {/* Personal information END */}
      {/* Work Experience */}
      <StyledCompanyCVEditEmployeeInfoSectionWrapper>
        <Text
          fontFamily={font.family}
          fontSize={24}
          fontWeight={700}
          color={colors.black}
          margin="0"
        >
          Work Experience
        </Text>
        {employeeCVData.workExperience.map((experience, experienceIndex) => (
          <StyledExperienceItemsWrapper
            key={experienceIndex}
            index={experienceIndex}
          >
            <StyledCompanyCVEditInputAndLabelWrapper>
              <StyledExperienceTitleAndRemoveBtnWrapper>
                <Text
                  fontFamily={font.family}
                  fontSize={24}
                  fontWeight={700}
                  color={colors.black}
                  margin="0"
                >
                  Company
                </Text>
                <StyledSkillAndToolDeleteIconBtn
                  onClick={() => deleteWorkExperience(experienceIndex)}
                >
                  <CloseIcon color={colors.error} />
                </StyledSkillAndToolDeleteIconBtn>
              </StyledExperienceTitleAndRemoveBtnWrapper>
              <StyledCompanyCVEditInput
                type="text"
                placeholder="Enter Company name"
                value={experience.company}
                onChange={handleWorkExperienceChange(
                  experienceIndex,
                  'company'
                )}
              />
            </StyledCompanyCVEditInputAndLabelWrapper>
            <StyledComponentCVEditorGridTwoInputsWrapper>
              <StyledCompanyCVEditInputAndLabelWrapper>
                Job Position
                <StyledCompanyCVEditInput
                  type="text"
                  placeholder="Enter Job Position"
                  value={experience.role}
                  onChange={handleWorkExperienceChange(experienceIndex, 'role')}
                />
              </StyledCompanyCVEditInputAndLabelWrapper>
              <StyledCompanyCVEditInputAndLabelWrapper>
                Duration
                <StyledCompanyCVEditInput
                  type="text"
                  placeholder="Enter Duration"
                  value={experience.dateRange}
                  onChange={handleWorkExperienceChange(
                    experienceIndex,
                    'dateRange'
                  )}
                />
              </StyledCompanyCVEditInputAndLabelWrapper>
            </StyledComponentCVEditorGridTwoInputsWrapper>
            <StyledCompanyCVEditResponsibilitiesInputAndBtnWrapper>
              <StyledCompanyCVEditInput
                type="text"
                placeholder="Add new Responsibility"
                value={responsibilityInputs[experienceIndex] || ''}
                onChange={handleResponsibilityInputChange(experienceIndex)}
              />
              <StyledCompanyCVEditAddNewResponsibilityButton
                onClick={() => handleAddResponsibility(experienceIndex)}
              >
                Add
              </StyledCompanyCVEditAddNewResponsibilityButton>
            </StyledCompanyCVEditResponsibilitiesInputAndBtnWrapper>
            <StyledCategoryContentListItemsWrapper>
              {experience.responsibilities.map(
                (responsibility, responsibilityIndex) => (
                  <StyledCategoryContentListItem key={responsibilityIndex}>
                    <StyledCompanyCVEditResponsibilityAndDeleteBtn>
                      <StyledCompanyCVEditInput
                        key={responsibilityIndex}
                        type="text"
                        placeholder="Add new Responsibility"
                        value={responsibility}
                        onChange={handleEditResponsibility(
                          experienceIndex,
                          responsibilityIndex
                        )}
                      />
                      <StyledCompanyCVEditResponsibilityDeleteBtnWrapper>
                        <StyledSkillAndToolDeleteIconBtn
                          onClick={() =>
                            deleteResponsibility(
                              experienceIndex,
                              responsibilityIndex
                            )
                          }
                        >
                          <DeleteIcon height="20px" width="20px" />
                        </StyledSkillAndToolDeleteIconBtn>
                      </StyledCompanyCVEditResponsibilityDeleteBtnWrapper>
                    </StyledCompanyCVEditResponsibilityAndDeleteBtn>
                  </StyledCategoryContentListItem>
                )
              )}
            </StyledCategoryContentListItemsWrapper>
          </StyledExperienceItemsWrapper>
        ))}
      </StyledCompanyCVEditEmployeeInfoSectionWrapper>
      <StyledCompanyCVEditAddNewButton onClick={addNewWorkExperience}>
        <PlusButton />
        Add New
      </StyledCompanyCVEditAddNewButton>
      {/* Work Experience END */}
      {/* Skills and Tools */}
      <StyledSkillsAndToolsWrapper>
        <StyledCompanyCVEditInputAndLabelWrapper>
          Skills
          <StyledCompanyCVEditInput
            type="text"
            placeholder="Add new skill"
            value={skillInput}
            onChange={(e) => setSkillInput(e.target.value)}
            onKeyDown={handleSkillKeyDown}
          />
        </StyledCompanyCVEditInputAndLabelWrapper>
        <StyledCompanyCVEditInputAndLabelWrapper>
          Tools
          <StyledCompanyCVEditInput
            type="text"
            placeholder="Add new tool"
            value={toolInput}
            onChange={(e) => setToolInput(e.target.value)}
            onKeyDown={handleToolKeyDown}
          />
        </StyledCompanyCVEditInputAndLabelWrapper>

        {/* Skills List */}
        <StyledSkillsAndToolsListWrapper>
          {employeeCVData.skills.map((skill, skillIndex) => (
            <StyledSkillAndLToolItem key={skillIndex}>
              {skill}
              <StyledSkillAndToolDeleteIconBtn
                onClick={() => handleDeleteSkill(skillIndex)}
              >
                <CloseIcon />
              </StyledSkillAndToolDeleteIconBtn>
            </StyledSkillAndLToolItem>
          ))}
        </StyledSkillsAndToolsListWrapper>

        {/* Tools List */}
        <StyledSkillsAndToolsListWrapper>
          {employeeCVData.tools.map((tool, toolIndex) => (
            <StyledSkillAndLToolItem key={toolIndex}>
              {tool}
              <StyledSkillAndToolDeleteIconBtn
                onClick={() => handleDeleteTool(toolIndex)}
              >
                <CloseIcon />
              </StyledSkillAndToolDeleteIconBtn>
            </StyledSkillAndLToolItem>
          ))}
        </StyledSkillsAndToolsListWrapper>
      </StyledSkillsAndToolsWrapper>
      {/* Skills and Tools END */}
      {/* Education */}
      <StyledCompanyCVEditEmployeeInfoSectionWrapper>
        <Text
          fontFamily={font.family}
          fontSize={24}
          fontWeight={700}
          color={colors.black}
          margin="0"
        >
          Education
        </Text>
        {employeeCVData.education.map((education, educationIndex) => (
          <StyledExperienceItemsWrapper
            key={educationIndex}
            index={educationIndex}
          >
            <StyledCompanyCVEditInputAndLabelWrapper>
              <StyledExperienceTitleAndRemoveBtnWrapper>
                <Text
                  fontFamily={font.family}
                  fontSize={24}
                  fontWeight={700}
                  color={colors.black}
                  margin="0"
                >
                  School, college, or university.
                </Text>
                <StyledSkillAndToolDeleteIconBtn
                  onClick={() => deleteEducation(educationIndex)}
                >
                  <CloseIcon color={colors.error} />
                </StyledSkillAndToolDeleteIconBtn>
              </StyledExperienceTitleAndRemoveBtnWrapper>
              <StyledCompanyCVEditInput
                type="text"
                placeholder="Enter name"
                value={education.institution}
                onChange={(e) =>
                  updateEducation(educationIndex, {
                    ...education,
                    institution: e.target.value,
                  })
                }
              />
            </StyledCompanyCVEditInputAndLabelWrapper>
            <StyledComponentCVEditorGridTwoInputsWrapper>
              <StyledCompanyCVEditInputAndLabelWrapper>
                Degree
                <StyledCompanyCVEditInput
                  type="text"
                  placeholder="Enter degree"
                  value={education.degree}
                  onChange={(e) =>
                    updateEducation(educationIndex, {
                      ...education,
                      degree: e.target.value,
                    })
                  }
                />
              </StyledCompanyCVEditInputAndLabelWrapper>
              <StyledCompanyCVEditInputAndLabelWrapper>
                Duration
                <StyledCompanyCVEditInput
                  type="text"
                  placeholder="Enter Duration"
                  value={education.dateRange}
                  onChange={(e) =>
                    updateEducation(educationIndex, {
                      ...education,
                      dateRange: e.target.value,
                    })
                  }
                />
              </StyledCompanyCVEditInputAndLabelWrapper>
            </StyledComponentCVEditorGridTwoInputsWrapper>
            <StyledCompanyCVEditResponsibilitiesInputAndBtnWrapper>
              <StyledCompanyCVEditInput
                type="text"
                placeholder="Add new Education Detail"
                value={educationDetailInputs[educationIndex] || ''}
                onChange={handleEducationDetailInputChange(educationIndex)}
              />
              <StyledCompanyCVEditAddNewResponsibilityButton
                onClick={() => handleAddEducationDetail(educationIndex)}
              >
                Add
              </StyledCompanyCVEditAddNewResponsibilityButton>
            </StyledCompanyCVEditResponsibilitiesInputAndBtnWrapper>
            <StyledCategoryContentListItemsWrapper>
              {education.details.map((detail, detailIndex) => (
                <StyledCategoryContentListItem key={detailIndex}>
                  <StyledCompanyCVEditResponsibilityAndDeleteBtn>
                    <StyledCompanyCVEditInput
                      key={detailIndex}
                      type="text"
                      placeholder="Add new detail"
                      value={detail}
                      onChange={handleEditEducationDetail(
                        educationIndex,
                        detailIndex
                      )}
                    />
                    <StyledCompanyCVEditResponsibilityDeleteBtnWrapper>
                      <StyledSkillAndToolDeleteIconBtn
                        onClick={() =>
                          handleDeleteEducationDetail(
                            educationIndex,
                            detailIndex
                          )
                        }
                      >
                        <DeleteIcon height="20px" width="20px" />
                      </StyledSkillAndToolDeleteIconBtn>
                    </StyledCompanyCVEditResponsibilityDeleteBtnWrapper>
                  </StyledCompanyCVEditResponsibilityAndDeleteBtn>
                </StyledCategoryContentListItem>
              ))}
            </StyledCategoryContentListItemsWrapper>
          </StyledExperienceItemsWrapper>
        ))}
      </StyledCompanyCVEditEmployeeInfoSectionWrapper>
      <StyledCompanyCVEditAddNewButton onClick={addEducation}>
        <PlusButton />
        Add New
      </StyledCompanyCVEditAddNewButton>
      {/* Education END */}
    </StyledCompanyCVEditComponentWrapper>
  );
};

export default CompanyCvEditComponent;
