import styled, { keyframes } from 'styled-components';
import { font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';

export const StyledGlobalJobBoardMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  font-family: ${font.familyMontserrat};
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 8px solid ${colors.grey_light || '#f3f3f3'};
  border-top: 8px solid ${colors.secondary_500 || '#3498db'};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
