import React from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';

interface TeamManagementProps {
  // eslint-disable-next-line
  user: any;
  // loading: boolean;
  // eslint-disable-next-line
  setUser: (user: any | null | undefined) => void;
  navigate?: NavigateFunction;
}

export const TeamManagement: React.FC<TeamManagementProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer>
              Team management
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
