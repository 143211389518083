import React, { useEffect } from 'react';
import {
  StyledGlobalJobBoardApplyCenterComponentsInnerWrapper,
  StyledGlobalJobBoardApplyCenterComponentsWrapper,
  StyledGlobalJobBoardApplyMainWrapper,
} from './global-job-board-apply.styled';
import { NavigateFunction, useLocation } from 'react-router-dom';
import GlobalJobBoardNavbar from '../components/navbar/global-job-board-navbar';
import { StyledGlobalJobBoardJobDetailsInfoBackBtn } from '../global-job-board-job-details/components/job-info/global-job-board-job-details-info.styled';
import { LeftArrowSettings } from '../../../icons/arrow-left-settings';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import GlobalJobBoardFooter from '../components/footer/global-job-board-footer';
import { GlobalJobBoardApplyForm } from './components/apply-form/global-job-board-apply-form';

interface GlobalJobBoardApplyTypes {
  // eslint-disable-next-line
    user: any;
  navigate: NavigateFunction;
}

const GlobalJobBoardApply: React.FC<GlobalJobBoardApplyTypes> = ({
  navigate,
  user,
}) => {
  const location = useLocation();
  const job = location.state?.job;

  useEffect(() => {
    if (!job) {
      console.error('Job data is missing!');
    }
  }, [job]);

  return (
    <StyledGlobalJobBoardApplyMainWrapper>
      <GlobalJobBoardNavbar navigate={navigate} user={user} />
      <StyledGlobalJobBoardApplyCenterComponentsWrapper>
        <StyledGlobalJobBoardApplyCenterComponentsInnerWrapper>
          <StyledGlobalJobBoardJobDetailsInfoBackBtn
            onClick={() => navigate(-1)}
          >
            <LeftArrowSettings />
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.neutral_400}
              margin="0"
            >
              Back
            </Text>
          </StyledGlobalJobBoardJobDetailsInfoBackBtn>
          <GlobalJobBoardApplyForm job={job} />
        </StyledGlobalJobBoardApplyCenterComponentsInnerWrapper>
      </StyledGlobalJobBoardApplyCenterComponentsWrapper>
      <GlobalJobBoardFooter navigate={navigate} />
    </StyledGlobalJobBoardApplyMainWrapper>
  );
};

export default GlobalJobBoardApply;
