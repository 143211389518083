// src/components/super-admin/super-admin-tab/SuperAdminTab.tsx

import React, { useEffect, useState } from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { SuperAdminSidebar } from '../../../layout/sidebar/super-admin-sidebar';
import { SuperAdmins } from './admins';
import { AdminModal } from './modal/modal';
import MessagePopup from '../../../messages/message-popup/message-popup';
import { Overlay } from '../../job-board/job-board.styled';
import { EmployeeData } from '../../../../interfaces/employee-interfaces';

interface SuperAdminTabProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;

  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const SuperAdminTab: React.FC<SuperAdminTabProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  // eslint-disable-next-line
  const [selectedAdmin, setSelectedAdmin] = useState<any | null>(null);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const openAddModal = () => {
    setIsEditMode(false);
    setSelectedAdmin(null);
    setIsModalOpen(true);
  };

  const openEditModal = (admin: EmployeeData) => {
    setIsEditMode(true);
    setSelectedAdmin(admin);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdmin(null);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <LayoutContainer height={height}>
      {isModalOpen && <Overlay />}

      <TopMenu
        navigationUrl={'/super-admin'}
        user={user}
        setUser={setUser}
        isSuperAdmin={user.account_type === 'superAdmin'}
      />

      <MainContentWrapper>
        <SuperAdminSidebar
          setUser={setUser}
          company={user}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <SuperAdmins openModal={openAddModal} onEdit={openEditModal} />
            {isModalOpen && (
              <AdminModal
                onClose={closeModal}
                setPopUp={setPopUp}
                isEdit={isEditMode}
                adminData={selectedAdmin || undefined}
              />
            )}
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </LayoutContainer>
  );
};
