import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const SidebarContainer = styled.div<{
  height?: number;
  fromPage?: string;
}>`
  width: 180px;
  /* width: 288px; */
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ fromPage }) =>
    fromPage === 'employee' ? 'space-between' : 'space-around'};
  background: rgb(253, 253, 253);
  height: fit-content;
  padding: 32px 16px 32px 0px;
  border-radius: 0px 16px 16px 0px;
  margin-top: 50px;
  height: ${({ fromPage }) =>
    fromPage === 'employee' ? '450px' : 'fit-content'};
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: 68px;
  gap: 22px;
`;

export const IconWrapper = styled.div<{ isHighlighted: boolean }>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: ${({ isHighlighted }) => isHighlighted && `rgb(248, 248, 254);`};
`;

export const DropDownContainer = styled.div`
  background: ${colors.primary_400};
  border-radius: 0px 9px 9px 0px;
`;

export const Dropdown = styled.div<{
  isActive?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: lightteal;
  padding: 0 26px;
  // border-radius: 8px;
  border-radius: 0px 8px 8px 0px;
  justify-content: space-between;
  width: 180px;
  background-color: ${(props) =>
    props.isActive ? colors.primary_400 : colors.white};
  color: ${(props) => (props.isActive ? 'white' : colors.grey_dark)};
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.isActive ? `${colors.primary_400}` : `${colors.primary_50}`};
  }
`;

// Dropdown link styled component
export const DropdownLink = styled.a<{ isGradientLink?: boolean }>`
  display: flex;
  align-items: center;
  color: ${colors.white};
  font-family: ${font.family};
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  height: 44px;
  width: 140px;
  background: ${colors.primary_400};

  background: ${({ isGradientLink }) =>
    isGradientLink &&
    `linear-gradient(90deg, #c92c23 0%, rgba(201, 44, 35, 0) 106%)`};

  border-radius: 8px;
  margin-left: 24px;
  margin-bottom: 10px;

  &:hover {
    background: ${colors.primary_300};
  }
`;

export const ButtonContainer = styled.button`
  background: ${colors.primary_400};
  width: fitContent;
  height: 43px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 600;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
`;

export const NameContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${font.family};
  font-size: 14;
  font-weight: 700;
  color: ${colors.primary_500};
  cursor: pointer;
  align-self: center;
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: center;
  white-space: normal;
`;
