import styled from 'styled-components';
import { colors } from '../../style-utils/colors';

export const InputAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const ContentButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 0 auto;
`;

export const TitleAndSubtitle = styled.div<{ direction: 'row' | 'column' }>`
  height: 66px;
  padding-bottom: 5%;
  display: flex;
  flex-direction: ${({ direction }) => direction && direction};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  flex: 2;
`;

export const LayoutContainer = styled.div<{ height: number }>`
  display: flex;
  max-height: ${({ height }) => height && `${height}px`};
  flex-direction: column;
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  background: ${colors.creme_100};
  height: calc(100svh - 88px);
  position: relative;
`;

export const ContentWrapper = styled.div<{
  dashboard?: boolean;
  bgColor?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: ${({ dashboard }) => (dashboard ? '0' : '32px')};
  background: ${({ bgColor }) => bgColor || 'none'};

  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ContentWrapperCandidates = styled.div<{
  dashboard?: boolean;
  bgColor?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* padding: ${({ dashboard }) => (dashboard ? '0' : '32px')}; */
  background: ${({ bgColor }) => bgColor || 'none'};
  padding: 32px;
  height: 100%;
`;

export const StepAndProgressContainer = styled.div<{ isMainMenu?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 40px;
  flex: 1;
  ${({ isMainMenu }) => !isMainMenu && `border-bottom: 1px solid lightgray;`}
  margin-bottom: 12px;
`;

export const Step = styled.div`
  height: 40px;
  width: 40px;
`;

export const Progress = styled.div`
  height: 40px;
  width: 200px;
`;

export const NavbarAndContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  background: #fdfdfd;
`;

export const NavbarAndContentTransparentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: transparent;
  padding: 30px 0;
  gap: 16px;
  padding-right: 40px;
`;

export const NavbarContainer = styled.div<{
  dashboard?: boolean;
  isMainMenu?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: ${({ dashboard }) => (dashboard ? '2' : '4')};
  max-width: ${({ dashboard }) => (dashboard ? '240px' : 'inherit')};
  align-items: inherit;
  justify-content: space-between;
  background: ${({ isMainMenu }) => (isMainMenu ? 'transparent' : '#f8f8fe')};
`;

export const ContentAndButtonsContainer = styled.div<{
  dashboard?: boolean;
  bgColor?: string;
  alignItems?: string;
}>`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex: 6;
  padding: ${({ dashboard }) => (dashboard ? '0px' : '0px 32px 0px 32px')};
  background: ${({ bgColor }) => bgColor || `${colors.dashboard_light_grey}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
`;
