import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  Legend,
} from 'recharts';
import {
  StyledChartsMainWrapper,
  StyledMultiLineChartTitleAndTabsWrapper,
} from '../charts.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { ReportingBarAndMultiLineChartDataTypes } from '../charts-data-types';
import MultiLineChartTabs from './components/multi-line-chart-tabs/multi-line-chart-tabs';

interface MultiLineChartProps {
  name: string;
  multiLineChartData: ReportingBarAndMultiLineChartDataTypes[];
}

const ReportingMultiLineChart: React.FC<MultiLineChartProps> = ({
  name,
  multiLineChartData,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <StyledChartsMainWrapper>
      <StyledMultiLineChartTitleAndTabsWrapper>
        <Text
          fontSize={24}
          fontFamily={font.family}
          color={colors.base_black}
          fontWeight={700}
          margin="0"
        >
          {name}
        </Text>
        <MultiLineChartTabs
          tabs={['Monthly', 'Quarteerly', 'Yearly']}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </StyledMultiLineChartTitleAndTabsWrapper>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={multiLineChartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            contentStyle={{
              backgroundColor: '#fff',
              border: 'none',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
            cursor={false}
          />
          <Legend verticalAlign="bottom" height={36} />
          {/* Area fill for Avg */}
          <Area
            type="monotone"
            dataKey="avg"
            stroke="none"
            fill="rgba(0, 183, 238, 0.3)"
          />
          {/* Dashed line for Avg */}
          <Line
            type="monotone"
            dataKey="avg"
            stroke="#00b7ee"
            strokeWidth={3}
            dot={false}
            strokeDasharray="5 5"
          />
          {/* Dashed line for Max */}
          <Line
            type="monotone"
            dataKey="max"
            stroke="#e74c3c"
            strokeWidth={3}
            dot={false}
            strokeDasharray="5 5"
          />
          {/* Dashed line for Min */}
          <Line
            type="monotone"
            dataKey="min"
            stroke="#3b6ebf"
            strokeWidth={3}
            dot={false}
            strokeDasharray="5 5"
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledChartsMainWrapper>
  );
};

export default ReportingMultiLineChart;
