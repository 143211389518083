import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  StyledCompanyComponentDateWrapper,
  StyledCompanyComponentImage,
  StyledCompanyComponentImageAndNameWrapper,
  StyledTopCompaniesComponentMainWrapper,
  StyledTopCompanyComponentWrapper,
} from '../home.styled';
import logoTeam from '../../../../logo_team.png';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { apiRoute } from '../../../../constants/api-constants';

interface Props {
  fromPage: 'latest' | 'top' | 'lastPayment' | 'companyOwes';
}

const CompaniesList: React.FC<Props> = ({ fromPage }) => {
  const baseURL = apiRoute;
  const [companies, setCompanies] = useState<
    {
      name: string;
      createdAt?: string;
      employeeCount?: number;
      date?: string;
      waitingDays?: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        if (fromPage === 'latest' || fromPage === 'top') {
          const endpoint =
            fromPage === 'latest'
              ? `${baseURL}/super-admin/dashboard/recent-companies`
              : `${baseURL}/super-admin/dashboard/top-companies`;

          const response = await axios.get(endpoint);
          setCompanies(response.data);
        } else {
          const data = {
            lastPayment: [
              { name: 'Adobe Inc', date: '29/07/2024' },
              { name: 'Microsoft', date: '29/06/2024' },
              { name: 'Google Inc', date: '29/05/2024' },
            ],
            companyOwes: [
              { name: 'Adobe Inc', waitingDays: 15 },
              { name: 'Microsoft', waitingDays: 10 },
              { name: 'Google Inc', waitingDays: 5 },
            ],
          };
          setCompanies(data[fromPage]);
        }
      } catch (error) {
        console.error(`Error fetching ${fromPage} companies:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, [fromPage, baseURL]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    return date.toLocaleDateString('en-GB'); // Format: DD/MM/YYYY
  };

  if (loading) {
    return (
      <StyledTopCompaniesComponentMainWrapper>
        <Text
          fontFamily={font.family}
          fontSize={22}
          fontWeight={700}
          color={colors.black}
          margin="0"
        >
          Loading...
        </Text>
      </StyledTopCompaniesComponentMainWrapper>
    );
  }

  return (
    <StyledTopCompaniesComponentMainWrapper>
      <Text
        fontFamily={font.family}
        fontSize={22}
        fontWeight={700}
        color={colors.black}
        margin="0"
      >
        {fromPage === 'latest'
          ? 'Latest Registered Companies'
          : fromPage === 'top'
          ? 'Top Rising Companies'
          : fromPage === 'lastPayment'
          ? 'Last payment'
          : 'Company owes'}
      </Text>

      {companies.length === 0 ? (
        <Text
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
          color={colors.base_black}
          margin="20px 0"
        >
          No companies found.
        </Text>
      ) : (
        companies.map((company, index) => (
          <StyledTopCompanyComponentWrapper
            key={index}
            backgroundColor={
              fromPage === 'companyOwes'
                ? colors.primary_50
                : fromPage === 'lastPayment'
                ? colors.secondary_50
                : fromPage === 'latest'
                ? colors.neutral_50
                : fromPage === 'top'
                ? colors.secondary_50
                : undefined
            }
          >
            <StyledCompanyComponentImageAndNameWrapper>
              <StyledCompanyComponentImage src={logoTeam} alt="icon" />
              <Text
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.base_black}
                margin="0"
              >
                {company.name}
              </Text>
            </StyledCompanyComponentImageAndNameWrapper>
            <StyledCompanyComponentDateWrapper
              backgroundColor={
                fromPage === 'companyOwes'
                  ? colors.primary_400
                  : colors.secondary_400
              }
            >
              <Text
                fontFamily={font.family}
                fontSize={12}
                fontWeight={700}
                color={colors.base_white}
                margin="0"
              >
                {fromPage === 'latest'
                  ? formatDate(company.createdAt || '')
                  : fromPage === 'top'
                  ? `${company.employeeCount} Employees`
                  : fromPage === 'lastPayment'
                  ? company.date
                  : `Waiting ${company.waitingDays} days...`}
              </Text>
            </StyledCompanyComponentDateWrapper>
          </StyledTopCompanyComponentWrapper>
        ))
      )}
    </StyledTopCompaniesComponentMainWrapper>
  );
};

export default CompaniesList;
