import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  ApplicantsContentWrapper,
  DownArrowContainer,
  EmployeeStyledArrowIconWrapper,
  EmployeeStyledArrowJobTitleWrapper,
  EmployeeStyledJobTitlesSelectWrapper,
  EmployeeStyledSelectJobTitleAndImgWrapper,
  HeadingWrapper,
  JobSelectMenuDiv,
  StyledCVButton,
  StyledCvAndCompanyCvButtonsWrapper,
  StyledEmployeeStatusAndIconWrapper,
  StyledEmployeesWrapper,
  StyledSelectMenu,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  WrapperDiv,
} from './employees.styled';
import { ButtonContainer } from '../../login/login.styled';
import { fetchAllItems } from '../../requests/requests';
import { EmployeeData } from '../../../interfaces/employee-interfaces';
import { Text, font } from '../../../style-utils/text';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../style-utils/colors';
import { DownArrow } from '../../icons/down-arrow';
import { EmployeeJobData } from '../../../interfaces/employee-job-interfaces';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import {
  ArrowIconContainer,
  StyledJobTitleOption,
  StyledJobTitlesSelectContentWrapper,
} from '../applicants/candidates/candidates.styled';
import { ChevronDownIcon } from '../../icons/chevron-down-icon';
import { TableEmployeeStatusColorCircleIcon } from '../../icons/table-employee-status-color-circle-icon';
import { DownloadEnabled } from '../../icons/download-enabled';
import { DownloadDisabled } from '../../icons/download-disabled';
import { useEmployeeCVData } from '../company-cv/hooks/use-employee-company-cv-data';
import LoadingSpinner from '../../icons/small-spinner';
import EmployeeAttachCvPortfolioModal from './employee-attach-cv-portfolio/employee-attach-cv-portfolio-modal';
import MessagePopup from '../../messages/message-popup/message-popup';
import EmployeesErrorGeneratingCompanyCvPopup from './employees-error-generating-company-cv-popup/employees-error-generating-company-cv-popup';
// import { useSelectedJob } from '../../../context/selected-job-context';
// import { usePagination } from '../../../hooks/use-pagination';
// import { PaginationButtons } from '../../../table-pagination/table-pagination';
// import { UpdatedApplicantStatusData } from '../../../interfaces/applicant-status-interfaces';
// import axios, { AxiosError } from 'axios';
// import { apiRoute } from '../../../constants/api-constants';

// eslint-disable-next-line
export const EmployeesTable = ({ company }: any) => {
  // const {
  //   selectedJob,
  // }: {
  //   selectedJob: JobOfferData | null | undefined;
  // } = useSelectedJob();
  const { fetchCVData, error } = useEmployeeCVData();

  const [employeesData, setEmployeesData] = useState<EmployeeData[]>([]);
  const [jobOffersData, setJobOffersData] = useState<EmployeeJobData[]>([]);
  const [employeeFilterValue, setEmployeeFilterValue] = useState('');
  const [showSelectJobTitle, setShowSelectJobTitle] = useState<number | null>(
    null
  );
  const [filterByStatusSelectedStatus, setFilterByStatusSelectedStatus] =
    useState<string>('all');
  const [loadingEmployeeId, setLoadingEmployeeId] = useState<string | null>(
    null
  );
  const [employeeIdToUploadCV, setEmployeeIdToUploadCV] = useState<
    string | null
  >(null);
  const [employeeDocumentTypeToUpload, setEmployeeDocumentTypeToUpload] =
    useState<'CV' | 'portfolio'>('CV');
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [
    errorGenerateingCompanyCvPopUpVisible,
    setErrorGenerateingCompanyCvPopUpVisible,
  ] = useState<boolean>(false);
  const [employeeIdToCreateCompanyCV, setEmployeeIdToCreateCompanyCV] =
    useState<string>();

  useEffect(() => {
    getEmployeesFromDB();
  }, [filterByStatusSelectedStatus]);

  const jobTitleDropdownRef = useRef<HTMLDivElement>(null);

  const employeeFilterFields = [
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Phone Number',
      value: 'phone_number',
    },
  ];

  const navigate = useNavigate();

  // const {
  //   currentPage,
  //   displayedData,
  //   nextPage,
  //   previousPage,
  //   hasPreviousPage,
  //   hasNextPage,
  //   getTotalPages,
  // } = usePagination(candidatesData);

  // const totalPages = getTotalPages();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // const getJobsFromDB = async () => {
  //   // eslint-disable-next-line
  //   let response: any = [];

  //   //This is a post request (but it's acctually a get req) to get only the job offers that are assigned to a specific company
  //   if (company.id) {
  //     response = await createItem(`${company.name}/hiring/job-offers`, company);

  //     if (response) {
  //       response = response.filter(
  //         (job: JobOfferData) => job.status === 'active'
  //       );
  //     }
  //   }
  //   setJobOffersData(response as JobOfferData[]);
  // };
  const baseURL = apiRoute;

  useEffect(() => {
    getJobsFromDB();
    getEmployeesFromDB();
  }, []);

  const getEmployeesFromDB = async () => {
    let response;

    if (company.id) {
      response = await fetchAllItems(
        `/employees/${company.id}/${filterByStatusSelectedStatus}`
      );
    }

    setEmployeesData(response as []);
  };

  const getJobsFromDB = async () => {
    let response: EmployeeJobData[] = [];

    if (company.id) {
      axios
        .get(`${baseURL}/employee_job_positions/${company.id}`)
        .then((res) => {
          if (res.data && res.data.data) {
            response = res.data.data;
          }
          setJobOffersData(response);
        })
        .catch((error) => {
          console.error('Error fetching job offers:', error);
          setJobOffersData([]);
        });
    } else {
      setJobOffersData(response);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      jobTitleDropdownRef.current &&
      !jobTitleDropdownRef.current.contains(event.target as Node)
    ) {
      setShowSelectJobTitle(null);
    }
  };

  const handleViewProfile = (id: string) => {
    navigate(`${id}`);
  };

  const handleOnFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setEmployeeFilterValue(value);
  };

  const toggleSelectJobTitle = (index: number) => {
    setShowSelectJobTitle((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChangeJobTitle = (employeeId: string, jobOfferId: string) => {
    // console.log(employeeId, jobOfferId);
    updateEmployeeJobTitle(employeeId, jobOfferId);
  };

  const updateEmployeeJobTitle = async (
    employeeId: string,
    jobOfferId: string
  ) => {
    try {
      const response = await axios.put(
        `${apiRoute}/employee-update-job-title/${employeeId}/${jobOfferId}`
      );

      if (response.status === 200) {
        console.log('Job title updated successfully:', response.data);
        getEmployeesFromDB();
      }
    } catch (error) {
      console.error('Error updating job title:', error);
    }
  };

  const createCompanyCV = (employeeCvUrl: string, employeeId: string) => {
    setLoadingEmployeeId(employeeId);

    fetchCVData(employeeCvUrl, employeeId, true)
      .then(() => {
        getEmployeesFromDB();
      })
      .catch((error) => {
        console.error('Error fetching CV data:', error);
      })
      .finally(() => {
        setLoadingEmployeeId(null);
      });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  useEffect(() => {
    if (error) {
      setErrorGenerateingCompanyCvPopUpVisible(true);
    }
  }, [error]);

  console.log(employeesData);

  return (
    <ApplicantsContentWrapper>
      <StyledEmployeesWrapper>
        <HeadingWrapper>
          <Text
            mt={0}
            mb={0}
            fontSize={38}
            fontFamily={font.family}
            lineHeight={'normal'}
            fontWeight={700}
          >
            Employees
          </Text>
          <WrapperDiv>
            <JobSelectMenuDiv isFilterJobSelect>
              <StyledSelectMenu
                name="chosenEmployeeToFilter"
                defaultValue={employeeFilterValue}
                onChange={(e) =>
                  setFilterByStatusSelectedStatus(e.target.value)
                }
                isFilterJobSelect
                width="160px"
              >
                <option disabled value="">
                  {'Filter by status'}{' '}
                </option>
                <option value={'all'}>{'All'} </option>
                <option value={'active'}>{'Active'} </option>
                <option value={'inactive'}>{'Inactive'} </option>
              </StyledSelectMenu>
              <DownArrowContainer isEmployeeFilter>
                <DownArrow />
              </DownArrowContainer>
            </JobSelectMenuDiv>
            <ButtonContainer
              backgroundColor={colors.primary_500}
              isFontWeight
              onClick={() => navigate(`add-employee`)}
            >
              + Add New Employee
            </ButtonContainer>
            <JobSelectMenuDiv isFilterJobSelect>
              <StyledSelectMenu
                name="chosenEmployeeToFilter"
                defaultValue={employeeFilterValue}
                onChange={handleOnFilterChange}
                isFilterJobSelect
                width="160px"
              >
                <option value={'filter_by'}>{'Filter by'} </option>
                {employeeFilterFields.map((employee, index) => (
                  <option
                    key={index}
                    value={employee.value}
                    // selected={selectedJob?.job_position === employee.job_position}
                  >
                    {employee.label}
                  </option>
                ))}
              </StyledSelectMenu>
              <DownArrowContainer isEmployeeFilter>
                <DownArrow />
              </DownArrowContainer>
            </JobSelectMenuDiv>
          </WrapperDiv>
        </HeadingWrapper>
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh columnWidth={'170px'}>Name</StyledTh>
              <StyledTh columnWidth={'170px'}>Email</StyledTh>
              <StyledTh columnWidth={'120px'}>Phone</StyledTh>
              <StyledTh columnWidth={'250px'}>Job Title</StyledTh>
              <StyledTh columnWidth={'170px'}>CV</StyledTh>
              <StyledTh columnWidth={'120px'}>Portfolio</StyledTh>
              <StyledTh columnWidth={'100px'}>Status</StyledTh>
              {/* <StyledTh columnWidth={'200px'}>Additional Files</StyledTh> */}
            </StyledTr>
          </StyledThead>
          <tbody>
            {employeesData.map((employee, index) => (
              <>
                <StyledTr key={index}>
                  <StyledTd data-label="Name">
                    {employee.first_name} {employee.last_name}
                  </StyledTd>

                  <StyledTd data-label="Email">{employee.email}</StyledTd>

                  <StyledTd data-label="Phone">
                    {employee.phone_number}
                  </StyledTd>

                  <StyledTd
                    data-label="Job Title"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectJobTitle(index);
                    }}
                  >
                    <EmployeeStyledSelectJobTitleAndImgWrapper>
                      <EmployeeStyledArrowJobTitleWrapper>
                        {jobOffersData.find((job) => job.id === employee.job_id)
                          ?.job_position || 'Unknown Position'}
                      </EmployeeStyledArrowJobTitleWrapper>
                      <EmployeeStyledArrowIconWrapper>
                        {showSelectJobTitle !== index && (
                          <ArrowIconContainer rotate={false}>
                            <ChevronDownIcon />
                          </ArrowIconContainer>
                        )}
                        {showSelectJobTitle === index && (
                          <ArrowIconContainer rotate={true}>
                            <ChevronDownIcon />
                          </ArrowIconContainer>
                        )}
                      </EmployeeStyledArrowIconWrapper>
                      {showSelectJobTitle === index && (
                        <EmployeeStyledJobTitlesSelectWrapper>
                          <StyledJobTitlesSelectContentWrapper
                            ref={jobTitleDropdownRef}
                          >
                            {jobOffersData.map((jobOffer, index) => (
                              <StyledJobTitleOption
                                key={index}
                                onClick={() =>
                                  handleChangeJobTitle(employee.id, jobOffer.id)
                                }
                              >
                                {jobOffer.job_position}
                              </StyledJobTitleOption>
                            ))}
                          </StyledJobTitlesSelectContentWrapper>
                        </EmployeeStyledJobTitlesSelectWrapper>
                      )}
                    </EmployeeStyledSelectJobTitleAndImgWrapper>
                  </StyledTd>

                  {/* <StyledTd data-label="CV">
                    {employee.cv ? (
                      <ButtonContainer
                        backgroundColor={colors.primary_500}
                        mt={1}
                        onClick={() => window.open(employee.cv, '_blank')}
                        isFontWeight
                      >
                        Download CV
                      </ButtonContainer>
                    ) : (
                      <p>No CV Attached</p>
                    )}
                  </StyledTd> */}
                  <StyledTd data-label="CV">
                    <StyledCvAndCompanyCvButtonsWrapper>
                      {employee.cv ? (
                        <StyledCVButton
                          onClick={() => window.open(employee.cv, '_blank')}
                        >
                          CV
                          <DownloadEnabled />
                        </StyledCVButton>
                      ) : (
                        <StyledCVButton
                          onClick={() => {
                            setEmployeeIdToUploadCV(employee.id),
                              setEmployeeDocumentTypeToUpload('CV');
                          }}
                        >
                          CV
                          <DownloadDisabled />
                        </StyledCVButton>
                      )}
                      {employee.company_cv ? (
                        <StyledCVButton
                          onClick={() =>
                            navigate(
                              `/${company.name}/${employee.id}/company-cv/edit/${employee.company_cv}`
                            )
                          }
                        >
                          Company CV
                          <DownloadEnabled />
                        </StyledCVButton>
                      ) : (
                        <StyledCVButton
                          disabled={loadingEmployeeId !== null}
                          onClick={() => {
                            if (employee.cv) {
                              // const cvUrl =
                              //   employee.cv instanceof URL
                              //     ? employee.cv.toString()
                              //     : employee.cv;

                              // if (cvUrl) {
                              //   navigate(
                              //     `/${company.name}/${
                              //       employee.id
                              //     }/company-cv/create/${encodeURIComponent(
                              //       cvUrl
                              //     )}`
                              //   );
                              // }
                              setEmployeeIdToCreateCompanyCV(employee.id),
                                createCompanyCV(
                                  employee.cv.toString(),
                                  employee.id
                                );
                            } else {
                              navigate(
                                `/${company.name}/${employee.id}/company-cv/new`
                              );
                            }
                          }}
                        >
                          Company CV
                          {loadingEmployeeId !== employee.id && (
                            <DownloadDisabled />
                          )}
                          {loadingEmployeeId === employee.id && (
                            <LoadingSpinner />
                          )}
                        </StyledCVButton>
                      )}
                    </StyledCvAndCompanyCvButtonsWrapper>
                  </StyledTd>
                  <StyledTd data-label="Portfolio">
                    {/* {employee.portfolio ? (
                      <ButtonContainer
                        backgroundColor={colors.primary_500}
                        mt={1}
                        isFontWeight
                        onClick={() =>
                          window.open(employee.portfolio, '_blank')
                        }
                      >
                        Download Portfolio
                      </ButtonContainer>
                    ) : (
                      <p>No Portfolio Attached</p>
                    )} */}
                    {employee.portfolio ? (
                      <StyledCVButton
                        onClick={() =>
                          window.open(employee.portfolio, '_blank')
                        }
                      >
                        Portfolio
                        <DownloadEnabled />
                      </StyledCVButton>
                    ) : (
                      <StyledCVButton
                        onClick={() => {
                          setEmployeeIdToUploadCV(employee.id),
                            setEmployeeDocumentTypeToUpload('portfolio');
                        }}
                      >
                        Portfolio
                        <DownloadDisabled />
                      </StyledCVButton>
                    )}
                  </StyledTd>
                  <StyledTd data-label="Status">
                    <StyledEmployeeStatusAndIconWrapper>
                      <TableEmployeeStatusColorCircleIcon
                        status={employee.status}
                      />
                      {employee.status.charAt(0).toUpperCase() +
                        employee.status.slice(1)}
                    </StyledEmployeeStatusAndIconWrapper>
                  </StyledTd>
                  {/* <StyledTd data-label="Additional Files">
                    {employee.additional_files ? (
                      <ButtonContainer
                        backgroundColor={colors.primary_500}
                        mt={1}
                        isFontWeight
                        onClick={() =>
                          window.open(employee.additional_files, '_blank')
                        }
                      >
                        Download Files
                      </ButtonContainer>
                    ) : (
                      <p>No Additional Files</p>
                    )}
                  </StyledTd> */}
                  <StyledTd>
                    <ButtonContainer
                      backgroundColor={colors.secondary_600}
                      onClick={() => handleViewProfile(employee.id)}
                    >
                      <Text
                        mt={0}
                        mb={0}
                        align="center"
                        pl={20}
                        pr={20}
                        fontFamily={font.family}
                        fontWeight={700}
                      >
                        View Profile
                      </Text>
                    </ButtonContainer>
                  </StyledTd>
                </StyledTr>
              </>
            ))}
          </tbody>
        </StyledTable>
      </StyledEmployeesWrapper>

      {/* <div style={{ width: '60%' }}>
        <PaginationButtons
          currentPage={currentPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalPages={totalPages}
        />
      </div> */}
      {employeeIdToUploadCV && (
        <EmployeeAttachCvPortfolioModal
          onClose={() => setEmployeeIdToUploadCV(null)}
          employeeId={employeeIdToUploadCV}
          documentType={employeeDocumentTypeToUpload}
          getEmployeesFromDB={getEmployeesFromDB}
          setPopUp={setPopUp}
        />
      )}
      {errorGenerateingCompanyCvPopUpVisible && (
        <EmployeesErrorGeneratingCompanyCvPopup
          employeeId={employeeIdToCreateCompanyCV}
          companyName={company.name}
          navigate={navigate}
          onClose={() => {
            setErrorGenerateingCompanyCvPopUpVisible(false);
          }}
        />
      )}
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </ApplicantsContentWrapper>
  );
};
