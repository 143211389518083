import React from 'react';
import { EmployeeDocumentsTypes } from './employee';
import {
  StyledDocumentsDeleteDocumentModalBackBtn,
  StyledDocumentsDeleteDocumentModalBtnsWrapper,
  StyledDocumentsDeleteDocumentModalMainWrapper,
  StyledDocumentsDeleteDocumentModalSubmitBtn,
  StyledDocumentsDeleteDocumentModalWrapper,
} from './employee.styled';
import { font, Text } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';

interface EmployeeDocumentsDeleteDocumentProps {
  document: EmployeeDocumentsTypes;
  onClose: () => void;
  employeeDocumentsFromDB: () => void;
  setPopUp: React.Dispatch<
    React.SetStateAction<{
      text: string;
      type: 'success' | 'error';
    } | null>
  >;
}

const EmployeeDocumentsDeleteDocument: React.FC<
  EmployeeDocumentsDeleteDocumentProps
> = ({ onClose, document, setPopUp, employeeDocumentsFromDB }) => {
  const handleDelete = () => {
    axios
      .delete(`${apiRoute}/delete-document/${document.id}`)
      .then((response) => {
        console.log(response.data.message);
        setPopUp({
          text: 'The document was successfully deleted!',
          type: 'success',
        });
        employeeDocumentsFromDB();
        onClose();
      })
      .catch((error) => {
        console.error(
          'Error deleting document:',
          error.response?.data || error.message
        );
        setPopUp({
          text: `Error deleting document: ${
            error.response?.data || error.message
          }`,
          type: 'error',
        });
      });
  };

  return (
    <StyledDocumentsDeleteDocumentModalMainWrapper onClick={onClose}>
      <StyledDocumentsDeleteDocumentModalWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text
          fontFamily={font.family}
          mb={24}
          color={colors.base_black}
          fontSize={22}
          fontWeight={700}
        >
          Are you sure you want to delete {document.document_name} ?
        </Text>
        <StyledDocumentsDeleteDocumentModalBtnsWrapper>
          <StyledDocumentsDeleteDocumentModalBackBtn onClick={onClose}>
            Back
          </StyledDocumentsDeleteDocumentModalBackBtn>
          <StyledDocumentsDeleteDocumentModalSubmitBtn onClick={handleDelete}>
            Delete
          </StyledDocumentsDeleteDocumentModalSubmitBtn>
        </StyledDocumentsDeleteDocumentModalBtnsWrapper>
      </StyledDocumentsDeleteDocumentModalWrapper>
    </StyledDocumentsDeleteDocumentModalMainWrapper>
  );
};

export default EmployeeDocumentsDeleteDocument;
