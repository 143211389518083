import styled from 'styled-components';

export const InfoCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  flex: 1;
  padding: 24px;
  cursor: pointer;
`;

export const InfoIconWrapper = styled.div<{ toDoList?: boolean }>`
  padding-right: ${({ toDoList }) => (toDoList ? '0' : '24px')};
`;

export const TextWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;
