import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';

export const GeneralStyle = styled.div`
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f9fa;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 15px 0;
  background-color: white;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 150px;

  ${media.laptop(`
     width: 120px;
  `)}
`;

export const Header = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HomeButton = styled.img`
  width: 25px;

  ${media.laptop(`
    width: 20px;
  `)}
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 50px 0;
  width: 90%;
  max-width: 1440px;
`;

export const MainHeading = styled.h1`
  font-size: 30px;
  color: black;
`;

export const SmallHeader = styled.h2`
  font-size: 20px;
  padding-top: 35px;
  color: #f9836f;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-size: 16px;
`;

export const List = styled.ul`
  margin: 10px;
`;

export const Item = styled.li`
  font-weight: 500;
  padding: 10px 0;
`;
