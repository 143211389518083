import React from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { EmployeesTable } from './employees-table';
import { Chat } from '../../chat/chat';
interface EmployeesProps {
  // updateEditableJobData: (newValue: JobOfferData) => void;
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  cookies?: Cookie;
}

export const Employees: React.FC<EmployeesProps> = ({
  navigate,
  company,
  setUser,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={company} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={company}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <EmployeesTable company={company} />
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
