import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export const PageButton = styled.button<{ disabled: boolean }>`
  background-color: ${colors.primary_500};
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  border-radius: 4px;

  &:hover {
    background-color: ${colors.primary_500};
  }
`;

export const PageNumber = styled.button<{ isActive?: boolean }>`
  background-color: ${(props) =>
    props.isActive ? colors.primary_500 : colors.base_white};
  color: ${(props) => (props.isActive ? 'white' : colors.primary_500)};
  border: 1px solid ${colors.primary_500};
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${(props) =>
      props.isActive ? colors.primary_500 : colors.base_white};
  }
`;

export const Ellipsis = styled.span`
  padding: 10px;
`;
