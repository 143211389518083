import React from 'react';

export const SendMessage = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F26954" />
      <path
        d="M18.3078 21.6923L23.1539 16.8462M28.1113 13.8887L24.0207 27.1833C23.6541 28.3747 23.4706 28.9707 23.1544 29.1683C22.8802 29.3396 22.5406 29.3683 22.2419 29.2443C21.8975 29.1014 21.618 28.5433 21.0603 27.428L18.4694 22.2461C18.3809 22.0691 18.3366 21.981 18.2775 21.9043C18.225 21.8363 18.1645 21.7749 18.0965 21.7225C18.0215 21.6647 17.9349 21.6214 17.7658 21.5369L12.5719 18.9399C11.4566 18.3823 10.8989 18.1032 10.756 17.7588C10.6321 17.4601 10.6603 17.1202 10.8317 16.846C11.0293 16.5297 11.6252 16.346 12.817 15.9793L26.1116 11.8887C27.0486 11.6004 27.5173 11.4563 27.8337 11.5725C28.1094 11.6737 28.3267 11.8908 28.4279 12.1665C28.544 12.4828 28.3999 12.9513 28.1119 13.8873L28.1113 13.8887Z"
        stroke="#FDFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
