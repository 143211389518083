import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';

export const StyledEmployeeChangeEmploymentStatusMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledEmployeeChangeEmploymentStatusWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colors.neutral_50};
  /* height: 200px; */
  width: 540px;
  padding: 16px;
  border-radius: 8px;
  gap: 36px;
`;

export const StyledCloseButtonWrapper = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  cursor: pointer;
`;

export const StyledChangeEmploymentStatusBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  margin-left: auto;
  background-color: ${colors.primary_500};
  border-radius: 4px;
  padding: 8px 40px;
  border-style: none;
  cursor: pointer;
`;
