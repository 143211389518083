import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledReportingEmployeesTableMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledReportingEmployeesTableHeadersWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0;
  gap: 8px;
`;

export const StyledReportingEmployeesTableColumnWrapper = styled(
  StyledReportingEmployeesTableHeadersWrapper
)`
  background-color: ${colors.neutral_50};
  border-radius: 8px;
`;

export const StyledReportingEmployeesColumn = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  line-break: anywhere;
`;

export const StyledReportingEmployeesTableAndTitleMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.base_white};
  border-radius: 8px;
  padding: 24px;
`;

export const StyledReportingEmployeesTableTitleAndActionsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${colors.base_black};
`;

export const StyledReportingEmployeesTableActionsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledReportingEmployeesTableActionBtn = styled.button`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledReportingEmployeesTableJobTitleCol = styled.div`
  box-sizing: border-box;
  background-color: ${colors.secondary_50};
  padding: 4px 8px;
  border-radius: 4px;
  flex-wrap: wrap;
  line-break: anywhere;
`;

export const StyledReportingRenewalStatusCol = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: ${colors.light_green};
  padding: 4px 8px;
  border-radius: 4px;
  flex-wrap: wrap;
  line-break: anywhere;
`;
