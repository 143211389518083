import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';

export const StyledChartsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.base_white};
  border-radius: 8px;
  padding: 24px;
`;

export const StyledMultiLineChartTitleAndTabsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
`;
