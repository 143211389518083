import React from 'react';

export const ErrorMsgLabelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 10C18.5 14.4183 14.9183 18 10.5 18C6.08172 18 2.5 14.4183 2.5 10C2.5 5.58172 6.08172 2 10.5 2C14.9183 2 18.5 5.58172 18.5 10ZM11.5 14C11.5 14.5523 11.0523 15 10.5 15C9.94772 15 9.5 14.5523 9.5 14C9.5 13.4477 9.94772 13 10.5 13C11.0523 13 11.5 13.4477 11.5 14ZM10.5 5C9.94772 5 9.5 5.44772 9.5 6V10C9.5 10.5523 9.94772 11 10.5 11C11.0523 11 11.5 10.5523 11.5 10V6C11.5 5.44772 11.0523 5 10.5 5Z"
        fill="#A82621"
      />
    </svg>
  );
};
