import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  StyledSettingsChangeColorsMainWrapper,
  StyledSaveButton,
  StyledDescriptionMainWrapper,
  StyledDescriptionAndTitleWrapper,
} from './change-colors.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import templates from '../../../../../../../../style-utils/color-templates';
import { DefaultComponentProps } from '../../../../../../../app-router/component-props';
import { apiRoute } from '../../../../../../../../constants/api-constants';
import MessagePopup from '../../../../../../../messages/message-popup/message-popup';
import { colors } from '../../../../../../../../style-utils/colors';
import SelectCustomColors from './components/select-custom-colors';
import PreviewColorTemplate from './components/preview-color-template';
import SelectColorTemplates from './components/select-color-templates';

const SelectColorsTab: React.FC<DefaultComponentProps> = ({ user }) => {
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [colorComponents, setColorComponents] = useState(
    [
      { label: 'Title color', color: '#010101' },
      { label: 'Company title color', color: '#E94E3C' },
      { label: 'Text color', color: '#010101' },
      { label: 'Filter text color', color: '#5E5A57' },
      { label: 'Filter color', color: '#F3F2F2' },
      { label: 'Button text color', color: '#FDFDFD' },
      { label: 'Button color', color: '#E94E3C' },
      { label: 'Background fill color', color: '#FDFDFD' },
      { label: 'Background color', color: '#F7F8FA' },
    ].map((item) => ({
      ...item,
      name: item.label.toLowerCase().replace(/ /g, '_'),
      hasError: false,
    }))
  );
  const [selectedTemplate, setSelectedTemplate] = useState('Color Template 01');

  const fetchColorTemplate = () => {
    axios
      .get(`${apiRoute}/color_template/${user.id}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const fetchedTemplate = response.data;

          setColorComponents((prevComponents) =>
            prevComponents.map((component) => ({
              ...component,
              color: fetchedTemplate[component.name],
            }))
          );
          setSelectedTemplate(
            fetchedTemplate.selected_template || 'Color Template 01'
          );
          console.log(fetchedTemplate);
        } else {
          console.log('No color template found, using default values.');
        }
      })
      .catch((error) => {
        console.error('Error fetching color template:', error);
      });
  };

  useEffect(() => {
    fetchColorTemplate();
  }, []);

  const handleSave = () => {
    const hasErrors = colorComponents.some((component) => component.hasError);

    if (hasErrors) {
      console.log('Please fix the errors before saving.');
      setPopUp({
        text: 'Please either fill in the inputs with valid HEX color codes or select a color template.',
        type: 'error',
      });

      return;
    }

    const colorTemplateData = colorComponents.reduce(
      (acc, component) => {
        acc[component.name] = component.color;

        return acc;
      },
      {
        company_id: user.id,
        selected_template: selectedTemplate,
      } as { [key: string]: string | undefined }
    );

    axios
      .post(`${apiRoute}/color_template`, colorTemplateData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log('Color template updated successfully');
          setPopUp({
            text: 'Color template updated successfully',
            type: 'success',
          });
        }
      })
      .catch((error) => {
        console.error('Error updating color template:', error);
        console.log('An error occurred while updating the color template');
        setPopUp({
          text: 'An error occurred while updating the color template',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <StyledSettingsChangeColorsMainWrapper>
      {/* Description */}
      <StyledDescriptionAndTitleWrapper>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={20}
          fontWeight={700}
          color={colors.base_black}
        >
          Choose template
        </Text>
        <StyledDescriptionMainWrapper>
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={18}
            fontWeight={700}
            lineHeight="24px"
            color={colors.neutral_900}
          >
            Personalize Your Experience
          </Text>
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={14}
            fontWeight={400}
            lineHeight="27px"
            color={colors.neutral_900}
          >
            You can choose from a variety of color templates to see how your
            website will look. Select from our palettes or create your own by
            adjusting the colors below. Explore the possibilities and make your
            website truly yours with our easy-to-use customization tools. Simply
            select a color template or fine-tune individual colors to match your
            brand&apos;s style.
          </Text>
        </StyledDescriptionMainWrapper>
      </StyledDescriptionAndTitleWrapper>
      {/* Description END */}
      {/* Custom colors */}
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={20}
        fontWeight={700}
        color={colors.base_black}
      >
        Select custom color
      </Text>
      <SelectCustomColors
        colorComponents={colorComponents}
        setColorComponents={setColorComponents}
      />
      {/* Custom colors END */}
      {/* Color templates */}
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={20}
        fontWeight={700}
        color={colors.base_black}
      >
        Select color template
      </Text>
      <SelectColorTemplates
        templates={templates}
        colorComponents={colorComponents}
        setColorComponents={setColorComponents}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      {/* Color templates END */}
      {/* Preview color templates */}
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={20}
        fontWeight={700}
        color={colors.base_black}
      >
        Preview color template
      </Text>
      <PreviewColorTemplate colorComponents={colorComponents} />
      {/* Preview color templates END */}
      <StyledSaveButton onClick={handleSave}>Save</StyledSaveButton>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </StyledSettingsChangeColorsMainWrapper>
  );
};

export default SelectColorsTab;
