import { useContext } from 'react';
import { CreateSurveyContext } from './create-survey-context';
import { CreateSurveyContextType } from './survey-types';

export const useSurvey = (): CreateSurveyContextType => {
  const context = useContext(CreateSurveyContext);
  if (!context) {
    throw new Error('useSurvey must be used within a SurveyProvider');
  }

  return context;
};
