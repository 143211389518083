import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import {
  StyledInterviewConfirmationIconAndMessageWrapper,
  StyledInterviewConfirmationMainWrapper,
} from './interview-confirmation.styled';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { Success } from '../../icons/success';

interface ErrorResponse {
  message: string;
  // Include other fields that might be in your error response
}

export const InterviewConfirmation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const confirmInterview = async () => {
      const token = searchParams.get('token');
      if (!token) {
        console.log('Token not found');
        navigate('/');

        return;
      }

      try {
        const res = await axios.post(`${apiRoute}/confirm-interview`, {
          token,
        });
        console.log('API Response:', res.data);
      } catch (error) {
        console.error('Error verifying email:', error as AxiosError);
        const displayError = error as AxiosError<ErrorResponse>;
        setError(
          (displayError.response?.data?.message as string) ||
            'Error verifying email'
        );
      } finally {
        setIsLoading(false);
      }
    };

    confirmInterview();
  }, [searchParams, navigate]);

  if (isLoading) {
    return (
      <StyledInterviewConfirmationMainWrapper></StyledInterviewConfirmationMainWrapper>
    );
  }

  if (error) {
    return (
      <StyledInterviewConfirmationMainWrapper>
        <Text
          color={colors.error}
          fontSize={36}
          fontWeight={700}
          fontFamily={font.family}
          margin="0"
        >
          {error}
        </Text>
      </StyledInterviewConfirmationMainWrapper>
    );
  }

  return (
    <StyledInterviewConfirmationMainWrapper>
      <StyledInterviewConfirmationIconAndMessageWrapper>
        <Success size={80} />
        <Text
          color={colors.grey_dark}
          fontSize={36}
          fontWeight={700}
          fontFamily={font.family}
          margin="0"
        >
          Thank you for confirming the interview!
        </Text>
      </StyledInterviewConfirmationIconAndMessageWrapper>
    </StyledInterviewConfirmationMainWrapper>
  );
};
