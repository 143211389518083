import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { Chat } from '../../../chat/chat';
import BackToDashboard from '../../settings/components/back-btn/back-btn';
import { SurveyForm } from './components/survey-form';
import { SurveyProvider } from './hooks/create-survey-context';

interface SurveyComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const CreateSurveyPage: React.FC<SurveyComponentProps> = ({
  user,
  navigate,
  setUser,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper style={{ padding: '45px' }}>
          <BackToDashboard user={user} navigate={navigate} />
          <SurveyProvider>
            <SurveyForm companyId={user.id} />
          </SurveyProvider>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
