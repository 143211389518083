import styled from 'styled-components';

export const StyledBackToDashboardBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  background-color: transparent;
  border-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
