import React from 'react';
import {
  StyledSettingsTabSelectorMainWrapper,
  StyledTabSelectButton,
} from '../../../settings/components/tabs/components/tab-selector/tab-selector.styled';
import { font, Text } from '../../../../../style-utils/text';

interface SettingsTabSelectorProps {
  onTabChange: (tabId: number) => void;
  activeTabId: number;
}

const AdminSettingsTabSelector: React.FC<SettingsTabSelectorProps> = ({
  onTabChange,
  activeTabId,
}) => {
  return (
    <StyledSettingsTabSelectorMainWrapper>
      <StyledTabSelectButton
        isActive={activeTabId === 0}
        onClick={() => onTabChange(0)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Company Details
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 1}
        onClick={() => onTabChange(1)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Payment
        </Text>
      </StyledTabSelectButton>
      <StyledTabSelectButton
        isActive={activeTabId === 2}
        onClick={() => onTabChange(2)}
      >
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={400}
        >
          Subscription Plan
        </Text>
      </StyledTabSelectButton>
    </StyledSettingsTabSelectorMainWrapper>
  );
};

export default AdminSettingsTabSelector;
