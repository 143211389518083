import styled from 'styled-components';

export const ModalMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: #e4eaf5;
  padding: 16px;
  border-radius: 8px;
`;

export const OptionBox = styled.div`
  width: 70%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background-color: white;
  border: none;
  padding-left: 5px;
  border-radius: 8px;
`;

export const InputText = styled.input`
  width: 66%;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 15px;

  ::placeholder {
    font-size: 14px;
    font-weight: 300;
  }
`;
