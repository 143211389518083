import Resizer from 'react-image-file-resizer';

export const resizeImage = (file: File): Promise<Blob> => {
  // eslint-disable-next-line
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file, // the file from input
      800, // max width
      800, // max height
      'JPEG', // compress format
      100, // quality
      0, // rotation
      (uri) => {
        // Assert the uri as Blob
        resolve(uri as Blob);
      },
      'blob' // output type as blob to append directly to formData
    );
  });
};
