import React, { useState } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
  ContentWrapperCandidates,
} from '../../../layout/layout-container.styled';
// import { Text, font } from '../../../style-utils/text';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { CandidatesTable } from '../../applicants/candidates/candidates-table';

interface InterviewsTodayComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const InterviewsToday: React.FC<InterviewsTodayComponentProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();

  const [forceUpdate, setForceUpdate] = useState(0);

  const triggerReRender = () => {
    setForceUpdate(forceUpdate + 1);
  };

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapperCandidates>
          <NavbarAndContentContainer>
            <CandidatesTable
              company={user}
              fromPage="todayInterviews"
              navigate={navigate}
              triggerReRender={triggerReRender}
            />
          </NavbarAndContentContainer>
        </ContentWrapperCandidates>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
