export const industries = [
  'Agriculture',
  'Mining',
  'Oil and Gas',
  'Automotive',
  'Aerospace',
  'Chemicals',
  'Consumer Goods',
  'Electronics',
  'Food and Beverage',
  'Pharmaceuticals',
  'Textiles',
  'Industrial Equipment',
  'Architecture',
  'Construction',
  'Real Estate Development',
  'Information Technology',
  'Software Development',
  'Telecommunications',
  'Data Analytics',
  'AI and Robotics',
  'Banking',
  'Insurance',
  'Investment Management',
  'Consulting',
  'Legal Services',
  'Retail',
  'E-commerce',
  'Hospitality',
  'Travel and Tourism',
  'Higher Education',
  'K-12 Education',
  'Corporate Training',
  'Hospitals and Clinics',
  'Biotechnology',
  'Pharmaceuticals',
  'Local Government',
  'Federal Government',
  'Nonprofit Organizations',
  'Renewable Energy',
  'Utilities (Water, Electricity, Gas)',
  'Film and Television',
  'Publishing',
  'Digital Media',
  'Airlines',
  'Shipping',
  'Supply Chain Management',
  'Waste Management',
  'Environmental Consulting',
];
