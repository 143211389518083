import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';
import { font } from '../../../../../../../../style-utils/text';

export const StyledGlobalJobBoardActiveJobsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const StyledGlobalJobBoardActiveJobsFilterAndTotalResults = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledGlobalJobBoardActiveJobsSortyByMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 8px;
`;

export const StyledGlobalJobBoardActiveJobsSortyByInputAndArrow = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const StyledGlobalJobBoardActiveJobsSortyBy = styled.select`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 8px;
  background-color: transparent;
  border: none;
  appearance: none; /* Remove default dropdown styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  font-family: ${font.familyMontserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: ${colors.base_black};
  cursor: pointer;
  padding: 0 24px 0 0; /* Remove padding for a cleaner look */
  margin: 0; /* Ensure no additional spacing is added */
`;

export const StyledGlobalJobBoardActiveJobsSortyByArrow = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 3px;
  right: 0;
`;

export const StyledGlobalJobBoardActiveJobsShowMoreBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  padding: 12px 16px;
  border-radius: 24px;
  border: 1px solid ${colors.secondary_400};
  background-color: transparent;
  margin: 24px auto;
  cursor: pointer;
`;
