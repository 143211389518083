import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';
import { colors } from '../../../style-utils/colors';
// import { colors } from '../../../style-utils/colors';
// import { font } from '../../../style-utils/text';

export const DesktopHeaderWrapper = styled.div`
  background: var(--Shadow-0, #fdfdfd);
  display: flex;
  flex: 0 1 auto;
  max-height: 88px;
  padding: 10px 0px;
  /* padding: 0 32px; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${media.laptop(`
      visiblity:hidden;
      display:none;
  `)}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NotificationAndUserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 40px;
  gap: 16px;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const TextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
`;

export const UserDropdown = styled.div`
  position: absolute;
  width: 100px;
  top: 50px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.base_black};
  background-color: ${colors.base_white};
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const UserDropdownContent = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: ponter;
  ${media.laptop(`
    padding: 0px;
  `)}
`;

export const MobileHeaderWrapper = styled.div`
  visiblity: hidden;
  display: none;
  background: var(--Shadow-0, #fdfdfd);
  flex: 0 1 auto;
  max-height: 88px;
  padding: 0 32px;
  justify-content: space-between;
  align-items: center;
  ${media.laptop(`
      visiblity: visible;
      display: flex;
      padding: 0px;
  `)}
`;
