// TabSelector.tsx
import React, { Dispatch, SetStateAction } from 'react';
import {
  StyledReportingTabsMainWrapper,
  StyledSwiper,
} from './tab-selector.styled';
import { font, Text } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import Tab from './components/tab';
import { ReportingTabDataTypes } from './tab-data';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

interface TabSelectornProps {
  fromComponent?: string;
  activeTabId: number;
  setActiveTabId: Dispatch<SetStateAction<number>>;
  numberOfTabs?: number;
  tabsData: ReportingTabDataTypes[];
}

const TabSelector: React.FC<TabSelectornProps> = ({
  activeTabId,
  setActiveTabId,
  numberOfTabs = 4,
  tabsData,
}) => {
  const handleTabClick = (index: number) => {
    setActiveTabId(index);
  };

  return (
    <StyledReportingTabsMainWrapper>
      <Text
        fontSize={24}
        fontFamily={font.family}
        color={colors.base_black}
        fontWeight={700}
        margin="0"
      >
        Categories
      </Text>
      <StyledSwiper
        spaceBetween={20}
        slidesPerView={numberOfTabs}
        onSlideChange={() => console.log('slide change')}
        onSwiper={() => console.log('swiper')}
        navigation={true}
        pagination={{ clickable: true }}
      >
        {tabsData.map((tab) => (
          <SwiperSlide
            key={tab.id}
            onClick={() => {
              handleTabClick(tab.id);
            }}
            style={{
              backgroundColor:
                activeTabId === tab.id
                  ? colors.secondary_200
                  : colors.secondary_50,
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
          >
            <Tab
              key={tab.id}
              title={tab.title}
              description={tab.description}
              count={tab.count}
              trending={tab.trending}
              icon={tab.icon}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </StyledReportingTabsMainWrapper>
  );
};

export default TabSelector;
