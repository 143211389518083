import React from 'react';
export const OnboardingChecklistOptionSelected: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M6 7.83333L8 9.83333L14.6667 3.16666"
        stroke="#E94E3C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V3.83333C2 3.47971 2.14048 3.14057 2.39052 2.89052C2.64057 2.64048 2.97971 2.5 3.33333 2.5H10.6667"
        stroke="#E94E3C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
