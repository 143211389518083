import { useState } from 'react';
import {
  ReportingBarAndMultiLineChartDataTypes,
  ReportingCustomBarChartDataTypes,
  ReportingLineChartDataTypes,
} from '../../components/charts/charts-data-types';

export const useReportingChartData = () => {
  const [barChartData, setBarChartData] = useState<
    ReportingBarAndMultiLineChartDataTypes[]
  >([
    { name: 'Jan', avg: 60, max: 100, min: 80 },
    { name: 'Feb', avg: 40, max: 90, min: 70 },
    { name: 'Mar', avg: 50, max: 85, min: 65 },
    { name: 'Apr', avg: 60, max: 95, min: 75 },
    { name: 'May', avg: 55, max: 90, min: 70 },
    { name: 'Jun', avg: 65, max: 100, min: 80 },
    { name: 'Jul', avg: 50, max: 85, min: 65 },
    { name: 'Aug', avg: 60, max: 95, min: 75 },
    { name: 'Sep', avg: 65, max: 100, min: 80 },
    { name: 'Oct', avg: 55, max: 90, min: 70 },
    { name: 'Nov', avg: 60, max: 95, min: 75 },
    { name: 'Dec', avg: 55, max: 85, min: 65 },
  ]);

  const [lineChartData, setLineChartData] = useState<
    ReportingLineChartDataTypes[]
  >([
    { name: 'Jan', value: 40 },
    { name: 'Feb', value: 45 },
    { name: 'Mar', value: 50 },
    { name: 'Apr', value: 60 },
    { name: 'May', value: 65 },
    { name: 'Jun', value: 55 },
    { name: 'Jul', value: 50 },
    { name: 'Aug', value: 58 },
    { name: 'Sep', value: 62 },
    { name: 'Oct', value: 50 },
    { name: 'Nov', value: 48 },
    { name: 'Dec', value: 55 },
  ]);

  const [multiLineChartData, setMultiLineChartData] = useState<
    ReportingBarAndMultiLineChartDataTypes[]
  >([
    { name: 'Jan', avg: 10, max: 40, min: 5 },
    { name: 'Feb', avg: 20, max: 50, min: 10 },
    { name: 'Mar', avg: 30, max: 60, min: 15 },
    { name: 'Apr', avg: 25, max: 55, min: 20 },
    { name: 'May', avg: 35, max: 70, min: 25 },
    { name: 'Jun', avg: 30, max: 60, min: 20 },
    { name: 'Jul', avg: 40, max: 80, min: 30 },
    { name: 'Aug', avg: 35, max: 65, min: 25 },
    { name: 'Sep', avg: 40, max: 70, min: 35 },
    { name: 'Oct', avg: 30, max: 60, min: 20 },
    { name: 'Nov', avg: 35, max: 55, min: 25 },
    { name: 'Dec', avg: 25, max: 50, min: 15 },
  ]);

  const [customBarChartData, setCustomBarChartData] = useState<
    ReportingCustomBarChartDataTypes[]
  >([
    { name: 'Jan', series1: 30, series2: 45, series3: 20 },
    { name: 'Feb', series1: 40, series2: 55, series3: 25 },
    { name: 'Mar', series1: 50, series2: 60, series3: 30 },
    { name: 'Apr', series1: 45, series2: 65, series3: 35 },
    { name: 'May', series1: 55, series2: 70, series3: 40 },
    { name: 'Jun', series1: 50, series2: 60, series3: 30 },
    { name: 'Jul', series1: 60, series2: 80, series3: 45 },
    { name: 'Aug', series1: 55, series2: 65, series3: 35 },
    { name: 'Sep', series1: 60, series2: 75, series3: 40 },
    { name: 'Oct', series1: 50, series2: 60, series3: 30 },
    { name: 'Nov', series1: 55, series2: 70, series3: 35 },
    { name: 'Dec', series1: 50, series2: 60, series3: 30 },
  ]);

  return {
    barChartData,
    lineChartData,
    multiLineChartData,
    customBarChartData,
    setBarChartData,
    setLineChartData,
    setMultiLineChartData,
    setCustomBarChartData,
  };
};
