import React from 'react';

export const SettingsAddressIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6281 22.7196L11.6314 22.7211C11.868 22.824 12 22.8 12 22.8C12 22.8 12.132 22.824 12.3695 22.7207L12.3719 22.7196L12.3788 22.7165L12.4005 22.7064C12.4186 22.6981 12.4436 22.6863 12.4751 22.6711C12.538 22.6408 12.627 22.5967 12.7384 22.5386C12.9609 22.4226 13.2734 22.2503 13.646 22.0194C14.3895 21.5586 15.3808 20.8591 16.3746 19.9013C18.3626 17.9852 20.4 14.991 20.4 10.8C20.4 6.16083 16.6392 2.40002 12 2.40002C7.36078 2.40002 3.59998 6.16083 3.59998 10.8C3.59998 14.991 5.63737 17.9852 7.6254 19.9013C8.61914 20.8591 9.6105 21.5586 10.354 22.0194C10.7265 22.2503 11.039 22.4226 11.2616 22.5386C11.3729 22.5967 11.4619 22.6408 11.5249 22.6711C11.5564 22.6863 11.5814 22.6981 11.5994 22.7064L11.6212 22.7165L11.6281 22.7196ZM12 13.5C13.4911 13.5 14.7 12.2912 14.7 10.8C14.7 9.30886 13.4911 8.10002 12 8.10002C10.5088 8.10002 9.29998 9.30886 9.29998 10.8C9.29998 12.2912 10.5088 13.5 12 13.5Z"
        fill="#5E5A57"
      />
    </svg>
  );
};
