import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from 'recharts';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { StyledChartsMainWrapper } from '../charts.styled';
import { ReportingLineChartDataTypes } from '../charts-data-types';

interface LineChartProps {
  name: string;
  lineChartData: ReportingLineChartDataTypes[];
}

const ReportingLineChart: React.FC<LineChartProps> = ({
  name,
  lineChartData,
}) => {
  return (
    <StyledChartsMainWrapper>
      <Text
        fontSize={24}
        fontFamily={font.family}
        color={colors.base_black}
        fontWeight={700}
        margin="0"
      >
        {name}
      </Text>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={lineChartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 80]} />
          <Tooltip
            contentStyle={{
              backgroundColor: '#fff',
              border: 'none',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
            cursor={false}
          />
          {/* Light blue area fill */}
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fill="rgba(0, 123, 255, 0.1)"
          />
          {/* Red line with smooth curves */}
          <Line
            type="monotone"
            dataKey="value"
            stroke="#e74c3c"
            strokeWidth={3}
            dot={{ r: 5, stroke: '#fff', strokeWidth: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledChartsMainWrapper>
  );
};

export default ReportingLineChart;
