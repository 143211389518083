import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  StyledAddChecklistButton,
  StyledAddNewOptionButton,
  StyledBlueInfoComponentWrapper,
  StyledChecklistHeading,
  StyledChecklistOptionTitleInput,
  StyledChecklistOptionsAndTitleWrapper,
  StyledChecklistWrapper,
  StyledCompanySettingsOnboardingTabMainWrapper,
  StyledDeleteIconWrapper,
  StyledOnboardingInputCircleWrapper,
  StyledOnboardingInputDeleteWrapper,
  StyledOptionWrapper,
  StyledOptionsTitleAndAddOptionButtonWrapper,
  StyledPublishButton,
} from './onboarding.styled';
import { colors } from '../../../../../../../../style-utils/colors';
import { Text, font } from '../../../../../../../../style-utils/text';
import { DeleteIcon } from '../../../../../../../icons/delete-icon';
import { PlusIconOnboarding } from '../../../../../../../icons/plus-icon-onboarding';
import { OnboardingInputCircle } from '../../../../../../../icons/onboarding-input-circle';
import { PlusIcon } from '../../../../../../../icons/plus-icon-small';
import { DefaultComponentProps } from '../../../../../../../app-router/component-props';
import { apiRoute } from '../../../../../../../../constants/api-constants';
import MessagePopup from '../../../../../../../messages/message-popup/message-popup';

interface ChecklistOption {
  option_name: string;
  order: number; // Add order field
}

interface Checklist {
  title: string;
  options: ChecklistOption[];
  order: number; // Add order field
}

const CompanySettingsOnboardingTab: React.FC<DefaultComponentProps> = ({
  user,
}) => {
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [initialChecklists, setInitialChecklists] = useState<Checklist[]>([]);
  const [companyID] = useState<string>(user.id);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const fetchChecklists = async () => {
    try {
      const response = await axios.get(
        `${apiRoute}/onboarding/checklists/latest/${companyID}`
      );
      const fetchedChecklists = response.data;
      console.log('Fetched checklists', fetchedChecklists);
      setChecklists(
        fetchedChecklists.sort(
          (a: Checklist, b: Checklist) => a.order - b.order
        )
      ); // Sort by order

      const deepCopyChecklists = JSON.parse(JSON.stringify(fetchedChecklists));
      setInitialChecklists(deepCopyChecklists);

      if (fetchedChecklists.length === 0) {
        handleAddChecklist();
      }
    } catch (error) {
      console.error('Failed to fetch latest checklists', error);
    }
  };

  useEffect(() => {
    fetchChecklists();
  }, [companyID]);

  const handleInputChange = (
    index: number,
    field: keyof Checklist,
    value: string
  ) => {
    const updatedChecklists = [...checklists];
    if (field === 'title') {
      // eslint-disable-next-line
      updatedChecklists[index][field] = value as any;
    }
    setChecklists(updatedChecklists);
  };

  const handleOptionChange = (
    checklistIndex: number,
    optionIndex: number,
    value: string
  ) => {
    const updatedChecklists = [...checklists];
    updatedChecklists[checklistIndex].options[optionIndex].option_name = value;
    setChecklists(updatedChecklists);
  };

  const handleDeleteChecklist = (index: number) => {
    const updatedChecklists = [...checklists];
    updatedChecklists.splice(index, 1);
    setChecklists(updatedChecklists);
  };

  const handleDeleteOption = (checklistIndex: number, optionIndex: number) => {
    const updatedChecklists = [...checklists];
    updatedChecklists[checklistIndex].options.splice(optionIndex, 1);
    setChecklists(updatedChecklists);
  };

  const handlePublishClick = () => {
    if (checklists.length === 0) {
      setPopUp({
        text: 'You must have at least one checklist to publish',
        type: 'error',
      });

      return;
    }

    const hasInvalidChecklist = checklists.some((checklist) => {
      const hasEmptyOptions = checklist.options.some(
        (option) => !option.option_name.trim()
      );

      return (
        !checklist.title.trim() ||
        checklist.options.length === 0 ||
        hasEmptyOptions
      );
    });

    if (hasInvalidChecklist) {
      setPopUp({
        text: 'Each checklist must have a title and no empty options',
        type: 'error',
      });

      return;
    }

    const areChecklistsEqual = (
      list1: Checklist[],
      list2: Checklist[]
    ): boolean => {
      if (list1.length !== list2.length) return false;

      for (let i = 0; i < list1.length; i++) {
        const checklist1 = list1[i];
        const checklist2 = list2[i];

        if (checklist1.title !== checklist2.title) return false;

        if (checklist1.options.length !== checklist2.options.length)
          return false;

        for (let j = 0; j < checklist1.options.length; j++) {
          if (
            checklist1.options[j].option_name !==
            checklist2.options[j].option_name
          ) {
            return false;
          }
        }
      }

      return true;
    };

    const isChanged = !areChecklistsEqual(checklists, initialChecklists);

    if (!isChanged) {
      setPopUp({
        text: 'No new changes detected',
        type: 'error',
      });

      return;
    }

    axios
      .post(`${apiRoute}/onboarding/checklists/create-or-update`, {
        checklists,
        company_id: companyID,
      })
      .then((response) => {
        console.log('Checklist saved successfully', response.data);
        fetchChecklists();
        setPopUp({
          text: 'Checklist saved successfully',
          type: 'success',
        });
      })
      .catch((error) => {
        console.error('Failed to save checklist', error);
        setPopUp({
          text: 'Failed to save checklist',
          type: 'error',
        });
      });
  };

  const handleAddChecklist = () => {
    const newChecklist: Checklist = {
      title: '',
      order: checklists.length + 1, // Set order based on current length
      options: [
        {
          option_name: '',
          order: 1, // Set default order for the first option
        },
      ],
    };

    setChecklists([...checklists, newChecklist]);
  };

  const handleAddOption = (index: number) => {
    const updatedChecklists = [...checklists];
    const newOrder = updatedChecklists[index].options.length + 1; // Set order based on length
    updatedChecklists[index].options.push({ option_name: '', order: newOrder });
    setChecklists(updatedChecklists);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <StyledCompanySettingsOnboardingTabMainWrapper>
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={32}
        fontWeight={700}
        color={colors.base_black}
      >
        Employee Onboarding
      </Text>
      <StyledBlueInfoComponentWrapper>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={20}
          fontWeight={700}
          color={colors.neutral_900}
        >
          Thank you for taking part
        </Text>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={14}
          fontWeight={400}
          color={colors.neutral_900}
        >
          Please create this checklist to help us improve some stuff!
        </Text>
      </StyledBlueInfoComponentWrapper>
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={16}
        fontWeight={700}
        color={colors.base_black}
      >
        Onboarding checklist
      </Text>
      {checklists.map((checklist, index) => (
        <StyledChecklistWrapper key={index}>
          <StyledChecklistHeading>
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={16}
              fontWeight={700}
              color={colors.base_white}
            >
              {checklist.title}
            </Text>
            <StyledDeleteIconWrapper
              onClick={() => handleDeleteChecklist(index)}
            >
              <DeleteIcon fromPage="onboarding" color={colors.base_white} />
            </StyledDeleteIconWrapper>
          </StyledChecklistHeading>
          <StyledChecklistOptionsAndTitleWrapper>
            <StyledChecklistOptionTitleInput
              type="text"
              placeholder="Title"
              value={checklist.title}
              onChange={(e) =>
                handleInputChange(index, 'title', e.target.value)
              }
            />
            <StyledOptionsTitleAndAddOptionButtonWrapper>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={14}
                fontWeight={400}
                color={colors.base_black}
              >
                Options:
              </Text>
              <StyledAddNewOptionButton onClick={() => handleAddOption(index)}>
                <PlusIconOnboarding />
                <Text
                  margin="0"
                  fontFamily={font.family}
                  fontSize={12}
                  fontWeight={400}
                  color={colors.base_white}
                >
                  Add Options
                </Text>
              </StyledAddNewOptionButton>
            </StyledOptionsTitleAndAddOptionButtonWrapper>
            {checklist.options.map((option, optionIndex) => (
              <StyledOptionWrapper key={optionIndex}>
                <StyledOnboardingInputCircleWrapper>
                  <OnboardingInputCircle />
                </StyledOnboardingInputCircleWrapper>
                <StyledChecklistOptionTitleInput
                  type="text"
                  placeholder="Option name"
                  value={option.option_name}
                  onChange={(e) =>
                    handleOptionChange(index, optionIndex, e.target.value)
                  }
                  leftPadding={true}
                />
                <StyledOnboardingInputDeleteWrapper
                  onClick={() => handleDeleteOption(index, optionIndex)}
                >
                  <DeleteIcon fromPage="onboarding" />
                </StyledOnboardingInputDeleteWrapper>
              </StyledOptionWrapper>
            ))}
          </StyledChecklistOptionsAndTitleWrapper>
        </StyledChecklistWrapper>
      ))}
      <StyledAddChecklistButton onClick={handleAddChecklist}>
        <PlusIcon />
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={700}
          color={colors.base_white}
        >
          Add Checklist
        </Text>
      </StyledAddChecklistButton>
      <StyledPublishButton onClick={handlePublishClick}>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={16}
          fontWeight={700}
          color={colors.neutral_50}
        >
          Publish
        </Text>
      </StyledPublishButton>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </StyledCompanySettingsOnboardingTabMainWrapper>
  );
};

export default CompanySettingsOnboardingTab;
