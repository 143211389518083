import React from 'react';
import {
  StyledJobPositionTabTableActionsIconWrapper,
  StyledJobPositionTabTableActionsMainWrapper,
} from './job-position-tab-table-actions.styled';
import { SettingsEditIcon } from '../../../../../../../../../../../icons/settings-edit-icon';
import { DeleteIcon } from '../../../../../../../../../../../icons/delete-icon';

interface JobPositionTabTableActionsProps {
  onEdit: () => void;
  onDelete: () => void;
}

const JobPositionTabTableActions: React.FC<JobPositionTabTableActionsProps> = ({
  onEdit,
  onDelete,
}) => {
  return (
    <StyledJobPositionTabTableActionsMainWrapper>
      <StyledJobPositionTabTableActionsIconWrapper onClick={onEdit}>
        <SettingsEditIcon />
      </StyledJobPositionTabTableActionsIconWrapper>
      <StyledJobPositionTabTableActionsIconWrapper onClick={onDelete}>
        <DeleteIcon />
      </StyledJobPositionTabTableActionsIconWrapper>
    </StyledJobPositionTabTableActionsMainWrapper>
  );
};

export default JobPositionTabTableActions;
