import { css } from 'styled-components';
import { sizes } from './sizes';

export const media = {
  smallMobile: (styles: string) =>
    css`
      @media (max-width: ${sizes.smallMobile}) {
        ${styles}
      }
    `,
  mobile: (styles: string) =>
    css`
      @media (max-width: ${sizes.mobile}) {
        ${styles}
      }
    `,
  phablet: (styles: string) =>
    css`
      @media (max-width: ${sizes.phablet}) {
        ${styles}
      }
    `,
  tablet: (styles: string) =>
    css`
      @media (max-width: ${sizes.tablet}) {
        ${styles}
      }
    `,
  laptop: (styles: string) =>
    css`
      @media (max-width: ${sizes.laptop}) {
        ${styles}
      }
    `,
  desktop: (styles: string) =>
    css`
      @media (max-width: ${sizes.desktop}) {
        ${styles}
      }
    `,
  largeDesktop: (styles: string) =>
    css`
      @media (min-width: ${sizes.largeDesktop}) {
        ${styles}
      }
    `,
};
