import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledGlobalJobBoardHeroMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 140px;
  background-color: ${colors.alabaster};
`;

export const StyledGlobalJobBoardHeroInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
`;
