import { useState } from 'react';
import { ReportingBarAndMultiLineChartDataTypes } from '../../components/charts/charts-data-types';
import { ReportingTableState } from '../../components/tables/reporting-employees-table/reporting-employees-table';

export interface ReportingEmployeeTableDataTypes {
  name: string;
  age: string;
  gender: string;
  department: string;
  jobTitle: string;
  dateHired: string;
  location: string;
}

export interface ReportingEmployeeTableTurnoverDataTypes {
  name: string;
  dateOfDeparture: string;
  reasonForLeaving: string;
  tenure: string;
  department: string;
  jobTitle: string;
}

export const useReportingEmployeesData = () => {
  const [barChartData, setBarChartData] = useState<
    ReportingBarAndMultiLineChartDataTypes[]
  >([
    { name: 'Jan', avg: 60, max: 100, min: 80 },
    { name: 'Feb', avg: 40, max: 90, min: 70 },
    { name: 'Mar', avg: 50, max: 85, min: 65 },
    { name: 'Apr', avg: 60, max: 95, min: 75 },
    { name: 'May', avg: 55, max: 90, min: 70 },
    { name: 'Jun', avg: 65, max: 100, min: 80 },
    { name: 'Jul', avg: 50, max: 85, min: 65 },
    { name: 'Aug', avg: 60, max: 95, min: 75 },
    { name: 'Sep', avg: 65, max: 100, min: 80 },
    { name: 'Oct', avg: 55, max: 90, min: 70 },
    { name: 'Nov', avg: 60, max: 95, min: 75 },
    { name: 'Dec', avg: 55, max: 85, min: 65 },
  ]);

  const [reportingEmployeeTableData, setReportingEmployeeTableData] = useState<
    ReportingTableState<ReportingEmployeeTableDataTypes>
  >({
    headers: [
      'Name',
      'Age',
      'Gender',
      'Department',
      'Job Title',
      'Date Hired',
      'Location',
    ],

    data: [
      {
        name: 'John Don',
        age: '38 year',
        gender: 'Male',
        department: 'Programers',
        jobTitle: '.Net programer',
        dateHired: '12.05.2019',
        location: 'Skopje Macedonia',
      },
      {
        name: 'John Don',
        age: '38 year',
        gender: 'Male',
        department: 'Programers',
        jobTitle: '.Net programer',
        dateHired: '12.05.2019',
        location: 'Skopje Macedonia',
      },
      {
        name: 'John Don',
        age: '38 year',
        gender: 'Male',
        department: 'Programers',
        jobTitle: '.Net programer',
        dateHired: '12.05.2019',
        location: 'Skopje Macedonia',
      },
      {
        name: 'John Don',
        age: '38 year',
        gender: 'Male',
        department: 'Programers',
        jobTitle: '.Net programer',
        dateHired: '12.05.2019',
        location: 'Skopje Macedonia',
      },
      {
        name: 'John Don',
        age: '38 year',
        gender: 'Male',
        department: 'Programers',
        jobTitle: '.Net programer',
        dateHired: '12.05.2019',
        location: 'Skopje Macedonia',
      },
      {
        name: 'John Don',
        age: '38 year',
        gender: 'Male',
        department: 'Programers',
        jobTitle: '.Net programer',
        dateHired: '12.05.2019',
        location: 'Skopje Macedonia',
      },
    ],
  });

  const [
    reportingEmployeeTableTurnoverData,
    setReportingEmployeeTableTurnoverData,
  ] = useState<ReportingTableState<ReportingEmployeeTableTurnoverDataTypes>>({
    headers: [
      'Name',
      'Date of Departure',
      'Reason for Leaving',
      'Tenure',
      'Department',
      'Job Title',
    ],

    data: [
      {
        name: 'John Don',
        dateOfDeparture: '25.04.2024',
        reasonForLeaving: 'Lorem ipsum dolor',
        tenure: 'Lorem ipsum',
        department: 'Lorem ipsum dolor',
        jobTitle: '.Net programer',
      },
      {
        name: 'John Don',
        dateOfDeparture: '25.04.2024',
        reasonForLeaving: 'Lorem ipsum dolor',
        tenure: 'Lorem ipsum',
        department: 'Lorem ipsum dolor',
        jobTitle: '.Net programer',
      },
      {
        name: 'John Don',
        dateOfDeparture: '25.04.2024',
        reasonForLeaving: 'Lorem ipsum dolor',
        tenure: 'Lorem ipsum',
        department: 'Lorem ipsum dolor',
        jobTitle: '.Net programer',
      },
      {
        name: 'John Don',
        dateOfDeparture: '25.04.2024',
        reasonForLeaving: 'Lorem ipsum dolor',
        tenure: 'Lorem ipsum',
        department: 'Lorem ipsum dolor',
        jobTitle: '.Net programer',
      },
      {
        name: 'John Don',
        dateOfDeparture: '25.04.2024',
        reasonForLeaving: 'Lorem ipsum dolor',
        tenure: 'Lorem ipsum',
        department: 'Lorem ipsum dolor',
        jobTitle: '.Net programer',
      },
      {
        name: 'John Don',
        dateOfDeparture: '25.04.2024',
        reasonForLeaving: 'Lorem ipsum dolor',
        tenure: 'Lorem ipsum',
        department: 'Lorem ipsum dolor',
        jobTitle: '.Net programer',
      },
    ],
  });

  return {
    barChartData,
    setBarChartData,
    reportingEmployeeTableData,
    reportingEmployeeTableTurnoverData,
    setReportingEmployeeTableData,
    setReportingEmployeeTableTurnoverData,
  };
};
