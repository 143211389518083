import React, { useState } from 'react';
import axios from 'axios';
import {
  StyledCreateNewEmployeeJobPositionBackBtn,
  StyledCreateNewEmployeeJobPositionBtnsWrapper,
  StyledCreateNewEmployeeJobPositionForm,
  StyledCreateNewEmployeeJobPositionFormInput,
  StyledCreateNewEmployeeJobPositionFormItem,
  StyledCreateNewEmployeeJobPositionMainWrapper,
  StyledCreateNewEmployeeJobPositionSubmitBtn,
} from './create-edit-delete-job-position.styled';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Text, font } from '../../../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../../../style-utils/colors';
import { Company } from '../../../../../../../../../app-router/component-props';
import { apiRoute } from '../../../../../../../../../../constants/api-constants';

interface Props {
  onClose: (success: boolean, message?: string) => void;
  user: Company;
  jobPosition: { id: number; job_position: string };
}

interface EditEmployeeJobPositionTypes {
  job_position: string;
}

export const editEmployeeJobPositionValuesValidationSchema = yup
  .object()
  .shape({
    job_position: yup.string().required('Job position title is required!'),
  });

const EditEmployeeJobPosition: React.FC<Props> = ({
  onClose,
  user,
  jobPosition,
}) => {
  const baseURL = apiRoute;
  const [validateOnChange] = useState(false);

  const editEmployeeJobPositionValues: EditEmployeeJobPositionTypes = {
    job_position: jobPosition.job_position,
  };

  const formik = useFormik({
    initialValues: editEmployeeJobPositionValues,
    validationSchema: editEmployeeJobPositionValuesValidationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: (values) => {
      axios
        .put(`${baseURL}/employee_job_position/${jobPosition.id}/${user.id}`, {
          job_position: values.job_position,
        })
        .then(() => {
          onClose(true, 'Job position updated');
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.error || 'Error updating job position';
          onClose(false, errorMessage);
        });
    },
  });

  return (
    <StyledCreateNewEmployeeJobPositionMainWrapper>
      <StyledCreateNewEmployeeJobPositionForm
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <Text
          fontFamily={font.family}
          margin="0"
          color={colors.base_black}
          fontSize={28}
          fontWeight={700}
        >
          Edit job position
        </Text>
        <StyledCreateNewEmployeeJobPositionFormItem>
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_black}
            fontSize={18}
            fontWeight={700}
          >
            Title
          </Text>
          <StyledCreateNewEmployeeJobPositionFormInput
            type="text"
            name="job_position"
            placeholder="Enter title"
            value={formik.values.job_position}
          />
          {formik.errors.job_position && (
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.error}
              fontSize={12}
              fontWeight={700}
            >
              {formik.errors.job_position}
            </Text>
          )}
        </StyledCreateNewEmployeeJobPositionFormItem>
        <StyledCreateNewEmployeeJobPositionBtnsWrapper>
          <StyledCreateNewEmployeeJobPositionBackBtn
            type="button"
            onClick={() => onClose(false)}
          >
            Back to Settings
          </StyledCreateNewEmployeeJobPositionBackBtn>
          <StyledCreateNewEmployeeJobPositionSubmitBtn type="submit">
            Save
          </StyledCreateNewEmployeeJobPositionSubmitBtn>
        </StyledCreateNewEmployeeJobPositionBtnsWrapper>
      </StyledCreateNewEmployeeJobPositionForm>
    </StyledCreateNewEmployeeJobPositionMainWrapper>
  );
};

export default EditEmployeeJobPosition;
