import React, { ChangeEvent, useState } from 'react';
import { StyledSelectFilter, FilterWrapper } from './filter.styled';
import { DownArrow } from '../../../../../icons/down-arrow';

export const SkillMapFilter: React.FC = () => {
  const [employeeFilterValue, setEmployeeFilterValue] = useState('');

  const handleOnFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setEmployeeFilterValue(value);
  };

  return (
    <FilterWrapper width="124px">
      <StyledSelectFilter
        defaultValue={employeeFilterValue}
        onChange={handleOnFilterChange}
      >
        <option value={'filter_by'}>{'Filter by'} </option>
        <option value={'department'}>{'Department'} </option>
        <option value={'role'}>{'Role'} </option>
        <option value={'location'}>{'Location'} </option>
        <option value={'experience'}>{'Experience level'} </option>
      </StyledSelectFilter>
      <DownArrow />
    </FilterWrapper>
  );
};
