import styled from 'styled-components';
import { colors } from '../../../../../../../../../../style-utils/colors';
import { font } from '../../../../../../../../../../style-utils/text';

export const StyledCreateNewEmployeeJobPositionMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledCreateNewEmployeeJobPositionForm = styled.form`
  box-sizing: border-box;
  width: 670px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background-color: ${colors.creme_100};
  border-radius: 16px;
`;

export const StyledCreateNewEmployeeJobPositionFormItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.base_white};
  border-radius: 8px;
  padding: 16px 24px;
`;

export const StyledCreateNewEmployeeJobPositionFormInput = styled.input`
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${colors.neutral_50};
  border-style: none;
  padding: 12px 16px;
`;

export const StyledCreateNewEmployeeJobPositionBtnsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

export const StyledCreateNewEmployeeJobPositionSubmitBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  padding: 16px 24px;
  background-color: ${colors.primary_500};
  font-family: ${font.family};
  color: ${colors.base_white};
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
`;

export const StyledCreateNewEmployeeJobPositionBackBtn = styled(
  StyledCreateNewEmployeeJobPositionSubmitBtn
)`
  background-color: ${colors.primary_100};
  color: ${colors.base_black};
`;
