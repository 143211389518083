import React from 'react';

export const PhoneIconCyan = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M14.6667 11.78V13.78C14.6675 13.9657 14.6294 14.1494 14.555 14.3195C14.4807 14.4897 14.3716 14.6424 14.2348 14.7679C14.0979 14.8934 13.9364 14.989 13.7605 15.0485C13.5847 15.1079 13.3983 15.13 13.2134 15.1133C11.1619 14.8904 9.19137 14.1894 7.46004 13.0667C5.84926 12.0431 4.48359 10.6774 3.46004 9.06665C2.33336 7.32745 1.6322 5.34731 1.41337 3.28665C1.39671 3.10229 1.41862 2.91649 1.4777 2.74107C1.53679 2.56564 1.63175 2.40444 1.75655 2.26773C1.88134 2.13102 2.03324 2.02179 2.20256 1.947C2.37189 1.87221 2.55493 1.83349 2.74004 1.83332H4.74004C5.06357 1.83013 5.37723 1.9447 5.62254 2.15567C5.86786 2.36664 6.02809 2.65961 6.07337 2.97998C6.15779 3.62003 6.31434 4.24847 6.54004 4.85332C6.62973 5.09193 6.64915 5.35126 6.59597 5.60057C6.5428 5.84988 6.41928 6.07872 6.24004 6.25998L5.39337 7.10665C6.34241 8.77568 7.72434 10.1576 9.39337 11.1067L10.24 10.26C10.4213 10.0807 10.6501 9.95722 10.8994 9.90405C11.1488 9.85088 11.4081 9.87029 11.6467 9.95998C12.2516 10.1857 12.88 10.3422 13.52 10.4267C13.8439 10.4723 14.1396 10.6355 14.3511 10.885C14.5625 11.1345 14.6748 11.453 14.6667 11.78Z"
          stroke="#5789C2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
