import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../../../../constants/api-constants';

interface HeroStatistics {
  activeCompaniesCount: string;
  totalEmployeesCount: string;
}

interface UseHeroStatisticsState {
  data: HeroStatistics | null;
  loading: boolean;
  error: string | null;
}

const useGlobalJobBoardHeroStatistics = () => {
  const [statistics, setStatistics] = useState<UseHeroStatisticsState>({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    axios
      .get(`${apiRoute}/global-job-board/statistics`)
      .then((response) => {
        const { data } = response.data;
        setStatistics({ data, loading: false, error: null });
      })
      .catch((error) => {
        console.error('Error fetching hero statistics:', error);
        setStatistics({ data: null, loading: false, error: error.message });
      });
  }, []);

  return statistics;
};

export default useGlobalJobBoardHeroStatistics;
