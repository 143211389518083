export const extractDateAndTime = (isoString: string | undefined) => {
  if (!isoString) {
    // Handle undefined or empty string input
    return { formattedDate: 'Invalid Date', formattedTime: 'Invalid Time' };
  }

  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return { formattedDate: 'Invalid Date', formattedTime: 'Invalid Time' };
  }

  // Extract the date part in YYYY-MM-DD format
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  // Extract the time part in HH:mm AM/PM format
  let hours = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = `${`0${hours}`.slice(-2)}:${minutes} ${ampm}`;

  return { formattedDate, formattedTime };
};
