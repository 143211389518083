import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';
import { font } from '../../../../../style-utils/text';

export const StyledGlobalJobBoardFooterMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondary_500};
  padding: 64px 140px;
`;

export const StyledGlobalJobBoardFooterCenterWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledGlobalJobBoardFooterUpperWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 0.5px solid ${colors.white};
`;

export const StyledGlobalJobBoardFooterLogoImg = styled.img`
  box-sizing: border-box;
  height: 50px;
`;

export const StyledGlobalJobBoardFooterMenuItemsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const StyledGlobalJobBoardFooterMenuItem = styled.div`
  box-sizing: border-box;
  font-family: ${font.familyMontserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: '19.5px';
  color: ${colors.white};
  cursor: pointer;
`;

export const StyledGlobalJobBoardFooterSocialMediaIconsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledGlobalJobBoardFooterSocialMediaIcon = styled.div`
  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledGlobalJobBoardFooterBottomWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
`;
