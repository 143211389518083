import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiRoute } from '../../constants/api-constants';

const useCheckTrialPeriod = (userId: string | null) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [user, setUser] = useState<any>(null);
  // console.log('Initial user state:', user);

  const [loading, setLoading] = useState(true);
  // console.log('Initial loading state:', loading);

  const testing = false;
  console.log('Testing mode:', testing);

  const fetchUserData = async () => {
    console.log('fetchUserData called with userId:', userId);
    if (!userId) {
      console.warn('No userId provided. Exiting fetchUserData.');

      return;
    }

    try {
      console.log('Fetching user data to check trial period.');
      if (testing) console.log(`User ID: ${userId}`);

      const response = await axios.get(`${apiRoute}/entity/${userId}`);
      console.log('Axios GET response:', response);

      setUser(response.data);
      console.log('User data set to state:', response.data);
      if (testing) console.log('User data fetched:', response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
      console.log('Loading state set to false.');
    }
  };

  useEffect(() => {
    console.log('useEffect triggered by userId change:', userId);

    if (userId) {
      fetchUserData();
    } else {
      setLoading(false);
      console.log('No userId provided. Loading state set to false.');
    }
  }, [userId]);

  useEffect(() => {
    console.log('useEffect triggered by user, loading, or userId change.');
    console.log(
      'Current states - userId:',
      userId,
      ', loading:',
      loading,
      ', user:',
      user
    );

    if (!userId) {
      console.warn('No userId. Exiting trial period check.');

      return;
    }

    if (loading) {
      console.warn('Data is still loading. Exiting trial period check.');

      return;
    }

    if (!user) {
      console.warn('User data is not available. Exiting trial period check.');

      return;
    }

    if (user.is_blocked) {
      console.warn('User is blocked. Redirecting to /blocked.');
      navigate('/blocked');

      return;
    }

    if (testing) console.log('User data available:', user);

    const isEmployee = !!user?.company?.last_subscription_payed_at;
    console.log('isEmployee:', isEmployee);

    const company = isEmployee ? user.company : user;
    console.log('Company data:', company);

    if (!company) {
      if (testing) console.error('Company details missing.');
      console.error('Company details missing.');

      return;
    }

    const currentDate = new Date();
    console.log('Current Date:', currentDate);

    const timezoneFlexInMillis = 1000 * 3600 * 24;
    console.log('Timezone flexibility in milliseconds:', timezoneFlexInMillis);

    if (company.is_on_trial) {
      console.log('Company is on trial period.');

      const createdAt = new Date(company.createdAt);
      console.log('Company createdAt date:', createdAt);

      const daysDifference =
        (currentDate.getTime() - createdAt.getTime()) / (1000 * 3600 * 24);
      console.log('Days difference since creation:', daysDifference);

      if (daysDifference > 7) {
        if (testing) console.log('Trial expired. Redirecting to Change Plan.');
        console.log('Trial expired. Redirecting to Change Plan.');
        navigate('/change-plan');
      } else {
        const daysLeft = 7 - Math.floor(daysDifference);
        if (testing) console.log(`Trial active. ${daysLeft} days left.`);
        console.log(`Trial active. ${daysLeft} days left.`);
      }
    } else {
      console.log('Company is not on trial period.');

      const lastSubscriptionPaidAt = new Date(
        company.last_subscription_payed_at || null
      );
      console.log('Last subscription paid at:', lastSubscriptionPaidAt);

      if (isNaN(lastSubscriptionPaidAt.getTime())) {
        console.error('Invalid subscription date.');

        return;
      }

      const subscriptionValidUntil = new Date(lastSubscriptionPaidAt);
      subscriptionValidUntil.setMonth(subscriptionValidUntil.getMonth() + 1);
      console.log('Subscription valid until:', subscriptionValidUntil);

      const validFrom = new Date(
        lastSubscriptionPaidAt.getTime() - timezoneFlexInMillis
      );
      console.log('Subscription valid from:', validFrom);

      const validUntil = new Date(
        subscriptionValidUntil.getTime() + timezoneFlexInMillis
      );
      console.log(
        'Subscription valid until (with timezone flexibility):',
        validUntil
      );

      if (testing)
        console.log('Subscription valid from:', validFrom, 'to:', validUntil);

      if (currentDate >= validFrom && currentDate <= validUntil) {
        if (testing) console.log('Subscription is currently valid.');
        console.log('Subscription is currently valid.');
      } else {
        if (testing)
          console.log('Subscription expired. Redirecting to Change Plan.');
        console.log('Subscription expired. Redirecting to Change Plan.');
        if (user.company) {
          console.warn(
            'User is an employee. Redirecting to /blocked/employee.'
          );
          navigate('/employee/blocked');
        } else {
          navigate('/change-plan');
        }
      }
    }
  }, [user, navigate, loading, testing, userId]);

  return { loading };
};

export default useCheckTrialPeriod;
