import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logOut } from '../../../function-utils/log-out';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';

interface PaymentSuccessProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = ({
  user,
  setUser,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        await logOut(setUser, navigate);
      } catch (error) {
        console.error('Error checking payment status:', error);
        setError('An error occurred while checking your payment status.');
      } finally {
        setLoading(false);
      }
    };

    checkPaymentStatus();

    const timer = setTimeout(() => {
      logOut(setUser);
      navigate('/login');
    }, 8000);

    return () => clearTimeout(timer);
  }, [navigate, searchParams, user]);

  return (
    <div>
      {loading ? (
        <FullScreenSpinner />
      ) : error ? (
        <div>
          <h1>Error</h1>
          <p>{error}</p>
        </div>
      ) : (
        <>
          <h1>Payment Successful!</h1>
          <p>You will be logged out and redirected shortly.</p>
        </>
      )}
    </div>
  );
};
