import React, { useRef, useState } from 'react';
import axios from 'axios';
import {
  StyledButtonsWrapper,
  StyledCloseButton,
  StyledDeleteBtn,
  StyledDeleteBtnAndFilenameWrapper,
  StyledEmployeeAttachCVPortfolioModalFileInput,
  StyledEmployeeAttachCVPortfolioModalFileInputBtn,
  StyledEmployeeAttachCVPortfolioModalForm,
  StyledEmployeeAttachCVPortfolioModalMainWrapper,
  StyledFileInputAndErrorMsgWrapper,
  StyledSubmitButton,
} from './employee-attach-cv-portfolio-modal.styled';
import { font, Text } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { CloseIcon } from '../../../icons/close-icon';
import { apiRoute } from '../../../../constants/api-constants';

interface EmployeeAttachCvPortfolioModalProps {
  onClose: () => void;
  employeeId: string;
  documentType: 'CV' | 'portfolio';
  getEmployeesFromDB: () => void;
  setPopUp: React.Dispatch<
    React.SetStateAction<{
      text: string;
      type: 'success' | 'error';
    } | null>
  >;
}

const EmployeeAttachCvPortfolioModal: React.FC<
  EmployeeAttachCvPortfolioModalProps
> = ({ onClose, employeeId, documentType, setPopUp, getEmployeesFromDB }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (documentType === 'CV') {
      if (file) {
        const fileType = file.type;

        if (fileType !== 'application/pdf') {
          setErrorMessage('Only PDF files are allowed for CV uploads.');
          setSelectedFile(null);

          return;
        }

        setSelectedFile(file);
        setErrorMessage(null);
      } else {
        setSelectedFile(null);
        setErrorMessage(null);
      }
    } else {
      setSelectedFile(file);
      setErrorMessage(null);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedFile) {
      setErrorMessage('Please attach a file before submitting.');

      return;
    }

    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('employeeId', employeeId);
    formData.append('documentType', documentType);

    axios
      .post(`${apiRoute}/employee/upload-cv-or-portfolio`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('File uploaded successfully:', response.data);
        setErrorMessage(null);
        getEmployeesFromDB();
        setPopUp({
          text: 'The document was successfully added!',
          type: 'success',
        });
        onClose();
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        setErrorMessage('Failed to upload the file. Please try again.');
        setPopUp({
          text: `Error uploading file: ${
            error.response?.data || error.message
          }`,
          type: 'error',
        });
      });
  };

  return (
    <StyledEmployeeAttachCVPortfolioModalMainWrapper onClick={onClose}>
      <StyledEmployeeAttachCVPortfolioModalForm
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <Text
          fontFamily={font.family}
          margin="0"
          color={colors.base_black}
          fontSize={24}
          fontWeight={700}
        >
          Attach {documentType === 'CV' ? 'CV' : 'Portfolio'}
        </Text>
        <StyledFileInputAndErrorMsgWrapper>
          <StyledEmployeeAttachCVPortfolioModalFileInputBtn
            type="button"
            onClick={handleFileInputClick}
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={16}
              fontWeight={700}
            >
              Attach{' '}
              {documentType === 'CV'
                ? 'CV File (pdf only)'
                : 'Portfolio File (pdf, png, jpg)'}
            </Text>
          </StyledEmployeeAttachCVPortfolioModalFileInputBtn>
          {selectedFile && (
            <StyledDeleteBtnAndFilenameWrapper>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.secondary_500}
                fontSize={14}
                fontWeight={700}
              >
                {selectedFile.name}
              </Text>
              <StyledDeleteBtn
                type="button"
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <CloseIcon />
              </StyledDeleteBtn>
            </StyledDeleteBtnAndFilenameWrapper>
          )}
          {errorMessage && (
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.error}
              fontSize={12}
              fontWeight={700}
            >
              {errorMessage}
            </Text>
          )}
        </StyledFileInputAndErrorMsgWrapper>
        <StyledEmployeeAttachCVPortfolioModalFileInput
          type="file"
          name="documents"
          id="documents"
          accept="application/pdf, image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {/* Submit button */}
        <StyledButtonsWrapper>
          <StyledCloseButton type="button" onClick={onClose}>
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_white}
              fontSize={16}
              fontWeight={700}
            >
              Close
            </Text>
          </StyledCloseButton>
          <StyledSubmitButton type="submit">
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_white}
              fontSize={16}
              fontWeight={700}
            >
              Submit
            </Text>
          </StyledSubmitButton>
        </StyledButtonsWrapper>
      </StyledEmployeeAttachCVPortfolioModalForm>
    </StyledEmployeeAttachCVPortfolioModalMainWrapper>
  );
};

export default EmployeeAttachCvPortfolioModal;
