import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  StyledReportingTabBackBtn,
  StyledReportingTabsCenterWrapper,
  StyledReportingTabsMainWrapper,
  StyledReportingTabTitleAndBackBtnWrapper,
} from '../tabs.styled';
import { font, Text } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import TabSelector from '../../components/tab-selector/tab-selector';
import { reportingTabData } from '../../components/tab-selector/tab-data';
import { BackArrow } from '../../../../icons/back-arrow';
import ReportingContractorUtilization from './tabs/contractor-utilization/reporting-contractor-utilization';
import ReportingContractRenewal from './tabs/contract-renewal/reporting-contract-renewal';

interface ReportingContractorsMainProps {
  setActiveTabIdToParent: Dispatch<SetStateAction<number>>;
}

const ReportingContractorsMain: React.FC<ReportingContractorsMainProps> = ({
  setActiveTabIdToParent,
}) => {
  const [activeTabId, setActiveTabId] = useState<number>(0);

  const tabs = [
    <ReportingContractorUtilization key={0} />,
    <ReportingContractRenewal key={1} />,
  ];

  return (
    <StyledReportingTabsMainWrapper>
      <StyledReportingTabsCenterWrapper>
        <StyledReportingTabTitleAndBackBtnWrapper>
          <Text
            fontSize={38}
            fontFamily={font.family}
            color={colors.base_black}
            fontWeight={700}
            margin="0"
          >
            Reporting - Contractors
          </Text>
          <StyledReportingTabBackBtn onClick={() => setActiveTabIdToParent(0)}>
            <BackArrow />
            <Text
              fontSize={16}
              fontFamily={font.family}
              color={colors.base_black}
              fontWeight={700}
              margin="0"
            >
              Back
            </Text>
          </StyledReportingTabBackBtn>
        </StyledReportingTabTitleAndBackBtnWrapper>
        <TabSelector
          setActiveTabId={setActiveTabId}
          activeTabId={activeTabId}
          numberOfTabs={3}
          tabsData={reportingTabData}
        />
        {tabs[activeTabId]}
      </StyledReportingTabsCenterWrapper>
    </StyledReportingTabsMainWrapper>
  );
};

export default ReportingContractorsMain;
