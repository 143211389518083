import styled from 'styled-components';

export const StyledJobPositionTabTableActionsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const StyledJobPositionTabTableActionsIconWrapper = styled.div`
  cursor: pointer;
`;
