import React from 'react';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import {
  JobPostingsContainer,
  JobPosition,
  PositionAndDateContainer,
  Date,
  JobDescriptionContainer,
  GrayBlockText,
  GrayBlockTextContainer,
  CompanyName,
  SalaryAndLocation,
  SalaryAndLocationContainer,
  IconContainer,
  ApplyButton,
  IconAndApplyButtonContainer,
  FormPositionWrapper,
} from './job-postings.styled';
import {
  formatDateToLocal,
  replaceSlashWithDot,
} from '../../../function-utils/utc-date-converter';
import { ShareIcon } from '../../icons/upload';
import { Company } from '../../app-router/component-props';
import { NavigateFunction } from 'react-router-dom';
import useScreenSize from '../../../style-utils/use-screen-size';
import { ColorTemplate } from './company-job-board';

interface JobPostingProps {
  job: JobOfferWithCompanyData;
  selectJob: (job: JobOfferWithCompanyData) => void;
  toggleApplyForm?: () => void;
  company: Company | null | undefined;
  navigate: NavigateFunction;
  openFormJobId: string | null;
  setOpenFormJobId: (job: JobOfferWithCompanyData | null) => void;
  jobPostMobilePath: string;
  colorTemplate: ColorTemplate;
  fromPage?: string;
}

export const JobPostings: React.FC<JobPostingProps> = ({
  job,
  selectJob,
  company,
  navigate,
  openFormJobId,
  setOpenFormJobId,
  jobPostMobilePath,
  colorTemplate,
  fromPage,
}) => {
  console.log(fromPage);

  const handleToggleShareForm = () => {
    setOpenFormJobId(openFormJobId === job.id ? null : job);
  };

  const { isTablet } = useScreenSize();

  return (
    <>
      <JobPostingsContainer
        background={colorTemplate.background_fill_color}
        onClick={() => {
          selectJob(job);
          isTablet && navigate(jobPostMobilePath);
        }}
      >
        <PositionAndDateContainer>
          <div>
            <JobPosition color={colorTemplate.title_color}>
              {job.job_position}
            </JobPosition>
            <CompanyName color={colorTemplate.company_title_color}>
              {company?.name || job.company_name}
            </CompanyName>
          </div>
          <Date color={colorTemplate.title_color}>
            {replaceSlashWithDot(formatDateToLocal(job.createdAt))}
          </Date>
        </PositionAndDateContainer>
        <JobDescriptionContainer
          dangerouslySetInnerHTML={{ __html: job.job_description }}
          color={colorTemplate.text_color}
        />
        <GrayBlockTextContainer>
          <GrayBlockText
            backgroundColor={colorTemplate.filter_color}
            textColor={colorTemplate.filter_text_color}
          >
            {job.job_category}
          </GrayBlockText>
          <GrayBlockText
            backgroundColor={colorTemplate.filter_color}
            textColor={colorTemplate.filter_text_color}
          >
            {job.seniority_level}
          </GrayBlockText>
          <GrayBlockText
            backgroundColor={colorTemplate.filter_color}
            textColor={colorTemplate.filter_text_color}
          >
            {job.work_location}
          </GrayBlockText>
        </GrayBlockTextContainer>
        <SalaryAndLocationContainer>
          <SalaryAndLocation textColor={colorTemplate.title_color}>
            {job.location}
          </SalaryAndLocation>
          <IconAndApplyButtonContainer>
            <FormPositionWrapper>
              <IconContainer
                onClick={handleToggleShareForm}
                backgroundColor={
                  colorTemplate.background_fill_color === '#FDFDFD'
                    ? '#F3F2F2'
                    : '#FDFDFD'
                }
              >
                <ShareIcon strokeColor={colorTemplate.button_color} />
              </IconContainer>
            </FormPositionWrapper>

            <ApplyButton
              onClick={() => {
                selectJob(job);
                isTablet && navigate(jobPostMobilePath);
              }}
              backgroundColor={colorTemplate.button_color}
              textColor={colorTemplate.button_text_color}
            >
              View
            </ApplyButton>
          </IconAndApplyButtonContainer>
        </SalaryAndLocationContainer>
      </JobPostingsContainer>
    </>
  );
};
