const getApiRoute = (apiRoute: string | undefined) => {
  switch (apiRoute) {
    case 'dev':
      return process.env.REACT_APP_DEV;
    case 'staging':
      return process.env.REACT_APP_STAGING;
    case 'prod':
      return process.env.REACT_APP_PROD;
    default:
      return process.env.REACT_APP_DEV;
  }
};

export const apiRoute = getApiRoute(process.env.REACT_APP_ENV);
