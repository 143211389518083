import React from 'react';
import {
  FooterContainer,
  FooterElements,
  Links,
  SocialMediaIcons,
} from './footer.styled';
import { LinkedinIcon } from '../icons/linkedin';
import { FacebookIcon } from '../icons/facebook';
import { InstagramIcon } from '../icons/instagram';
import { Link } from 'react-router-dom';
import { Text } from '../../style-utils/text';

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterElements>
        <Links>
          <Text
            fontFamily="Lato"
            fontSize={13}
            fontWeight={300}
            color="#5e5a57"
          >
            Terms of Use
          </Text>
          |
          <Text
            fontFamily="Lato"
            fontSize={13}
            fontWeight={300}
            color="#5e5a57"
          >
            Privacy Policy
          </Text>
        </Links>
        <Text fontFamily="Lato" fontSize={13} fontWeight={300} color="#5e5a57">
          Copyright 2024 © All rights by Worwise
        </Text>
        <SocialMediaIcons>
          <Link to="https://www.linkedin.com/company/worwise" target="blank">
            <LinkedinIcon />
          </Link>
          <Link
            to="https://www.facebook.com/people/WorWise/61564151662235/"
            target="blank"
          >
            <FacebookIcon />
          </Link>
          <Link to="https://www.instagram.com/worwise_/" target="blank">
            <InstagramIcon />
          </Link>
        </SocialMediaIcons>
      </FooterElements>
    </FooterContainer>
  );
};
