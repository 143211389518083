import React from 'react';
import {
  StyledGlobalJobBoardFooterBottomWrapper,
  StyledGlobalJobBoardFooterCenterWrapper,
  StyledGlobalJobBoardFooterLogoImg,
  StyledGlobalJobBoardFooterMainWrapper,
  StyledGlobalJobBoardFooterMenuItem,
  StyledGlobalJobBoardFooterMenuItemsWrapper,
  StyledGlobalJobBoardFooterSocialMediaIcon,
  StyledGlobalJobBoardFooterSocialMediaIconsWrapper,
  StyledGlobalJobBoardFooterUpperWrapper,
} from './global-job-board-footer.styled';
import WhiteLogo from '../../../../../assets/images/worwise_white_logo.png';
import { GlobalJobBoardFooterMenuItemsData } from './global-job-board-footer-data';
import { NavigateFunction } from 'react-router-dom';
import { LinkedinIcon } from '../../../../icons/linkedin';
import { colors } from '../../../../../style-utils/colors';
import { FacebookIcon } from '../../../../icons/facebook';
import { InstagramIcon } from '../../../../icons/instagram';
import { Text, font } from '../../../../../style-utils/text';

interface GlobalJobBoardFooterTypes {
  navigate: NavigateFunction;
}

const GlobalJobBoardFooter: React.FC<GlobalJobBoardFooterTypes> = ({
  navigate,
}) => {
  return (
    <StyledGlobalJobBoardFooterMainWrapper>
      <StyledGlobalJobBoardFooterCenterWrapper>
        <StyledGlobalJobBoardFooterUpperWrapper>
          <StyledGlobalJobBoardFooterLogoImg src={WhiteLogo} />
          <StyledGlobalJobBoardFooterMenuItemsWrapper>
            {GlobalJobBoardFooterMenuItemsData.map((menuItem, index) => (
              <StyledGlobalJobBoardFooterMenuItem
                key={index}
                onClick={() => navigate(menuItem.linkTo)}
              >
                {menuItem.name}
              </StyledGlobalJobBoardFooterMenuItem>
            ))}
          </StyledGlobalJobBoardFooterMenuItemsWrapper>
          <StyledGlobalJobBoardFooterSocialMediaIconsWrapper>
            <StyledGlobalJobBoardFooterSocialMediaIcon
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/company/worwise',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <LinkedinIcon fillColor={colors.white} />
            </StyledGlobalJobBoardFooterSocialMediaIcon>
            <StyledGlobalJobBoardFooterSocialMediaIcon
              onClick={() =>
                window.open(
                  'https://www.facebook.com/people/WorWise/61564151662235/',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <FacebookIcon strokeColor={colors.white} />
            </StyledGlobalJobBoardFooterSocialMediaIcon>
            <StyledGlobalJobBoardFooterSocialMediaIcon
              onClick={() =>
                window.open(
                  'https://www.instagram.com/worwise_/',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <InstagramIcon strokeColor={colors.white} />
            </StyledGlobalJobBoardFooterSocialMediaIcon>
          </StyledGlobalJobBoardFooterSocialMediaIconsWrapper>
        </StyledGlobalJobBoardFooterUpperWrapper>
        <StyledGlobalJobBoardFooterBottomWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={14}
            fontWeight={400}
            lineHeight="17.07px"
            color={colors.white}
            margin="0"
          >
            Copyright 2024 © All rights by Worwise
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={14}
            fontWeight={400}
            lineHeight="17.07px"
            color={colors.white}
            margin="0"
          >
            Terms & Conditions | Privacy Policy
          </Text>
        </StyledGlobalJobBoardFooterBottomWrapper>
      </StyledGlobalJobBoardFooterCenterWrapper>
    </StyledGlobalJobBoardFooterMainWrapper>
  );
};

export default GlobalJobBoardFooter;
