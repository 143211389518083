import React, { useState } from 'react';
import {
  ButtonContainer,
  ButtonTitle,
  InputContainer,
  Label,
  StyledInput,
} from '../../login/login.styled';
import { Text } from '../../../style-utils/text';
import { createItem } from '../../requests/requests';
import { CheckRing } from '../../icons/check-ring';
import axios from 'axios';

interface AddAdminInputProps {
  name: string;
  id: string;
}

export const AddAdminInputs: React.FC<AddAdminInputProps> = ({ name, id }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
  };

  const handleButtonClick = async () => {
    if (isValidEmail(email)) {
      try {
        const response = await createItem('add-admin', {
          email,
          name,
          companyId: id,
        });
        console.log(response);
        setSuccess(true);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage =
            err.response?.data?.message ||
            'Failed to send the email. Please try again.';
          setError(errorMessage);
        } else {
          setError('An unexpected error occurred. Please try again.');
        }
        setSuccess(false);
      }
    } else {
      setError('Please enter a valid email address.');
    }
  };

  const handleBackClick = () => {
    setSuccess(false);
  };

  return success ? (
    <>
      <InputContainer>
        <Text>
          Email sent successfully!
          <CheckRing />
        </Text>
      </InputContainer>
      <ButtonContainer mt={1} type="button" onClick={() => handleBackClick()}>
        <ButtonTitle>{'<'}</ButtonTitle>
        <ButtonTitle>Add Another Admin</ButtonTitle>
      </ButtonContainer>
    </>
  ) : (
    <>
      <InputContainer>
        <Label>Email address</Label>
        <StyledInput
          type="text"
          name="email"
          value={email}
          placeholder="Email address"
          onChange={handleEmailChange}
        />
        {error && (
          <Text color="red" mt={12}>
            {error}
          </Text>
        )}
      </InputContainer>
      <ButtonContainer mt={12} type="button" onClick={handleButtonClick}>
        <ButtonTitle>Send Invitation Email</ButtonTitle>
        <ButtonTitle>{'>'}</ButtonTitle>
      </ButtonContainer>
    </>
  );
};
