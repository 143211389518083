import React from 'react';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import CompanyDemoImage from '../../../../assets/images/img_avatar.png';
import { SettingsEmailIcon } from '../../../icons/settings-email-icon';
import { SettingsWebsiteIcon } from '../../../icons/settings-website-icon';
import {
  StyledCompanyDataGrid,
  StyledCompanyDataGridItem,
  StyledCompanyDataWrapper,
  StyledCompanyImage,
  StyledCompanyInfoAndImageWrapper,
  StyledCompanyInfoWrapper,
  //   StyledEditBtn,
} from '../../settings/components/company-info/company-info.styled';

const AdminInfo: React.FC<DefaultComponentProps> = ({ user }) => {
  return (
    <StyledCompanyInfoWrapper>
      {/* <StyledEditBtn>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={14}
          fontWeight={700}
          color={colors.base_black}
        >
          Edit
        </Text>
        <SettingsEditIcon />
      </StyledEditBtn> */}
      <StyledCompanyInfoAndImageWrapper>
        <StyledCompanyImage src={CompanyDemoImage} />
        <StyledCompanyDataWrapper>
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={36}
            fontWeight={700}
            color={colors.neutral_900}
          >
            {user.name}
          </Text>
          <StyledCompanyDataGrid>
            <StyledCompanyDataGridItem>
              <SettingsEmailIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.email ? user.email : '/'}
              </Text>
            </StyledCompanyDataGridItem>
            <StyledCompanyDataGridItem>
              <SettingsWebsiteIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.website ? user.website : '/'}
              </Text>
            </StyledCompanyDataGridItem>
          </StyledCompanyDataGrid>
        </StyledCompanyDataWrapper>
      </StyledCompanyInfoAndImageWrapper>
    </StyledCompanyInfoWrapper>
  );
};

export default AdminInfo;
