import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';

export const FaqContainer = styled.div`
  width: inherit;
  height: auto;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.h3`
  font-size: 30px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #f9846f29;
  text-decoration-thickness: 5px;
  text-underline-offset: 2px;

  ${media.laptop(`
    font-size: 22px;
  `)}
`;

export const QuestionsContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-content: center;

  ${media.laptop(`
    width: 90%;
  `)}
`;

export const QuestionsList = styled.ul`
  width: 100%;
  max-width: 1440px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-content: center;
  gap: 25px;
  padding-inline-start: 0px;

  ${media.laptop(`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin: 0;
    padding: 0;grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin: 0;
    padding: 0;
  `)}
`;

export const QuestionItem = styled.li`
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  align-self: start;

  ${media.laptop(`
    width: 90%;
  `)}
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

  ${media.laptop(`
    font-size: 14px;
  `)}
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const AnswerText = styled.p`
  font-size: 14px;
  line-height: 25px;
  padding-top: 10px;
`;
