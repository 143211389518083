import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { StyledChartsMainWrapper } from '../charts.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { ReportingCustomBarChartDataTypes } from '../charts-data-types';

interface CustomBarChartProps {
  name: string;
  customBarChartData: ReportingCustomBarChartDataTypes[];
}

const ReportingCustomBarChart: React.FC<CustomBarChartProps> = ({
  name,
  customBarChartData,
}) => {
  return (
    <StyledChartsMainWrapper>
      <Text
        fontSize={24}
        fontFamily={font.family}
        color={colors.base_black}
        fontWeight={700}
        margin="0"
      >
        {name}
      </Text>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={customBarChartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="series1" fill="#8884d8" radius={[10, 10, 0, 0]} />
          <Bar dataKey="series2" fill="#82ca9d" radius={[10, 10, 0, 0]} />
          <Bar dataKey="series3" fill="#ffc658" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </StyledChartsMainWrapper>
  );
};

export default ReportingCustomBarChart;
