import React from 'react';
import {
  StyledStep,
  StyledStepIndicatorWrapper,
} from './step-indicator.styled';

interface StepIndicatorPropTypes {
  currentStep: number;
  numberOfSteps: number;
}

const StepIndicator: React.FC<StepIndicatorPropTypes> = ({
  currentStep,
  numberOfSteps,
}) => {
  return (
    <StyledStepIndicatorWrapper>
      {Array.from({ length: numberOfSteps }, (_, index) => (
        <StyledStep
          key={index}
          isActive={index + 1 === currentStep}
          isPrevStep={index + 1 < currentStep}
        >
          {index + 1}
        </StyledStep>
      ))}
    </StyledStepIndicatorWrapper>
  );
};

export default StepIndicator;
