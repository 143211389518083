import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LogInWrapper,
  LogInFormContainer,
  TextContainer,
  CompanyTitle,
  CompanySubtitle,
  Description,
  LogInFormWrapper,
} from '../../login/login.styled';
import logoTeam from '../../../logo_team.png';
import //   Wrapper,
//   Button,
//   ButtonsWrapper,
'../change-plan/change-plan.styled';
import styled from 'styled-components';
import { font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { logOut } from '../../../function-utils/log-out';

interface BlockedEmployeePageProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  padding-bottom: 16px;
  font-size: 24px;
  font-family: ${font.family};
  color: ${colors.primary_500};
`;

export const SubTitle = styled.h1`
  display: flex;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: ${font.family}
  color: ${colors.base_black};
  font-weight: 500;
`;

export const Wrapper = styled.div<{
  isSignUp: boolean;
  padding?: string;
}>`
  background: ${colors.white};
  border-radius: 16px;
  padding: ${({ isSignUp, padding }) => (isSignUp ? padding || '40px' : '0px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
`;

export const BlockedEmployeePage: React.FC<BlockedEmployeePageProps> = ({
  user,
  setUser,
}) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  const handleLogout = async () => {
    if (setUser) {
      await logOut(setUser, navigate);
    }
  };

  useEffect(() => {
    handleLogout();
  });

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
          alt="Company Logo"
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer maxWidth="640px" overflow="none">
          <Wrapper padding="40px" id="wrapper" isSignUp={true} key={user?.id}>
            <Title>Your Subscription Plan Has Ended </Title>
            <SubTitle>
              We hope you’ve enjoyed our service. We wanted to inform you that
              your subscription plan has officially ended. To continue enjoying
              service, you can renew your subscription plan.
            </SubTitle>
            {/* <ButtonsWrapper>
              <Button disabled={false} onClick={handleLogout}>
                Logout Now
              </Button>
            </ButtonsWrapper> */}
          </Wrapper>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
