import React from 'react';
import {
  StyledGlobalJobBoardJobDetailsCenterComponentsInnerWrapper,
  StyledGlobalJobBoardJobDetailsCenterComponentsWrapper,
  StyledGlobalJobBoardJobDetailsMainWrapper,
} from './global-job-board-job-details.styled';
import GlobalJobBoardNavbar from '../components/navbar/global-job-board-navbar';
import { NavigateFunction, useLocation } from 'react-router-dom';
import GlobalJobBoardJobDetailsInfo from './components/job-info/global-job-board-job-details-info';
import useGlobalJobBoardJobAndCompanyData from './components/job-info/use-global-job-board-job-and-company-data';
import GlobalJobBoardOtherJobs from './components/other-and-similar-jobs/other-jobs/global-job-board-other-jobs';
import { StyledGlobalJobBoardFeaturedJobsWrapper } from '../components/featured-jobs/global-job-board-featured-jobs.styled';
import GlobalJobBoardSimilarJobs from './components/other-and-similar-jobs/similar-jobs/global-job-board-similar-jobs';
import GlobalJobBoardFooter from '../components/footer/global-job-board-footer';
import { RotatingSpinner } from '../../../spinner-new/spinner-new';

interface GlobalJobBoardJobDetailsTypes {
  // eslint-disable-next-line
  user: any;
  navigate: NavigateFunction;
}

const GlobalJobBoardJobDetails: React.FC<GlobalJobBoardJobDetailsTypes> = ({
  navigate,
  user,
}) => {
  const location = useLocation();
  const jobId = location.pathname.split('/').pop();

  const { globalJobBoardJobAndCompanyData, loading } =
    // useGlobalJobBoardJobAndCompanyData('97ef165f-ee73-49cf-a4e7-531c5d1833db');
    useGlobalJobBoardJobAndCompanyData(jobId);

  return (
    <StyledGlobalJobBoardJobDetailsMainWrapper>
      <GlobalJobBoardNavbar navigate={navigate} user={user} />
      <StyledGlobalJobBoardJobDetailsCenterComponentsWrapper>
        {loading ? (
          <RotatingSpinner />
        ) : (
          <StyledGlobalJobBoardJobDetailsCenterComponentsInnerWrapper>
            <GlobalJobBoardJobDetailsInfo
              navigate={navigate}
              jobData={globalJobBoardJobAndCompanyData}
            />
            <StyledGlobalJobBoardFeaturedJobsWrapper>
              <GlobalJobBoardOtherJobs navigate={navigate} jobId={jobId} />
              <GlobalJobBoardSimilarJobs
                navigate={navigate}
                jobId={jobId}
                jobTitle={globalJobBoardJobAndCompanyData?.job.job_position}
              />
            </StyledGlobalJobBoardFeaturedJobsWrapper>
          </StyledGlobalJobBoardJobDetailsCenterComponentsInnerWrapper>
        )}
      </StyledGlobalJobBoardJobDetailsCenterComponentsWrapper>
      {!loading && <GlobalJobBoardFooter navigate={navigate} />}
    </StyledGlobalJobBoardJobDetailsMainWrapper>
  );
};

export default GlobalJobBoardJobDetails;
