import React, { ChangeEvent, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import {
  ContentAndButtonsContainer,
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentContainer,
} from '../../layout/layout-container.styled';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Sidebar } from '../../layout/sidebar/sidebar';
import { Text, font } from '../../../style-utils/text';
import {
  CreateVacancyInput,
  StyledJobInput,
  SubmitButtonsContainer,
} from '../jobs/jobs.styled';
import { colors } from '../../../style-utils/colors';
import { ButtonContainer, ButtonTitle } from './employees.styled';
import { NewEmployeeDataTypes } from './add-employee';

interface EditEmployeeProps {
  // updateEditableJobData: (newValue: JobOfferData) => void;
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  cookies?: Cookie;
  employee: NewEmployeeDataTypes | undefined;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const EditEmployee: React.FC<EditEmployeeProps> = ({
  navigate,
  company,
  employee,
  setUser,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateEmployee, setUpdateEmployee] = useState<
    NewEmployeeDataTypes | undefined
  >(employee);
  const [employeeData, setEmployeeData] = useState({
    dateOfBirth: '1990-05-15',
    address: '123 Main Street, Cityville',
    country: 'United States',
    nationality: 'American',
    job_position: 'Software Engineer',
    seniority: 'Senior',
    primary_technology: 'JavaScript',
    department: 'Engineering',
  });
  const height = useWindowHeight();

  const handleEdit = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (updateEmployee) {
      setUpdateEmployee({
        ...updateEmployee,
        [name]: value,
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmployeeData({ ...employeeData, [name]: value });
  };

  const handleSubmit = () => {
    console.log(updateEmployee);
  };

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={company} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={company}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer bgColor={colors.creme_100}>
              <div>
                <Text
                  fontSize={30}
                  mb={0}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Edit Employee
                </Text>
                <Text fontSize={14} mt={6} fontFamily={font.family}>
                  Edit your personal information here!
                </Text>
              </div>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  First name
                </Text>
                <StyledJobInput
                  type="text"
                  name="first_name"
                  placeholder={'Enter your first name'}
                  value={updateEmployee?.first_name}
                  onChange={handleEdit}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Last name
                </Text>
                <StyledJobInput
                  type="text"
                  name="last_name"
                  placeholder={'Enter your last name'}
                  value={updateEmployee?.last_name}
                  onChange={handleEdit}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Date of Birth
                </Text>
                <StyledJobInput
                  type="text"
                  name="dateOfBirth"
                  placeholder={'Enter your birth date'}
                  value={employeeData.dateOfBirth}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Address
                </Text>
                <StyledJobInput
                  type="text"
                  name="address"
                  placeholder={'Enter your address'}
                  value={employeeData.address}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Country
                </Text>
                <StyledJobInput
                  type="text"
                  name="country"
                  placeholder={'Enter your current country'}
                  value={employeeData.country}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Email
                </Text>
                <StyledJobInput
                  type="text"
                  name="email"
                  placeholder={'Enter your email'}
                  value={updateEmployee?.email}
                  onChange={handleEdit}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Phone Number
                </Text>
                <StyledJobInput
                  type="text"
                  name="phone_number"
                  placeholder={'Enter your phone number'}
                  value={updateEmployee?.phone_number}
                  onChange={handleEdit}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Nationality
                </Text>
                <StyledJobInput
                  type="text"
                  name="country"
                  placeholder={'Enter your nationality'}
                  value={employeeData.nationality}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Job Position
                </Text>
                <StyledJobInput
                  type="text"
                  name="job_position"
                  placeholder={'Enter your job position'}
                  value={employeeData.job_position}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Seniority
                </Text>
                <StyledJobInput
                  type="text"
                  name="seniority"
                  placeholder={'Enter your seniority'}
                  value={employeeData.seniority}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Primiary Technology
                </Text>
                <StyledJobInput
                  type="text"
                  name="primary_technology"
                  placeholder={'Enter your primary technology'}
                  value={employeeData.primary_technology}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Department
                </Text>
                <StyledJobInput
                  type="text"
                  name="department"
                  placeholder={'Enter your department'}
                  value={employeeData.department}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              <SubmitButtonsContainer>
                <ButtonContainer
                  backgroundColor={colors.primary_100}
                  type="button"
                  mt={32}
                  onClick={() =>
                    navigate(`${company.name}/employees/${updateEmployee?.id}`)
                  }
                >
                  <ButtonTitle fontColor={colors.base_black}>
                    Cancel
                  </ButtonTitle>
                </ButtonContainer>
                <ButtonContainer
                  backgroundColor={colors.primary_500}
                  type="button"
                  mt={32}
                  onClick={handleSubmit}
                >
                  <ButtonTitle fontColor={colors.base_white}>
                    Update
                  </ButtonTitle>
                </ButtonContainer>
              </SubmitButtonsContainer>
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
