import React from 'react';

export const NotificationBellIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75001 8.9998C5.75012 5.27197 8.77215 2.25 12.5 2.25C16.2279 2.25 19.25 5.27208 19.25 9L19.2498 9.04919V9.75C19.2498 11.8731 20.0508 13.8074 21.3684 15.2699C21.5349 15.4547 21.5989 15.71 21.5393 15.9516C21.4797 16.1931 21.3042 16.3893 21.0709 16.4755C19.5269 17.0455 17.9105 17.4659 16.2396 17.7192C16.2465 17.812 16.25 17.9056 16.25 18C16.25 20.0711 14.5711 21.75 12.5 21.75C10.4289 21.75 8.75001 20.0711 8.75001 18C8.75001 17.9056 8.75351 17.812 8.7604 17.7192C7.08934 17.4659 5.47287 17.0455 3.92875 16.4755C3.69539 16.3893 3.51992 16.1931 3.46033 15.9516C3.40073 15.71 3.46476 15.4547 3.63126 15.2699C4.94879 13.8074 5.74981 11.8731 5.74981 9.75L5.75001 8.9998ZM10.2522 17.8993C10.2508 17.9326 10.25 17.9662 10.25 18C10.25 19.2426 11.2574 20.25 12.5 20.25C13.7427 20.25 14.75 19.2426 14.75 18C14.75 17.9662 14.7493 17.9326 14.7478 17.8992C14.0072 17.9659 13.2574 18 12.4998 18C11.7424 18 10.9927 17.966 10.2522 17.8993Z"
        fill="#2E2926"
      />
    </svg>
  );
};
