import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';

export const StyledMultiLineChartTabs = styled.div`
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledMultiLineChartTab = styled.div<{ isSelected: boolean }>`
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: ${({ isSelected }) =>
    isSelected ? colors.primary_400 : 'transparent'};
  border: 1px solid ${colors.primary_400};
  border-radius: 16px;
  cursor: pointer;
`;
