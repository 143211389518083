import { useState } from 'react';
import { ReportingBarAndMultiLineChartDataTypes } from '../reporting/components/charts/charts-data-types';

export const useHomeChartsData = () => {
  const [barChartData, setBarChartData] = useState<
    ReportingBarAndMultiLineChartDataTypes[]
  >([
    { name: 'Jan', avg: 60, max: 100, min: 80 },
    { name: 'Feb', avg: 40, max: 90, min: 70 },
    { name: 'Mar', avg: 50, max: 85, min: 65 },
    { name: 'Apr', avg: 60, max: 95, min: 75 },
    { name: 'May', avg: 55, max: 90, min: 70 },
    { name: 'Jun', avg: 65, max: 100, min: 80 },
    { name: 'Jul', avg: 50, max: 85, min: 65 },
    { name: 'Aug', avg: 60, max: 95, min: 75 },
    { name: 'Sep', avg: 65, max: 100, min: 80 },
    { name: 'Oct', avg: 55, max: 90, min: 70 },
    { name: 'Nov', avg: 60, max: 95, min: 75 },
    { name: 'Dec', avg: 55, max: 85, min: 65 },
  ]);

  const [multiLineChartData, setMultiLineChartData] = useState<
    ReportingBarAndMultiLineChartDataTypes[]
  >([
    { name: 'Jan', avg: 10, max: 40, min: 5 },
    { name: 'Feb', avg: 20, max: 50, min: 10 },
    { name: 'Mar', avg: 30, max: 60, min: 15 },
    { name: 'Apr', avg: 25, max: 55, min: 20 },
    { name: 'May', avg: 35, max: 70, min: 25 },
    { name: 'Jun', avg: 30, max: 60, min: 20 },
    { name: 'Jul', avg: 40, max: 80, min: 30 },
    { name: 'Aug', avg: 35, max: 65, min: 25 },
    { name: 'Sep', avg: 40, max: 70, min: 35 },
    { name: 'Oct', avg: 30, max: 60, min: 20 },
    { name: 'Nov', avg: 35, max: 55, min: 25 },
    { name: 'Dec', avg: 25, max: 50, min: 15 },
  ]);

  return {
    barChartData,
    multiLineChartData,
    setBarChartData,
    setMultiLineChartData,
  };
};
