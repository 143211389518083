import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../style-utils/text';
import { ButtonContainer } from '../login/login.styled';
import {
  ConfirmPopupMainWrapper,
  ConfirmPopupWrapper,
} from './confirm-popup.styled';
import { Alert } from '../icons/alert';
import { Success } from '../icons/success';
import { NavigateFunction } from 'react-router-dom';
import { colors } from '../../style-utils/colors';

interface ConfirmPopupProps {
  msgText: string;
  handleToggler: (currentState: boolean) => void;
  handleToggleApplyForm?: (currentState: boolean) => void;
  navigate?: NavigateFunction;
  navigateTo?: string;
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  msgText,
  handleToggler,
  handleToggleApplyForm,
}) => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    handleToggler(false);
    navigate(-1);
    if (handleToggleApplyForm) {
      handleToggleApplyForm(false);
    }
    if (msgText.includes('error')) {
      return;
    }
  };

  return (
    <ConfirmPopupMainWrapper>
      <ConfirmPopupWrapper>
        {msgText.includes('error') ? <Alert /> : <Success />}
        <Text
          fontFamily="Inter, sans-serif"
          fontWeight={700}
          mb={25}
          style={{ alignSelf: 'center' }}
        >
          {msgText}
        </Text>
        <ButtonContainer
          display="block"
          onClick={handleCloseModal}
          backgroundColor={colors.primary_400}
        >
          Close
        </ButtonContainer>
      </ConfirmPopupWrapper>
    </ConfirmPopupMainWrapper>
  );
};
