import styled from 'styled-components';

export const SkillsSearchInputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  max-height: 48px;
  border-radius: 8px;
  border: none;
  width: 360px;
  align-self: center;
`;

export const SkillsStyledInput = styled.input<{
  borderColor?: string;
}>`
  flex: 1;
  border: none;
  outline: none;
  height: 43px;
  padding-left: 16px;
  border-radius: 8px;
  border: 2px solid
    ${({ borderColor }) => borderColor || 'var(--Primary-500, #e94e3c)'};

  &::placeholder {
    color: #8b0000;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  cursor: pointer;
  pointer-events: auto;
`;
