import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';

export const StyledCompanySettingsOnboardingTabMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 40px;
  gap: 16px;
  border-radius: 8px;
`;

export const StyledBlueInfoComponentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${colors.secondary_50};
  padding: 16px;
  gap: 8px;
`;

export const StyledChecklistWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const StyledChecklistHeading = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: ${colors.secondary_300};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const StyledDeleteIconWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const StyledChecklistOptionsAndTitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colors.neutral_50};
  padding: 16px 16px 32px 16px;
  gap: 16px;
  border-radius: 4px;
`;

export const StyledChecklistOptionTitleInput = styled.input<{
  leftPadding?: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ leftPadding }) =>
    leftPadding ? `16px 16px 16px 36px` : `16px`};
  border-radius: 8px;
  border-style: none;
  background-color: ${colors.base_white};
`;

export const StyledOptionsTitleAndAddOptionButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAddNewOptionButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  gap: 4px;
  background-color: ${colors.secondary_300};
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledOptionWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 48px;
`;

export const StyledOnboardingInputCircleWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const StyledOnboardingInputDeleteWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const StyledAddChecklistButton = styled.button`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondary_300};
  border-radius: 8px;
  border-style: none;
  gap: 8px;
  cursor: pointer;
`;

export const StyledPublishButton = styled.button`
  box-sizing: border-box;
  height: 48px;
  width: fit-content;
  padding: 16px 24px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary_500};
  border-radius: 8px;
  border-style: none;
  gap: 8px;
  cursor: pointer;
`;
