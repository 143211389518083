import React, { useState } from 'react';
import {
  Button,
  ButtonsContainer,
  ModalBackground,
  ModalContent,
  Row,
} from '../../super-admin/super-admin-main/modal/modal.styled';
import { Text } from '../../../../style-utils/text';
import {
  ModalMessageWrapper,
  OptionBox,
  InputText,
} from './survey-modal.styled';
import {
  FormWrapper,
  InputType,
  QuestionBox,
  QuestionContent,
  QuestionHeader,
  QuestionsWrapper,
} from '../create-survey/create-survey.styled';

type SurveyResponse = string | { [option: string]: boolean };

export const EmployeeSurvey: React.FC = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(true);

  // Example questions; like fetching from backend
  const questions = [
    {
      id: 1,
      questionText: 'Question title',
      selectedQuestionType: '3',
      options: ['Option name 1', 'Option name 2', 'Option name 3'],
    },
    {
      id: 2,
      questionText: 'Question title',
      selectedQuestionType: '1',
      options: [''],
    },
    {
      id: 3,
      questionText: 'Question title',
      selectedQuestionType: '2',
      options: ['Option name 1', 'Option name 2', 'Option name 3'],
    },
  ];

  const [responses, setResponses] = useState<{ [key: number]: SurveyResponse }>(
    {}
  );

  const handleResponseChange = (questionId: number, value: string) => {
    setResponses({
      ...responses,
      [questionId]: value,
    });
  };

  const handleOptionChange = (
    questionId: number,
    option: string,
    isChecked: boolean
  ) => {
    setResponses((prevResponses) => {
      const currentResponse = prevResponses[questionId];
      const updatedResponse =
        typeof currentResponse === 'object' && currentResponse !== null
          ? currentResponse
          : {};

      return {
        ...prevResponses,
        [questionId]: {
          ...updatedResponse,
          [option]: isChecked,
        },
      };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Survey responses:', responses);
    // Here send the responses to backend
  };

  return (
    <>
      {modalVisible && (
        <ModalBackground onClick={() => setModalVisible(false)}>
          <ModalContent
            onClick={(e) => e.stopPropagation()}
            padding="40px"
            maxWidth="700px"
            maxHeight="80vh"
            bg="white"
            style={{ overflowY: 'auto' }}
          >
            <FormWrapper onSubmit={handleSubmit} width="100%" gap="18px">
              <Row justifyContent="space-between">
                <Text
                  margin="0"
                  fontFamily="Lato"
                  fontSize={33}
                  fontWeight={700}
                >
                  Survey title
                </Text>
                <Text margin="0" fontFamily="Lato" fontSize={12}>
                  07 Jul 2024
                </Text>
              </Row>
              <ModalMessageWrapper>
                <Text
                  margin="0"
                  fontFamily="Lato"
                  fontSize={20}
                  fontWeight={700}
                >
                  Thank you for taking part
                </Text>
                <Text
                  margin="0"
                  fontFamily="Lato"
                  fontSize={13}
                  fontWeight={400}
                >
                  Please complete this survey to help us improve some stuff!
                </Text>
              </ModalMessageWrapper>
              <Text margin="0" fontFamily="Lato" fontSize={16} fontWeight={700}>
                Answer questions
              </Text>
              <QuestionsWrapper
                gap="15px"
                style={{
                  maxHeight: '60vh',
                  overflowY: 'auto',
                }}
              >
                {questions.map((question, index) => (
                  <QuestionBox key={index} pb="5px">
                    <QuestionHeader>
                      <Text
                        color="white"
                        fontFamily="Lato"
                        fontSize={15}
                        fontWeight={700}
                        margin="0"
                      >{`${index + 1}. ${question.questionText}`}</Text>
                    </QuestionHeader>
                    <QuestionContent>
                      {question.selectedQuestionType === '1' && (
                        <>
                          <Text
                            fontFamily="Lato"
                            margin="0"
                            fontSize={14}
                            fontWeight={400}
                          >
                            Answer question in field:
                          </Text>
                          <InputText
                            type="text"
                            width="66%"
                            placeholder="Question text goes here."
                            value={
                              typeof responses[question.id] === 'string'
                                ? (responses[question.id] as string)
                                : ''
                            }
                            onChange={(e) =>
                              handleResponseChange(question.id, e.target.value)
                            }
                          />
                        </>
                      )}
                      {question.selectedQuestionType === '2' && (
                        <div>
                          <Text
                            fontFamily="Lato"
                            margin="0"
                            fontSize={14}
                            fontWeight={400}
                            pb={10}
                          >
                            Select one option:
                          </Text>
                          {question.options.map((option, optionIndex) => (
                            <OptionBox key={optionIndex}>
                              <InputType
                                type="radio"
                                name={`singleChoice_${question.id}`}
                                value={option}
                                onChange={() =>
                                  handleResponseChange(question.id, option)
                                }
                              />
                              <Text
                                fontFamily="Lato"
                                fontSize={14}
                                fontWeight={400}
                                pl={25}
                              >
                                {option}
                              </Text>
                            </OptionBox>
                          ))}
                        </div>
                      )}
                      {question.selectedQuestionType === '3' && (
                        <div>
                          <Text
                            fontFamily="Lato"
                            margin="0"
                            fontSize={14}
                            fontWeight={400}
                            pb={10}
                          >
                            Select multiple options:
                          </Text>
                          {question.options.map((option, optionIndex) => (
                            <OptionBox key={optionIndex}>
                              <InputType
                                type="checkbox"
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(
                                    question.id,
                                    option,
                                    e.target.checked
                                  )
                                }
                              />
                              <Text
                                fontFamily="Lato"
                                fontSize={14}
                                fontWeight={400}
                                pl={25}
                              >
                                {option}
                              </Text>
                            </OptionBox>
                          ))}
                        </div>
                      )}
                    </QuestionContent>
                  </QuestionBox>
                ))}
              </QuestionsWrapper>
              <ButtonsContainer pt="0px">
                <Button onClick={handleSubmit} isBack={false}>
                  Complete
                </Button>
              </ButtonsContainer>
            </FormWrapper>
          </ModalContent>
        </ModalBackground>
      )}
    </>
  );
};
