import React from 'react';
import { StyledReportingTabWrapper } from '../../../tabs.styled';
import { useReportingEmployeesData } from '../../use-reporting-employees-data';
import ReportingBarChart from '../../../../components/charts/bar-chart/bar-chart';
import ReportingEmployeesTable from '../../../../components/tables/reporting-employees-table/reporting-employees-table';

const ReportingEmployeeTurnover: React.FC = () => {
  const { barChartData, reportingEmployeeTableTurnoverData } =
    useReportingEmployeesData();

  return (
    <StyledReportingTabWrapper>
      <ReportingBarChart name="Average employee" barChartData={barChartData} />
      <ReportingEmployeesTable
        name="Turnover rate"
        tableData={reportingEmployeeTableTurnoverData}
      />
    </StyledReportingTabWrapper>
  );
};

export default ReportingEmployeeTurnover;
