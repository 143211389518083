import React from 'react';
import {
  ContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../../layout/layout-container.styled';
import BackToDashboard from '../../settings/components/back-btn/back-btn';
import AdminInfo from './admin-info';
import AdminSettingsTabs from './settings/admin-settings';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';

interface AdminCompanyInfoProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  cookies?: Cookie;
  handleBack: () => void;
}

const AdminCompanyInfo: React.FC<AdminCompanyInfoProps> = ({
  user,
  navigate,
  cookies,
  handleBack,
  setUser,
}) => {
  return (
    <ContentWrapper>
      <NavbarAndContentTransparentContainer>
        <BackToDashboard
          handleBack={handleBack}
          user={user}
          navigate={navigate}
        />
        <AdminInfo user={user} />
        <AdminSettingsTabs setUser={setUser} user={user} cookies={cookies} />
      </NavbarAndContentTransparentContainer>
    </ContentWrapper>
  );
};

export default AdminCompanyInfo;
