import React from 'react';
import axios from 'axios';
import {
  StyledCreateNewEmployeeJobPositionBackBtn,
  StyledCreateNewEmployeeJobPositionBtnsWrapper,
  StyledCreateNewEmployeeJobPositionForm,
  StyledCreateNewEmployeeJobPositionMainWrapper,
  StyledCreateNewEmployeeJobPositionSubmitBtn,
} from './create-edit-delete-job-position.styled';
import { Text, font } from '../../../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../../../style-utils/colors';
import { Company } from '../../../../../../../../../app-router/component-props';
import { apiRoute } from '../../../../../../../../../../constants/api-constants';

interface Props {
  onClose: (success: boolean, message?: string) => void;
  user: Company;
  jobPosition: { id: number; job_position: string };
}

const DeleteEmployeeJobPosition: React.FC<Props> = ({
  onClose,
  user,
  jobPosition,
}) => {
  const baseURL = apiRoute;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .patch(
        `${baseURL}/employee_job_positions/${user.id}/${jobPosition.id}/soft_delete`
      )
      .then((response) => {
        console.log(response);
        onClose(true, 'Job position soft deleted successfully');
      })
      .catch((error) => {
        onClose(
          false,
          error.response
            ? error.response.data.error
            : 'Error deleting job position'
        );
      });
  };

  return (
    <StyledCreateNewEmployeeJobPositionMainWrapper>
      <StyledCreateNewEmployeeJobPositionForm onSubmit={handleSubmit}>
        <Text
          fontFamily={font.family}
          mb={24}
          color={colors.error}
          fontSize={22}
          fontWeight={700}
        >
          Are you sure you want to delete this job position?
        </Text>
        <StyledCreateNewEmployeeJobPositionBtnsWrapper>
          <StyledCreateNewEmployeeJobPositionBackBtn
            type="button"
            onClick={() => onClose(false)}
          >
            Back to Settings
          </StyledCreateNewEmployeeJobPositionBackBtn>
          <StyledCreateNewEmployeeJobPositionSubmitBtn type="submit">
            Delete
          </StyledCreateNewEmployeeJobPositionSubmitBtn>
        </StyledCreateNewEmployeeJobPositionBtnsWrapper>
      </StyledCreateNewEmployeeJobPositionForm>
    </StyledCreateNewEmployeeJobPositionMainWrapper>
  );
};

export default DeleteEmployeeJobPosition;
