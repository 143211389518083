import React from 'react';

export const CheckOffIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1667 2H3.83333C3.09695 2 2.5 2.59695 2.5 3.33333V12.6667C2.5 13.403 3.09695 14 3.83333 14H13.1667C13.903 14 14.5 13.403 14.5 12.6667V3.33333C14.5 2.59695 13.903 2 13.1667 2Z"
        stroke="#E94E3C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 6L10.5 10"
        stroke="#E94E3C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 6L6.5 10"
        stroke="#E94E3C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
