import React, { useEffect, useRef, useState } from 'react';
import {
  StyledCandidateCommentsMainWrapper,
  StyledCandidateCommentsWrapper,
  StyledCloseButtonWrapper,
} from './candidate-comments.styled';
import { CloseIcon } from '../../../../../icons/close-icon';
import CandidateAddNewComment from './components/candidate-add-new-comment';
import CandidateListOfComments from './components/candidate-list-of-comments';
import axios from 'axios';
import { apiRoute } from '../../../../../../constants/api-constants';

interface CandidateCommentsPropTypes {
  // eslint-disable-next-line
  company: any;
  closeCandidateComments: () => void;
  candidateId: string;
}

export interface ApplicantComment {
  id: string;
  applicant_id: string;
  author_name: string;
  author_id: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
}

const CandidateComments: React.FC<CandidateCommentsPropTypes> = ({
  company,
  closeCandidateComments,
  candidateId,
}) => {
  const baseURL = apiRoute;
  const [comments, setComments] = useState<ApplicantComment[]>([]);
  const [loading, setLoading] = useState(true);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const loadComments = () => {
    setLoading(true);
    axios
      .get(`${baseURL}/applicant/comments/${candidateId}`)
      .then((response) => {
        setComments(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching comments:', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadComments();
  }, [candidateId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        closeCandidateComments();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeCandidateComments]);

  return (
    <StyledCandidateCommentsMainWrapper>
      <StyledCandidateCommentsWrapper ref={wrapperRef}>
        <StyledCloseButtonWrapper onClick={() => closeCandidateComments()}>
          <CloseIcon />
        </StyledCloseButtonWrapper>
        <CandidateListOfComments comments={comments} loading={loading} />
        <CandidateAddNewComment
          company={company}
          candidateId={candidateId}
          loadComments={loadComments}
        />
      </StyledCandidateCommentsWrapper>
    </StyledCandidateCommentsMainWrapper>
  );
};

export default CandidateComments;
