import React from 'react';
import styled, { keyframes } from 'styled-components';

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures the spinner is on top of other elements */
`;

export const Spinner = () => {
  return (
    <svg
      width="102"
      height="110"
      viewBox="0 0 102 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="46" width="10" height="10" rx="5" fill="#E94E3C" />
      <rect
        opacity="0.5"
        x="46"
        y="100"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect
        opacity="0.9"
        x="75"
        y="10"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect
        opacity="0.1"
        x="17"
        y="10"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect
        opacity="0.6"
        x="75"
        y="91"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect
        opacity="0.4"
        x="17"
        y="91"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect
        opacity="0.8"
        x="92"
        y="33"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect
        opacity="0.7"
        x="92"
        y="66"
        width="10"
        height="10"
        rx="5"
        fill="#E94E3C"
      />
      <rect opacity="0.3" y="66" width="10" height="10" rx="5" fill="#E94E3C" />
      <rect opacity="0.2" y="33" width="10" height="10" rx="5" fill="#E94E3C" />
    </svg>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingWrapper = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RotatingSpinner = () => (
  <RotatingWrapper>
    <Spinner />
  </RotatingWrapper>
);

export const FullScreenSpinner = () => (
  <FullScreenWrapper>
    <RotatingSpinner />
  </FullScreenWrapper>
);
