import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const StyledCompanyCVMainWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const StyledCompanyCVEditAndExportBtnWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: auto;
`;

export const StyledExportEditBackBtn = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  border-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
`;

export const StyledCenterCVComponent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const StyledLoadingWrapper = styled.div`
  position: fixed;
  background-color: ${colors.creme_200};
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCompanyCVBackAndSaveButtonsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 900px;
`;

export const StyledCompanyCVBackAndSaveButtons = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
  width: fit-content;
`;

export const StyledCompanyCVBackAndSaveButton = styled.button<{
  backgroundColor: string;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-style: none;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: ${font.family};
  font-size: 16px;
  font-weight: 700;
  color: ${colors.neutral_50};
  cursor: pointer;
`;
