import styled from 'styled-components';
import { colors } from '../../../../../../../style-utils/colors';

export const StyledSettingsTabSelectorMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 16px;
  background-color: ${colors.base_white};
  border-radius: 8px;
`;

export const StyledTabSelectButton = styled.button<{ isActive: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 8px 12px;
  border-style: none;
  background-color: ${({ isActive }) =>
    isActive ? colors.primary_400 : 'transparent'};
  color: ${({ isActive }) =>
    isActive ? colors.base_white : colors.neutral_700};
  cursor: pointer;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? colors.primary_500 : 'rgba(0, 0, 0, 0.1)'};
  }
`;
