import React, { useState } from 'react';
import axios from 'axios';
import { StyledEmployeeTimeoffChangeStatusSelect } from './update-status.styled';
import { EmployeesTimeoffDataTypes } from '../../../employees-timeoff-types';
import { apiRoute } from '../../../../../../../constants/api-constants';
import { useTimeOff } from '../../../context/timeoff-context';

interface PropsTypes {
  application: EmployeesTimeoffDataTypes;
}

const EmployeesTimeoffUpdateStatusSelect: React.FC<PropsTypes> = ({
  application,
}) => {
  const { fetchData, currentPage } = useTimeOff();
  const baseURL = apiRoute;
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const status = event.target.value;
    setSelectedStatus(status);

    try {
      const response = await axios.post(
        `${baseURL}/timeoff-application/update-status`,
        {
          id: application.id,
          employee_id: application.employee_id,
          status: status,
          duration: application.duration,
          prev_status: application.status,
        }
      );
      console.log(response.data.message);
      fetchData(currentPage);
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      setSelectedStatus('');
    }
  };

  return (
    <StyledEmployeeTimeoffChangeStatusSelect
      onChange={handleStatusChange}
      value={selectedStatus}
    >
      <option value="" disabled>
        Update status
      </option>
      <option value="Confirmed">Confirm</option>
      <option value="Denied">Deny</option>
    </StyledEmployeeTimeoffChangeStatusSelect>
  );
};

export default EmployeesTimeoffUpdateStatusSelect;
