import React from 'react';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';
import { Xicon } from '../../../../../icons/x-skill';
import { CheckSkillIcon } from '../../../../../icons/y-skill';
import {
  StyledSkillsTable,
  StyledHeaders,
  StyledFirstTh,
  StyledTh,
  StyledBodyRow,
  StyledFeatureTd,
  StyledTd,
  SkillMapTableWrapper,
  SkillText,
  FeatureRotatedText,
} from '../../employees-skills-table.styled';

export interface Skill {
  Beginner: boolean;
  Intermediate: boolean;
  Advanced: boolean;
}

export interface Employee {
  name: string;
  skills: Skill;
}

export interface SkillMapSearchProps {
  employees: Employee[];
  setFilteredEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
}

export const SkillMapTable: React.FC<SkillMapSearchProps> = ({ employees }) => {
  const features = [
    'Technical',
    'Soft Skills',
    'Leadership',
    'Team Work',
    'Management',
  ];
  const skillLevels = ['Beginner', 'Intermediate', 'Advanced'];

  return (
    <SkillMapTableWrapper>
      <StyledSkillsTable>
        <StyledHeaders>
          <StyledFirstTh backgroundColor={colors.dark_blue} colSpan={4}>
            <SkillText
              fontFamily={font.family}
              color={colors.base_white}
              fontSize={16}
              fontWeight={700}
              lineHeight="22px"
              margin="0"
            >
              Features
            </SkillText>
          </StyledFirstTh>
          {employees.map((employee) => (
            <StyledTh key={employee.name} backgroundColor={colors.primary_500}>
              <SkillText
                fontFamily={font.family}
                color={colors.base_white}
                fontSize={16}
                fontWeight={700}
                lineHeight="22px"
                margin="0"
              >
                {employee.name}
              </SkillText>
            </StyledTh>
          ))}
        </StyledHeaders>

        <tbody>
          {features.map((feature) => (
            <React.Fragment key={feature}>
              {skillLevels.map((level) => (
                <StyledBodyRow key={`${feature}-${level}`}>
                  {level === 'Beginner' && (
                    <StyledFeatureTd
                      backgroundColor={colors.dark_blue}
                      rowSpan={3}
                      colSpan={1}
                    >
                      <FeatureRotatedText
                        fontFamily={font.family}
                        color={colors.base_white}
                        fontSize={14}
                        fontWeight={600}
                      >
                        {feature}
                      </FeatureRotatedText>
                    </StyledFeatureTd>
                  )}
                  <StyledTd
                    backgroundColor={
                      level === 'Beginner'
                        ? '#E4EAF5'
                        : level === 'Intermediate'
                        ? '#BCCCE6'
                        : '#93AFD7'
                    }
                    colSpan={3}
                  >
                    <SkillText
                      fontFamily={font.family}
                      color={colors.black}
                      fontSize={16}
                      fontWeight={400}
                      lineHeight="20px"
                      margin="0"
                    >
                      {level}
                    </SkillText>
                  </StyledTd>
                  {employees.map((employee) => (
                    <StyledTd key={employee.name}>
                      {employee.skills[level as keyof Skill] ? (
                        <CheckSkillIcon />
                      ) : (
                        <Xicon />
                      )}
                    </StyledTd>
                  ))}
                </StyledBodyRow>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </StyledSkillsTable>
    </SkillMapTableWrapper>
  );
};
