import React, { useState } from 'react';

// eslint-disable-next-line
export const usePagination = (data: any[] | undefined, itemsPerPage = 6) => {
  const [currentPage, setCurrentPage] = useState(1);

  const getTotalPages = () => {
    // pazi da ne e neparen broj oti ke ostane decimala
    return Math.ceil((data?.length || 0) / itemsPerPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedData =
    data && data.length > 0 ? data.slice(startIndex, endIndex) : [];

  const nextPage = () => {
    if (data && currentPage < getTotalPages()) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const hasPreviousPage = currentPage > 1;
  const hasNextPage = data ? endIndex < data.length : false;

  return {
    currentPage,
    displayedData,
    nextPage,
    previousPage,
    hasPreviousPage,
    hasNextPage,
    getTotalPages,
  };
};
