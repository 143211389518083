import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  StyledChangeEmploymentStatusBtn,
  StyledCloseButtonWrapper,
  StyledEmployeeChangeEmploymentStatusMainWrapper,
  StyledEmployeeChangeEmploymentStatusWrapper,
} from './employee-change-employment-status-confirmation.styled';
import { CloseIcon } from '../../icons/close-icon';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { NewEmployeeDataTypes } from './add-employee';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';

interface EmployeeChangeEmploymentStatusConfirmationProps {
  closeEmployeeChangeEmploymentStatusConfirmation: () => void;
  setPopUp: Dispatch<
    SetStateAction<{ text: string; type: 'success' | 'error' } | null>
  >;
  employeeFromDB: () => void;
  employee: NewEmployeeDataTypes;
}

const EmployeeChangeEmploymentStatusConfirmation: React.FC<
  EmployeeChangeEmploymentStatusConfirmationProps
> = ({
  closeEmployeeChangeEmploymentStatusConfirmation,
  setPopUp,
  employeeFromDB,
  employee,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const updateEmployeeStatus = (employeeId: string, newStatus: string) => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString();
    const formattedTime = now.toLocaleTimeString();

    axios
      .put(`${apiRoute}/update-employee-status/${employeeId}`, {
        status: newStatus,
      })
      .then((response) => {
        console.log('Status updated successfully:', response.data);
        employeeFromDB();

        const action = newStatus === 'inactive' ? 'ended' : 'restarted';
        setPopUp({
          text: `The employment for ${employee.first_name} ${employee.last_name} was ${action} on ${formattedDate} at ${formattedTime}`,
          type: 'success',
        });

        closeEmployeeChangeEmploymentStatusConfirmation();
      })
      .catch((error) => {
        console.error('Error updating status:', error);
        setPopUp({
          text: 'Error updating employee status. Please try again.',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        closeEmployeeChangeEmploymentStatusConfirmation();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeEmployeeChangeEmploymentStatusConfirmation]);

  return (
    <StyledEmployeeChangeEmploymentStatusMainWrapper>
      <StyledEmployeeChangeEmploymentStatusWrapper ref={wrapperRef}>
        <StyledCloseButtonWrapper
          onClick={() => closeEmployeeChangeEmploymentStatusConfirmation()}
        >
          <CloseIcon />
        </StyledCloseButtonWrapper>
        <Text
          fontFamily={font.family}
          margin="0"
          color={colors.base_black}
          fontSize={16}
          fontWeight={700}
        >
          {employee.status === 'active'
            ? `Are you sure you want to end employment for ${employee.first_name} ${employee.last_name}?`
            : employee.status === 'inactive'
            ? `Are you sure you want to rehire ${employee.first_name} ${employee.last_name}?`
            : `Please check the employment status for ${employee.first_name} ${employee.last_name}.`}
        </Text>

        <StyledChangeEmploymentStatusBtn
          type="button"
          onClick={() =>
            updateEmployeeStatus(
              employee.id,
              employee.status === 'active' ? 'inactive' : 'active'
            )
          }
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
          >
            {employee.status === 'active'
              ? `End Employment`
              : employee.status === 'inactive'
              ? `Rehire`
              : `Wrong status`}
          </Text>
        </StyledChangeEmploymentStatusBtn>
      </StyledEmployeeChangeEmploymentStatusWrapper>
    </StyledEmployeeChangeEmploymentStatusMainWrapper>
  );
};

export default EmployeeChangeEmploymentStatusConfirmation;
