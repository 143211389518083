import React, { useState } from 'react';
import {
  ManagerNotesContainer,
  Heading3,
  ButtonsContainer,
  TabButtons,
  TabBtn,
  ListWrapper,
  List,
  NotesItem,
  ItemUpContent,
  Heading6,
  Date,
  Paragraph,
  ViewBtn,
  SortButton,
  BoxWrapper,
  ButtonContainer,
} from '../employee-performance-tab.styled';
import { ArrowUp } from '../../../../icons/arrow-up';
import { DownArrow } from '../../../../icons/down-arrow';

type NotesType = {
  title: string;
  date: string;
  content: string;
};

export const ManagerNotesComponent = () => {
  const [selectedTab, setSelectedTab] = useState<string>('current_notes');
  const [showAllNotes, setShowAllNotes] = useState<boolean>(false);
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const handleSelectedTab = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const handleSortToggle = () => {
    setSortAscending(!sortAscending);
  };

  const managerNotes = (): NotesType[] => {
    const notes: Record<string, NotesType[]> = {
      current_notes: [
        {
          title: 'Name of Manager',
          date: '26.05.2024',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Manager',
          date: '13.04.2024',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Manager',
          date: '15.06.2024',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
      ],
      action_items: [
        {
          title: 'Name of Peer 1',
          date: '15.06.2024',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Peer 2',
          date: '07.07.2024',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
        {
          title: 'Name of Peer 3',
          date: '06.07.2024',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id ligula a ipsum volutpat eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean accumsan quis metus sed posuere. Ut dignissim augue id ligula dictum, quis vulputate leo scelerisque. Vestibulum hendrerit suscipit massa id dapibus.',
        },
      ],
    };

    if (selectedTab && notes[selectedTab]) {
      const selectedFeedback = notes[selectedTab];

      return sortAscending ? selectedFeedback : [...selectedFeedback].reverse();
    }

    return [];
  };

  const visibleNotes = showAllNotes
    ? managerNotes()
    : managerNotes().slice(0, 2);

  return (
    <ManagerNotesContainer>
      <Heading3>Manager Notes</Heading3>
      <BoxWrapper>
        <ButtonsContainer>
          <TabButtons>
            <TabBtn
              active={selectedTab === 'current_notes'}
              onClick={() => handleSelectedTab('current_notes')}
            >
              Current Notes
            </TabBtn>
            <TabBtn
              active={selectedTab === 'action_items'}
              onClick={() => handleSelectedTab('action_items')}
            >
              Action Items
            </TabBtn>
          </TabButtons>
          <ButtonContainer>
            <Paragraph>Sort by:</Paragraph>
            <SortButton onClick={handleSortToggle}>
              {sortAscending ? <>Date Descending</> : <>Date Ascending</>}
            </SortButton>
          </ButtonContainer>
        </ButtonsContainer>
        {managerNotes().length > 0 && (
          <ListWrapper>
            <List>
              {visibleNotes.map((note, i) => (
                <NotesItem key={i}>
                  <ItemUpContent>
                    <Heading6>{note.title}</Heading6>
                    <Date>{note.date}</Date>
                  </ItemUpContent>
                  <Paragraph>{note.content}</Paragraph>
                </NotesItem>
              ))}
            </List>
            {managerNotes().length > 2 && (
              <ViewBtn onClick={() => setShowAllNotes(!showAllNotes)}>
                {showAllNotes ? 'Show less' : 'View all'}
                {showAllNotes ? <ArrowUp /> : <DownArrow />}
              </ViewBtn>
            )}
          </ListWrapper>
        )}
      </BoxWrapper>
    </ManagerNotesContainer>
  );
};
