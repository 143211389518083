import React, { useState, useEffect, useRef } from 'react';
import useDebouncedApiCall from './hooks/useDebouncedApiCall';
import { SearchIcon } from '../icons/search';
import {
  IconWrapper,
  SearchInputContainer,
  StyledInput,
} from './search.styled';
import { Company } from '../app-router/component-props';
import SearchResults from './components/search-results';

export interface Data {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface SearchBarProps {
  user: Company;
}

export const SearchBar: React.FC<SearchBarProps> = ({ user }) => {
  const { name, setName, data } = useDebouncedApiCall<Data[]>(500, user.id);
  const [showResults, setShowResults] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setShowResults(false);
      setName(''); // Reset the search input value
      setIsInputVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (name.trim().length > 0 && data) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [name, data]);

  return (
    <SearchInputContainer ref={containerRef}>
      {isInputVisible && (
        <StyledInput
          placeholder="Enter Search"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      )}
      <IconWrapper onClick={() => setIsInputVisible(!isInputVisible)}>
        <SearchIcon />
      </IconWrapper>
      {showResults && <SearchResults data={data} user={user} />}
    </SearchInputContainer>
  );
};
