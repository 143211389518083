import React from 'react';
import {
  StyledReportingEmployeesColumn,
  StyledReportingEmployeesTableActionBtn,
  StyledReportingEmployeesTableActionsWrapper,
  StyledReportingEmployeesTableAndTitleMainWrapper,
  StyledReportingEmployeesTableColumnWrapper,
  StyledReportingEmployeesTableHeadersWrapper,
  StyledReportingEmployeesTableJobTitleCol,
  StyledReportingEmployeesTableMainWrapper,
  StyledReportingEmployeesTableTitleAndActionsWrapper,
  StyledReportingRenewalStatusCol,
} from './reporting-employees-table-styled';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import ReportingIcon from '../../../../../icons/reporting-employees-tab-icons';

export interface ReportingTableState<T> {
  headers: string[];
  data: T[];
}

interface ReportingEmployeesTableProps<T extends object> {
  tableData: ReportingTableState<T>;
  name: string;
}

const ReportingEmployeesTable = <T extends object>({
  tableData,
  name,
}: ReportingEmployeesTableProps<T>) => {
  const { headers, data } = tableData;
  //   return (
  //     <table>
  //       <thead>
  //         <tr>
  //           {generatedHeaders.map((header, index) => (
  //             <th key={index}>{String(header)}</th>
  //           ))}
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {data.map((row, rowIndex) => (
  //           <tr key={rowIndex}>
  //             {generatedHeaders.map((header, colIndex) => (
  //               <td key={colIndex}>{String(row[header])}</td>
  //             ))}
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   );

  return (
    <StyledReportingEmployeesTableAndTitleMainWrapper>
      <StyledReportingEmployeesTableTitleAndActionsWrapper>
        <Text
          fontSize={24}
          fontFamily={font.family}
          color={colors.base_black}
          fontWeight={700}
          margin="0"
        >
          {name}
        </Text>
        <StyledReportingEmployeesTableActionsWrapper>
          <StyledReportingEmployeesTableActionBtn>
            <ReportingIcon iconType="employeesTableActionSearch" />
          </StyledReportingEmployeesTableActionBtn>
          <StyledReportingEmployeesTableActionBtn>
            <ReportingIcon iconType="employeesTableActionFilter" />
          </StyledReportingEmployeesTableActionBtn>
          <StyledReportingEmployeesTableActionBtn>
            <ReportingIcon iconType="employeesTableActionDownload" />
          </StyledReportingEmployeesTableActionBtn>
        </StyledReportingEmployeesTableActionsWrapper>
      </StyledReportingEmployeesTableTitleAndActionsWrapper>
      <StyledReportingEmployeesTableMainWrapper>
        <StyledReportingEmployeesTableHeadersWrapper>
          {headers?.map((header, index) => (
            <StyledReportingEmployeesColumn key={index}>
              <Text
                fontSize={16}
                fontFamily={font.family}
                color={colors.base_black}
                fontWeight={700}
                margin="0"
              >
                {String(header)}
              </Text>
            </StyledReportingEmployeesColumn>
          ))}
        </StyledReportingEmployeesTableHeadersWrapper>
        {data.map((row, rowIndex) => (
          <StyledReportingEmployeesTableColumnWrapper key={rowIndex}>
            {Object.entries(row).map(([key, col], colIndex) => (
              <StyledReportingEmployeesColumn key={colIndex}>
                {key === 'jobTitle' ||
                key === 'totalDaysTaken' ||
                key === 'totalLeaveTaken' ? (
                  <StyledReportingEmployeesTableJobTitleCol>
                    <Text
                      fontSize={14}
                      fontFamily={font.family}
                      color={colors.base_black}
                      fontWeight={400}
                      margin="0"
                    >
                      {col}
                    </Text>
                  </StyledReportingEmployeesTableJobTitleCol>
                ) : key === 'renewalStatus' ? (
                  <StyledReportingRenewalStatusCol>
                    <ReportingIcon iconType="employeesTableSuccess" />
                    <Text
                      fontSize={14}
                      fontFamily={font.family}
                      color={colors.forest_green}
                      fontWeight={400}
                      margin="0"
                    >
                      {col}
                    </Text>
                  </StyledReportingRenewalStatusCol>
                ) : (
                  <Text
                    fontSize={14}
                    fontFamily={font.family}
                    color={colors.base_black}
                    fontWeight={400}
                    margin="0"
                  >
                    {col}
                  </Text>
                )}
              </StyledReportingEmployeesColumn>
            ))}
          </StyledReportingEmployeesTableColumnWrapper>
        ))}
      </StyledReportingEmployeesTableMainWrapper>
    </StyledReportingEmployeesTableAndTitleMainWrapper>
  );
};

export default ReportingEmployeesTable;
