import React from 'react';
import { Text, font } from '../../../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../../../style-utils/colors';
import {
  StyledJobPositionHeadingAddPositionBtn,
  StyledJobPositionHeadingMainWrapper,
} from './job-position-tab-heading.styled';
import { PlusButton } from '../../../../../../../../../icons/plus-icon';
import CreateNewEmployeeJobPosition from '../create-edit-delete-job-position/create-new-job-position';
import MessagePopup from '../../../../../../../../../messages/message-popup/message-popup';
import { DefaultComponentProps } from '../../../../../../../../../app-router/component-props';

interface JobPositionTabHeadingProps extends DefaultComponentProps {
  createNewJobPositionVisible: boolean;
  popUpVisible: boolean;
  closePopUp: () => void;
  popUpMessage: string;
  popUpType: 'success' | 'error';
  onOpenCreateNewJobPosition: () => void;
  onCloseCreateNewJobPosition: (success: boolean, message?: string) => void;
}

const JobPositionTabHeading: React.FC<JobPositionTabHeadingProps> = ({
  user,
  createNewJobPositionVisible,
  popUpVisible,
  closePopUp,
  popUpMessage,
  popUpType,
  onOpenCreateNewJobPosition,
  onCloseCreateNewJobPosition,
}) => {
  return (
    <StyledJobPositionHeadingMainWrapper>
      <Text
        margin="0"
        fontFamily={font.family}
        fontSize={24}
        fontWeight={700}
        color={colors.base_black}
      >
        Job position
      </Text>
      <StyledJobPositionHeadingAddPositionBtn
        onClick={onOpenCreateNewJobPosition}
      >
        <PlusButton />
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={14}
          fontWeight={700}
          color={colors.base_white}
        >
          Add new
        </Text>
      </StyledJobPositionHeadingAddPositionBtn>
      {createNewJobPositionVisible && (
        <CreateNewEmployeeJobPosition
          onClose={onCloseCreateNewJobPosition}
          user={user}
          closePopUp={closePopUp}
        />
      )}
      {popUpVisible && <MessagePopup text={popUpMessage} type={popUpType} />}
    </StyledJobPositionHeadingMainWrapper>
  );
};

export default JobPositionTabHeading;
