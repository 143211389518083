import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { apiRoute } from '../../../constants/api-constants';

export const TestPaypal = () => {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    if (clientId) {
      setClientId(clientId);
    } else {
      console.error(
        'PayPal Client ID is missing. Please set it in the environment variables.'
      );
    }
  }, []);

  //   eslint-disable-next-line
  const handleChange = (e: any) => {
    setAmount(e.target.value);
    console.log('Amount set to:', e.target.value);
  };

  const createOrder = async () => {
    setLoading(true);
    try {
      console.log('Sending request to:', `${apiRoute}/paypal-pay`);
      console.log('Amount being sent:', amount);
      const response = await axios.post(`${apiRoute}/paypal-pay`, {
        amount,
      });
      setLoading(false);

      console.log('Received order link:', response.data.forwardLink);

      return response.data.forwardLink;
    } catch (error) {
      console.error('There was an error creating the payment!', error);
      setLoading(false);
    }
  };

  if (!clientId) {
    return <p>PayPal Client ID is missing. Please check your configuration.</p>;
  }

  return (
    <PayPalScriptProvider options={{ clientId }}>
      <div>
        <h2>Pay with PayPal</h2>
        <input
          type="number"
          value={amount}
          onChange={handleChange}
          placeholder="Enter Amount"
        />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <PayPalButtons
            createOrder={async () => {
              const orderID = await createOrder();
              console.log('Order ID:', orderID);

              return orderID;
            }}
            onApprove={async (data, actions) => {
              if (actions && actions.order) {
                const details = await actions.order.capture();
                const payerName = details.payer?.name?.given_name || 'Customer';
                alert('Transaction completed by ' + payerName);
              }
            }}
          />
        )}
      </div>
    </PayPalScriptProvider>
  );
};
