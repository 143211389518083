import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../lp-images/logo_team.png';
import homeIcon from '../../lp-images/home.png';
import {
  GeneralStyle,
  HeaderContainer,
  Logo,
  Header,
  HomeButton,
  ContentContainer,
  MainHeading,
  SmallHeader,
  Paragraph,
  List,
  Item,
} from './privacy-policy.styled';

export const PrivacyPolicy: React.FC = () => {
  return (
    <GeneralStyle>
      <HeaderContainer>
        <Header>
          <Logo src={logo} alt="logo icon" />
          <Link to="/">
            <HomeButton src={homeIcon} alt="home icon" />
          </Link>
        </Header>
      </HeaderContainer>
      <ContentContainer>
        <MainHeading>Privacy Policy</MainHeading>
        <SmallHeader>Background:</SmallHeader>
        <Paragraph>
          We at WorWise understand that your privacy is important to you.
          Furthermore, we acknowledge that you care about how your personal data
          is used. We respect and value the privacy of everyone who visits our
          website mloyalty.com. Therefore, we will only collect and use personal
          data in ways that are described here, and in a way that is consistent
          with our obligations and your rights under the law.
        </Paragraph>
        <Paragraph>
          Please read this Privacy Policy carefully and ensure that you
          understand it. Your acceptance of this document must be confirmed if
          you are willing to use our websites services. You will be required to
          read and accept this Privacy Policy when signing up for an Account or
          when opting four our newsletter or eBook promotions. If you do not
          accept and agree with this Privacy Policy, you must stop using our
          website starting immediately.
        </Paragraph>

        <SmallHeader>About Us</SmallHeader>
        <Paragraph>
          Our website is owned and operated by Moxelle DOO, a limited company
          registered in North Macedonia.
        </Paragraph>
        <Paragraph>
          Registered address: Majakovski 44/1-5 1000 Skopje, Republic of North
          Macedonia
        </Paragraph>
        <Paragraph>VAT number : MK4080016558441.</Paragraph>
        <Paragraph>Data Protection Officer: Dejan Konevski</Paragraph>
        <Paragraph>
          Postal Address: Majakovski 44/1-5 1000 Skopje, Republic of North
          Macedonia
        </Paragraph>
        <SmallHeader>What Does This Policy Cover?</SmallHeader>
        <Paragraph>
          This Privacy Policy applies only to your use of our website, and only
          our website. Our website may contain links to other websites (e.g.
          advertisers). Please note that we have no control over how your data
          is collected, stored, or used by other websites and we advise you to
          check the privacy policies of any such websites before providing any
          data to them.
        </Paragraph>
        <SmallHeader>What is Personal Data?</SmallHeader>
        <Paragraph>
          Personal data is defined by the General Data Protection Regulation (EU
          Regulation 2016/679) (the GDPR) as any information relating to an
          identifiable person who can be directly or indirectly identified in
          particular by reference to an identifier. Personal data is any
          information about you that enables us to identify you. Personal data
          covers obvious information such as your name and contact details (like
          email). But, it also covers less obvious information, such as
          identification numbers, electronic location data, and other online
          identifiers.
        </Paragraph>
        <SmallHeader>What Are My Rights?</SmallHeader>
        <Paragraph>
          Under the GDPR, you have the following rights, which we will always
          uphold:
        </Paragraph>
        <List>
          <Item>
            The right to be informed about our collection and use of your
            personal data. This Privacy Policy should tell you everything you
            need to know. Additionally, you can always contact us to find out
            more or to ask any questions using the details in Part 15.
          </Item>
          <Item>
            The right to access any personal data we hold about you. You can
            find out how to do this in Part 13 of this Privacy Policy.
          </Item>
          <Item>
            The right to have your personal data rectified if any of your
            personal data held by us is inaccurate or incomplete. Please contact
            us using the details in Part 15 to find out more on how to do this.
          </Item>
          <Item>
            The right to be forgotten, i.e. the right to ask us to delete or
            otherwise dispose of any of your personal data that we have on you.
            Please contact us using the details in Part 15 to find out more.
          </Item>
          <Item>
            The right to restrict (i.e. prevent) the processing of your personal
            data.
          </Item>
          <Item>
            The right to object to us using your personal data for a particular
            purpose or purposes.
          </Item>
          <Item>
            The right to data portability. This means that, if you have provided
            personal data to us directly, we are using it with your consent or
            for the performance of a contract, and that data is processed using
            automated means. You can ask us for a copy of that personal data to
            re-use with another service or business in many cases.
          </Item>
          <Item>
            Rights relating to automated decision-making and profiling.
          </Item>
        </List>
        <Paragraph>
          For more information about our use of your personal data or exercising
          your rights as outlined above, please contact us using the details
          provided in Part 15.
        </Paragraph>
        <Paragraph>
          Further information about your rights can also be obtained from the
          Information Commissioners Office or your local Citizens Advice Bureau.
        </Paragraph>
        <Paragraph>
          If you have any cause for complaint about our use of your personal
          data, you have the right to lodge a complaint with the Information
          Commissioners Office.
        </Paragraph>
        <SmallHeader>What Data Do We Collect?</SmallHeader>
        <Paragraph>
          Depending upon your use of our website, we may collect some or all of
          the following personal and non-personal data (please also see Part 14
          on Our use of Cookies and similar technologies):
        </Paragraph>
        <List>
          <Item>Name;</Item>
          <Item>Date of birth;</Item>
          <Item>Gender;</Item>
          <Item>Address;</Item>
          <Item>Email address;</Item>
          <Item>Telephone number;</Item>
          <Item>Job title;</Item>
          <Item>Profession;</Item>
          <Item>Information about your preferences and interests;</Item>
          <Item>IP address;</Item>
          <Item>Web browser type and version;</Item>
          <Item>Operating system;</Item>
          <Item>
            A list of URLs starting with a referring site, your activity on our
            website, and the site you exit to;
          </Item>
        </List>
        <SmallHeader>How Do You Use My Personal Data?</SmallHeader>
        <Paragraph>
          Under the GDPR, we must always have a lawful reason for using your
          personal data. This may be because the data is necessary for our
          performance of a contract with you, because you have consented to our
          use of your personal data, or because it is in our legitimate business
          interests to use it. Your personal data may be used for the following
          purposes:
        </Paragraph>
        <List>
          <Item>Providing and managing your access to our website;</Item>
          <Item>
            Personalizing and improving your experience on our website;
          </Item>
          <Item>
            Supplying our products and/or services to you. Your personal details
            are required in order for us to enter into a contract with you.
          </Item>
          <Item>
            Personalizing and improving our products and services for you.
          </Item>
          <Item>
            Communicating with you. This may include responding to emails or
            calls from you.
          </Item>
          <Item>
            Supplying you with information by email and/or post that you have
            opted-in to (you may unsubscribe or opt-out at any time at the
            bottom of any email you received from us).
          </Item>
          <Item>
            Analyzing your use of our website with certified tools (like Google
            Analytics) and gathering feedback to enable us to continually
            improve our website and your user experience.
          </Item>
        </List>
        <Paragraph>
          With your permission and/or where permitted by law, we may also use
          your personal data for marketing purposes. Such marketing purpose may
          include contacting you by email and/or telephone and/or text message
          and/or post with information, news, and offers on our products and/or
          services. You will not be sent any unlawful marketing or spam. We will
          always work to fully protect your rights and comply with Our
          obligations under the GDPR and the Privacy and Electronic
          Communication (EC Directive) Regulations.
        </Paragraph>
        <Paragraph>
          Third Parties including Google, Facebook, LinkedIn, and Instagram,
          whose content appears on our website may use third-party Cookies, as
          detailed below in Part 14. Please refer to Part 14 for any additional
          information on controlling cookies. Please note that we do not control
          the activities of such third parties, nor the data that they collect
          and use themselves. Therefore, we advise you to check the privacy
          policies of any such third parties.
        </Paragraph>
        <SmallHeader>How Long Will You Keep My Personal Data?</SmallHeader>
        <Paragraph>
          We will not keep your personal data for any longer than is necessary
          for the light of the reason(s) for which it was first collected.
        </Paragraph>
        <SmallHeader>
          How and Where Do You Store or Transfer My Personal Data?
        </SmallHeader>
        <Paragraph>
          We may store or transfer some or all of your personal data in
          countries that are not part of the European Economic Area (the EEA
          consists of all EU member states, plus Norway, Iceland, and
          Liechtenstein). These are known as third countries and may not have
          data protection laws that are as strong as those in the EEA. This
          means that we will take additional steps in order to ensure that your
          personal data is treated just as safely and securely as it would be
          within the EEA and under the GDPR as follows.
        </Paragraph>
        <Paragraph>
          We share your data within the group of companies that we work with.
          Where this involves the transfer of personal data outside the EEA, We
          ensure that personal data is protected by requiring all companies
          within the group to follow the same rules with respect to personal
          data usage. These are known as binding corporate rules. More
          information on binding corporate rules is available from the European
          Commission.
        </Paragraph>
        <SmallHeader>Do You Share My Personal Data?</SmallHeader>
        <Paragraph>
          We may share personal data with other companies in that we cooperate
          with the goal of improving the services we offer to you. This includes
          present clients, as well as any future clients.
        </Paragraph>
        <Paragraph>
          If any of your personal data is required by a third party, as
          described above, we will take steps to ensure that your personal data
          is handled safely, securely, and in accordance with your rights, Our
          obligations, and the third partys obligations under the law, as
          described above in Part 9.
        </Paragraph>
        <Paragraph>
          If any personal data is transferred outside of the EEA, we will take
          suitable steps in order to ensure that your personal data is treated
          just as safely and securely as it would be within the EEA and under
          the GDPR, as explained above in Part 9.
        </Paragraph>
        <SmallHeader>How Can I Control My Personal Data?</SmallHeader>
        <Paragraph>
          In addition to your rights under the GDPR, set out in Part 5, when you
          submit personal data via our website, you may be given options to
          restrict Our use of your personal data. In particular, we aim to give
          you strong controls on Our use of your data for direct marketing
          purposes (including the ability to opt-out of receiving emails from us
          which you may do by unsubscribing using the links provided in Our
          emails)
        </Paragraph>
        <SmallHeader>Can I Withhold Information?</SmallHeader>
        <Paragraph>
          You may access certain areas of our website without providing any
          personal data at all. However, to use all features and functions
          available on our website you may be required to submit or allow for
          the collection of certain data.
        </Paragraph>
        <SmallHeader>How Can I Access My Personal Data?</SmallHeader>
        <Paragraph>
          If you want to know what personal data we have about you, you can ask
          us for details of that personal data and for a copy of it (where any
          such personal data is held). This is known as a subject access
          request.
        </Paragraph>
        <Paragraph>
          All subject access requests should be made in writing and sent to the
          postal addresses shown in Part 15. To make this as easy as possible
          for you, a Subject Access Request Form is available for you to use.
          You do not have to use this form, but it is the easiest way to tell us
          everything we need to know to respond to your request as quickly as
          possible.
        </Paragraph>
        <Paragraph>
          There is not normally any charge for a subject access request. If your
          request is manifestly unfounded or excessive (for example, if you make
          repetitive requests) a fee may be charged to cover Our administrative
          costs in responding. We will respond to your subject access request
          within less than one month and, in any case, not more than one month
          of receiving it. Normally, we aim to provide a complete response,
          including a copy of your personal data within that time. In some
          cases, however, particularly if your request is more complex, more
          time may be required up to a maximum of three months from the date we
          receive your request. You will be kept fully informed of Our progress.
        </Paragraph>
        <SmallHeader>How Do You Use Cookies?</SmallHeader>
        <Paragraph>
          Our website may place and access certain first-party (owned by us)
          Cookies on your computer, mobile phone, or tablet. First-party Cookies
          are those placed directly by us and are used only by us. We use these
          Cookies to facilitate and improve your experience on our website and
          to provide and improve our services. We have carefully chosen these
          Cookies and have taken steps to ensure that your privacy and personal
          data is protected and respected at all times.
        </Paragraph>
        <Paragraph>
          By using our website, you may also receive certain third-party Cookies
          on your computer, tablet, or mobile phone. Third-party Cookies are
          those placed by websites, services, and/or parties other than us.
          Third-party Cookies are used on our website for website analytics. For
          more details, please click Further Details. Non-essential Cookies are
          not integral to the functioning of our website and your use and
          experience of our website will not be impaired by refusing consent to
          them.
        </Paragraph>
        <Paragraph>
          All Cookies used by and on our website are used in accordance with
          current Cookie Law.
        </Paragraph>
        <Paragraph>
          Before Cookies are placed on your computer, mobile phone, or tablet,
          you will be shown the message By continuing to use the site, you agree
          to the use of our first-party cookies. Yes I agree or you can continue
          with limited features No I disagree requesting your consent to set
          those Cookies. By giving your consent to the placing of Cookies you
          are enabling us to provide the best possible experience and service to
          you.
        </Paragraph>
        <Paragraph>
          Certain features of our website depend on Cookies to function. Cookie
          Law deems these Cookies to be strictly necessary. These Cookies are
          defined as Essential Cookies. Your consent will not be sought to place
          these Cookies, but it still important that you are aware of them. You
          may still block these Cookies by changing your internet browser’s
          settings as detailed below, but please be aware that our website may
          not work properly if you do so. We have taken great care to ensure
          that your privacy is not at risk by allowing such cookies to be placed
          in your browser.
        </Paragraph>
        <Paragraph>
          Our website uses analytics services provided by Google Analytics,
          Facebook Analytics, Google Ads, and other analytics tools. Website
          analytics refers to a set of tools used to collect and analyze
          anonymous usage information, enabling us to better understand how our
          website is used. This enables us to improve our website and the
          services offered through it.
        </Paragraph>
        <Paragraph>
          The analytics service used by our website uses Cookies to gather the
          required information. You do not have to allow us to use these
          Cookies, however, while Our use of them does not pose any risk to your
          privacy or your safe use of our website, it does enable us to
          continually improve our website, making it a better and more useful
          experience for you.
        </Paragraph>
        <Paragraph>
          In addition to the controls that we provide, you can choose to enable
          or disable Cookies in your internet browser. Most internet browsers
          also enable you to choose whether you wish to disable all Cookies or
          only third-party Cookies. By default, most internet browsers accept
          Cookies, but this can be changed. For further details, please consult
          the help menu in your internet browser or the documentation that came
          with your device.
        </Paragraph>
        <Paragraph>
          You can choose to delete Cookies on your computer or device at any
          time, however, you may lose any information that enables you to access
          our website more quickly and efficiently including, but not limited
          to, login and personalization settings.
        </Paragraph>
        <Paragraph>
          It is recommended that you keep your internet browser and operating
          system up-to-date and that you consult the help and guidance provided
          by the developer of your internet browser and manufacturer of your
          computer or device if you are unsure about adjusting your privacy
          settings.
        </Paragraph>
        <SmallHeader>How Do I Contact You?</SmallHeader>
        <Paragraph>
          To contact us about anything to do with your personal data and data
          protection, including to make a subject access request use the
          following details.
        </Paragraph>
        <Paragraph>Email: worwise@moxelle.com</Paragraph>
        <Paragraph>Telephone number: + 1 302 3106570</Paragraph>
        <Paragraph>
          Postal Address: Majakovski 44/1-5 1000 Skopje, Republic of North
          Macedonia
        </Paragraph>
        <SmallHeader>Changes to this Privacy Policy</SmallHeader>
        <Paragraph>
          We may change this Privacy Notice from time to time. This may be
          necessary, for example, if the law changes, or if we change Our
          business in a way that affects personal data protection.
        </Paragraph>
        <Paragraph>
          Any changes will be immediately posted on our website and you will be
          deemed to have accepted the terms of the Privacy Policy on your first
          use of our website following the alterations. We recommend that you
          check this page regularly to keep up-to-date. The latest update of
          this Privacy Policy document was done on 2/04/2022
        </Paragraph>
      </ContentContainer>
    </GeneralStyle>
  );
};
