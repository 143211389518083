import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';
import { font } from '../../../../../../../../style-utils/text';

export const StyledGlobalJobBoardHeroTitleAndSearchMainWrapper = styled.div`
  box-sizing: border-box;
  width: 630px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 97, 160, 0.08) 0%,
    rgba(0, 161, 154, 0) 100%
  );
`;

export const StyledGlobalJobBoardHeroTitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledGlobalJobBoardHeroSearchWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 0;
  border-radius: 40px;
  overflow: hidden;
  background-color: ${colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledGlobalJobBoardHeroSearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 24px 24px 24px 60px;
  border-style: none;
  outline: none;
  font-family: ${font.familyMontserrat};

  ::placeholder {
    font-family: ${font.familyMontserrat};
    color: ${colors.grey_light};
    font-size: 16px;
    opacity: 1;
  }
`;

export const StyledGlobalJobBoardHeroSearchInputAndIconWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
`;

export const StyledGlobalJobBoardHeroSearchInputIconWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  left: 24px;
`;

export const StyledGlobalJobBoardHeroSearchInputSeparator = styled.div`
  box-sizing: border-box;
  height: 25px;
  width: 4px;
  background-color: ${colors.grey_light};
  opacity: 0.5;
  border-radius: 40px;
`;

export const StyledGlobalJobBoardHeroSearchBtn = styled.button`
  box-sizing: border-box;
  padding: 16px 40px;
  background-color: ${colors.secondary_500};
  border-style: none;
  border-radius: 40px;
  cursor: pointer;
`;
