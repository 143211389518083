import React from 'react';

export const GenderIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      viewBox="0 -960 960 960"
      width="20px"
      fill="none"
      stroke="#000" // Set the stroke color
      strokeWidth="30" // Adjust the stroke width if needed
    >
      <path d="M216-96v-288h-72v-192q0-29.7 21.15-50.85Q186.3-648 216-648h144q29.7 0 50.85 21.15Q432-605.7 432-576v192h-72v288H216Zm71.75-600q-34.75 0-59.25-24.75t-24.5-59.5q0-34.75 24.75-59.25t59.5-24.5q34.75 0 59.25 24.75t24.5 59.5q0 34.75-24.75 59.25t-59.5 24.5ZM600-96v-192H480l105-314q7-20 24.2-33t38.8-13q21.6 0 38.8 13 17.2 13 24.2 33l105 314H696v192h-96Zm47.75-600q-34.75 0-59.25-24.75t-24.5-59.5q0-34.75 24.75-59.25t59.5-24.5q34.75 0 59.25 24.75t24.5 59.5q0 34.75-24.75 59.25t-59.5 24.5Z" />
    </svg>
  );
};
