import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const StyledEmployeeOnboardingTabWMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledEditWrapper = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const StyledOnboardingCategoriesListMainWrapper = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledEmployeeChecklistMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledEmployeeChecklistName = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: ${colors.primary_50};
  border-radius: 4px;
`;

export const StyledEmployeeChecklistNameCircle = styled.div`
  box-sizing: border-box;
  background-color: ${colors.primary_500};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`;

export const StyledOptionsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

export const CheckboxWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

export const StyledOnboardingChecklistOptionSelectedStatusIcon = styled.div`
  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledEmployeeChecklistSubmitBtn = styled.button`
  box-sizing: border-box;
  background-color: ${colors.primary_500};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  border-style: none;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
`;
