import React from 'react';
import { PaginationArrowProps } from './pagination-left-arrow';

export const PaginationRightArrow: React.FC<PaginationArrowProps> = ({
  color,
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 6L15.5 12L9.5 18" stroke={color} />
    </svg>
  );
};
