import React from 'react';
export const OnboardingChecklistOptionNotSelected: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M12.6667 2.5H3.33333C2.59695 2.5 2 3.09695 2 3.83333V13.1667C2 13.903 2.59695 14.5 3.33333 14.5H12.6667C13.403 14.5 14 13.903 14 13.1667V3.83333C14 3.09695 13.403 2.5 12.6667 2.5Z"
        stroke="#5E5A57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
