import React from 'react';
import {
  QuestionBox,
  QuestionHeader,
  Text,
} from '../../create-survey/create-survey.styled';

interface Option {
  text: string;
  votes: number;
}

interface QuestionResultsProps {
  questionText: string;
  options: Option[];
}

export const QuestionResults: React.FC<QuestionResultsProps> = ({
  questionText,
  options,
}) => {
  const totalVotes = options.reduce((acc, option) => acc + option.votes, 0);

  return (
    <div style={{ marginTop: '16px' }}>
      <QuestionBox>
        <QuestionHeader style={{ padding: '15px' }}>
          <Text color="white">{questionText}</Text>
        </QuestionHeader>
        {options.map((option, index) => {
          const percentage = ((option.votes / totalVotes) * 100).toFixed(2);

          return (
            <div
              key={index}
              style={{
                margin: '15px',
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                width: '40%',
              }}
            >
              <span
                style={{
                  flex: '2',
                  background: 'white',
                  padding: '16px',
                  borderRadius: '8px',
                }}
              >
                {option.text}
              </span>
              <span
                style={{
                  background: '#2E77B8',
                  color: 'white',
                  padding: '16px',
                  borderRadius: '8px',
                }}
              >{`${percentage}%`}</span>
            </div>
          );
        })}
      </QuestionBox>
    </div>
  );
};
