import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import axios from 'axios';
import { Company } from '../../../../app-router/component-props';
import { apiRoute } from '../../../../../constants/api-constants';
import { EmployeesTimeoffDataTypes } from '../employees-timeoff-types';

interface TimeOffContextType {
  filteredData: EmployeesTimeoffDataTypes[];
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  user: Company;
  fetchData: (page?: number, status?: string) => void;
  searchByName: (name: string, page?: number, status?: string) => void;
  currentPage: number;
  totalPages: number;
  setPage: (page: number, status?: string) => void;
}

const TimeOffContext = createContext<TimeOffContextType | undefined>(undefined);

export const useTimeOff = () => {
  const context = useContext(TimeOffContext);
  if (!context) {
    throw new Error('useTimeOff must be used within a TimeOffProvider');
  }

  return context;
};

export const TimeOffProvider: React.FC<{
  children: ReactNode;
  user: Company;
}> = ({ children, user }) => {
  const [data, setData] = useState<EmployeesTimeoffDataTypes[]>([]);
  const [filteredData, setFilteredData] = useState<EmployeesTimeoffDataTypes[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [status, setStatus] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const baseURL = apiRoute;
  console.log(data);

  const fetchData = (page = 1, status = 'All') => {
    axios
      .get(`${baseURL}/all-timeoff-applications/${user.id}/${page}`, {
        params: { status },
      })
      .then((response) => {
        setData(response.data.data);
        setFilteredData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const searchByName = (name: string, page = 1, status = 'All') => {
    if (!name) {
      fetchData(page, status);

      return;
    }

    axios
      .get(`${baseURL}/search-employee-timeoff/${user.id}/${name}`, {
        params: { status, page },
      })
      .then((response) => {
        setFilteredData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.error('Error searching by name:', error);
      });
  };

  const setPage = (page: number, status = 'All') => {
    if (searchTerm) {
      searchByName(searchTerm, page, status);
    } else {
      fetchData(page, status);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.id]);

  useEffect(() => {
    if (searchTerm) {
      searchByName(searchTerm, 1, status);
    } else {
      fetchData(1, status);
    }
  }, [searchTerm, status]);

  const value: TimeOffContextType = {
    filteredData,
    setSearchTerm,
    setStatus,
    user,
    fetchData,
    searchByName,
    currentPage,
    totalPages,
    setPage,
  };

  return (
    <TimeOffContext.Provider value={value}>{children}</TimeOffContext.Provider>
  );
};
