import React from 'react';

export const TrendingUpIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 3L6.75 7.75L4.25 5.25L0.5 9"
        stroke="#FDFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3H11.5V6"
        stroke="#FDFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
