import styled from 'styled-components';
import { font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';

export const ToggleContainer = styled.div`
  display: flex;
  position: relative;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f3f4f6;
  width: 186px;
  height: 48px;
  cursor: pointer;
`;

export const ActiveIndicator = styled.div<{ isMonthly: boolean }>`
  position: absolute;
  top: 5%;
  left: ${({ isMonthly }) => (isMonthly ? '2%' : '49%')};
  width: 49%;
  height: 90%;
  background-color: ${colors.secondary_500};
  border-radius: 10px;
  transition: all 0.3s ease;
`;

export const ToggleOption = styled.div<{ active: boolean }>`
  z-index: 1;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: ${({ active }) => (active ? '700' : '500')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  user-select: none;
  font-family: ${font.family};
`;
