import React, { useEffect, useState } from 'react';
import {
  StyledGlobalJobBoardSubscribeInnerWrapper,
  StyledGlobalJobBoardSubscribeInput,
  StyledGlobalJobBoardSubscribeInputAndBtnWrapper,
  StyledGlobalJobBoardSubscribeMainWrapper,
  StyledGlobalJobBoardSubscribeSubmitBtn,
  StyledGlobalJobBoardSubscribeTitleWrapper,
} from './global-job-board-subscribe.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import axios from 'axios';
import MessagePopup from '../../../../../messages/message-popup/message-popup';
import { apiRoute } from '../../../../../../constants/api-constants';

const GlobalJobBoardSubscribe: React.FC = () => {
  const [email, setEmail] = useState('');
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const handleSubscribe = () => {
    if (!isValidEmail(email)) {
      setPopUp({
        text: 'Please enter a valid email address.',
        type: 'error',
      });

      return;
    }

    axios
      .post(`${apiRoute}/global-job-board/subscribe`, { email })
      .then((response) => {
        setPopUp({
          text: response.data.message || 'Successfully subscribed!',
          type: 'success',
        });
        setEmail('');
      })
      .catch((error) => {
        const errorMsg =
          error.response?.data?.message ||
          'An error occurred. Please try again.';
        setPopUp({
          text: errorMsg,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <StyledGlobalJobBoardSubscribeMainWrapper>
      <StyledGlobalJobBoardSubscribeInnerWrapper>
        <StyledGlobalJobBoardSubscribeTitleWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={24}
            fontWeight={600}
            lineHeight="29.26px"
            color={colors.base_white}
            margin="0"
          >
            Stay Ahead in Your Job Search!
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={42}
            fontWeight={600}
            lineHeight="51.2px"
            color={colors.neutral_800}
            margin="0"
          >
            Subscribe now for real-time
            <br /> job alerts and helpful tips straight
            <br /> to your inbox!
          </Text>
        </StyledGlobalJobBoardSubscribeTitleWrapper>
        <StyledGlobalJobBoardSubscribeInputAndBtnWrapper>
          <StyledGlobalJobBoardSubscribeInput
            type="text"
            placeholder="Enter your mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <StyledGlobalJobBoardSubscribeSubmitBtn
            type="submit"
            onClick={handleSubscribe}
          >
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={500}
              lineHeight="19.5px"
              color={colors.white}
              margin="0"
            >
              Subscribe
            </Text>
          </StyledGlobalJobBoardSubscribeSubmitBtn>
        </StyledGlobalJobBoardSubscribeInputAndBtnWrapper>
      </StyledGlobalJobBoardSubscribeInnerWrapper>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </StyledGlobalJobBoardSubscribeMainWrapper>
  );
};

export default GlobalJobBoardSubscribe;
