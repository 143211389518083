import React, { useEffect, useRef, useState } from 'react';
import { ReadMoreBtn } from '../pages/applicants/candidate-profile/candidate-profile-styled';

interface Props {
  text: React.ReactNode; // Change this back to ReactNode
}

export const ReadMore = ({ text }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const ref = useRef<HTMLParagraphElement>(null); // Specify type here

  useEffect(() => {
    if (ref.current) {
      setShowButton(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, [text]);

  return (
    <>
      <p
        ref={ref}
        style={{ maxHeight: isOpen ? 'none' : '3.5rem', overflow: 'hidden' }}
      >
        {text}
      </p>
      {showButton && (
        <ReadMoreBtn onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? 'read less...' : 'continue reading...'}
        </ReadMoreBtn>
      )}
    </>
  );
};
