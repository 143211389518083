import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';

export const DashboardInfoCardWrapper = styled.div`
  display: flex;
  flex: 1;
  max-height: 108px;
  padding-bottom: 32px;
  gap: 8px;
`;

export const PieChartWrapper = styled.div`
  background: white;
  display: flex;
  flex: 1;
  max-height: 246px;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
  gap: 72px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const ActiveJobOffersWrapper = styled.div`
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const CircleWrapper = styled.div`
  min-height: 200px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const JobOffersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  background: ${colors.base_white};
`;

export const NotificationContainer = styled.div<{
  margin?: string;
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 14px 24px;
  background: ${colors.primary_50};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : `${colors.primary_50}`};
  border-radius: 8px;
  ${({ margin }) => margin && `margin: ${margin}`}
`;

export const NotificationDescriptionDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const ToDoDescriptionDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

export const DueDateDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: ${colors.base_white};
  padding: 8px 16px;
`;

export const NoteDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: ${colors.base_white};
  padding: 8px 16px;
`;

export const JobOffersContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  background: ${colors.base_white};
`;

export const StyledGenderBalanceAndAvgEmployeeChartWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;
`;

export const StyledHomeChartWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;
