import React from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
// import { Text, font } from '../../../style-utils/text';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { SuperAdminSidebar } from '../../layout/sidebar/super-admin-sidebar';
import {
  StyledComponentsMainWrapper,
  StyledLastPaymentAndCompanyOwesWrapper,
  StyledLatestRegisteredAndTopRisingWrapper,
} from './home.styled';
import InfoCircles from './home-components/info-circles';
import CompaniesList from './home-components/companies-list';

interface SuperAdminHomeProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const SuperAdminHome: React.FC<SuperAdminHomeProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu
        navigationUrl={'/super-admin'}
        setUser={setUser}
        user={user}
        isSuperAdmin={user.account_type === 'superAdmin'}
      />
      <MainContentWrapper>
        <SuperAdminSidebar
          setUser={setUser}
          company={user}
          navigate={navigate}
        />
        <ContentWrapper>
          {/* <NavbarAndContentContainer>Homeeee</NavbarAndContentContainer> */}
          <StyledComponentsMainWrapper>
            <InfoCircles />
            <StyledLatestRegisteredAndTopRisingWrapper>
              <CompaniesList fromPage={'latest'} />
              <CompaniesList fromPage={'top'} />
            </StyledLatestRegisteredAndTopRisingWrapper>
            <StyledLastPaymentAndCompanyOwesWrapper>
              <CompaniesList fromPage={'lastPayment'} />
              <CompaniesList fromPage={'companyOwes'} />
            </StyledLastPaymentAndCompanyOwesWrapper>
          </StyledComponentsMainWrapper>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
