import React, { useEffect, useRef, useState } from 'react';
import {
  StyledCustomColorCircleAndArrowWrapper,
  StyledCustomColorHexInput,
  StyledCustomColorTitleAndColorWrapper,
  StyledCustomColorWrapper,
  StyledFirstColorCircle,
  StyledHexColorPickerWrapper,
  StyledSelectCustomColorsMainWrapper,
} from '../change-colors.styled';
import { DownArrow } from '../../../../../../../../icons/down-arrow';
import { Text, font } from '../../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../../style-utils/colors';
import { HexColorPicker } from 'react-colorful';

interface SelectCustomColorsProps {
  colorComponents: {
    label: string;
    color: string;
    name: string;
    hasError: boolean;
  }[];
  setColorComponents: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        color: string;
        name: string;
        hasError: boolean;
      }[]
    >
  >;
}

const SelectCustomColors: React.FC<SelectCustomColorsProps> = ({
  colorComponents,
  setColorComponents,
}) => {
  const [colorPickerVisible, setColorPickerVisible] = useState('');
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  // Validation function for hex colors
  const isValidHexColor = (color: string) => {
    const hexRegex = /^#[0-9A-Fa-f]{6}$/;

    return hexRegex.test(color);
  };

  // Handle input change
  const handleColorChange = (name: string, newColor: string) => {
    setColorComponents((prevColors) =>
      prevColors.map((colorComponent) =>
        colorComponent.name === name
          ? {
              ...colorComponent,
              color: newColor,
              hasError: !isValidHexColor(newColor),
            }
          : colorComponent
      )
    );
  };

  // Handle color picker visibility toggle
  const toggleColorPicker = (
    name: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (colorPickerVisible === name) return;
    setColorPickerVisible(name);
  };

  // Close color picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setColorPickerVisible('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRef]);

  return (
    <StyledSelectCustomColorsMainWrapper>
      {colorComponents.map((colorComponent, index) => (
        <StyledCustomColorTitleAndColorWrapper key={index}>
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={16}
            fontWeight={400}
            color={colors.base_black}
          >
            {colorComponent.label}
          </Text>
          <StyledCustomColorWrapper>
            <StyledCustomColorCircleAndArrowWrapper
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                toggleColorPicker(colorComponent.name, e)
              }
            >
              <StyledFirstColorCircle color={colorComponent.color} />
              <DownArrow />
            </StyledCustomColorCircleAndArrowWrapper>
            <StyledCustomColorHexInput
              name={colorComponent.name}
              value={colorComponent.color}
              onChange={(e) =>
                handleColorChange(colorComponent.name, e.target.value)
              }
              hasError={colorComponent.hasError}
            />
          </StyledCustomColorWrapper>
          {colorPickerVisible === colorComponent.name && (
            <StyledHexColorPickerWrapper ref={colorPickerRef}>
              <HexColorPicker
                color={colorComponent.color}
                onChange={(newColor) =>
                  handleColorChange(colorComponent.name, newColor)
                }
              />
            </StyledHexColorPickerWrapper>
          )}
          {colorComponent.hasError && (
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={12}
              fontWeight={700}
              color={colors.error}
            >
              Wrong HEX color code!
            </Text>
          )}
        </StyledCustomColorTitleAndColorWrapper>
      ))}
    </StyledSelectCustomColorsMainWrapper>
  );
};

export default SelectCustomColors;
