import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';
import { font } from '../../../../../style-utils/text';

export const StyledCompanyCVEditComponentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base_white};
  width: 900px;
  padding: 40px 40px 40px 40px;
  gap: 32px;
`;

export const StyledCompanyCVEditProfilePictureWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 64px;
  align-items: center;
`;

export const StyledCompanyCVEditProfilePicture = styled.img`
  box-sizing: border-box;
  height: 164px;
  width: 164px;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledUploadProfilePictureHiddenInput = styled.input`
  display: none;
`;

export const StyledCompanyCVEditProfilePictureButtonAndDesc = styled.div`
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: fit-content;
  font-family: ${font.family};
  font-size: 18px;
  font-weight: 700;
  color: ${colors.base_black};
`;

export const StyledCompanyCVEditRemoveProfilePicture = styled.button`
  box-sizing: border-box;
  width: fit-content;
  border-style: none;
  padding: 8px 24px;
  background-color: ${colors.primary_100};
  font-family: ${font.family};
  font-size: 16px;
  font-weight: 700;
  color: ${colors.base_black};
  border-radius: 8px;
  cursor: pointer;
`;

export const StyledCompanyCVEditEmployeeInfoSectionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledCompanyCVEditEmployeeInfoSectionGridWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 24px 16px;

  grid-template-rows: auto auto auto;

  & > :nth-child(1) {
    grid-column: 1 / -1;
  }
`;

export const StyledCompanyCVEditInputAndLabelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: ${font.family};
  font-size: 18px;
  font-weight: 700;
  color: ${colors.base_black};
`;

export const StyledCompanyCVEditInput = styled.input`
  box-sizing: border-box;
  border-style: none;
  background-color: ${colors.neutral_50};
  padding: 16px 40px 16px 16px;
  border-radius: 8px;
  width: 100%;
`;

export const StyledCompanyCVEditSelect = styled.select`
  box-sizing: border-box;
  border-style: none;
  background-color: ${colors.neutral_50};
  padding: 16px;
  border-radius: 8px;
`;

export const StyledComponentCVEditProfessionalSummaryTextArea = styled.textarea`
  box-sizing: border-box;
  display: flex;
  border-style: none;
  background-color: ${colors.neutral_50};
  padding: 16px;
  border-radius: 8px;
  min-height: 120px;
  resize: none;
`;

export const StyledExperienceItemsWrapper = styled.div<{ index: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: ${({ index }) => (index !== 0 ? '50px' : '0')};
`;

export const StyledComponentCVEditorGridTwoInputsWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const StyledCompanyCVEditResponsibilitiesInputAndBtnWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledCompanyCVEditAddNewResponsibilityButton = styled.button`
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: fit-content;
  gap: 4px;
  background-color: ${colors.primary_500};
  border-radius: 8px;
  border-style: none;
  font-family: ${font.family};
  font-size: 16px;
  font-weight: 700;
  color: ${colors.base_white};
  cursor: pointer;
`;

export const StyledCompanyCVEditResponsibilityAndDeleteBtn = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
`;

export const StyledCompanyCVEditResponsibilityDeleteBtnWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 14px;
  right: 12px;
`;

export const StyledCompanyCVEditAddNewButton = styled.button`
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: fit-content;
  gap: 4px;
  background-color: ${colors.secondary_300};
  border-radius: 8px;
  border-style: none;
  font-family: ${font.family};
  font-size: 16px;
  font-weight: 700;
  color: ${colors.base_white};
  cursor: pointer;
`;

export const StyledSkillsAndToolsWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: start;
`;

export const StyledSkillsAndToolsListWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

export const StyledSkillAndLToolItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${colors.creme_200};
  padding: 8px 16px;
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 400;
  color: ${colors.base_black};
  border-radius: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
`;

export const StyledSkillAndToolDeleteIconBtn = styled.button`
  box-sizing: border-box;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledExperienceTitleAndRemoveBtnWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
`;
