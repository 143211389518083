import React from 'react';
interface DeleteIconProps {
  fromPage?: string;
  color?: string;
  width?: number | string; // Allow both number and string for flexibility
  height?: number | string;
}

export const DeleteIcon: React.FC<DeleteIconProps> = ({
  fromPage,
  color,
  width,
  height,
}) => {
  const defaultWidth = fromPage === 'onboarding' ? '16px' : '24px';
  const defaultHeight = fromPage === 'onboarding' ? '16px' : '25px';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || defaultWidth}
      height={height || defaultHeight}
      viewBox="0 0 24 25"
      fill={color || 'none'}
    >
      <path
        d="M21 4.5H8L1 12.5L8 20.5H21C21.5304 20.5 22.0391 20.2893 22.4142 19.9142C22.7893 19.5391 23 19.0304 23 18.5V6.5C23 5.96957 22.7893 5.46086 22.4142 5.08579C22.0391 4.71071 21.5304 4.5 21 4.5V4.5Z"
        stroke={color || 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9.5L12 15.5"
        stroke={color || 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.5L18 15.5"
        stroke={color || 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
