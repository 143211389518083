import React from 'react';
import {
  StyledGlobalJobBoardHeroInnerWrapper,
  StyledGlobalJobBoardHeroMainWrapper,
} from './global-job-board-hero.styled';
import GlobalJobBoardHeroLeftComponent from './components/hero-left-component/global-job-board-hero-left-component';
import GlobalJobBoardHeroRightComponent from './components/hero-right-component/global-job-board-hero-right-component';

const GlobalJobBoardHero: React.FC = () => {
  return (
    <StyledGlobalJobBoardHeroMainWrapper>
      <StyledGlobalJobBoardHeroInnerWrapper>
        <GlobalJobBoardHeroLeftComponent />
        <GlobalJobBoardHeroRightComponent />
      </StyledGlobalJobBoardHeroInnerWrapper>
    </StyledGlobalJobBoardHeroMainWrapper>
  );
};

export default GlobalJobBoardHero;
