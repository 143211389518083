import React from 'react';
import {
  Circle,
  InnerCircle,
  ViewsText,
  RotatingWrapper,
  PieChartWrapper,
} from './pie-chart.styled';

interface DynamicCircleProps {
  number: number;
  string: string;
  light: number;
  dark: number;
}

const DynamicCircle: React.FC<DynamicCircleProps> = ({
  number,
  string,
  light,
  dark,
}) => {
  const lightblueDegrees = (light / 100) * 360;
  const darkblueDegrees = (dark / 100) * 360;

  return (
    <PieChartWrapper>
      <RotatingWrapper>
        <Circle light={lightblueDegrees} dark={darkblueDegrees} />
      </RotatingWrapper>
      <InnerCircle />
      <ViewsText>
        <div>{number}</div>
        <div>{string}</div>
      </ViewsText>
    </PieChartWrapper>
  );
};

export default DynamicCircle;
