import React, { useEffect, useState } from 'react';
import {
  DateWrapper,
  FormWrapper,
  Heading,
  Input,
  InputWrapper,
  SurveyFormMainWrapper,
  Text,
  TextArea,
  SmallerText,
  QuestionsWrapper,
  QuestionBox,
  QuestionHeader,
  IconsWrapper,
  QuestionContent,
  DropDown,
  DropDownWrapper,
  Wrapper,
  OptionsBtn,
  OptionWrapper,
  SaveBtn,
  NewQuestionWrapper,
  InputType,
  DeleteIconWrapper,
  StartBtn,
  ButtonWrapper,
} from '../create-survey.styled';
import { DeleteIconWhite } from '../../../../icons/delete-icon-white';
import { DeleteIcon } from '../../../../icons/delete-icon';
import { PlusIcon } from '../../../../icons/plus-icon-small';
import { DownArrow } from '../../../../icons/down-arrow';
import { SurveyPublishModal } from './survey-publish-modal/survey-publish-modal';
import { useSurvey } from '../hooks/use-create-survey';
import { Question } from '../hooks/survey-types';
import MessagePopup from '../../../../messages/message-popup/message-popup';
import { SelectDate } from '../../../applicants/candidates/date-picker';
import { colors } from '../../../../../style-utils/colors';

interface SurveyFormProps {
  companyId: string;
}

export const SurveyForm: React.FC<SurveyFormProps> = ({ companyId }) => {
  const [surveyModalVisible, setSurveyModalVisible] = useState<boolean>(false);
  const {
    survey,
    addOptionToQuestion,
    deleteOptionFromQuestion,
    addQuestion,
    updateQuestionType,
    removeQuestion,
    updateQuestionText,
    updateOptionText,
    saveSurveyAsDraft,
    updateSurveyDetails,
  } = useSurvey();

  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <SurveyFormMainWrapper>
      <Heading>Create Survey</Heading>
      <FormWrapper>
        {/* Survey Details */}
        <InputWrapper>
          <Text>Survey name</Text>
          <Input
            placeholder="Enter text here"
            value={survey.name}
            onChange={(e) => updateSurveyDetails({ name: e.target.value })}
          />
        </InputWrapper>
        <InputWrapper>
          <Text>Description</Text>
          <TextArea
            placeholder="Enter text here"
            value={survey.description}
            onChange={(e) =>
              updateSurveyDetails({ description: e.target.value })
            }
          />
        </InputWrapper>

        {/* Survey Date */}
        <InputWrapper>
          <Text>Survey Date</Text>
          <DateWrapper>
            <InputWrapper gap="4px">
              <SmallerText>Start date</SmallerText>
              <SelectDate
                style={{
                  width: '90%',
                  backgroundColor: colors.neutral_50,
                  borderStyle: 'none',
                  padding: '7px',
                  borderRadius: '8px',
                }}
                value={survey.start_date ? new Date(survey.start_date) : null}
                onChange={(date) =>
                  updateSurveyDetails({
                    start_date: date ? date.toISOString() : undefined,
                  })
                }
                onClean={() =>
                  updateSurveyDetails({
                    start_date: '',
                  })
                }
              />
            </InputWrapper>
            <InputWrapper gap="4px">
              <SmallerText>End date</SmallerText>
              <SelectDate
                style={{
                  width: '90%',
                  backgroundColor: colors.neutral_50,
                  borderStyle: 'none',
                  padding: '7px',
                  borderRadius: '8px',
                }}
                value={survey.end_date ? new Date(survey.end_date) : null}
                onChange={(date) =>
                  updateSurveyDetails({
                    end_date: date ? date.toISOString() : undefined,
                  })
                }
                onClean={() =>
                  updateSurveyDetails({
                    end_date: '',
                  })
                }
              />
            </InputWrapper>
          </DateWrapper>
          <DateWrapper>
            <InputWrapper gap="4px">
              <SmallerText>Start time</SmallerText>
              <Input
                type="time"
                width="70%"
                textTransform="uppercase"
                value={survey.start_time}
                onChange={(e) =>
                  updateSurveyDetails({ start_time: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper gap="4px">
              <SmallerText>End time</SmallerText>
              <Input
                type="time"
                width="70%"
                textTransform="uppercase"
                value={survey.end_time}
                onChange={(e) =>
                  updateSurveyDetails({ end_time: e.target.value })
                }
              />
            </InputWrapper>
          </DateWrapper>
        </InputWrapper>

        {/* Create Questions */}
        <InputWrapper>
          <Text>Create Questions</Text>
          <QuestionsWrapper>
            {survey.questions.map((question, questionIndex) => (
              <QuestionBox key={questionIndex}>
                <QuestionHeader>
                  <Text color="white">Question {questionIndex + 1}</Text>
                  <IconsWrapper>
                    {/* <div style={{ cursor: 'pointer' }}>
                      <EditIconWhite width={16} />
                    </div> */}
                    <div
                      onClick={() => removeQuestion(questionIndex)}
                      style={{ cursor: 'pointer' }}
                    >
                      <DeleteIconWhite width={16} />
                    </div>
                  </IconsWrapper>
                </QuestionHeader>
                <QuestionContent>
                  <Wrapper>
                    <Input
                      placeholder="Question name"
                      width="75%"
                      background="white"
                      value={question.text}
                      onChange={(e) => {
                        updateQuestionText(questionIndex, e.target.value);
                      }}
                    />

                    {/* Dropdown */}
                    <DropDownWrapper>
                      <DropDown
                        value={question.type}
                        onChange={(e) =>
                          updateQuestionType(
                            questionIndex,
                            e.target.value as Question['type']
                          )
                        }
                      >
                        <option value="0" disabled>
                          Question type
                        </option>
                        <option value="text">Text response</option>
                        <option value="single-choice">Single choice</option>
                        <option value="multiple-choice">Multiple choice</option>
                      </DropDown>
                      <DeleteIconWrapper>
                        <DownArrow />
                      </DeleteIconWrapper>
                    </DropDownWrapper>
                  </Wrapper>
                  <InputWrapper width="70%">
                    <Wrapper>
                      <SmallerText>Options:</SmallerText>
                      {question.type !== 'text' && (
                        <OptionsBtn
                          onClick={() => addOptionToQuestion(questionIndex, '')}
                        >
                          + Add Options
                        </OptionsBtn>
                      )}
                    </Wrapper>

                    {/* Conditionally rendering inputs */}
                    {question.type === 'text' && (
                      <Input
                        placeholder="Enter your response"
                        width="94%"
                        background="white"
                        value={question.options[0]}
                        onChange={(e) => {
                          updateOptionText(questionIndex, 0, e.target.value);
                        }}
                      />
                    )}

                    {question.type === 'single-choice' && (
                      <div>
                        {question.options.map((option, optionIndex) => (
                          <OptionWrapper key={optionIndex}>
                            <InputType
                              type="radio"
                              name={`singleChoice_${questionIndex}`}
                              checked={false}
                            />
                            <Input
                              placeholder={`Option ${optionIndex + 1}`}
                              width="94%"
                              background="white"
                              paddingLeft="25px"
                              value={option}
                              onChange={(e) => {
                                updateOptionText(
                                  questionIndex,
                                  optionIndex,
                                  e.target.value
                                );
                              }}
                            />
                            <DeleteIconWrapper
                              onClick={() =>
                                deleteOptionFromQuestion(
                                  questionIndex,
                                  optionIndex
                                )
                              }
                            >
                              <DeleteIcon width={16} />
                            </DeleteIconWrapper>
                          </OptionWrapper>
                        ))}
                      </div>
                    )}

                    {question.type === 'multiple-choice' && (
                      <div>
                        {question.options.map((option, optionIndex) => (
                          <OptionWrapper key={optionIndex}>
                            <InputType type="checkbox" checked={false} />
                            <Input
                              placeholder={`Option ${optionIndex + 1}`}
                              width="94%"
                              background="white"
                              paddingLeft="25px"
                              value={option}
                              onChange={(e) => {
                                updateOptionText(
                                  questionIndex,
                                  optionIndex,
                                  e.target.value
                                );
                              }}
                            />
                            <DeleteIconWrapper
                              onClick={() =>
                                deleteOptionFromQuestion(
                                  questionIndex,
                                  optionIndex
                                )
                              }
                            >
                              <DeleteIcon width={16} />
                            </DeleteIconWrapper>
                          </OptionWrapper>
                        ))}
                      </div>
                    )}
                  </InputWrapper>
                </QuestionContent>
              </QuestionBox>
            ))}
          </QuestionsWrapper>

          <NewQuestionWrapper
            onClick={() => {
              addQuestion({ text: '', type: 'single-choice', options: [''] });
            }}
          >
            <PlusIcon />
            Add Question
          </NewQuestionWrapper>
        </InputWrapper>
      </FormWrapper>
      <ButtonWrapper>
        {surveyModalVisible && (
          <SurveyPublishModal onClose={() => setSurveyModalVisible(false)} />
        )}
        {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
        <SaveBtn
          onClick={() => {
            saveSurveyAsDraft(companyId, (message) => setPopUp(message));
          }}
        >
          Save Draft
        </SaveBtn>
        <StartBtn onClick={() => setSurveyModalVisible(true)}>
          Start Survey
        </StartBtn>
      </ButtonWrapper>
    </SurveyFormMainWrapper>
  );
};
