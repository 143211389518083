import React, { useState } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { colors } from '../../../../style-utils/colors';
import { GenerateWithAi } from './steps/generate-with-ai/generate-with-ai';
import { JobOfferProvider } from './hooks/global-job-offer-provider';
import { AdditionalInformations } from './steps/additional-informations/additional-informations';
import { AboutCompany } from './steps/about-company/about-company';
import { NavigateFunction } from 'react-router-dom';

interface CreateJobOfferFlowProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const CreateJobOfferFlow: React.FC<CreateJobOfferFlowProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();

  const [activeLinkId, setActiveLinkId] = useState<number>(0);
  setActiveLinkId;

  const handleNextStep = () => {
    setActiveLinkId((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setActiveLinkId((prev) => prev - 1);
  };

  const steps = [
    <GenerateWithAi user={user} key={0} handleNextStep={handleNextStep} />,
    <AdditionalInformations
      key={1}
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
    />,
    <AboutCompany key={2} user={user} handlePrevStep={handlePrevStep} />,
  ];

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper dashboard={true}>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer
              dashboard={true}
              bgColor={colors.creme_100}
            >
              <JobOfferProvider>{steps[activeLinkId]}</JobOfferProvider>
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
