import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const StyledEmployeesErrorGeneratingCompanyCvPopupMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledEmployeesErrorGeneratingCompanyCvPopupModalWrapper = styled.div`
  box-sizing: border-box;
  width: 670px;
  max-height: 90vh;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  padding: 32px;
`;

export const StyledEmployeesErrorGeneratingCompanyCvPopupModalCreateCVBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  background-color: ${colors.primary_500};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px 40px;
  margin-top: 24px;
  border-style: none;
  cursor: pointer;
`;

export const StyledEmployeesErrorGeneratingCompanyCvPopupModalCloseBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  padding: 0;
  margin: 0 0 0 auto;
  cursor: pointer;
`;
