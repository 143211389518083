import React from 'react';
export const PlusIconOnboarding: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 3.33325V12.6666"
        stroke="#F6F7F7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 8H12.6666"
        stroke="#FDFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
