import React, { createContext, useState, ReactNode } from 'react';
import { Survey, Question, CreateSurveyContextType } from './survey-types';
import axios from 'axios';
import { apiRoute } from '../../../../../constants/api-constants';

const initialSurveyState: Survey = {
  name: '',
  description: '',
  start_date: '',
  end_date: '',
  start_time: '',
  end_time: '',
  questions: [
    {
      text: '',
      type: 'single-choice',
      options: [''],
    },
    {
      text: '',
      type: 'text',
      options: [''],
    },
    {
      text: '',
      type: 'multiple-choice',
      options: [''],
    },
  ],
};

export const CreateSurveyContext = createContext<
  CreateSurveyContextType | undefined
>(undefined);

interface SurveyProviderProps {
  children: ReactNode;
}

export const SurveyProvider: React.FC<SurveyProviderProps> = ({ children }) => {
  const [survey, setSurvey] = useState<Survey>(initialSurveyState);

  const updateSurveyDetails = (updatedDetails: Partial<Survey>) => {
    setSurvey((prevSurvey) => ({ ...prevSurvey, ...updatedDetails }));
  };

  const addQuestion = (question: Question) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      questions: [...prevSurvey.questions, question],
    }));
  };

  const updateQuestion = (index: number, updatedQuestion: Question) => {
    setSurvey((prevSurvey) => {
      const updatedQuestions = [...prevSurvey.questions];
      updatedQuestions[index] = updatedQuestion;

      return { ...prevSurvey, questions: updatedQuestions };
    });
  };

  const removeQuestion = (index: number) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      questions: prevSurvey.questions.filter((_, i) => i !== index),
    }));
  };

  const addOptionToQuestion = (questionIndex: number, newOption: string) => {
    setSurvey((prevSurvey) => {
      const updatedQuestions = [...prevSurvey.questions];
      const question = updatedQuestions[questionIndex];
      if (
        question.type === 'single-choice' ||
        question.type === 'multiple-choice'
      ) {
        question.options = [...question.options, newOption];
      }

      return { ...prevSurvey, questions: updatedQuestions };
    });
  };

  const deleteOptionFromQuestion = (
    questionIndex: number,
    optionIndex: number
  ) => {
    setSurvey((prevSurvey) => {
      const updatedQuestions = [...prevSurvey.questions];
      const question = updatedQuestions[questionIndex];
      if (
        question.type === 'single-choice' ||
        question.type === 'multiple-choice'
      ) {
        question.options = question.options.filter((_, i) => i !== optionIndex);
      }

      return { ...prevSurvey, questions: updatedQuestions };
    });
  };

  const updateOptionText = (
    questionIndex: number,
    optionIndex: number,
    newText: string
  ) => {
    setSurvey((prevSurvey) => {
      const updatedQuestions = [...prevSurvey.questions];
      const question = updatedQuestions[questionIndex];
      if (question.options.length === 0) {
        question.options.push(newText);
      } else {
        question.options[optionIndex] = newText;
      }

      return { ...prevSurvey, questions: updatedQuestions };
    });
  };

  const updateQuestionType = (
    questionIndex: number,
    newType: Question['type']
  ) => {
    setSurvey((prevSurvey) => {
      const updatedQuestions = [...prevSurvey.questions];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        type: newType,
      };

      return { ...prevSurvey, questions: updatedQuestions };
    });
  };

  const updateQuestionText = (index: number, newText: string) => {
    setSurvey((prevSurvey) => {
      const updatedQuestions = [...prevSurvey.questions];
      updatedQuestions[index] = { ...updatedQuestions[index], text: newText };

      return { ...prevSurvey, questions: updatedQuestions };
    });
  };

  const saveSurveyAsDraft = (
    companyId: string,
    setPopup: (message: { text: string; type: 'success' | 'error' }) => void
  ) => {
    if (!survey.name) {
      setPopup({
        text: 'Survey name is required.',
        type: 'error',
      });

      return;
    }

    if (!companyId) {
      setPopup({
        text: 'Company ID is missing.',
        type: 'error',
      });

      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (survey.start_date && new Date(survey.start_date) < today) {
      setPopup({
        text: 'Start date cannot be before today.',
        type: 'error',
      });

      return;
    }

    if (!survey.start_date && survey.end_date) {
      setPopup({
        text: 'End date cannot be set without a start date.',
        type: 'error',
      });

      return;
    }

    if (survey.start_date && !survey.end_date) {
      setPopup({
        text: 'Start date cannot be set without an end date.',
        type: 'error',
      });

      return;
    }

    if (
      survey.start_date &&
      survey.end_date &&
      new Date(survey.start_date) >= new Date(survey.end_date)
    ) {
      setPopup({
        text: 'Start date must be before end date.',
        type: 'error',
      });

      return;
    }

    const surveyData = {
      ...survey,
      company_id: companyId,
    };

    axios
      .post(`${apiRoute}/create-survey-with-questions`, surveyData)
      .then((response) => {
        console.log('Survey submitted successfully:', response.data);
        setPopup({ text: 'Survey saved successfully!', type: 'success' });
      })
      .catch((error) => {
        console.error('Error submitting survey:', error);
        setPopup({ text: 'Failed to save survey.', type: 'error' });
      });
  };

  const value: CreateSurveyContextType = {
    survey,
    updateSurveyDetails,
    addQuestion,
    updateQuestion,
    removeQuestion,
    addOptionToQuestion,
    deleteOptionFromQuestion,
    updateOptionText,
    updateQuestionType,
    updateQuestionText,
    saveSurveyAsDraft,
  };

  return (
    <CreateSurveyContext.Provider value={value}>
      {children}
    </CreateSurveyContext.Provider>
  );
};
