import React from 'react';
import {
  StyledReportingComponentMainWrapper,
  StyledReportingTabWrapper,
} from '../../../tabs.styled';
import { useReportingEmployeesData } from '../../use-reporting-employees-data';
import ReportingBarChart from '../../../../components/charts/bar-chart/bar-chart';
import { EmployeePerformanceTab } from '../../../../../employees/employee-perfromance-tab/employee-performance-tab';

const ReportingEmployeePerformaceOverview: React.FC = () => {
  const { barChartData } = useReportingEmployeesData();

  return (
    <StyledReportingTabWrapper>
      <ReportingBarChart name="Average employee" barChartData={barChartData} />
      <StyledReportingComponentMainWrapper>
        <EmployeePerformanceTab hideEdit />
      </StyledReportingComponentMainWrapper>
    </StyledReportingTabWrapper>
  );
};

export default ReportingEmployeePerformaceOverview;
