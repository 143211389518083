import { useState, useEffect } from 'react';
import axios from 'axios';
import { JobListing } from '../../../../../components/featured-jobs/global-job-board-featured-jobs-types';
import { apiRoute } from '../../../../../../../../constants/api-constants';

interface JobListingsResponse {
  jobListings: JobListing[];
  totalJobCount: number;
  message?: string;
}

const useGlobalJobBoardJustLandedData = () => {
  const [data, setData] = useState<JobListing[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get<JobListingsResponse>(`${apiRoute}/global-job-board/job-listings`, {
        params: { limit: 3, sort: 'date_added_desc' },
      })
      .then((response) => {
        setData(response.data.jobListings);
        setError(null);
      })
      .catch((error) => {
        console.error('Error fetching job listings:', error);
        setError('Failed to fetch job listings.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { data, isLoading, error };
};

export default useGlobalJobBoardJustLandedData;
