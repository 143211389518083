import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledEmployeesTimeoffFilter = styled.select`
  box-sizing: border-box;
  height: 48px;
  border: 2px solid ${colors.primary_500};
  border-radius: 8px;
  padding: 14px 16px;
  background-color: ${colors.base_white};
`;
