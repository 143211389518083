import React, { createContext, useState, ReactNode, useContext } from 'react';
import { JobOfferData } from '../interfaces/job-interfaces';

interface SelectedJobContextType {
  setJob: (job: JobOfferData | null) => void;
  selectedJob: JobOfferData | null;
}

export const SelectedJobContext = createContext<
  SelectedJobContextType | undefined
>(undefined);

export const useSelectedJob = (): SelectedJobContextType => {
  const context = useContext(SelectedJobContext);

  if (!context) {
    throw new Error('useSelectedJob must be used within a SelectedJobProvider');
  }

  return context;
};

interface SelectedJobProviderProps {
  children: ReactNode;
}

export const SelectedJobProvider = ({ children }: SelectedJobProviderProps) => {
  const [selectedJob, setJob] = useState<JobOfferData | null>(null);

  // eslint-disable-next-line
  const updateSelectedJob = (job: JobOfferData | null) => {
    setJob(job);
  };

  const selectedJobContextValue: SelectedJobContextType = {
    setJob: updateSelectedJob,
    selectedJob,
  };

  return (
    <SelectedJobContext.Provider value={selectedJobContextValue}>
      {children}
    </SelectedJobContext.Provider>
  );
};
