import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../function-utils/log-out';
import { loadSubscriptionPlans } from '../../../function-utils/subscription-plan-utils';
import { SubscriptionPlan } from '../super-admin/subscription-plans/interface';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { loadStripe } from '@stripe/stripe-js';
import { LogInFormContainer, LogInFormWrapper } from '../../login/login.styled';
import {
  // SubTitle,
  // Title,
  Wrapper,
  SubscriptionPlansAndToggleButtonsWrapper,
  SubscriptionPlansTextWrapper,
  ToggleButtonsWrapper,
  Button,
  ButtonsWrapper,
} from './change-plan.styled';
import { Toggle } from './toggle/toggle';
import { PaymentPlansTable } from './payment-plans-table/payment-plans-table';
// import { Xicon } from '../../icons/x-skill';
import { SignUpData } from '../../hooks/use-auth-forms';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';

interface ChangePlanSettingsProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser?: (user: any | null) => void;
  refetchUser?: () => Promise<void>;
  handlePlanSelection?: (plan: string) => void;
  setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData: SignUpData;
}

export const ChangePlanSettings: React.FC<ChangePlanSettingsProps> = ({
  user,
  setUser,
  refetchUser,
  handlePlanSelection,
  setSignUpData,
  signUpData,
}) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [selectedPlanAmount, setSelectedPlanAmount] = useState<number | null>(
    null
  );

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plans = await loadSubscriptionPlans();
        if (isMounted) {
          setPlans(plans);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching subscription plans:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const fetchUser = async () => {
      // setUser(null);
      if (refetchUser) await refetchUser();
    };

    fetchPlans();
    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectedPlan = (planId: string, amount: number) => {
    setSelectedPlanId(planId);
    setSelectedPlanAmount(amount);
    handlePlanSelection && handlePlanSelection(planId);
    console.log(`Selected Plan ID: ${planId}, Amount: ${amount}`);
  };

  const handleUpgrade = async () => {
    if (selectedPlanId && selectedPlanAmount) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiRoute}/create-checkout-session`,
          {
            planId: selectedPlanId,
            customerEmail: user?.email,
          }
        );

        const { checkoutSessionId } = response.data;

        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
        );

        if (!stripe) {
          console.error('Stripe failed to initialize.');
          setLoading(false);

          return;
        }

        const stripeResult = await stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        });

        if (stripeResult.error) {
          console.error(
            'Error redirecting to Stripe checkout:',
            stripeResult.error.message
          );
        } else {
          navigate('/payment-success');
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.error(
          'Error during plan upgrade:',
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Please select a plan before upgrading.');
    }
  };

  const handleLogout = async () => {
    if (setUser) {
      await logOut(setUser, navigate);
    }
  };

  const [isMonthly, setIsMonthly] = useState(true);

  const handleToggle = (toggleState: boolean) => {
    setIsMonthly(toggleState);
  };

  const defaultSelectedPlanId = user?.subscription_plan_id;

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <LogInFormWrapper id="LoginFormWrapper">
      <LogInFormContainer padding={'0'} maxWidth="929px" overflow="none">
        <Wrapper padding="40px" isSignUp={true} key={user?.id}>
          {/* <Title>
            <Xicon />
            Your Trial Has Expired
          </Title>
          <SubTitle>
            To continue using our services, please upgrade your plan.
          </SubTitle> */}

          <SubscriptionPlansAndToggleButtonsWrapper isSignUp={false}>
            <SubscriptionPlansTextWrapper>
              Subscription Plans
            </SubscriptionPlansTextWrapper>
            <ToggleButtonsWrapper>
              <Toggle isMonthly={isMonthly} handleToggle={handleToggle} />
            </ToggleButtonsWrapper>
          </SubscriptionPlansAndToggleButtonsWrapper>
          <PaymentPlansTable
            user={user}
            isSignUp={true}
            setSignUpData={setSignUpData}
            signUpData={signUpData}
            plans={plans}
            setSelectedPlan={setSelectedPlan}
            defaultSelectedPlanId={defaultSelectedPlanId || null}
            isMonthly={isMonthly}
          />
          <ButtonsWrapper>
            <Button onClick={handleLogout}>Logout</Button>
            <Button
              onClick={handleUpgrade}
              disabled={
                selectedPlanId && selectedPlanId?.length > 0 ? false : true
              }
            >
              {loading ? 'Loading' : 'Upgrade Plan'}
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </LogInFormContainer>
    </LogInFormWrapper>
  );
};
