import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';

export const StyledGlobalJobBoardJustLandedListingMainWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor || colors.ghostWhite};
  cursor: pointer;
`;

export const StyledGlobalJobBoardJustLandedListingLogoAndCompanyInfo = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledGlobalJobBoardJustLandedListingLogo = styled.img`
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: contain;
`;

export const StyledGlobalJobBoardJustLandedListingCompanyInfo = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledGlobalJobBoardJustLandedListingJobDetails = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledGlobalJobBoardJobListingInformationsSeparator = styled.div`
  box-sizing: border-box;
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  line-height: '17.07px';
  color: ${colors.neutral_800};
`;
