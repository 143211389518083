import styled, { keyframes } from 'styled-components';
import { colors } from '../../../style-utils/colors';

// Keyframes for filling the circle
const fillAnimation = (percentage: number, fillColor: string) => keyframes`
  0% {
    background: conic-gradient(${fillColor} 0deg, ${colors.neutral_50} 0deg);
  }
  100% {
    background: conic-gradient(${fillColor} 0deg, ${fillColor} ${
  percentage * 360
}deg, ${colors.neutral_50} ${percentage * 360}deg);
  }
`;

const rotateDuringFill = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledComponentsMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* max-width: 1032px; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 18px;
`;

export const StyledInfoCirclesMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 32px 48px;
  gap: 8px;
  justify-content: space-around;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const StyledInfoCircleWrapper = styled.div<{
  backgroundColor?: string;
  percentage: number;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) =>
      `conic-gradient(${props.backgroundColor || colors.neutral_50} 0deg, ${
        colors.neutral_50
      } 0deg)`};
    animation: ${(props) =>
          fillAnimation(
            props.percentage,
            props.backgroundColor || colors.neutral_50
          )}
        0.5 ease-in-out forwards,
      ${rotateDuringFill} 2s ease-in-out forwards;
    z-index: 0;
  }
`;

export const StyledInfoInnerCircle = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${colors.white};
  z-index: 1;
  position: absolute;
`;

export const StyledLatestRegisteredAndTopRisingWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1.9fr 1.1fr;
  gap: 16px;
`;

export const StyledTopCompaniesComponentMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const StyledTopCompanyComponentWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 16px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.neutral_50};
  border-radius: 8px;
`;

export const StyledCompanyComponentImageAndNameWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;

export const StyledCompanyComponentDateWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 60px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.secondary_400};
`;

export const StyledCompanyComponentImage = styled.img`
  box-sizing: border-box;
  max-height: 24px;
`;

export const StyledLastPaymentAndCompanyOwesWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;
