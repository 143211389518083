import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';

export const StyledInterviewConfirmationMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  margin: 0;
  padding: 0;
  background-color: ${colors.creme_200};
`;

export const StyledInterviewConfirmationIconAndMessageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
