import styled from 'styled-components';

export const SearchInputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  border: none;
  width: 340px;
  align-self: center;
`;

export const StyledInput = styled.input<{
  borderColor?: string;
}>`
  box-sizing: border-box;
  flex: 1;
  border: none;
  outline: none;
  height: 48px;
  padding-left: 16px;
  border-radius: 8px;
  border: 2px solid
    ${({ borderColor }) => borderColor || 'var(--Primary-500, #e94e3c)'};
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  pointer-events: none;
`;
