import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledGlobalJobBoardJobsMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 140px 32px 140px;
  background-color: ${colors.alabaster};
`;

export const StyledGlobalJobBoardJobsInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  gap: 40px;
`;
