import React from 'react';
import {
  ToggleContainer,
  ActiveIndicator,
  ToggleOption,
} from './toggle.styled';

interface ToggleProps {
  isMonthly: boolean;
  handleToggle: (toggleState: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = ({ isMonthly, handleToggle }) => {
  return (
    <ToggleContainer>
      <ActiveIndicator isMonthly={isMonthly} />
      <ToggleOption active={isMonthly} onClick={() => handleToggle(true)}>
        Monthly
      </ToggleOption>
      <ToggleOption active={!isMonthly} onClick={() => handleToggle(false)}>
        Yearly
      </ToggleOption>
    </ToggleContainer>
  );
};
