import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const MainContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  background: ${colors.creme_100};
  height: calc(100vh - 88px);
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 32px;
  background: none;
`;

export const LayoutContainer = styled.div<{ height: number }>`
  display: flex;
  max-height: ${({ height }) => height}px;
  flex-direction: column;
  width: 100%;
`;

export const TitleAndSubtitle = styled.div<{ direction: 'row' | 'column' }>`
  height: 66px;
  padding-bottom: 5%;
  display: flex;
  flex-direction: ${({ direction }) => direction};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ContentButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  flex: 2;
`;

export const NotificationTypeSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid ${colors.primary_400};
  border-radius: 5px;
  font-family: ${font.family};
  margin-bottom: 20px;
`;

export const NotificationMessageInput = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid ${colors.primary_400};
  border-radius: 5px;
  font-family: ${font.family};
`;

export const CompaniesSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid ${colors.primary_400};
  border-radius: 5px;
  font-family: ${font.family};
  margin-bottom: 20px;
  height: 150px;
  overflow-y: auto;
`;
