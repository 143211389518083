export interface ReportingTabDataTypes {
  id: number;
  title: string;
  description: string;
  count: number | string;
  trending: string;
  icon: string;
}

export const reportingMainTabData = [
  {
    id: 1,
    title: 'Employees',
    description: 'Total Employees',
    count: 50,
    trending: 'up', // "up" or "down"
    icon: 'mainTabEmployees',
  },
  {
    id: 2,
    title: 'Contractors',
    description: 'Total Contractors',
    count: 10,
    trending: 'up',
    icon: 'mainTabContractors',
  },
  {
    id: 3,
    title: 'Time Off/Vacations',
    description: 'Total time',
    count: '5%',
    trending: 'up',
    icon: 'mainTabVacations',
  },
  {
    id: 4,
    title: 'Salaries',
    description: 'Average Salary',
    count: '4%',
    trending: 'down',
    icon: 'mainTabSalaries',
  },
];

export const employeesTabData = [
  {
    id: 0,
    title: 'Employee Distribution',
    description: 'Total Employees',
    count: 50,
    trending: 'up', // "up" or "down"
    icon: 'mainTabEmployees',
  },
  {
    id: 1,
    title: 'Turnover',
    description: 'Turnover rate',
    count: '10%',
    trending: 'up',
    icon: 'employeeTabTurnover',
  },
  {
    id: 2,
    title: 'Performace Overview',
    description: '',
    count: '',
    trending: '',
    icon: 'employeeTabPerformanceOverview',
  },
];

export const reportingTabData = [
  {
    id: 0,
    title: 'Contractor Utilization',
    description: 'Total Contractors',
    count: 50,
    trending: 'up', // "up" or "down"
    icon: 'reportingTabContractUtilization',
  },
  {
    id: 1,
    title: 'Contract Renewal',
    description: 'Turnover renewal',
    count: '10%',
    trending: 'up',
    icon: 'reportingTabContractRenewal',
  },
];

export const timeOffTabData = [
  {
    id: 0,
    title: 'Time Off Usage',
    description: 'Total time',
    count: 50,
    trending: 'up', // "up" or "down"
    icon: 'mainTabVacations',
  },
  {
    id: 1,
    title: 'Leave Balance',
    description: 'Leave taken',
    count: '10%',
    trending: 'up',
    icon: 'employeeTabTurnover',
  },
  {
    id: 2,
    title: 'Historical Leave Data',
    description: '',
    count: '',
    trending: '',
    icon: 'employeeTabPerformanceOverview',
  },
];

export const salariesTabData = [
  {
    id: 0,
    title: 'Payroll Overview',
    description: '',
    count: '',
    trending: '', // "up" or "down"
    icon: 'salariesTabPayrollOverview',
  },
  {
    id: 1,
    title: 'Salary Trends',
    description: '',
    count: '',
    trending: '',
    icon: 'salariesTabSalaryTrend',
  },
  {
    id: 2,
    title: 'Compensation Comparation',
    description: '',
    count: '',
    trending: '',
    icon: 'salariesTabCompensationComparation',
  },
];
